import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AppQna } from "graphql/query/select";
import { Link, useHistory } from "react-router-dom";
import { FormatDate } from "components/FormatDate";
import { Form, Button, Modal } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_APP_QNA } from "graphql/gql/delete";
import { UPDATE_APP_QNA, SEND_USER_FCM } from "graphql/gql/update";
import { SimpleNotification } from "components/SimpleNotification";
import { getImagePath, hasValue, LoadingSpinner } from "components/common";
import useSettings from "stores/settings";

const DetailPage = ({ match }) => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const { id } = match.params;
  if (!hasValue(id)) return <div>비정상적인 접근입니다.</div>
  
  const items = AppQna(id).result;

  if (!items) return <LoadingSpinner />
  return (
    <div>
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">고객센터</li>
            <Link to="/service-center/app-user-qna-table" className="breadcrumb-item active mt-1" aria-current="page">
              의견보내기
            </Link>
            <Link to={`/service-center/app-user-qna-detail/${id}`} className="breadcrumb-item active mt-1" aria-current="page">
              의견내용
            </Link>
          </ol>
        </nav>
      </div>
      <div className="row">
        <InfoForm items={items} />
      </div>
    </div>
  );
};

const InfoForm = ({ items }) => {
  const settings = useSettings();
  const [reply, setReply] = useState(items.reply);
  const [comment, setComment] = useState(items.comment);

  // 답변 수정
  const { id } = items;

  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  //#region 데이터 저장
  const [qnaUpdate, { data: updateData }] = useMutation(UPDATE_APP_QNA, {
    onError(err) {
      console.log(err);
      SimpleNotification({ type:'danger', message: err.message || "Error" });
    },
  });

  useEffect(() => {
    if (updateData?.updateAppQna?.id) {
      SimpleNotification({ type:'success', message: '성공적으로 답변이 작성되었습니다.'});

      fetch(settings.pushUrl, {
        method: 'POST',
        body: JSON.stringify({
          topic: 'P',
          title: '앱 문의 답변',
          contents: reply === "0" ? '앱 문의 답변이 작성되었습니다.' : '앱 문의 답변이 수정되었습니다.',
          uids: [items.user.id],
        }),
        headers:{
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(res => {
        setReply("1");
        if (res?.res_code === 0) {
          return SimpleNotification({ title: 'success', type: 'success', message: '성공적으로 푸시메시지가 발송되었습니다.'});
        }
        else {
          return SimpleNotification({ title: 'failed', type: 'danger', message: '푸시메시지 발송에 실패하였습니다.'});
        }
      })
    }
  }, [updateData]);

  const handleUpdate = () => {
    qnaUpdate({
      variables: {
        id,
        comment,
        reply: '1',
      },
    });
  };
  //#endregion

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <form className="form-sample">
              <div className="mt-2 border-gray-200">
                <div className="lex justify-between card-title-wrap">
                  <h4 className="card-title">의견 내용</h4>
                </div>
                <div className="card-wrap">
                  <table className="app-table bg-white w-full">
                    <tr>
                      <td className="th">문의 유형</td>
                      <td colSpan="3">
                        {items.commonCode.name}
                      </td>
                    </tr>
                    <tr className="m-0 p-0">
                      <td className="th w-2/12">등록자</td>
                      <td className="w-4/12">{items.user.name}</td>
                      <td className="th w-2/12">등록 일시</td>
                      <td className="w-4/12">{FormatDate(new Date(parseInt(items.createAt, 10)))}</td>
                    </tr>
                    <tr>
                      <td className="th">제목</td>
                      <td colSpan="3">
                        {items.title}
                      </td>
                    </tr>
                    <tr>
                      <td className="th">첨부파일</td>
                      <td colSpan="3">
                        <div className="attach-wrap">
                          {
                            items.fileInfos.length > 0 ? (
                              items.fileInfos.map(info => (
                                <a href={getImagePath(info)} className="attach-item" download aria-current="page">
                                  {info.name}
                                </a>
                              ))
                            ) : ''
                          }
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="th" colSpan="4">문의 내용</td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        {items.content}
                      </td>
                    </tr> 
                    <tr>
                      <td className="th" colSpan="4">답변 내용</td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <Form.Control
                          value={comment}
                          as="textarea" 
                          rows={7}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </td>
                    </tr> 
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-12 btnbar">
        <div className="flex justify-end">
          {/* <button className="btn btn-danger" onClick={() => setMdShow(true)}>
            삭제하기
          </button> */}
          <button className="btn btn-primary" onClick={handleUpdate}>
            저장하기
          </button>
        </div>
        {/* Modal Starts  */}
        {/* <Modal show={mdShow} onHide={() => setMdShow(false)} aria-labelledby="example-modal-sizes-title-md">
          <Modal.Header closeButton>
            <Modal.Title>해당 내용을 삭제하시겠습니까?</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>삭제를 원하시면 삭제 버튼을 눌러주세요.</p>
          </Modal.Body>

          <Modal.Footer className="fleex-wrap">
            <Button variant="danger m-2" onClick={handleDelete}>
              삭제
            </Button>
            <Button variant="primary m-2" onClick={() => setMdShow(false)}>
              취소
            </Button>
          </Modal.Footer>
        </Modal> */}
        {/* Modal Ends */}
      </div>
    </>
  );
};

DetailPage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DetailPage;
