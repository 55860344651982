import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import useSettings from "stores/settings";
import jwtDecode from "jwt-decode";
import { observer } from "mobx-react-lite";
import "react-notifications-component/dist/theme.css";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import Footer from "./shared/Footer";
import "assets/main.css";
import "assets/scss/style.scss";

// 파일업로드 css
import "assets/filebox.css";

const App = observer(() => {
  const tokenCheck = window.localStorage.getItem("token");

  // 로그인 체크
  const settings = useSettings();
  
  if (tokenCheck) {
    const tokenDecode = jwtDecode(tokenCheck);
    const current_time = Date.now() / 1000;
    if (tokenDecode.exp < current_time) {
      /* expired */
      settings.isLogin = false;
      settings.pixelroId = "";
      settings.email = "";
      settings.token = "";
      window.localStorage.setItem("token", "");
    } else {
      settings.isLogin = true;
      settings.pixelroId = tokenDecode.user.id;
      settings.email = tokenDecode.user.email;
      settings.token = tokenCheck;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      {settings.isLogin ? (
        <div className="body-wrapper">
          <Sidebar />
          <ReactNotification />
          <div className="page-body-wrapper ">
            <Navbar />
            <div className="main-panel">
              <div className="relative">
                <div className="content-wrapper">
                  <AppRoutes />
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        <div className="relative">
          <AppRoutes />
        </div>
      )}
    </>
  );
});

export default withRouter(App);
