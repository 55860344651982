/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

// 공지사항 & FAQ 삭제
export const DELETE_SERVICE_CENTER = gql`
  mutation($id: String!) {
    deleteServiceCenter(where: { id: $id }) {
      id
    }
  }
`;

// QNA 삭제
export const DELETE_QNA = gql`
  mutation($id: String!) {
    deleteQna(where: { id: $id }) {
      id
    }
  }
`;

// APP QNA 삭제
export const DELETE_APP_QNA = gql`
  mutation($id: String!) {
    deleteAppQna(where: { id: $id }) {
      id
    }
  }
`;

// 게시판 - 매거진 & 이벤트 삭제
export const DELETE_NOTICE_BOARD = gql`
  mutation($id: String!) {
    deleteNoticeBoard(where: { id: $id }) {
      id
    }
  }
`;

// 배너 삭제
export const DELETE_BANNER = gql`
  mutation($id: String!) {
    deleteBanner(where: { id: $id }) {
      id
    }
  }
`;

// 회원사 삭제
export const DELETE_MEMBER = gql`
  mutation($id: String!) {
    deleteMember(where: { id: $id }) {
      id
    }
  }
`;

// 상품 삭제
export const DELETE_PRODUCT = gql`
  mutation($id: String!) {
    deleteProduct(where: { id: $id }) {
      id
    }
  }
`;
// 신청 상품 삭제
export const DELETE_PRODUCT_REGISTER = gql`
  mutation($id: String!) {
    deleteProductRegister(where: { id: $id }) {
      id
    }
  }
`;

// 상품 업로드시 임시저장한 폴더에서 파일 삭제
export const REMOVE_PRODUCT_TEMP_FILE = gql`
  mutation {
    deleteTempFile {
      result
      error
    }
  }
`;

// 쿠폰 삭제
export const DELETE_COUPON = gql`
  mutation($id: String!) {
    deleteCoupon(where: { id: $id }) {
      id
    }
  }
`;
// 관리자 삭제
export const DELETE_PIXELRO = gql`
  mutation($id: String!) {
    deletePixelro(where: { id: $id }) {
      id
    }
  }
`;

export const DELETE_COMPANY_INFO_FOR_PARTNER = gql`
  mutation($wheres: [CompanyInfoWhereUniqueInput]) {
    deleteCompanyInfoForPartner(wheres: $wheres) {
      result
    }
  }
`

export const DELETE_EXERCISE = gql`
  mutation($group: String!, $type: String!, $priority: Int!) {
    deleteExercise(where: { group : $group, type: $type, priority: $priority }) {
      result
    }
  }
`

export const DELETE_EXERCISE_TYPE1 = gql`
  mutation($group: String!) {
    deleteExerciseType1(where: { group: $group }) {
      result
    }
  }
`

export const DELETE_EXERCISE_TYPE2 = gql`
  mutation($group: String!) {
    deleteExerciseType2(where: { group: $group }) {
      result
    }
  }
`

export const DELETE_EXERCISE_TYPE3 = gql`
  mutation($group: String!) {
    deleteExerciseType3(where: { group: $group }) {
      result
    }
  }
`

export const DELETE_EXERCISE_TYPE4 = gql`
  mutation($group: String!) {
    deleteExerciseType4(where: { group: $group }) {
      result
    }
  }
`

export const DELETE_EXERCISE_TYPE5 = gql`
  mutation($group: String!) {
    deleteExerciseType5(where: { group: $group }) {
      result
    }
  }
`

export const DELETE_EXERCISE_TYPE6 = gql`
  mutation($group: String!) {
    deleteExerciseType6(where: { group: $group }) {
      result
    }
  }
`

export const DELETE_EXERCISE_SUB = gql`
  mutation($group: String!) {
    deleteExerciseSub(where: { group: $group }) {
      result
    }
  }
`