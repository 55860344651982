import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import { AllCompanyInfos } from "graphql/query/select.js";
import { FormatDateShort } from "components/FormatDate";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMove from 'array-move';
import { SimpleNotification } from "components/SimpleNotification";
import { UPDATE_COMPANY_INFO_PRIORITY } from "graphql/gql/update.js";
import { getImagePathForObject, LoadingSpinner } from "components/common.js"
import useSettings from "stores/settings";

const TablePage = () => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const items = AllCompanyInfos({ where: { type: "C", lang: "ko" }, order : { orderByKey:"priority", orderByValue:"asc" }}).result;
  if (!items) return <LoadingSpinner />;

  return (
    <>
      <div>
        <div>
          <nav aria-label="breadcrumb" role="navigation">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">회사정보 관리</li>
              <Link
                to="/company/parter-table"
                className="breadcrumb-item active mt-1"
                aria-current="page"
              >
                협력사 목록
              </Link>
            </ol>
          </nav>
        </div>
        <InfoForm items={items} />
      </div>
    </>
  );
};

const InfoForm = ({ items }) => {
  const [users, setUsers] = useState(items);
  const type = "C";

  const onSortEnd = ({oldIndex, newIndex}) => {
    if (oldIndex === newIndex) return;

    const targetWhere = { group: users[oldIndex].group };
    setUsers(arrayMove(users, oldIndex, newIndex));

    let where = {};
    let data = {};
    const newPriority = newIndex + 1; 
    const oldPriority = oldIndex + 1; 
    const targetData = { priority: newPriority };
    
    if (oldPriority > newPriority) {
      where = { type, priority: { gte: newPriority, lt: oldPriority }};
      data = { priority: { increment: 1 }};
    }
    else {
      where = { type, priority: { gt: oldPriority, lte: newPriority }};
      data = { priority: { decrement: 1 }};
    }
    companyInfoPriorityUpdate({ variables: { where, data, targetWhere, targetData }});
  };

  const [companyInfoPriorityUpdate, { data: updateData }] = useMutation(UPDATE_COMPANY_INFO_PRIORITY, {
    onError(err) {
      SimpleNotification({
        message: err.message || "Error",
      });
    }
  });

  useEffect(() => {
    if (updateData && updateData.updateCompanyInfoPriority && updateData.updateCompanyInfoPriority.result != "ok") {
      alert(`노출순서 변경에 실패하였습니다.`);
      window.location.reload();
    }
  }, [updateData]);
  
  if (!users) return <div/>
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between card-title-wrap">
              <h4 className="card-title">협력사 목록</h4>
              <Link className="btn btn-primary" to={{ pathname: "/company/partner-create" }}>
                등록하기
              </Link>
            </div>
            <div className="card-wrap">
              <div className="overflow-hidden">
                <table className="app-table w-full">
                  <thead>
                    <tr>
                      <td className="th">로고 이미지</td>
                      <td className="th w-3/12">업체명</td>
                      <td className="th">담당자</td>
                      <td className="th">등록날짜</td>
                      <td className="th" />
                    </tr>
                  </thead>
                  <SortableList onSortEnd={onSortEnd} items={users} helperClass="sorting-row" useDragHandle />
                </table>
                <Page />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DragHandle = SortableHandle(() => <span>::</span>);
const SortableItem = SortableElement(({ item }) => {

  const imagePath = getImagePathForObject(item);

  if (!item) return ""
  return (
    <tr>
      <td>
        <img src={imagePath} alt="" className="mgz-thumb" />
      </td>
      <td> <DragHandle /> {item.name}</td>
      <td>{item.person}</td>
      <td>{FormatDateShort(new Date(parseInt(item.createAt, 10)))}</td>
      <td>
        <Link
          className="ti-pencil-alt"
          to={`/company/partner-detail/${item.group}`}
        >
          상세보기
        </Link>
      </td>
    </tr>
  );
});

const SortableList = SortableContainer(({ items, handleDeleteButton }) => {
  return (
    <tbody className="bg-white">
      {items.map((item, index) => {
        return (
          <SortableItem
            key={`item-${item.id}`}
            index={index}
            sortIndex={index}
            item={item}
            handleDeleteButton={handleDeleteButton}
          />
        )
      })}
    </tbody>
  );
});

//다른 곳에서 쓰는 페이지 그대로 쓰면 됨
const Page = () => {
  return (
    <div className="bg-white flex items-center justify-between board-pagination">
      <div className="hidden sm:block">
        <p className="text-sm leading-5 text-gray-700">
          <span className="font-medium mx-1">1</span>
          {" ~  "}
          <span className="font-medium mx-1">10</span>
          {" / 총 "}
          <span className="font-medium mx-1">20</span>
          {" 건"}
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end"></div>
    </div>
  );
};

export default TablePage;
