/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import useSettings from 'stores/settings';
import { FormatDate } from 'components/FormatDate';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { SimpleNotification } from 'components/SimpleNotification';
import {
	ALL_APP_QNAS_TABLE,
	APP_QNA_COUNT_MUTATION,
	ALL_COMMON_CODES,
	ALL_APP_QNAS
} from 'graphql/gql/select';
import { hasValue, LoadingSpinner, handleKeyPress } from 'components/common';
import ReactTable from 'react-table';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import matchSorter from 'match-sorter';

const Tables = () => {
	// 로그인 체크
	const settings = useSettings();
	const history = useHistory();
	if (!settings.isLogin) {
		history.push('/adminIn');
	}

	const [result, setResult] = useState();

	const { loading, error, data } = useQuery(ALL_APP_QNAS, {
		fetchPolicy: 'network-only',
		variables: {
			orderBy: { createAt: 'desc' }
		}
	});

	useEffect(() => {
		if (data?.allAppQnas) {
			setResult(data.allAppQnas);
		}
	}, [data]);

	if (error) {
		console.log(error);
		SimpleNotification({ type: 'danger', message: error.message || 'Error' });
	}

	if (loading || !result) return <LoadingSpinner />;

	return (
		<>
			<div>
				<div>
					<nav aria-label="breadcrumb" role="navigation">
						<ol className="breadcrumb">
							<li className="breadcrumb-item active">고객센터</li>
							<Link
								to="/service-center/app-user-qna-table"
								className="breadcrumb-item active mt-1"
								aria-current="page"
							>
								의견보내기 목록
							</Link>
						</ol>
					</nav>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="flex justify-between card-title-wrap">
									<h4 className="card-title">의견보내기 목록</h4>
								</div>
								<ReactTable
									data={result}
									className="card-wrap"
									defaultPageSize={10}
									filterable
									columns={[
										{
											id: 'name',
											Header: '구분',
											accessor: d => d.commonCode?.name,
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, {
													keys: [item => item.name]
												}),
											filterAll: true
										},
										{
											id: 'title',
											Header: '제목',
											accessor: d => d.title,
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, {
													keys: [item => item.title]
												}),
											filterAll: true
										},
										{
											id: 'createAt',
											Header: '등록날짜',
											accessor: 'createAt',
											filterable: false,
											Cell: row =>
												`${FormatDate(new Date(parseInt(row.value, 10)))}`
										},
										{
											id: 'reply',
											Header: '상태',
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, {
													keys: [item => item.reply]
												}),
											filterAll: true,

											accessor: d => (d.reply === '0' ? '답변대기' : '답변완료')
										},
										{
											id: 'detail',
											Header: '',
											accessor: 'detail',
											filterable: false,
											Cell: row => (
												<Link
													className="ti-pencil-alt"
													to={`/service-center/app-user-qna-detail/${row.original.id}`}
												>
													상세보기
												</Link>
											)
										}
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Tables;
