/* eslint-disable import/prefer-default-export */
import { store } from "react-notifications-component";

export const SimpleNotification = ({ title, message, type="info" }) => {
  store.addNotification({
    title,
    message,
    type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: false,
    },
  });
};
