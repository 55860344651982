/* eslint-disable import/no-named-as-default */
import React, { Component, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "./shared/Spinner";

import Dashboard from "./dashboard/Dashboard";

//  ------------------ 추가
// <<<<<<<<<<< 홈 >>>>>>>>>>>>
import PixelroIn from "./login/PixelroIn";

// <<<<<<<<<<< 앱멤버관리 >>>>>>>>>>>>
// 앱멤버관리 - 멤버목록
import UserTable from "./app-users/users/UserTable";
import UserDetail from "./app-users/users/UserDetail";
import UserEdit from "./app-users/users/UserEdit";
// 앱멤버관리 - 전문가 목록
import ExpertTable from "./app-users/expert/ExpertTable";
import ExpertDetail from "./app-users/expert/ExpertDetail";
import ExpertEdit from "./app-users/expert/ExpertEdit";
import ExpertCreate from "./app-users/expert/ExpertCreate";

//앱멤버 관리 - 푸시 메시지 발송
import NewAppPushMessage from "./app-users/appPushMessage/NewAppPushMessage";

//앱멤버 관리 - 검색 히스토리
import SearchHistoryTable from "./app-users/searchHistory/SearchHistoryTable";

// <<<<<<<<<<< 게시판관리 >>>>>>>>>>>>
// 게시판관리 - 매거진
import MagazineTable from "./notice-board/magazine/MagazineTable";
import MagazineDetail from "./notice-board/magazine/MagazineDetail";
import MagazineEdit from "./notice-board/magazine/MagazineEdit";
import MagazineCreate from "./notice-board/magazine/MagazineCreate";

// 게시판관리 - 이벤트
import Event from './notice-board/event/Event';

// <<<<<<<<<<< 눈운동관리 >>>>>>>>>>>>
import EyeMovementTable from "./eye-movement/EyeMovementTable";
import EyeMovementCreate from "./eye-movement/EyeMovementCreate";
import EyeMovementDetail from "./eye-movement/EyeMovementDetail";
import EyeMovementUpdate from "./eye-movement/EyeMovementUpdate";

// <<<<<<<<<<< 고객센터 >>>>>>>>>>>>
// 고객센터 - 공지사항
import NoticeTable from "./service-center/notice/NoticeTable";
import NoticeDetail from "./service-center/notice/NoticeDetail";
import NoticeEdit from "./service-center/notice/NoticeEdit";
import NoticeCreate from "./service-center/notice/NoticeCreate";

// 고객센터 - 멤버FAQ
import AppUserFAQTable from "./service-center/app-user-faq/AppUserFAQTable";
import AppUserFAQDetail from "./service-center/app-user-faq/AppUserFAQDetail";
import AppUserFAQEdit from "./service-center/app-user-faq/AppUserFAQEdit";
import AppUserFAQCreate from "./service-center/app-user-faq/AppUserFAQCreate";

// 고객센터 - 멤버QNA
import AppUserQnaTable from "./service-center/app-user-qna/AppUserQnaTable";
import AppUserQnaDetail from "./service-center/app-user-qna/AppUserQnaDetail";

// <<<<<<<<<<< 기업정보 >>>>>>>>>>>>
// 기업정보 - 이용약관
import ProvisionTable from "./company/provision/ProvisionTable";
import ProvisionDetail from "./company/provision/ProvisionDetail";
import ProvisionEdit from "./company/provision/ProvisionEdit";
import ProvisionCreate from "./company/provision/ProvisionCreate";

// 기업정보 - 기업정보
import CompanyInfoDetail from "./company/company-info/CompanyInfoDetail";
import CompanyInfoEdit from "./company/company-info/CompanyInfoEdit";

// 기업정보 - 협력사
import PartnerInfoTable from "./company/partner-info/PartnerInfoTable";
import PartnerInfoDetail from "./company/partner-info/PartnerInfoDetail";
import PartnerInfoEdit from "./company/partner-info/PartnerInfoEdit";
import PartnerInfoCreate from "./company/partner-info/PartnerInfoCreate";


// <<<<<<<<<<< 관리자 >>>>>>>>>>>>
// 관리자 - 관리자목록
import PixelroTable from "./pixelro/PixelroTable";
import PixelroEdit from "./pixelro/PixelroEdit";
import PixelroCreate from "./pixelro/PixelroCreate";

/*
==========================================================================
    안쓰는 페이지 :: 차후 필요할지 모름
==========================================================================
*/

// 고객센터 - 운영정책
import OperationTable from "./service-center/operation/OperationTable";
import OperationDetail from "./service-center/operation/OperationDetail";
import OperationEdit from "./service-center/operation/OperationEdit";
import OperationCreate from "./service-center/operation/OperationCreate";

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/adminIn" component={PixelroIn} />
          
          {/* 회원 관리 */}
          <Route path="/app-users/user-table" component={UserTable} />
          <Route path="/app-users/user-detail/:id" component={UserDetail} />
          <Route path="/app-users/user-edit/:id" component={UserEdit} />
          {/* 전문가 관리 */}
          <Route path="/app-users/expert-table" component={ExpertTable} />
          <Route path="/app-users/expert-detail/:group" component={ExpertDetail} />
          <Route path="/app-users/expert-edit/:group" component={ExpertEdit} />
          <Route path="/app-users/expert-create" component={ExpertCreate} />

          {/* 푸시 메시지 */}
          <Route path="/app-users/app-push-message" component={NewAppPushMessage} />
          {/* 푸시 메시지 */}
          <Route path="/app-users/search-history" component={SearchHistoryTable} />

          {/* 공지사항 */}
          <Route path="/service-center/notice-table" component={NoticeTable} />
          <Route path="/service-center/notice-detail/:id" component={NoticeDetail} />
          <Route path="/service-center/notice-edit/:id" component={NoticeEdit} />
          <Route path="/service-center/notice-create" component={NoticeCreate} />
          
          {/* FAQ */}
          <Route path="/service-center/app-user-faq-table" component={AppUserFAQTable} />
          <Route path="/service-center/app-user-faq-detail/:id" component={AppUserFAQDetail} />
          <Route path="/service-center/app-user-faq-edit/:id" component={AppUserFAQEdit} />
          <Route path="/service-center/app-user-faq-create" component={AppUserFAQCreate} />
          
          {/* 의견보내기 */}
          <Route path="/service-center/app-user-qna-table" component={AppUserQnaTable} />
          <Route path="/service-center/app-user-qna-detail/:id" component={AppUserQnaDetail} />
          
          {/* 매거진 */}
          <Route path="/notice-board/magazine-table" component={MagazineTable} />
          <Route path="/notice-board/magazine-detail/:id" component={MagazineDetail} />
          <Route path="/notice-board/magazine-edit/:id" component={MagazineEdit} />
          <Route path="/notice-board/magazine-create" component={MagazineCreate} />

          {/* 이벤트 */}
          <Route path="/notice-board/event" component={Event} />

          {/* 눈운동관리 */}
          <Route path="/eye-movement/table" component={EyeMovementTable} />
          <Route path="/eye-movement/create" component={EyeMovementCreate} />
          <Route path="/eye-movement/detail/:group" component={EyeMovementDetail} />
          <Route path="/eye-movement/update/:group" component={EyeMovementUpdate} />

          {/* 이용약관 */}
          <Route path="/company/provision-table" component={ProvisionTable} />
          <Route path="/company/provision-detail/:group" component={ProvisionDetail} />
          <Route path="/company/provision-edit/:group" component={ProvisionEdit} />
          <Route path="/company/provision-create" component={ProvisionCreate} />

          {/* 기업정보 */}
          <Route path="/company/company-info-detail" component={CompanyInfoDetail} />
          <Route path="/company/company-info-edit" component={CompanyInfoEdit} />

          {/* 협력사 정보 */}
          <Route path="/company/partner-table" component={PartnerInfoTable} />
          <Route path="/company/partner-detail/:group" component={PartnerInfoDetail} />
          <Route path="/company/partner-edit/:group" component={PartnerInfoEdit} />
          <Route path="/company/partner-create" component={PartnerInfoCreate} />

          {/* 픽셀로 관리자 */}
          <Route path="/pixelro/pixelro-table" component={PixelroTable} />
          <Route path="/pixelro/pixelro-edit/:id" component={PixelroEdit} />
          <Route path="/pixelro/pixelro-create" component={PixelroCreate} />

          {/*
          
          안쓰는 루트
          
          */}

          {/* 운영정책 */}
          <Route path="/service-center/operation-table" component={OperationTable} />
          <Route path="/service-center/operation-detail/:id" component={OperationDetail} />
          <Route path="/service-center/operation-edit/:id" component={OperationEdit} />
          <Route path="/service-center/operation-create" component={OperationCreate} />
          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
