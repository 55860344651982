import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Form } from "react-bootstrap";
import { SimpleNotification } from "components/SimpleNotification";
import { CREATE_SERVICE_CENTER } from "graphql/gql/insert";
import Editor from "app/shared/Editor"
import useSettings from "stores/settings";

const CreatePage = () => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }
  const goBack = () => {
    history.goBack();
  };

  const [category, setCategory] = useState("이용약관_회원사");
  const [topic, setTopic] = useState("");
  const [title, setTitle] = useState();
  const [content, setContent] = useState();

  const type = "provision";

  const [provisionCreate, { data: createData }] = useMutation(CREATE_SERVICE_CENTER, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (createData !== undefined) {
      goBack();
    }
  }, [createData]);

  const handleUpdate = () => {

    if (!title || !content || content === '' || !topic) {
      SimpleNotification({
        title: "",
        message: "내용을 입력해주세요.",
      });
      return;
    }

    provisionCreate({
      variables: {
        type,
        category,
        title,
        content,
        topic,
      },
    });
  };

  return (
    <>
      {/* breadcrumb */}
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">회사정보 관리</li>
            <Link to="/company/provision-table" className="breadcrumb-item active mt-1" aria-current="page">
              약관 관리
            </Link>
            <Link to="/company/provision-create" className="breadcrumb-item active mt-1" aria-current="page">
              약관 등록
            </Link>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">약관 수정</h4>
              </div>
              <div className="mt-6">
                <div className="card-wrap">
                  <table className="app-table bg-white w-full">
                    <tr>
                      <td className="th w-1/6">구분</td>
                      <td colSpan="3">
                        <select
                          className="form-control w-1/4"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value="이용약관_회원사">회원사</option>
                          <option value="이용약관_사용자">사용자</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="th w-1/6">주제</td>
                      <td colSpan="3">
                        {category === "이용약관_회원사" ? (
                          <select className="form-control w-1/2" value={topic} onChange={(e) => setTopic(e.target.value)}>
                            <option value="">옵션을 선택해주세요.</option>
                            <option value="서비스이용약관">서비스 이용약관</option>
                            <option value="개인정보처리">개인정보 처리</option>
                            <option value="전자상거래">전자상거래</option>
                            <option value="온오프라인계약서">온오프라인계약서</option>
                          </select>
                        ) : (
                          <select className="form-control w-1/2" value={topic} onChange={(e) => setTopic(e.target.value)}>
                            <option value="">옵션을 선택해주세요.</option>
                            <option value="서비스이용약관">서비스 이용약관</option>
                            <option value="개인정보처리">개인정보 처리</option>
                            <option value="위치정보이용약관동의">위치정보 이용약관 동의</option>
                          </select>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="th">제목</td>
                      <td colSpan="3">
                        <Form.Control type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                      </td>
                    </tr>
                  </table>
                </div>
              
                <div className="row mt-4">
                  <div className="col-md-12">
                    <Editor data={content} onBlurGetData={(data) => setContent(data)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 btnbar">
        <div className="row foot-edit">
          <button className="btn btn-warning" onClick={goBack}>
            취소하기
          </button>
          <button type="button" className="btn btn-primary" onClick={handleUpdate}>
            저장하기
          </button>
        </div>
      </div>
    </>
  );
};

CreatePage.prototype = {
  id: PropTypes.string.isRequired,
};

export default CreatePage;
