/* eslint-disable react/style-prop-object */
import { LOGIN } from "graphql/gql/select";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { observer } from "mobx-react-lite";
import jwtDecode from "jwt-decode";
import useSettings from "stores/settings";
import React, { useState, useEffect } from "react";
import { SimpleNotification } from "components/SimpleNotification";

const imgboxStyle = {
  width: "100% ",
  height: "100% ",
};

const ScreenAdmin = () => {
  const settings = useSettings();
  const history = useHistory();
  if (!settings.isLogin) {
    settings.isLogin = false;
    settings.token = "";
    window.localStorage.setItem("token", "");
  }

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  /* 로그인 버튼 클릭시 , mutation 생성 */
  const [pixelroIn, { data }] = useMutation(LOGIN, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const data1 = data.pixelroIn;
      if (data1.error) {
        SimpleNotification({
          title: "Error",
          message: data1.error,
        });
      } else {
        console.log("토큰 받음");
        const user = jwtDecode(data1.token);
        settings.isLogin = true;
        settings.pixelroId = user.user.id;
        settings.email = user.user.email;
        settings.token = data1.token;
        window.localStorage.setItem("token", data1.token);
        history.push("/dashboard");
      }
    }
  }, [data]);

  const handlerLoginClick = () => {
    if (!email || !password) {
      SimpleNotification({
        message: "이메일이나 암호를 입력해 주세요",
      });
      return;
    }
    pixelroIn({ variables: { email: email, password: password } });
  };

  // 비밀번호 입력 후, Enter 누르면 로그인 버튼 클릭과 같은 기능 호출
  const handlerLoginEnterClick = (e) => {
    if (e.key === "Enter") {
      if (!email || !password) {
        SimpleNotification({
          message: "이메일이나 암호를 입력해 주세요",
        });
        return;
      }
      pixelroIn({ variables: { email: email, password: password } });
    }
  };

  return (
    <div style={imgboxStyle}>
      <div className="h-screen font-noto-medium text-xs flex-col-center login-bg">
        {/* 아메일 , 암호 필드 표시  */}
        <div className="w-96 login-box">
          <div className="titlebox">
            <p className="title">관리자 로그인</p>
          </div>
          <div className="input-wrap">
            <input onChange={(e) => setEmail(e.target.value)} type="text" className="w-full px-2 py-2 outline-none" placeholder="아이디" />
          </div>

          <div className="input-wrap">
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              onKeyPress={handlerLoginEnterClick}
              className="w-full px-2 py-2  outline-none"
              placeholder="암호"
            />
          </div>

          <div className="flex flex-wrap items-center justify-center bg-black btn-login">
            <div className="w-full btn-text  text-center text-white font-bold py-2 px-4 my-auto" onClick={handlerLoginClick}>
              로그인
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Screen = () => {
  const settings = useSettings();
  const history = useHistory();
  if (!settings.isLogin) {
    settings.isLogin = false;
    settings.token = "";
    window.localStorage.setItem("token", "");
  }

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  /* 로그인 버튼 클릭시 , mutation 생성 */
  const [pixelroIn, { error }] = useMutation(LOGIN, {
    update: (proxy, result) => {
      if (result.data.pixelroIn.error) {
        SimpleNotification({
          message: result.data.pixelroIn.error,
        });
      } else {
        console.log("토큰 받음");
        const user = jwtDecode(result.data.pixelroIn.token);
        settings.isLogin = true;
        settings.pixelroId = user.user.id;
        settings.email = user.user.email;
        settings.token = result.data.pixelroIn.token;
        window.localStorage.setItem("token", result.data.pixelroIn.token);

        history.push("/dashboard");
      }
    },
    variables: { email: email, password: password },
  });

  // 로그인 버튼 클릭시, 필드 검사 후 pixelroIn 뮤테이션 호출
  const handlerLoginClick = () => {
    if (!email || !password) {
      SimpleNotification({
        message: "이메일이나 암호를 입력해 주세요",
      });
      return;
    }
    pixelroIn();
  };

  // 비밀번호 입력 후, Enter 누르면 로그인 버튼 클릭과 같은 기능 호출
  const handlerLoginEnterClick = (e) => {
    if (e.key === "Enter") {
      if (!email || !password) {
        SimpleNotification({
          message: "이메일이나 암호를 입력해 주세요",
        });
        return;
      }
      pixelroIn();
    }
  };

  return (
    <div>
      <div className="bg-grey-lighter h-screen w-full font-sans bg-local">
        <div className=" mx-auto mx-30 pt-32 items-center justify-center">
          <div className="col-lg-3 col-md-5 ml-auto mr-auto m-6 p-6 bg-white  ">
            <p className="font-medium text-5xl text-center mb-10">로그인</p>
            <p className="font-medium text-xl text-center mb-10">pixelro admin</p>
            <div className="mb-4">
              <label className="font-bold text-grey-darker block mb-2">아이디</label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                className="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-2 py-2 rounded shadow"
                placeholder="이메일"
              />
            </div>

            <div className="mb-8">
              <label className="font-bold text-grey-darker block mb-2">암호</label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="block appearance-none w-full bg-white border border-grey-light hover:border-grey px-2 py-2 rounded shadow"
                placeholder="암호"
                onKeyPress={(e) => handlerLoginEnterClick(e)}
              />
            </div>

            <div className="flex ">
              <button onClick={() => handlerLoginClick()} className="btn btn-primary text-white font-bold py-2 px-4 rounded w-full">
                로그인
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminIn = observer(() => {
  return (
    <div>
      <div className="">
      {/*<div className="hidden sm:hidden md:block">*/}
        <ScreenAdmin props />
      </div>
    </div>
  );
});

export default AdminIn;
