/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import useSettings from 'stores/settings';
import { useHistory, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { FormatDate } from 'components/FormatDate';
import { SimpleNotification } from 'components/SimpleNotification';
import { ALL_SERVICE_CENTERS } from 'graphql/gql/select';
import { LoadingSpinner } from 'components/common';
import ReactTable from 'react-table';
import matchSorter from "match-sorter";

const Tables = () => {
	// 로그인 체크
	const settings = useSettings();
	const history = useHistory();
	if (!settings.isLogin) {
		history.push('/adminIn');
	}

	const [result, setResult] = useState();

	const { loading, error, data } = useQuery(ALL_SERVICE_CENTERS, {
		fetchPolicy: 'network-only',
		variables: {
			where: { type: 'PV', lang:"ko" },
			orderBy: { createAt: 'desc' }
		}
	});

	useEffect(() => {
		if (data?.allServiceCenters) {
			setResult(data.allServiceCenters);
		}
	}, [data]);

	if (error) {
		console.log(error);
		SimpleNotification({ type: 'danger', message: error.message || 'Error' });
	}

	if (loading || !result) return <LoadingSpinner />;

	return (
		<>
			<div>
				<TableHeader />
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="flex justify-between card-title-wrap">
									<h4 className="card-title">약관 목록</h4>
									{/* <Link className="btn btn-primary" to="/company/provision-create">
                    등록하기
                  </Link> */}
								</div>
								<div className="row">
									<div className="col-12">
										<ReactTable
											data={result}
											className="card-wrap"
											defaultPageSize={10}
											filterable
											columns={[
												{
													id: 'category',
													Header: '구분',
													accessor: d => d.commonCode.category,
													filterMethod: (filter, rows) =>
														matchSorter(rows, filter.value, {
															keys: [item => item.category]
														}),
													filterAll: true
												},
												{
													id: 'name',
													Header: '주제',
													accessor: d => d.commonCode.name,
													filterMethod: (filter, rows) =>
														matchSorter(rows, filter.value, {
															keys: [item => item.name]
														}),
													filterAll: true
												},
												{
													id: 'title',
													Header: '제목',
													accessor: d => d.title,
													filterMethod: (filter, rows) =>
														matchSorter(rows, filter.value, {
															keys: [item => item.title]
														}),
													filterAll: true
												},
												{
													id: 'createAt',
													Header: '등록날짜',
													accessor: 'createAt',
													filterable: false,
													Cell: row =>
														`${FormatDate(new Date(parseInt(row.value, 10)))}`
												},
												{
													id: 'detail',
													Header: '',
													accessor: 'detail',
													filterable: false,
													Cell: row => (
														<Link
															className="ti-pencil-alt"
															to={`/company/provision-detail/${row.original.group}`}
														>
															상세보기
														</Link>
													)
												}
											]}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

// /* breadcrumb */
const TableHeader = () => {
	return (
		<div>
			<nav aria-label="breadcrumb" role="navigation">
				<ol className="breadcrumb">
					<li className="breadcrumb-item active">회사정보 관리</li>
					<Link
						to="/company/provision-table"
						className="breadcrumb-item active mt-1"
						aria-current="page"
					>
						약관관리
					</Link>
				</ol>
			</nav>
		</div>
	);
};

export default Tables;
