import React, { useState } from "react";
import PropTypes from "prop-types";
import { AllCommonCodes, AllServiceCenter } from "graphql/query/select";
import { Link, useHistory } from "react-router-dom";
import { FormatDate } from "components/FormatDate";
import { hasValue, LoadingSpinner } from "components/common";
import { Tabs, Tab } from "react-bootstrap";
import useSettings from "stores/settings";

const DetailPage = ({ match }) => {
  const { group } = match.params;

  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const goBack = () => {
    history.goBack();
  };
  
  if (!hasValue(group))
    goBack();

  const items = AllServiceCenter({ where : { group }}).result;

  const tabsParam = {
    where : {
      type: "L",
      isUse: "Y",
      lang: "ko"
    },
    order : {
      orderByKey : "priority",
      orderByValue : "asc",
    }
  }

  const tabs = AllCommonCodes(tabsParam).result; //한 영 일 중

  if (!tabs || !items) return <LoadingSpinner/>
  return (
    <div>
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">회사정보 관리</li>
            <Link to="/company/provision-table" className="breadcrumb-item active mt-1" aria-current="page">
              약관관리
            </Link>
            <Link to={`/company/provision-detail/${group}`} className="breadcrumb-item active mt-1" aria-current="page">
              약관 상세보기
            </Link>
          </ol>
        </nav>
      </div>
      <div className="col-12 mt-4">
        <div className="row justify-end">
          <Link className="btn btn-primary" to={`/company/provision-edit/${group}`}>
            수정하기
          </Link>
        </div>
      </div>
      <Tabs id="provision-info-tabs">
        {tabs.map((tab) => (
          <Tab eventKey={tab.value} title={tab.name}>
            <InfoForm
              lang={tab.value}
              item={items.filter((item) => item.lang === tab.value)[0]}
              goBack={goBack}
            />
          </Tab>
        ))}
      </Tabs>
      <div className="col-12 grid-margin mt-4">
        <div className="row justify-end">
          <Link className="btn btn-primary" to={`/company/provision-edit/${group}`}>
            수정하기
          </Link>
        </div>
      </div>
    </div>
  );
};

const InfoForm = ({ item }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between card-title-wrap">
              <h4 className="card-title">약관 상세보기</h4>
            </div>
            <div className="card-wrap">
              <table className="app-table bg-white w-full">
                <tr>
                  <td className="th">구분</td>
                  <td>{item?.commonCode?.category}</td>
                  <td className="th">주제</td>
                  <td>{item?.commonCode?.name}</td>
                </tr>
                <tr>
                  <td className="th">제목</td>
                  <td>{item?.title}</td>
                  <td className="th">등록날짜</td>
                  <td>{FormatDate(new Date(parseInt(item?.createAt, 10)))}</td>
                </tr>
              </table>
              <div className="w-full pt-4 pl-4 pr-4 ck-content">
                <div className="w-full">
                  <div
                    className="table-content table-responsive  cart-table-content  w-full html-content"
                    contentEditable="false"
                    dangerouslySetInnerHTML={{ __html: item?.content }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailPage.prototype = {
  id: PropTypes.string.isRequired,
};

export default DetailPage;
