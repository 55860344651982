import gql from "graphql-tag";

// 사용자 정보 수정
export const UPDATE_MEMBER = gql`
  mutation($id: String!, $email: String, $name: String, $tel: String, $password: String, $newPassword: String) {
    updateMember(where: { id: $id }, data: { email: $email, name: $name, tel: $tel, password: $password, newPassword: $newPassword }) {
      id
    }
  }
`;

// 상점 정보 수정
export const UPDATE_STORE = gql`
  mutation(
    $id: String!
    $name: String
    $tel: String
    $post: String
    $address: String
    $detailAddress: String
    $intro: String
    $onLine: Boolean
    $offLine: Boolean
    $category: String
    $keyword: String
    $site: String
    $blog: String
    $star: String
    $businessNumber: String
    $bankName: String
    $businessNumberImage: String
    $bankImage: String
    $bankNumber: String
    $accountHolder: String
    $image: String
    $withdraw: Boolean
    $withdrawStatus: String
    $messageCount: Float
  ) {
    updateStore(
      where: { id: $id }
      data: {
        name: $name
        tel: $tel
        post: $post
        address: $address
        detailAddress: $detailAddress
        intro: $intro
        onLine: $onLine
        offLine: $offLine
        category: $category
        keyword: $keyword
        site: $site
        blog: $blog
        star: $star
        businessNumber: $businessNumber
        bankName: $bankName
        bankNumber: $bankNumber
        businessNumberImage: $businessNumberImage
        bankImage: $bankImage
        accountHolder: $accountHolder
        image: $image
        withdraw: $withdraw
        withdrawStatus: $withdrawStatus
        messageCount: $messageCount
      }
    ) {
      id
    }
  }
`;

// 상점 소개 글 수정
export const UPDATE_STORE_INTRO = gql`
  mutation($id: String!, $intro: String) {
    updateStoreIntro(where: { id: $id }, data: { intro: $intro }) {
      id
    }
  }
`;

// 상품 정보 수정
export const UPDATE_PRODUCT = gql`
  mutation(
    $id: String!
    $name: String
    $price: String
    $discount: Int
    $description: String
    $productInfo: String
    $brand: String
    $model: String
    $option: String
    $categoryDetail: String
    $mainImage: String
    $detailImage: String
    $category: String
    $keyword: String
    $onSale: Boolean
    $visible: Boolean
  ) {
    updateProduct(
      where: { id: $id }
      data: {
        name: $name
        price: $price
        discount: $discount
        description: $description
        productInfo: $productInfo
        brand: $brand
        model: $model
        option: $option
        categoryDetail: $categoryDetail
        mainImage: $mainImage
        detailImage: $detailImage
        category: $category
        keyword: $keyword
        onSale: $onSale
        visible: $visible
      }
    ) {
      id
    }
  }
`;

// 앱 사용자 정보 수정
export const UPDATE_SETTLE_REPORT = gql`
  mutation($id: String!, $status: String, $settlementAt: String, $taxBillAt: String) {
    updateSettleReport(where: { id: $id }, data: { status: $status, settlementAt: $settlementAt, taxBillAt: $taxBillAt }) {
      id
    }
  }
`;

// 앱 사용자 정보 수정
export const UPDATE_USER = gql`
  mutation(
    $id: String!
    $email: String
    $password: String
    $newPassword: String
    $name: String
    $post: String
    $address: String
    $detailAddress: String
    $tel: String
    $birthday: String
    $gender: String
    $job: String
    $withdraw: Boolean
  ) {
    updateUser(
      where: { id: $id }
      data: {
        email: $email
        password: $password
        newPassword: $newPassword
        name: $name
        post: $post
        address: $address
        detailAddress: $detailAddress
        tel: $tel
        birthday: $birthday
        gender: $gender
        job: $job
        withdraw: $withdraw
      }
    ) {
      id
    }
  }
`;

// 공지사항 & FAQ 정보 수정
export const UPDATE_SERVICE_CENTER = gql`
  mutation($id: String!, $type: String, $category: String, $title: String, $content: String, $topic: String) {
    updateServiceCenter(where: { id: $id }, data: { type: $type, category: $category, title: $title, content: $content, topic: $topic }) {
      id
    }
  }
`;

// QNA
export const UPDATE_QNA = gql`
  mutation($id: String!, $category: String, $title: String, $content: String, $comment: String, $reply: String) {
    updateQna(where: { id: $id }, data: { category: $category, title: $title, content: $content, comment: $comment, reply: $reply }) {
      id
    }
  }
`;

// App QNA
export const UPDATE_APP_QNA = gql`
  mutation($id: String!, $category: String, $title: String, $content: String, $comment: String, $reply: String) {
    updateAppQna(where: { id: $id }, data: { category: $category, title: $title, content: $content, comment: $comment, reply: $reply }) {
      id
    }
  }
`;

// 게시판 관리
export const UPDATE_NOTICE_BOARD = gql`
  mutation(
    $id: String!
    $category: String
    $title: String
    $content: String
    $image: String
    $link1: String
    $linkImage1: String
    $link2: String
    $linkImage2: String
    $link3: String
    $linkImage3: String
    $no: String
    $visible: Boolean
    $startAt: String
    $endAt: String
    $fileWhere: [FileInfoWhereUniqueInput]
    $interests: [InterestCreateInput]
    $uploadSingleImage: UploadSingleImage
  ) {
    updateNoticeBoard(
      where: { id: $id }
      data: {
        category: $category
        title: $title
        content: $content
        image: $image
        link1: $link1
        linkImage1: $linkImage1
        link2: $link2
        linkImage2: $linkImage2
        link3: $link3
        linkImage3: $linkImage3
        no: $no
        visible: $visible
        startAt: $startAt
        endAt: $endAt
      },
      fileWhere: $fileWhere,
      interests: $interests,
      image: $uploadSingleImage,
    ) {
      id
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

// 정산
// updateSettlementRequest
export const UPDATE_SETTLEMENT_OK = gql`
  mutation($id: String, $settlementAt: String) {
    updateSettlementOk(id: $id, settlementAt: $settlementAt) {
      result
    }
  }
`;

export const UPDATE_BANNER = gql`
  mutation(
    $id: String
    $category: String
    $title: String
    $content: String
    $image: String
    $targetId: String
    $targetUrl: String
    $no: String
    $visible: Boolean
  ) {
    updateBanner(
      where: { id: $id }
      data: {
        category: $category
        title: $title
        content: $content
        image: $image
        targetId: $targetId
        targetUrl: $targetUrl
        no: $no
        visible: $visible
      }
    ) {
      id
    }
  }
`;

// 게시판 관리 - 현재 등록 배너 삭제
export const UPDATE_BANNER_TYPE = gql`
  mutation($id: String!, $type: String) {
    updateBanner(where: { id: $id }, data: { type: $type }) {
      id
    }
  }
`;

// 상점 - admin id 입력
export const UPDATE_STORE_GROUP = gql`
  mutation($id: String!, $adminId: String) {
    updateStoreGroup(where: { id: $id }, data: { adminId: $adminId }) {
      id
    }
  }
`;

// 상품 등록 요청 답변
export const UPDATE_PRODUCT_REGISTER_REPLY = gql`
  mutation($id: String!, $reply: String) {
    updateProductRegister(where: { id: $id }, data: { reply: $reply }) {
      id
    }
  }
`;

// 상점 등록 요청 답변
export const UPDATE_STORE_REGISTER_REPLY = gql`
  mutation($id: String!, $storeRegister: String) {
    updateStore(where: { id: $id }, data: { storeRegister: $storeRegister }) {
      id
    }
  }
`;

// 회원사 권한 변경
export const UPDATE_MEMBER_BASIC = gql`
  mutation($id: String!, $memberOpen: Boolean) {
    updateMemberBasic(where: { id: $id }, data: { memberOpen: $memberOpen }) {
      id
    }
  }
`;

// default 상점 등록
export const UPDATE_MEMBER_DEFAULT_STORE = gql`
  mutation($id: String!, $defaultStoreId: String) {
    updateMemberDefaultStore(where: { id: $id }, data: { defaultStoreId: $defaultStoreId }) {
      id
      defaultStoreId
    }
  }
`;

// 쿠폰 수정
export const UPDATE_COUPON = gql`
  mutation(
    $id: String
    $type: String
    $startAt: String
    $endAt: String
    $downloadCount: Int
    $downloadLimit: Int
    $adminActive: Boolean
    $storeActive: Boolean
    $min: Int
    $feeFree: Boolean
    $cashDiscount: Int
    $percentDiscount: Int
    $visit: Boolean
    $visitName: String
    $couponName: String
  ) {
    updateCoupon(
      where: { id: $id }
      data: {
        type: $type
        startAt: $startAt
        endAt: $endAt
        downloadCount: $downloadCount
        downloadLimit: $downloadLimit
        adminActive: $adminActive
        storeActive: $storeActive
        min: $min
        feeFree: $feeFree
        cashDiscount: $cashDiscount
        percentDiscount: $percentDiscount
        visit: $visit
        visitName: $visitName
        couponName: $couponName
      }
    ) {
      id
    }
  }
`;

// 주문 수정
export const UPDATE_ORDER = gql`
  mutation(
    $id: String
    $payId: String
    $productOption: String
    $productCount: String
    $productPrice: String
    $productAddPrice: String
    $productTotal: String
    $currency: String
    $reviewStar: String
    $reviewImage: String
    $reviewVideo: String
    $reviewText: String
    $reviewCreateAt: String
    $reply: String
    $replyCreateAt: String
    $receiveType: String
    $returnType: String
    $returnText: String
    $returnAt: String
    $delivery: String
    $trackingNumber: String
    $status: String
    $process1: String
    $process2: String
    $process3: String
    $process4: String
    $process5: String
    $fixAt: String
    $cancelPrice: String
    $cancel: String
    $coupon: String
    $couponDiscount: Int
    $point: Int
    $pixelroCouponDiscount: Int
    $changeOkAt: String
  ) {
    updateOrder(
      where: { id: $id }
      data: {
        payId: $payId
        productOption: $productOption
        productCount: $productCount
        productPrice: $productPrice
        productAddPrice: $productAddPrice
        productTotal: $productTotal
        currency: $currency
        reviewStar: $reviewStar
        reviewImage: $reviewImage
        reviewVideo: $reviewVideo
        reviewText: $reviewText
        reviewCreateAt: $reviewCreateAt
        reply: $reply
        replyCreateAt: $replyCreateAt
        receiveType: $receiveType
        returnType: $returnType
        returnText: $returnText
        returnAt: $returnAt
        delivery: $delivery
        trackingNumber: $trackingNumber
        status: $status
        process1: $process1
        process2: $process2
        process3: $process3
        process4: $process4
        process5: $process5
        fixAt: $fixAt
        cancelPrice: $cancelPrice
        cancel: $cancel
        coupon: $coupon
        couponDiscount: $couponDiscount
        point: $point
        pixelroCouponDiscount: $pixelroCouponDiscount
        changeOkAt: $changeOkAt
      }
    ) {
      id
    }
  }
`;

// 관리자 정보 수정
export const UPDATE_PIXELRO = gql`
  mutation($id: String, $name: String, $tel: String, $password: String) {
    updatePixelro(where: { id: $id }, data: { name: $name, tel: $tel, password: $password }) {
      id
    }
  }
`;

// 회사 정보 수정
export const UPDATE_COMPANY_INFO = gql`
  mutation($where: CompanyInfoWhereUniqueInput, $data: CompanyInfoCreateInput, $fileWhere: [FileInfoWhereUniqueInput], $image: UploadSingleImage) {
    updateCompanyInfo(
      where: $where,
      data: $data,
      fileWhere: $fileWhere
      image: $image
    ) {
      id
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

// 회사 정보 수정
export const UPDATE_COMPANY_INFO_PRIORITY = gql`
  mutation($where: CompanyInfoWherePriorityInput, $data: CompanyInfoUpdatePriorityInput, $targetWhere: CompanyInfoWhereUniqueInput, $targetData: CompanyInfoCreateInput) {
    updateCompanyInfoPriority(where:$where, data:$data, targetWhere:$targetWhere, targetData:$targetData) {
      result
    }
  }
`

// 상품 판매 수량 변경
export const UPDATE_PRODUCT_SALE_COUNT = gql`
  mutation($id: String) {
    updateProductSaleCount(id: $id) {
      result
    }
  }
`;

// FCM PUSH - USER
export const SEND_USER_FCM = gql`
  mutation($id: String, $title: String, $message: String, $link: String, $action: String, $value: String) {
    sendUserFcm(where: { id: $id }, title: $title, message: $message, link: $link, action: $action, value: $value) {
      result
    }
  }
`;

// 상점 탈퇴
export const WITHDRAW_STORE = gql`
  mutation($storeId: String, $withdrawDate: String, $memberId: String) {
    withdrawStore(storeId: $storeId, withdrawDate: $withdrawDate, memberId: $memberId) {
      result
      error
    }
  }
`;

// 회원 탈퇴
export const WITHDRAW_MEMBER = gql`
  mutation($memberId: String) {
    withdrawMember(memberId: $memberId) {
      result
      error
    }
  }
`;

// 메세지 승인
export const UPDATE_MESSAGE = gql`
  mutation($id: String!, $status: String, $checkAt: String) {
    updateMessage(where: { id: $id }, data: { status: $status, checkAt: $checkAt }) {
      id
    }
  }
`;

// 게시판 내용 등록
export const UPDATE_NOTICE_BOARD_CONTENT = gql`
  mutation($id: String!, $content: String) {
    updateNoticeBoardContent(where: { id: $id }, data: { content: $content }) {
      id
    }
  }
`;

// FCM PUSH - USERS
export const SEND_MANY_USER_FCM = gql`
  mutation($users: String, $title: String, $message: String, $link: String, $action: String, $value: String) {
    sendManyUserFcm(users: $users, title: $title, message: $message, link: $link, action: $action, value: $value) {
      result
    }
  }
`;

export const UPDATE_EXPERT = gql`
  mutation($where: ExpertWhereUniqueInput, $data: ExpertCreateInput, $fileWhere: [FileInfoWhereUniqueInput], $image: UploadSingleImage, $interests: [InterestCreateInput]) {
    updateExpert(where: $where, data: $data, fileWhere: $fileWhere, image:$image, interests:$interests) {
      id
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

export const WITHDRAW_EXPERT = gql`
  mutation($group: String!) {
    withdrawExpert(where: { group:$group }) {
      result
      error
    }
  }
`;

// 눈운동 순서 수정
export const UPDATE_EXERCISE_PRIORITY = gql`
  mutation($where: ExerciseWherePriorityInput, $data: ExerciseUpdatePriorityInput, $targetWhere: ExerciseWhereUniqueInput, $targetData: ExerciseCreateInput) {
    updateExercisePriority(where:$where, data:$data, targetWhere:$targetWhere, targetData:$targetData) {
      result
    }
  }
`

// 눈운동 정보 수정
export const UPDATE_EXERCISE = gql`
  mutation($where: ExerciseWhereUniqueInput, $data: ExerciseCreateInput, $fileWhere: [FileInfoWhereUniqueInput], $image: UploadSingleImage) {
    updateExercise( where: $where, data: $data, fileWhere: $fileWhere, image: $image) {
      id
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

export const UPDATE_EXERCISE_TYPE1 = gql`
  mutation($where: ExerciseType1WhereUniqueInput, $data: ExerciseType1CreateInput, $subDatas: [ExerciseSubCreateInput], $fileWhere: [FileInfoWhereUniqueInput], $image:UploadSingleImage) {
    updateExerciseType1withSubs( where:$where, data:$data, subDatas:$subDatas, fileWhere:$fileWhere, image:$image) {
      id
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

export const UPDATE_EXERCISE_TYPE2 = gql`
  mutation($where: ExerciseType2WhereUniqueInput, $data: ExerciseType2CreateInput, $subDatas: [ExerciseSubCreateInput], $fileWhere: [FileInfoWhereUniqueInput], $image:UploadSingleImage) {
    updateExerciseType2withSubs( where:$where, data:$data, subDatas:$subDatas, fileWhere:$fileWhere, image:$image) {
      id
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

export const UPDATE_EXERCISE_TYPE3 = gql`
  mutation($where: ExerciseType3WhereUniqueInput, $data: ExerciseType3CreateInput, $subDatas: [ExerciseSubCreateInput], $fileWhere: [FileInfoWhereUniqueInput], $openImage:UploadSingleImage, $closeImage:UploadSingleImage) {
    updateExerciseType3withSubs(where:$where, data:$data, subDatas:$subDatas, fileWhere:$fileWhere, openImage:$openImage, closeImage:$closeImage) {
      id
      eyeCloseImage
      eyeOpenImage
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

export const UPDATE_EXERCISE_TYPE4 = gql`
  mutation($where: ExerciseType4WhereUniqueInput, $data: ExerciseType4CreateInput, $subDatas: [ExerciseSubCreateInput], $fileWhere: [FileInfoWhereUniqueInput], $openImage:UploadSingleImage, $closeImage:UploadSingleImage) {
    updateExerciseType4withSubs(where:$where, data:$data, subDatas:$subDatas, fileWhere:$fileWhere, openImage:$openImage, closeImage:$closeImage) {
      id
      eyeCloseImage
      eyeOpenImage
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

export const UPDATE_EXERCISE_TYPE5 = gql`
  mutation(
    $where: ExerciseType5WhereUniqueInput
    $data: ExerciseType5CreateInput
    $subDatas: [ExerciseSubCreateInput]
    $fileWhere: [FileInfoWhereUniqueInput]
    $imageStep1: UploadSingleImage
    $imageStep2: UploadSingleImage
    $imageStep3: UploadSingleImage
    $imageStep4: UploadSingleImage
    $imageStep5: UploadSingleImage
  ) {
    updateExerciseType5withSubs(
      where: $where
      data: $data
      subDatas: $subDatas
      fileWhere: $fileWhere
      imageStep1: $imageStep1
      imageStep2: $imageStep2
      imageStep3: $imageStep3
      imageStep4: $imageStep4
      imageStep5: $imageStep5
    ) {
      id
      imageStep1
      imageStep2
      imageStep3
      imageStep4
      imageStep5
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

export const UPDATE_EXERCISE_TYPE6 = gql`
  mutation($where: ExerciseType6WhereUniqueInput, $data: ExerciseType6CreateInput, $subDatas: [ExerciseSubCreateInput]) {
    updateExerciseType6withSubs(where:$where, data:$data, subDatas:$subDatas) {
      id
    }
  }
`