import React, {useState, useEffect} from 'react'
import { Form } from "react-bootstrap";

const BlinkForceSubtitle = ({ item }) => {
    const [eyeCloseSubtitle, setEyeCloseSubtitle] = useState(item ? item.filter(i => i.condition === "4")[0]?.subtitles : '');
    const [eyeOpenSubtitle, setEyeOpenSubtitle] = useState(item ? item.filter(i => i.condition === "5")[0]?.subtitles : '');
   
    return (
        <>
            <tr>
                <td className="th w-1/6">눈 감았을 때 표시 자막</td>
                <td className="w-3/6">{eyeCloseSubtitle}</td>
            </tr>
            <tr>
                <td className="th w-1/6">눈 떴을 때 표시 자막</td>
                <td className="w-3/6">{eyeOpenSubtitle}</td>
            </tr>
        </>
    )
}

export default BlinkForceSubtitle;