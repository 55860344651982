import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Tabs, Tab } from "react-bootstrap";
import { SimpleNotification } from "components/SimpleNotification";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_COMPANY_INFO } from "graphql/gql/insert";
import { hasValue, checkImage, LoadingSpinner } from "components/common";
import { AllCommonCodes } from "graphql/query/select";
import cuid from "cuid";
import { UPDATE_COMPANY_INFO } from "graphql/gql/update";
import useSettings from "stores/settings";

const DetailPage = () => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const goBack = () => {
    history.goBack();
  };

  const tabsParam = {
    where : {
      type: "L",
      isUse: "Y",
      lang: "ko"
    },
    order : {
      orderByKey : "priority",
      orderByValue : "asc",
    }
  }

  const tabs = AllCommonCodes(tabsParam).result; //한 영 일 중
  const type = "C";
  const [group, setGroup] = useState(`grp${cuid()}`);
  const [koSaved, setKoSaved] = useState(false);
  const [tabSaved, setTabSaved] = useState({});
  const [tabKey, setTabKey] = useState();

  const handleTabKey = (key) => {
    if (key !== 'ko' && !koSaved) {
      SimpleNotification({ type:"warning", message : "한국어 저장 후 사용 가능한 탭입니다." });
      setTabKey('ko');
      return;
    };
    setTabKey(key);
  }

  if (!tabs || !group) return <LoadingSpinner/>
  return (
    <>
      <div>
        <div>
          <nav aria-label="breadcrumb" role="navigation">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">회사정보 관리</li>
              <Link
                to="/company/partner-table"
                className="breadcrumb-item active mt-1"
                aria-current="page"
              >
                협력사 목록
              </Link>
              <Link
                to="/company/partner-create"
                className="breadcrumb-item active mt-1"
                aria-current="page"
              >
                협력사 등록
              </Link>
            </ol>
          </nav>
        </div>
        <div className="my-4 pl-0">
          <Tabs
            id="partner-info-tabs"
            activeKey={tabKey}
            onSelect={(k) => handleTabKey(k)}
          >
            {tabs.map((tab) => (
              <Tab eventKey={tab.value} title={tab.name}>
                <Table
                  lang={tab.value}
                  goBack={goBack}
                  type={type}
                  group={group}
                  setKoSaved={setKoSaved}
                  tabSaved={tabSaved}
                  setTabSaved={setTabSaved}
                />
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </>
  );
};

const Table = ({ lang, goBack, type, group, setKoSaved, tabSaved, setTabSaved }) => {
  const [id, setId] = useState();
  const [image, setImage] = useState();
  const [paramImage, setParamImage] = useState();
  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [person, setPerson] = useState();
  const [fileInfos, setFileInfos] = useState();
  const [saved, setSaved] = useState();

  //#region create
  const [companyInfoCreate, { data: createData }] = useMutation(
    CREATE_COMPANY_INFO,
    {
      onError(err) {
        SimpleNotification({
          message: err.message || "Error",
        });
      },
    }
  );

  useEffect(() => {
    if (createData?.createCompanyInfo) {
      setParamImage(undefined);
      setId(createData.createCompanyInfo.id);
      setFileInfos(createData.createCompanyInfo.fileInfos);
      setSaved(true);
      if (lang==='ko') setKoSaved(true);
      else {
        tabSaved[lang] = true;
        setTabSaved({...tabSaved});
      }
      SimpleNotification({
        title: "success",
        type: "success",
        message: "성공적으로 등록하였습니다."
      });
    }
  }, [createData]);

  const handleCreate = async () => {
    if (!image) {
      SimpleNotification({
        title: "Error",
        message: "이미지를 등록해주세요.",
      });
      return;
    }

    if (!hasValue(name)) {
      return SimpleNotification({ title: "Error", type: "warning", message: "협력사명을 입력해주세요." });
    }

    if (!hasValue(address)) {
      return SimpleNotification({ title: "Error", type: "warning", message: "주소를 입력해주세요." });
    }

    if (!hasValue(person)) {
      return SimpleNotification({ title: "Error", type: "warning", message: "담당자명을 입력해주세요." });
    }

    // 데이터 저장
    companyInfoCreate({
      variables: {
        data : {
          type,
          group,
          name,
          address,
          person,
          lang
        },
        image: {
          image: paramImage,
          size: paramImage.size,
        },
      },
    });
  };
  //#endregion

  //#region update
  const [companyUpdate, { data: updateData }] = useMutation(UPDATE_COMPANY_INFO, {
      onError(err) {
        SimpleNotification({
          message: err.message || "Error",
        });
      },
    }
  );

  useEffect(() => {
    if (updateData?.updateCompanyInfo) {
      setParamImage(undefined);
      setFileInfos(updateData.updateCompanyInfo.fileInfos);
      SimpleNotification({title: "success", type: "success", message: "성공적으로 수정하였습니다." });
    }
  }, [updateData]);

  const handleChange = async () => {
    if (!id || !fileInfos) {
      alert("죄송합니다. 오류가 발생하였습니다.");
      goBack();
      return;
    }

    if (!hasValue(name)) {
      return SimpleNotification({ title: "Error", type: "warning", message: "협력사명을 입력해주세요." });
    }

    if (!hasValue(address)) {
      return SimpleNotification({ title: "Error", type: "warning", message: "주소를 입력해주세요." });
    }

    if (!hasValue(person)) {
      return SimpleNotification({ title: "Error", type: "warning", message: "담당자명을 입력해주세요." });
    }

    const variables = {
      where : {
        id
      },
      data : {
        name,
        address,
        person,
      },
      fileWhere: fileInfos.map(info => { return { id: info.id, parentId: info.parentId, path: info.path, name: info.name, type: info.type } }),
    }

    if (paramImage) {
      variables.image = {
        image: paramImage,
        size: paramImage.size,
      };
    }

    companyUpdate({ variables });
  };
  //#endregion

  // 이미지 변경
  const handleImageChange = async (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    if (!checkImage(file.name, file.size)) return;
    setParamImage(file); //bytearray 형태로 서버에 보낼 데이터
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => setImage([reader.result]); //base64 형태로 화면에 보여줄 데이터
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between card-title-wrap">
              <h4 className="card-title">협력사 수정</h4>
            </div>
            <div className="card-wrap">
              <table className="app-table bg-white w-full">
                <tr>
                  <td className="th w-1/6">로고 이미지</td>
                  <td>
                    <div className="form-file">
                      <form encType="multipart/form-data" className="w-full">
                        <label className="" for={`img_primary_${lang}`} className="mb-0">
                          파일을 선택하세요.
                          <i className="ti-upload"></i>
                        </label>
                        <input
                          name="document"
                          id={`img_primary_${lang}`}
                          type="file"
                          accept="image/*"
                          method="POST"
                          onChange={handleImageChange}
                        />
                      </form>
                    </div>
                    <img src={image} className="mgz-upload-thumb" />
                  </td>
                </tr>
                <tr>
                  <td className="th w-1/6">협력사명</td>
                  <td>
                    <Form.Control
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="th w-1/6">주소</td>
                  <td>
                    <Form.Control
                      type="text"
                      className="form-control"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="th w-1/6">담당자명</td>
                  <td>
                    <Form.Control
                      type="text"
                      className="form-control"
                      value={person}
                      onChange={(e) => setPerson(e.target.value)}
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="col-12 btnbar">
          <div className="row foot-edit">
            <button className="btn btn-warning" onClick={goBack}>
              취소하기
            </button>
            {
              !saved? (
                <button type="button" className="btn btn-primary" onClick={handleCreate}>
                  저장하기
                </button>  
              ) : (
                <button type="button" className="btn btn-primary" onClick={handleChange}>
                  수정하기
                </button>  
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
