import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <span>
          Copyright © 2021 <a href="http://www.pixelro.com/" target="_blank" rel="noopener noreferrer">pixelro</a>. All rights reserved.
        </span>
      </footer>
    );
  }
}

export default Footer;
