import React, { useState, useEffect } from 'react'
import EyeMuscleSubtitle from './Subtitle'
import { Form } from "react-bootstrap";
import Select from "react-select";
import { getNumberSelectBox, getSoundEffectSelectBox } from "components/common"

const EyeMuscleLayout = ({ colspan, isKorea, lang, maxComponent, soundEffects, info, subtitles }) => {

  const [name, setName] = useState(info.name);
  const [type, setType] = useState(info.type);
  const [repeatCnt, setRepeatCnt] = useState(info.repeatCnt);
  const [sound, setSound] = useState(info.sound);
  const [priority, setPriority] = useState(info.priority);

  useEffect(() => {
    info.name = name;
  }, [name]);

  useEffect(() => {
    info.type = type;
  }, [type]);

  useEffect(() => {
    info.repeatCnt = repeatCnt;
  }, [repeatCnt]);
  
  useEffect(() => {
    info.sound = sound;
  }, [sound]);
  
  useEffect(() => {
    info.priority = priority;
  }, [priority]);

  return (
    <table className="app-table w-full">
      <tr>
          <td className="th w-1/6">유형 제목</td>
          <td colspan={colspan}>
              <Form.Control type="text" placeholder="유형 제목을 입력하세요." value={name} onChange={e => setName(e.target.value)}/>
          </td>
      </tr>
      { 
        isKorea? (
          <>
            <tr>
              <td className="th w-1/6">운동 유형 선택</td>
              <td colspan={colspan}>
                <Select
                  placeholder="운동 유형 선택"
                  value={ type ? { value:type, label: `${type} 운동` } : undefined}
                  options={[
                    { value: "H", label: "H 운동" },
                    { value: "S", label: "S 운동" },
                  ]}
                  onChange={(e) => setType(e.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="th w-1/6">반복 횟수 설정</td>
              <td colspan={colspan}>
                <Select
                  placeholder="반복 횟수 선택"
                  value={{ value: repeatCnt, label: repeatCnt }}
                  options={Array.from({length: 10}, (_, i) => i + 1).map(v => {
                    return { value: v, label: v }
                  })}
                  onChange={(e) => setRepeatCnt(e.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="th w-1/6">효과음 설정</td>
              <td colspan={colspan}>
                { getSoundEffectSelectBox(soundEffects, { onChange : e => setSound(e.value) }, sound) }
              </td>
            </tr>
            <tr>
                <td className="th w-1/6">정렬 순서 선택</td>
                <td colspan={colspan}>
                  { getNumberSelectBox(maxComponent, { onChange : e => setPriority(e.value)}, priority) }              
                </td>
            </tr>
          </>
        ) : ''
      }
      <EyeMuscleSubtitle item={subtitles[0][lang]}/>
    </table>  
  )
}

export default EyeMuscleLayout;