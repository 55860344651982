import React, {useState, useEffect} from 'react'
import { Form } from "react-bootstrap";

const RestSubtitle = ({ item }) => {
    const [subtitles, setSubtitles] = useState(item ? item[0]?.subtitles : '');
    const [time, setTime] = useState(item ? item[0]?.time: '');

    return (
        <>
            <tr>
                <td className="th w-1/6">자막</td>
                <td className="w-3/6 border-right-0">{subtitles}</td>
                <td className="w-2/6 border-right-0">{time}</td>
            </tr>
        </>
    )
}

export default RestSubtitle;