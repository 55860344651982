import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Form } from "react-bootstrap";
import { SimpleNotification } from "components/SimpleNotification";
import { CREATE_SERVICE_CENTER } from "graphql/gql/insert";
import Editor from "app/shared/Editor"
import useSettings from "stores/settings";

const CreatePage = () => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }
  const goBack = () => {
    history.goBack();
  };

  const [title, setTitle] = useState();
  const [content, setContent] = useState();

  const type = "NT";
  const category = "1";

  const [noticeCreate, { data: createData }] = useMutation(CREATE_SERVICE_CENTER, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (createData !== undefined) {
      goBack();
    }
  }, [createData]);

  
  const handleUpdate = () => {
    if (!title || !content || content === '') {
      SimpleNotification({
        title: "",
        message: "내용을 입력해주세요.",
      });
      return;
    }

    noticeCreate({
      variables: {
        type,
        category,
        title,
        content
      },
    });
  };
    
  return (
    <>
      {/* breadcrumb */}
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">고객센터</li>
            <Link to="/service-center/notice-table" className="breadcrumb-item active mt-1" aria-current="page">
              공지사항
            </Link>
            <Link to="/service-center/notice-create" className="breadcrumb-item active mt-1" aria-current="page">
              공지사항 등록
            </Link>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="mt-6 border-gray-200">
            <div>
              <h3 className="text-ml leading-6 font-medium text-black">▶︎ 공지사항 작성</h3>
            </div>
            <div className="mt-6 sm:mt-5">
              <table className="shadow-sm bg-white w-full">
                <tr>
                  <th className="bg-gray-200 border text-left px-8 py-2 text-sm w-1/6">제목</th>
                  <td colSpan="3" className="border px-8 py-2 text-sm">
                    <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                  </td>
                </tr>
              </table>
              <div className="row grid-margin">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">내용</h4>
                      <Editor data={content} onBlurGetData={(data) => setContent(data)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 grid-margin mt-3">
        <div className="row justify-end mr-1">
          <button type="button" className="btn btn-primary mr-2" onClick={handleUpdate}>
            저장
          </button>
          <button className="btn btn-light" onClick={goBack}>
            취소
          </button>
        </div>
      </div>
    </>
  );
};

CreatePage.prototype = {
  id: PropTypes.string.isRequired,
};

export default CreatePage;
