import React, {useState, useEffect} from 'react'
import { Form } from "react-bootstrap";

const EyeMuscleSubtitle = ({ item }) => {
    const [subtitles, setSubtitles] = useState(item ? item[0]?.subtitles : '');
    
    return (
        <>
            <tr>
                <td className="th w-1/6">자막</td>
                <td>{subtitles}</td>
            </tr>
        </>
    )
}

export default EyeMuscleSubtitle;