/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

// 공지사항 & FAQ 등록
export const CREATE_SERVICE_CENTER = gql`
  mutation($category: String, $group: String, $type: String, $title: String, $content: String, $topic: String, $lang: String) {
    createServiceCenter(data: { 
      category: $category,
      group: $group,
      type: $type, 
      title: $title, 
      content: $content, 
      topic: $topic,
      lang: $lang
    }) {
      id
    }
  }
`;

// 게시판 관리 - 매거진 & 이벤트 등록
export const CREATE_NOTICE_BOARD = gql`
  mutation(
    $category: String
    $title: String
    $content: String
    $image: String
    $link1: String
    $linkImage1: String
    $link2: String
    $linkImage2: String
    $link3: String
    $linkImage3: String
    $no: String
    $visible: Boolean
    $startAt: String
    $endAt: String
    $interests: [InterestCreateInput]
    $uploadSingleImage: UploadSingleImage
  ) {
    createNoticeBoard(
      data: {
        category: $category
        title: $title
        content: $content
        image: $image
        link1: $link1
        linkImage1: $linkImage1
        link2: $link2
        linkImage2: $linkImage2
        link3: $link3
        linkImage3: $linkImage3
        no: $no
        visible: $visible
        startAt: $startAt
        endAt: $endAt
      },
      interests: $interests,
      image: $uploadSingleImage,
    ) {
      id
    }
  }
`;

export const CREATE_NOTICE_BOARD_FOR_EVENT = gql`
  mutation($data: NoticeBoardCreateInput, $image: UploadSingleImage) {
    createNoticeBoard(data: $data, image: $image) {
      id
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

// 배너 등록
export const CREATE_BANNER = gql`
  mutation(
    $category: String
    $title: String
    $content: String
    $image: String
    $targetId: String
    $targetUrl: String
    $no: String
    $visible: Boolean
  ) {
    createBanner(
      data: {
        category: $category
        title: $title
        content: $content
        image: $image
        targetId: $targetId
        targetUrl: $targetUrl
        no: $no
        visible: $visible
      }
    ) {
      id
    }
  }
`;

// Store Group 생성
export const CREATE_STORE_GROUP = gql`
  mutation($adminId: String) {
    createStoreGroup(data: { adminId: $adminId }) {
      id
    }
  }
`;

// 회원사 생성
export const CREATE_EMPLOYEE = gql`
  mutation(
    $email: String
    $name: String
    $tel: String
    $password: String
    $admin: Boolean
    $storeGroupId: String
    $defaultStoreId: String
  ) {
    createMember(
      data: {
        email: $email
        name: $name
        tel: $tel
        password: $password
        admin: $admin
        storeGroupId: $storeGroupId
        defaultStoreId: $defaultStoreId
      }
    ) {
      id
    }
  }
`;
// 회원사 생성
export const CREATE_MEMBER = gql`
  mutation(
    $email: String
    $name: String
    $tel: String
    $password: String
    $admin: Boolean
    $memberOpen: Boolean
    $storeGroupId: String
    $defaultStoreId: String
  ) {
    createMember(
      data: {
        email: $email
        name: $name
        tel: $tel
        password: $password
        admin: $admin
        memberOpen: $memberOpen
        storeGroupId: $storeGroupId
        defaultStoreId: $defaultStoreId
      }
    ) {
      id
    }
  }
`;

// signedAt: $signedAt

// 상점 생성
export const CREATE_STORE = gql`
  mutation(
    $place: String
    $name: String
    $tel: String
    $post: String
    $address: String
    $detailAddress: String
    $intro: String
    $onLine: Boolean
    $offLine: Boolean
    $category: String
    $keyword: String
    $site: String
    $blog: String
    $star: String
    $storeRegister: String
    $storeGroupId: String
    $businessNumber: String
    $bankName: String
    $bankNumber: String
    $businessNumberImage: String
    $bankImage: String
    $accountHolder: String
    $image: String
    $gpsLatitude: String
    $gpsLongitude: String
  ) {
    createStore(
      data: {
        place: $place
        name: $name
        tel: $tel
        post: $post
        address: $address
        detailAddress: $detailAddress
        intro: $intro
        onLine: $onLine
        offLine: $offLine
        category: $category
        keyword: $keyword
        site: $site
        blog: $blog
        star: $star
        storeRegister: $storeRegister
        storeGroupId: $storeGroupId
        businessNumber: $businessNumber
        bankName: $bankName
        bankNumber: $bankNumber
        businessNumberImage: $businessNumberImage
        bankImage: $bankImage
        accountHolder: $accountHolder
        image: $image
        gpsLatitude: $gpsLatitude
        gpsLongitude: $gpsLongitude
      }
    ) {
      id
    }
  }
`;
// pixelro 관리자가 상품 생성
export const CREATE_PRODUCT_ADMIN = gql`
  mutation(
    $name: String
    $price: String
    $discount: Int
    $description: String
    $productInfo: String
    $brand: String
    $model: String
    $option: String
    $mainImage: String
    $detailImage: String
    $category: String
    $categoryDetail: String
    $keyword: String
    $storeId: String
    $memberId: String
  ) {
    createProductAdmin(
      data: {
        name: $name
        price: $price
        discount: $discount
        description: $description
        productInfo: $productInfo
        brand: $brand
        model: $model
        option: $option
        mainImage: $mainImage
        detailImage: $detailImage
        category: $category
        categoryDetail: $categoryDetail
        keyword: $keyword
        storeId: $storeId
        memberId: $memberId
      }
    ) {
      id
    }
  }
`;

// 쿠폰 신청
export const CREATE_COUPON = gql`
  mutation(
    $type: String
    $storeId: String
    $startAt: String
    $endAt: String
    $downloadLimit: Int
    $downloadCount: Int
    $adminActive: Boolean
    $storeActive: Boolean
    $min: Int
    $feeFree: Boolean
    $cashDiscount: Int
    $percentDiscount: Int
    $visit: Boolean
    $visitName: String
    $couponName: String
  ) {
    createCoupon(
      data: {
        type: $type
        storeId: $storeId
        startAt: $startAt
        endAt: $endAt
        downloadLimit: $downloadLimit
        downloadCount: $downloadCount
        adminActive: $adminActive
        storeActive: $storeActive
        min: $min
        feeFree: $feeFree
        cashDiscount: $cashDiscount
        percentDiscount: $percentDiscount
        visit: $visit
        visitName: $visitName
        couponName: $couponName
      }
    ) {
      id
    }
  }
`;

// 픽셀로 관리자 등록
export const CREATE_PIXELRO = gql`
  mutation($name: String, $tel: String, $email: String, $password: String) {
    createPixelro(data: { name: $name, tel: $tel, email: $email, password: $password }) {
      id
    }
  }
`;

// 픽셀로 관리자 LOG
export const CREATE_LOG = gql`
  mutation($adminEmail: String, $ip: String, $url: String, $target: String, $detail: String) {
    createLog(data: { adminEmail: $adminEmail, ip: $ip, url: $url, target: $target, detail: $detail }) {
      id
    }
  }
`;

// 싱글 파일 업로드
export const SINGLE_FILE = gql`
  mutation($file: Upload!) {
    singleUpload(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

// 멀티 파일 업로드
export const MULTI_FILE = gql`
  mutation($files: [Upload], $size: String) {
    multiUpload(files: $files, size: $size) {
      filename
      mimetype
      encoding
    }
  }
`;

// 멀티 파일 업로드, 리사이즈
export const MULTI_FILE_RESIZE = gql`
  mutation($files: [Upload], $size: String) {
    multiUploadResize(files: $files, size: $size) {
      filename
      mimetype
      encoding
    }
  }
`;

export const CREATE_COMPANY_INFO = gql`
  mutation($data: CompanyInfoCreateInput, $image: UploadSingleImage) {
    createCompanyInfo(data:$data, image:$image) {
      id
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`
export const CREATE_EXPERT = gql`
  mutation($data: ExpertCreateInput, $image: UploadSingleImage, $interests: [InterestCreateInput]) {
    createExpert(data: $data, image:$image, interests: $interests) {
      id
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

export const CREATE_EXERCISE = gql`
  mutation($data: ExerciseCreateInput, $image: UploadSingleImage) {
    createExercise(data: $data, image:$image) {
      id
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

export const CREATE_EXERCISE_TYPE1 = gql`
  mutation($data: ExerciseType1CreateInput, $image: UploadSingleImage, $subDatas: [ExerciseSubCreateInput]) {
    createExerciseType1withSubs(data: $data, image: $image, subDatas: $subDatas) {
      id
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

export const CREATE_EXERCISE_TYPE2 = gql`
  mutation($data: ExerciseType2CreateInput, $image: UploadSingleImage, $subDatas: [ExerciseSubCreateInput]) {
    createExerciseType2withSubs(data: $data, image: $image, subDatas: $subDatas) {
      id
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

export const CREATE_EXERCISE_TYPE3 = gql`
  mutation($data: ExerciseType3CreateInput, $openImage: UploadSingleImage, $closeImage: UploadSingleImage, $subDatas: [ExerciseSubCreateInput]) {
    createExerciseType3withSubs(data: $data, openImage: $openImage, closeImage: $closeImage, subDatas: $subDatas) {
      id
      eyeCloseImage
      eyeOpenImage
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

export const CREATE_EXERCISE_TYPE4 = gql`
  mutation($data: ExerciseType4CreateInput, $openImage: UploadSingleImage, $closeImage: UploadSingleImage, $subDatas: [ExerciseSubCreateInput]) {
    createExerciseType4withSubs(data: $data, openImage: $openImage, closeImage: $closeImage, subDatas: $subDatas) {
      id
      eyeCloseImage
      eyeOpenImage
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

export const CREATE_EXERCISE_TYPE5 = gql`
  mutation(
    $data: ExerciseType5CreateInput, 
    $imageStep1: UploadSingleImage, $imageStep2: UploadSingleImage, $imageStep3: UploadSingleImage, $imageStep4: UploadSingleImage, $imageStep5: UploadSingleImage, 
    $subDatas: [ExerciseSubCreateInput]
    ){
    createExerciseType5withSubs(
      data: $data, 
      imageStep1: $imageStep1, imageStep2: $imageStep2, imageStep3: $imageStep3, imageStep4: $imageStep4, imageStep5: $imageStep5,
      subDatas: $subDatas
      ) {
      id
      imageStep1
      imageStep2
      imageStep3
      imageStep4
      imageStep5
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`

export const CREATE_EXERCISE_TYPE6 = gql`
  mutation($data: ExerciseType6CreateInput, $subDatas: [ExerciseSubCreateInput]) {
    createExerciseType6withSubs(data: $data, subDatas: $subDatas) {
      id
    }
  }
`

export const CREATE_MANY_ALARM_LOG = gql`
  mutation($data: [AlarmLogCreateInput]) {
    createManyAlarmLog(data: $data) {
      result
    }
  }
`