import React, { useState, useEffect } from 'react'
import RestSubtitle from './Subtitle'
import { Form } from "react-bootstrap";
import { getImagePathForObject } from "components/common"

const RestLayout = ({ colspan, isKorea, lang, idx, item }) => {

  const [image, setImage] = useState(item.fileInfos ? getImagePathForObject(item) : '');
  const [name, setName] = useState(item?.name);
  const [time, setTime] = useState(item?.time);
  const [priority, setPriority] = useState(item?.priority);

    return (
      <table className="app-table w-full">
        <tr>
            <td className="th w-1/6">유형 제목</td>
            <td colspan={colspan}> {name} </td>
        </tr>
        { 
          isKorea? (
            <>
              <tr>
                <td className="th w-1/6">이미지</td>
                <td colspan={colspan}>
                  <div className="form-file">
                    <img src={image} className="profile_preview" />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="th w-1/6">시간</td>
                <td colspan={colspan}>{time}</td>
              </tr>
              <tr>
                <td className="th w-1/6">정렬 순서</td>
                <td colspan={colspan}>{priority}</td>
              </tr>
            </>
          ) : ''
        }
        <RestSubtitle item={item.exerciseSubs}/>
      </table>  
    )
}

export default RestLayout;