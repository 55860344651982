/* eslint-disable react/sort-comp */
/* eslint-disable react/state-in-constructor */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";

class Sidebar extends Component {
  constructor() {
    super()
    this.state = {}
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/delivery", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/customers", state: "customersMenuOpen" },
      { path: "/app-customers", state: "appCustomersMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/cms", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/registered-store", state: "registeredMenuOpen" },
      { path: "/unregistered-store", state: "unRegisteredMenuOpen" },
      { path: "/app-users", state: "appUsersMenuOpen" },
      { path: "/members", state: "membersMenuOpen" },
      { path: "/service-center", state: "serviceCenterMenuOpen" },
      { path: "/pixelro", state: "pixelroMenuOpen" },
      { path: "/notice-board", state: "noticeBoardMenuOpen" },
      { path: "/old", state: "oldMenuOpen" },
      { path: "/company", state: "companyMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="pop-bg"></div>
        <div className="wrap">
          <div className="logo">
            <a href="/dashboard" className="img-logo"></a>
          </div>
          <ul className="nav">
            <li className={this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" to="/dashboard">
                <i className="ti-home menu-icon" />
                <span className="menu-title">홈</span>
              </Link>
            </li>
            {/* 앱 멤버 관리 */}
            <li className={this.isPathActive("/app-users") ? "nav-item active" : "nav-item"}>
              <div
                className={this.state.appUsersMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={() => this.toggleMenuState("appUsersMenuOpen")}
                data-toggle="collapse"
              >
                <i className="ti-user menu-icon" />
                <span className="menu-title">사용자 관리</span>
              </div>
              <Collapse in={this.state.appUsersMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link className={this.isPathActive("/app-users/user-table") ? "nav-link active" : "nav-link"} to="/app-users/user-table">
                      회원 목록
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={this.isPathActive("/app-users/expert-table") ? "nav-link active" : "nav-link"}
                      to="/app-users/expert-table"
                    >
                      전문가 목록
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={this.isPathActive("/app-users/app-push-message") ? "nav-link active" : "nav-link"}
                      to="/app-users/app-push-message"
                    >
                      푸시 메시지 발송
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={this.isPathActive("/app-users/search-history") ? "nav-link active" : "nav-link"}
                      to="/app-users/search-history"
                    >
                      검색기록 내역
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            {/* 게시판 관리 */}
            <li className={this.isPathActive("/notice-board") ? "nav-item active" : "nav-item"}>
              <div
                className={this.state.noticeBoardMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={() => this.toggleMenuState("noticeBoardMenuOpen")}
                data-toggle="collapse"
              >
                <i className="ti-list menu-icon" />
                <span className="menu-title">게시판 관리</span>
              </div>
              <Collapse in={this.state.noticeBoardMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={this.isPathActive("/notice-board/magazine-table") ? "nav-link active" : "nav-link"}
                      to="/notice-board/magazine-table"
                    >
                      매거진
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={this.isPathActive("/notice-board/event") ? "nav-link active" : "nav-link"}
                      to="/notice-board/event"
                    >
                      이벤트
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            {/* 눈 운동 관리 */}
            <li className={this.isPathActive("/eye-movement") ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" to="/eye-movement/table">
                {/*<img className="menu-icon mr-4" src="/exercise.png"/>*/}
                <i className="ti-eye menu-icon" />
                <span className="menu-title">눈 운동 관리</span>
              </Link>
            </li>
            {/* 고객센터 */}
            <li className={this.isPathActive("/service-center") ? "nav-item active" : "nav-item"}>
              <div
                className={this.state.serviceCenterMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={() => this.toggleMenuState("serviceCenterMenuOpen")}
                data-toggle="collapse"
              >
                <i className="ti-comment-alt menu-icon" />
                <span className="menu-title">고객센터</span>
              </div>
              <Collapse in={this.state.serviceCenterMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={this.isPathActive("/service-center/notice-table") ? "nav-link active" : "nav-link"}
                      to="/service-center/notice-table"
                    >
                      공지사항
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={this.isPathActive("/service-center/app-user-faq-table") ? "nav-link active" : "nav-link"}
                      to="/service-center/app-user-faq-table"
                    >
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={this.isPathActive("/service-center/app-user-qna-table") ? "nav-link active" : "nav-link"}
                      to="/service-center/app-user-qna-table"
                    >
                      의견보내기
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            {/* 회사정보 관리 */}
            <li className={this.isPathActive("/company") ? "nav-item active" : "nav-item"}>
              <div
                className={this.state.companyMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={() => this.toggleMenuState("companyMenuOpen")}
                data-toggle="collapse"
              >
                <i className="ti-blackboard menu-icon" />
                  <span className="menu-title">회사정보 관리</span>
              </div>
              <Collapse in={this.state.companyMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={this.isPathActive("/company/provision-table") ? "nav-link active" : "nav-link"}
                      to="/company/provision-table"
                    >
                      약관 관리
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={this.isPathActive("/company/company-info-detail") ? "nav-link active" : "nav-link"}
                      to="/company/company-info-detail"
                    >
                      기업 정보
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={this.isPathActive("/company/partner-table") ? "nav-link active" : "nav-link"}
                      to="/company/partner-table"
                    >
                      협력사 정보
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            {/* 관리자 목록 */}
            <li className={this.isPathActive("/pixelro") ? "nav-item active" : "nav-item"}>
              <div
                className={this.state.pixelroMenuOpen ? "nav-link menu-expanded" : "nav-link"}
                onClick={() => this.toggleMenuState("pixelroMenuOpen")}
                data-toggle="collapse"
              >
                <i className="ti-crown menu-icon" />
                  <span className="menu-title">관리자</span>
              </div>
              <Collapse in={this.state.pixelroMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={this.isPathActive("/pixelro/pixelro-table") ? "nav-link active" : "nav-link"}
                      to="/pixelro/pixelro-table"
                    >
                      관리자 목록
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          </ul>
        </div>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
    const popBg = document.querySelector('#sidebar .pop-bg');
    const sideBar = document.querySelector('#sidebar');
    popBg.addEventListener('click', () => {
      sideBar.classList.remove("active");
    });
  }
}

export default withRouter(Sidebar);
