import React, { useState, useEffect } from 'react'
import RestSubtitle from './Subtitle'
import { Form } from "react-bootstrap";
import { checkImage, getImagePathForObject, getNumberSelectBox } from "components/common"

const RestLayout = ({ colspan, isKorea, lang, idx, maxComponent, info, subtitles }) => {
  const handleImageChange = async (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    if (!checkImage(file.name, file.size)) return;
    info.paramImage = file;
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => setImage([reader.result]);
  };

  const [image, setImage] = useState(getImagePathForObject(info));
  const [name, setName] = useState(info.name);
  const [time, setTime] = useState(info.time);
  const [priority, setPriority] = useState(info.priority);

  useEffect(() => {
    info.name = name;
  }, [name])

  useEffect(() => {
    info.time = time;
  }, [time])

  useEffect(() => {
    info.priority = priority;
  }, [priority]);

    return (
      <table className="app-table w-full">
        <tr>
            <td className="th w-1/6">유형 제목</td>
            <td colspan={colspan}>
                <Form.Control type="text" placeholder="유형 제목을 입력하세요." value={name} onChange={e => setName(e.target.value)}/>
            </td>
        </tr>
        { 
          isKorea? (
            <>
              <tr>
                <td className="th w-1/6">이미지 선택</td>
                <td colspan={colspan}>
                  <div className="form-file">
                    <img src={image} className="profile_preview" />
                    <form encType="multipart/form-data" className="w-full">
                      <label className="" for={`img_primary${idx}`} className="mb-0">
                        이미지를 선택하세요. (지원 이미지 png, jpg, gif)
                        <i className="ti-upload"></i>
                      </label>
                      <input
                        name="document"
                        id={`img_primary${idx}`}
                        type="file"
                        accept=".jpg,.png,.gif"
                        method="POST"
                        onChange={(e) => handleImageChange(e)}
                      />
                    </form>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="th w-1/6">시간 설정</td>
                <td colspan={colspan}>
                  <Form.Control type="number" placeholder="유형 진행 시간을 입력하세요. (초단위)" value={time} onChange={e => setTime(e.target.value)} />
                </td>
              </tr>
              <tr>
                <td className="th w-1/6">정렬 순서 선택</td>
                <td colspan={colspan}>
                  { getNumberSelectBox(maxComponent, { onChange : e => setPriority(e.value)}, priority) }              
                </td>
              </tr>
            </>
          ) : ''
        }
        <RestSubtitle item={subtitles[0][lang]}/>
      </table>  
    )
}

export default RestLayout;