import { useQuery } from "@apollo/react-hooks";
import { AddComma, getImagePath2, LoadingSpinner } from "components/common";
import { USER_COUNT_QUERY, USER_TODAY_COUNT, USER_DELETE_COUNT, EXERCISE_RESULT_TODAY_POPULAR, EYE_CHECK_TODAY_POPULAR, APP_QNA_COUNT, APP_QNA_TODAY_COUNT, EXPERT_POPULAR } from "graphql/gql/select";
import React, { useState } from "react";
import { useHistory } from "react-router";
import useSettings from "stores/settings";

const DashBoard = () => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }
  
  const [cardTitleStyle, setCardTitleStyle] = useState({ fontWeight: "700" });
  const [cardStrongStyle, setCardStrongStyle] = useState({ ...cardTitleStyle, fontSize:"20px" });
  const [cardSpinnerStyle, setCardSpinnerStyle] = useState({ marginTop: "1rem" });
  const [cardImageSize, setCardImagesize] = useState("60px");
  const [cardContentClass, setCardContentClass] = useState("ml-4");
  const [cardContentInClass, setCardContentInClass] = useState("mt-2");
  const date = new Date();

  return (
    <main className="dashboard">
      <section className="my-4">
        <strong style={{ fontSize: "18px" }}>안녕하세요.</strong><br/>
        <span style={{ fontSize: "16px" }}>{settings.email}님, {date.getFullYear()}년 {date.getMonth() + 1}월 {date.getDate()}일입니다.</span>
      </section>
      
      <section className="my-4 container-fluid p-0 m-0">
        <article className="flex-row-left align-items-stretch">
          <div className="flex-col-left flex-grow-1">
            <div className="flex-row-left flex-grow-1">
              {/* 누적 가입자 수 */}
              <div className="col bg-white h-full">
                <div className="flex-row-left h-full">
                  <Users imageSize={cardImageSize} contentClass={cardContentClass} contentInClass={cardContentInClass} titleStyle={cardTitleStyle} spinnerStyle={cardSpinnerStyle}/>
                </div>
              </div>
              {/* 누적 탈퇴자 수 */}
              <div className="col bg-white h-full ml-4">
                <div className="flex-row-left h-full">
                  <Withdraws imageSize={cardImageSize} contentClass={cardContentClass} contentInClass={cardContentInClass} titleStyle={cardTitleStyle} spinnerStyle={cardSpinnerStyle}/>
                </div>
              </div>
            </div>
            <div className="flex-row-left flex-grow-1 mt-4">
              {/* 누적 IOS 가입자 수 */}
              <div className="col bg-white h-full">
                <div className="flex-row-left h-full">
                  <IOSUsers imageSize={cardImageSize} contentClass={cardContentClass} contentInClass={cardContentInClass} titleStyle={cardTitleStyle} spinnerStyle={cardSpinnerStyle}/>
                </div>
              </div>
              {/* 누적 AOS 가입자 수 */}
              <div className="col bg-white h-full ml-4">
                <div className="flex-row-left h-full">
                  <AOSUsers imageSize={cardImageSize} contentClass={cardContentClass} contentInClass={cardContentInClass} titleStyle={cardTitleStyle} spinnerStyle={cardSpinnerStyle}/>
                </div>
              </div>
            </div>
          </div>

          {/* 자주 사용한 눈 운동 제작자 랭킹 */}
          <Experts />
        </article>
      </section>

      <section className="my-4 container-fluid p-0 m-0">

        <article className="row p-0 m-0">
          {/* 오늘 가입자 수 */}
          <div className="col bg-white">
            <div className="row my-3 pl-3">
              <TodayJoin imageSize={cardImageSize} contentClass={cardContentClass} contentInClass={cardContentInClass} titleStyle={cardTitleStyle} spinnerStyle={cardSpinnerStyle}/>
            </div>
          </div>

          {/* 오늘 로그인 회원 수 */}
          <div className="col ml-4 bg-white">
            <div className="row my-3 pl-3">
              <TodayLogin imageSize={cardImageSize} contentClass={cardContentClass} contentInClass={cardContentInClass} titleStyle={cardTitleStyle} spinnerStyle={cardSpinnerStyle}/>
            </div>
          </div>

          {/* 오늘 인기 눈 검사 */}
          <div className="col ml-4 bg-white">
            <div className="row my-3 pl-3">
              <TodayTest imageSize={cardImageSize} contentClass={cardContentClass} contentInClass={cardContentInClass} titleStyle={cardTitleStyle} spinnerStyle={cardSpinnerStyle} strongStyle={cardStrongStyle}/>
            </div>
          </div>
        </article>

        <article className="row p-0 m-0 mt-4">

          {/* 오늘 인기 눈 운동 */}
          <div className="col bg-white">
            <div className="row my-3 pl-3">
              <TodayExercise imageSize={cardImageSize} contentClass={cardContentClass} contentInClass={cardContentInClass} titleStyle={cardTitleStyle} spinnerStyle={cardSpinnerStyle} strongStyle={cardStrongStyle}/>
            </div>
          </div>

          {/* 오늘 사용자 의견 문의 수 */}
          <div className="col ml-4 bg-white">
            <div className="row my-3 pl-3">
              <TodayQNA imageSize={cardImageSize} contentClass={cardContentClass} contentInClass={cardContentInClass} titleStyle={cardTitleStyle} spinnerStyle={cardSpinnerStyle}/>
            </div>
          </div>

          {/* 사용자 의견 미처리 건 수 */}
          <div className="col ml-4 bg-white">
            <div className="row my-3 pl-3">
              <TodayMiss imageSize={cardImageSize} contentClass={cardContentClass} contentInClass={cardContentInClass} titleStyle={cardTitleStyle} spinnerStyle={cardSpinnerStyle}/>
            </div>
          </div>
        </article>
      </section>
    </main> 
  );
}

const Users = ({ imageSize, contentClass, contentInClass, titleStyle, spinnerStyle }) => {
  const { loading:countLoading, data:countData } = useQuery(USER_COUNT_QUERY, { variables: { where : { withdraw: false } }, fetchPolicy: "network-only" });
  
  if (countLoading) return <LoadingSpinner styled={spinnerStyle}/>

  return (
    <>
      <img width={imageSize} src="/users.png" alt="현재 가입자 수"/>
      <div className={contentClass} style={titleStyle}>
        <div>
          현재 가입자 수
        </div>
        <div className={contentInClass}>
          <span className="lead">{AddComma(countData?.userCountQuery)}</span> 명
        </div>
      </div>
    </>
  )
}

const Withdraws = ({ imageSize, contentClass, contentInClass, titleStyle, spinnerStyle }) => {  
  const { loading:countLoading, data:countData } = useQuery(USER_DELETE_COUNT, { fetchPolicy: "network-only" });
  
  if (countLoading) return <LoadingSpinner styled={spinnerStyle}/>

  return (
    <>
      <img width={imageSize} src="/withdraws.png" alt="누적 탈퇴자 수"/>
      <div className={contentClass} style={titleStyle}>
        <div>
          누적 탈퇴자 수
        </div>
        <div className={contentInClass}>
          <span className="lead">{AddComma(countData?.userDeleteCount)}</span> 명
        </div>
      </div>
    </>
  )
}

const IOSUsers = ({ imageSize, contentClass, contentInClass, titleStyle, spinnerStyle }) => {
  const { loading:countLoading, data:countData } = useQuery(USER_COUNT_QUERY, { variables: { where : { withdraw: false, deviceOs: "I" } }, fetchPolicy: "network-only" });
  
  if (countLoading) return <LoadingSpinner styled={spinnerStyle}/>

  return (
    <>
      <img width={imageSize} src="/ios_users.png" alt="누적 iOS 가입자 수"/>
      <div className={contentClass} style={titleStyle}>
        <div>
          누적 iOS 가입자 수
        </div>
        <div className={contentInClass}>
          <span className="lead">{AddComma(countData?.userCountQuery)}</span> 명
        </div>
      </div>
    </>
  )
}

const AOSUsers = ({ imageSize, contentClass, contentInClass, titleStyle, spinnerStyle }) => {
  const { loading:countLoading, data:countData } = useQuery(USER_COUNT_QUERY, { variables: { where : { withdraw: false, deviceOs: "A" } }, fetchPolicy: "network-only" });
  
  if (countLoading) return <LoadingSpinner styled={spinnerStyle}/>

  return (
    <>
      <img width={imageSize} src="/aos_users.png" alt="누적 AOS 가입자 수"/>
      <div className={contentClass} style={titleStyle}>
        <div>
          누적 AOS 가입자 수
        </div>
        <div className={contentInClass}>
          <span className="lead">{AddComma(countData?.userCountQuery)}</span> 명
        </div>
      </div>
    </>
  )
}

const Experts = () => {
  const { loading:countLoading, data:countData } = useQuery(EXPERT_POPULAR, { variables: { fetchPolicy: "network-only" }});
  
  if (countLoading) return <LoadingSpinner/>
  if (!countLoading && countData?.expertPopular?.length < 3) {
    while(countData.expertPopular.length < 3)
      countData.expertPopular.push({});
  }

  return (
    <>
      <div className="ml-4 flex-grow-1 ranking-item">
        <div
          className="p-3 flex-col-left"
          style={{
            backgroundColor: "#58BFAB",
            width: "100%",
            height: "100%",
            color: "#FFFFFF",
          }}
        >
          <div style={{ fontWeight: "700", fontSize: "16px" }}>
            자주 사용한 눈 운동 제작자 랭킹
          </div>
          <div className="mt-2">
            {
              countData?.expertPopular?.map((data, idx) => (
                <div className="mt-2 flex-row-left">
                  <div style={{ fontWeight: "800", fontSize: "20px" }}>{idx + 1}위</div>
                  <div className="ml-3">
                    <img
                      width="38"
                      src={getImagePath2(data)}
                      style={{ borderRadius: "50%" }}
                      alt={data?.expertName ? data.expertName : undefined}
                    />
                  </div>
                  <div className="ml-3 flex-col-left">
                    <div style={{ fontWeight: "700", fontSize: "14px" }}>
                      {data?.expertName ? data.expertName : "-"}
                    </div>
                    <div style={{ fontWeight: "400", fontSize: "12px" }}>
                      {data?.companyName ? data.companyName : "-"}
                    </div>
                  </div>
                </div>  
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
}

const TodayJoin = ({ imageSize, contentClass, contentInClass, titleStyle, spinnerStyle }) => {
  const { loading:countLoading, data:countData } = useQuery(USER_TODAY_COUNT, { variables: { where : { withdraw: false }, targetField: "createAt" }, fetchPolicy: "network-only" });
  
  if (countLoading) return <LoadingSpinner styled={spinnerStyle}/>

  return (
    <>
      <img width={imageSize} src="/today_join.png" alt="오늘 가입자 수"/>
      <div className={contentClass} style={titleStyle}>
        <div>
          오늘 가입자 수
        </div>
        <div className={contentInClass}>
          <span className="lead">{AddComma(countData?.userTodayCount)}</span> 명
        </div>
      </div>
    </>
  )
}

const TodayLogin = ({ imageSize, contentClass, contentInClass, titleStyle, spinnerStyle }) => {
  const { loading:countLoading, data:countData } = useQuery(USER_TODAY_COUNT, { variables: { where : { withdraw: false }, targetField: "loginAt" }, fetchPolicy: "network-only" });
  
  if (countLoading) return <LoadingSpinner styled={spinnerStyle}/>

  return (
    <>
      <img width={imageSize} src="/today_login.png" alt="오늘 로그인 회원 수"/>
      <div className={contentClass} style={titleStyle}>
        <div>
          오늘 로그인 회원 수
        </div>
        <div className={contentInClass}>
          <span className="lead">{AddComma(countData?.userTodayCount)}</span> 명
        </div>
      </div>
    </>
  )
}

const TodayTest = ({ imageSize, contentClass, contentInClass, titleStyle, spinnerStyle, strongStyle }) => {
  const { loading:countLoading, data:countData } = useQuery(EYE_CHECK_TODAY_POPULAR, { fetchPolicy: "network-only" });
  
  if (countLoading) return <LoadingSpinner styled={spinnerStyle}/>

  return (
    <>
      <img width={imageSize} src="/today_test.png" alt="오늘 인기 눈 검사"/>
      <div className={contentClass} style={titleStyle}>
        <div>
          오늘 인기 눈 검사
        </div>
        <div className={contentInClass} style={strongStyle}>
          {countData?.eyeCheckTodayPopular ? countData.eyeCheckTodayPopular : "-"}
        </div>
      </div>
    </>
  )
}

const TodayExercise = ({ imageSize, contentClass, contentInClass, titleStyle, spinnerStyle, strongStyle }) => {
  const { loading:countLoading, data:countData } = useQuery(EXERCISE_RESULT_TODAY_POPULAR, { fetchPolicy: "network-only" });
  
  if (countLoading) return <LoadingSpinner styled={spinnerStyle}/>

  return (
    <>
      <img width={imageSize} src="/today_exercise.png" alt="오늘 인기 눈 운동"/>
      <div className={contentClass} style={titleStyle}>
        <div>
          오늘 인기 눈 운동
        </div>
        <div className={contentInClass} style={strongStyle}>
          {countData?.exerciseResultTodayPopular ? countData.exerciseResultTodayPopular : "-"}
        </div>
      </div>
    </>
  )
}

const TodayQNA = ({ imageSize, contentClass, contentInClass, titleStyle, spinnerStyle }) => {
  const { loading:countLoading, data:countData } = useQuery(APP_QNA_TODAY_COUNT, { fetchPolicy: "network-only" });
  
  if (countLoading) return <LoadingSpinner styled={spinnerStyle}/>

  return (
    <>
      <img width={imageSize} src="/today_qna.png" alt="오늘 사용자 의견 문의 수"/>
      <div className={contentClass} style={titleStyle}>
        <div>
          오늘 사용자 의견 문의 수
        </div>
        <div className={contentInClass}>
          <span className="lead">{AddComma(countData?.appQnaTodayCount)}</span> 건
        </div>
      </div>
    </>
  )
}

const TodayMiss = ({ imageSize, contentClass, contentInClass, titleStyle, spinnerStyle }) => {
  const { loading:countLoading, data:countData } = useQuery(APP_QNA_COUNT, { where : { reply : '0' }, fetchPolicy: "network-only" });
  
  if (countLoading) return <LoadingSpinner styled={spinnerStyle}/>

  return (
    <>
      <img width={imageSize} src="/today_miss.png" alt="사용자 의견 미처리 건 수"/>
      <div className={contentClass} style={titleStyle}>
        <div>
          사용자 의견 미처리 건 수
        </div>
        <div className={contentInClass}>
          <span className="lead">{AddComma(countData?.appQnaCount)}</span> 건
        </div>
      </div>
    </>
  )
}



export default DashBoard