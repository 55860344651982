import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import { useHistory, Link } from "react-router-dom";
import useSettings from "stores/settings";
import { ALL_PIXELROS } from "graphql/gql/select";
import { useQuery } from "@apollo/react-hooks";
import { FormatDateShort } from "components/FormatDate";
import { PhoneFormatter } from "components/PhoneFormatter";
import { SimpleNotification } from "components/SimpleNotification";
import { LoadingSpinner } from "components/common";

const ReactContentPage = () => {
  // 로그인 체크
  const settings = useSettings();
  const history = useHistory();
  if (!settings.isLogin) {
    history.push("/adminIn");
  }

	const [result, setResult] = useState();

	const { loading, error, data } = useQuery(ALL_PIXELROS, {
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (data?.allPixelros) {
      const pixelros = data.allPixelros
      pixelros.sort((a, b) => { return b.createAt - a.creatAt });
			setResult(pixelros);
		}
	}, [data]);

	if (error) {
		console.log(error);
		SimpleNotification({ type: 'danger', message: error.message || 'Error' });
	}

	if (loading || !result) return <LoadingSpinner />;

  return (
    <div>
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">관리자</li>
            <Link to="/pixelro/pixelro-table" className="breadcrumb-item active mt-1" aria-current="page">
              관리자 목록
            </Link>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">관리자 목록</h4>
                <span className="card-sub">{`Total : ${result.length}`}</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <div>
                    <ReactTable
                      data={result}
                      className="card-wrap"
                      filterable
                      defaultPageSize={10}
                      columns={[
                        {
                          Header: "이메일",
                          id: "email",
                          accessor: (d) => d.email,
                          filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
                          filterAll: true,
                        },
                        {
                          Header: "이름",
                          id: "name",
                          accessor: (d) => d.name,
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: ["name"],
                            }),
                          filterAll: true,
                        },
                        {
                          Header: "전화번호",
                          id: "tel",
                          accessor: (d) => d.tel,
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: ["tel"],
                            }),
                          filterAll: true,
                          Cell: (row) => PhoneFormatter(row.value),
                        },

                        {
                          Header: "등록날짜",
                          id: 'createAt',
                          accessor: "createAt",
                          Filter: () => false,
                          Cell: (row) => <div>{`${FormatDateShort(new Date(parseInt(row.value, 10)))}`}</div>,
                        },
                        {
                          Header: "",
                          id: 'edit',
                          accessor: "edit",
                          Filter: () => false,
                          Cell: (row) => (
                            <div className="flex">
                              <div>
                                {settings.email === "pixelro" || settings.email === row.original.email ? (
                                  <div>
                                    <Link className="ti-pencil-alt" to={`/pixelro/pixelro-edit/${row.original.id}`}>
                                      수정하기
                                    </Link>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {settings.email === "pixelro" ? (
        <div className="flex justify-end btnbar">
          <Link className="btn btn-primary" to="/pixelro/pixelro-create">
            등록하기
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReactContentPage;
