import React, {useState, useEffect} from 'react'
import { Form } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_EXERCISE_SUB } from "graphql/gql/delete";
import { SimpleNotification } from "components/SimpleNotification";
import { hasValue } from "components/common";

const DefaultSubtitles = ({ isKorea, lang, colspan, item }) => {
    return (
        <>
            {
                item ?
                    item.map((subtitle, idx) => 
                        <Subtitle key={subtitle.id.toString()} id={subtitle.id} item={subtitle} rowSpan={item.length+1} isKorea={isKorea} canDelete={idx!==0}/>)
                : (
                    <tr>
                        <td className="th w-1/6">자막</td>
                        <td className="w-3/6"></td>
                        <td className="w-2/6"></td>
                    </tr>
                )
            }
        </>
    )
}

const Subtitle = ({ id, item, isKorea, rowSpan, canDelete }) => {
    const [subtitles, setSubtitles] = useState(item.subtitles);
    const [time, setTime] = useState(item.time);
    
    return ( 
        <tr>
            { !canDelete ? <td className="th w-1/6" rowSpan={rowSpan}>자막</td> : '' }
            <td className="w-3/6">{subtitles}</td>
            <td className="w-2/6">{time}</td>
        </tr>             
    )
}

export default DefaultSubtitles;