/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Form } from "react-bootstrap";
import { CREATE_NOTICE_BOARD } from "graphql/gql/insert";
import { SimpleNotification } from "components/SimpleNotification";
import Interests, { addInterestCode, deleteInterestCode } from "app/shared/Interest"  
import Editor from "app/shared/Editor"
import { checkImage } from "components/common";
import useSettings from "stores/settings";

const CreatePage = () => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const goBack = () => {
    history.push("/notice-board/magazine-table");
  };

  // --------------------------
  // -- 데이터 값 저장 --
  const category = "1";
  const [image, setImage] = useState();
  const [paramImage, setParamImage] = useState();
  const [title, setTitle] = useState();
  const [content, setContent] = useState("");
  const [visible, setVisible] = useState(true);
  const [link1, setLink1] = useState();
  const [urlOpen1, setUrlOpen1] = useState(false); //유튜브 링크

  // 데이터 저장
  const [boardCreate, { data: createData }] = useMutation(CREATE_NOTICE_BOARD, {
    onError(err) {
      console.log("updateAdmin: err=", err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (createData) goBack();
  }, [createData])
  
  // 0. 저장 버튼 클릭
  const handleCreate = async () => {

    if (!title) {
      window.scrollTo(0, 0);
      SimpleNotification({
        title: "Error",
        message: "제목을 입력해주세요.",
      });
      return;
    }

    if (!image) {
      SimpleNotification({
        title: "Error",
        message: "이미지를 등록해주세요.",
      });
      return;
    }

    if (!content || content === "") {
      SimpleNotification({
        title: "Error",
        message: "내용을 입력해주세요.",
      });
      return;
    }

    // 데이터 저장
    boardCreate({
      variables: {
        category,
        title,
        content,
        link1,
        visible,
        no: "",
        startAt: "",
        endAt: "",
        interests: selectedInterestCode.map(code => { return { value: code } }),
        uploadSingleImage : {
          image: paramImage,
          size: paramImage.size
        }
      }
    });
  };

  // 메인 이미지 변경
  const handleImageChange = async (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    if (!checkImage(file.name, file.size)) return;
    setParamImage(file); //bytearray 형태로 서버에 보낼 데이터
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => setImage([reader.result]); //base64 형태로 화면에 보여줄 데이터
  };

  const [selectedInterestCode, setSelectedInterestCode] = useState([]);

  return (
    <>
      {/* breadcrumb */}
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">게시판 관리</li>
            <Link
              to="/notice-board"
              className="breadcrumb-item active mt-1"
              aria-current="page"
            >
              매거진
            </Link>
            <Link
              to="/notice-board/magazine-create"
              className="breadcrumb-item active mt-1"
              aria-current="page"
            >
              매거진 등록
            </Link>
          </ol>
        </nav>
      </div>
      {/* 매거진 정보 */}
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">매거진 등록</h4>
              </div>
              <div className="mt-6">
                {/* 배너 이미지 등록 */}
                <div className="card-wrap">
                  <table className="app-table bg-white w-full">
                    <tr>
                      <td className="th w-1/6">대표 이미지</td>
                      <td>
                        <div className="">
                          <div className="w-full">
                            <div className="form-file">
                              <form
                                encType="multipart/form-data"
                                className="w-full"
                              >
                                <label
                                  className=""
                                  for="img_primary"
                                  className="mb-0"
                                >
                                  파일을 선택하세요.
                                  <i className="ti-upload"></i>
                                </label>
                                <input
                                  name="document"
                                  id="img_primary"
                                  type="file"
                                  accept="image/*"
                                  method="POST"
                                  onChange={handleImageChange}
                                />
                              </form>
                            </div>
                          </div>
                          <img
                            src={image}
                            className="mgz-upload-thumb"
                          />
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                {/* url 등록 */}
                <div className="card-wrap">
                  <table className="app-table bg-white w-full">
                    {/* url1 */}
                    <tr>
                      <td className="th w-1/6">동영상 링크</td>
                      <td>
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={link1}
                          readOnly={urlOpen1}
                          onChange={(e) => setLink1(e.target.value)}
                          placeholder="Youtube 공유 링크를 넣어주세요."
                        />
                      </td>
                    </tr>
                  </table>
                </div>
                {/* 내용 등록 */}
                <div className="card-wrap">
                  <table className="app-table bg-white w-full">
                    <tr>
                      <td className="th w-1/6">제목</td>
                      <td>
                        <Form.Control
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="th w-1/6">입력태그</td>
                      <td>
                        <Interests lang="ko" selectMode={true} selectedInterestCode={selectedInterestCode} addInterestCode={addInterestCode} deleteInterestCode={deleteInterestCode} />
                      </td>
                    </tr>
                  </table>
                </div>

                {/* 내용 소개 */}
                <div className="row mt-2">
                  <div className="col-md-12">
                    <Editor data={content} onBlurGetData={(data) => setContent(data)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 btnbar">
        <div className="row foot-edit">
          <button className="btn btn-warning" onClick={goBack}>
            취소하기
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleCreate}
          >
            저장하기
          </button>
        </div>
      </div>
    </>
  );
};

CreatePage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default CreatePage;
