import React, { useEffect, useState, useRef } from 'react';
import ReactTable from 'react-table';
import { useHistory, Link } from 'react-router-dom';
import useSettings from 'stores/settings';
import { AllCommonCodes } from 'graphql/query/select';
import { ALL_EXPERTS } from 'graphql/gql/select';
import { Tabs, Tab, Form } from 'react-bootstrap';
import { FormatDateShort } from 'components/FormatDate';
import { PhoneFormatter } from 'components/PhoneFormatter';
import {  useQuery } from '@apollo/react-hooks';
import { SimpleNotification } from 'components/SimpleNotification';
import { LoadingSpinner } from 'components/common';
import matchSorter from "match-sorter";

const ReactContentPage = () => {
	// 로그인 체크
	const settings = useSettings();
	const history = useHistory();
	if (!settings.isLogin) {
		history.push('/adminIn');
	}

	const where = {
		type: 'S',
		isUse: 'Y',
		lang: 'ko'
	};

	const order = {
		orderByKey: 'priority',
		orderByValue: 'asc'
	};

	const commonCodes = AllCommonCodes({ where, order }).result;

	if (!commonCodes) return <LoadingSpinner />;

	return (
		<div>
			{/* breadcrumb */}
			<div>
				<nav aria-label="breadcrumb" role="navigation">
					<ol className="breadcrumb">
						<li className="breadcrumb-item active">사용자 관리</li>
						<Link
							to="/app-users/expert-table"
							className="breadcrumb-item active mt-1"
							aria-current="page"
						>
							전문가 목록
						</Link>
					</ol>
				</nav>
			</div>
			{/* Tab */}
			<div className="my-4 pl-0">
				<Tabs id="controlled-tab" defaultActiveKey={commonCodes[0].value}>
					{commonCodes.map(code => (
						<Tab eventKey={code.value} title={code.name}>
							<Table place={code.name} type={code.value} />
						</Tab>
					))}
				</Tabs>
			</div>
		</div>
	);
};

const Table = ({ place, type }) => {
	const [result, setResult] = useState();

	const { loading, error, data } = useQuery(ALL_EXPERTS, {
		fetchPolicy: 'network-only',
		variables: {
			where: { isDelete: 'N', lang: 'ko', type },
			orderBy: { createAt: 'desc' }
		}
	});

	useEffect(() => {
		if (data?.allExperts) {
			setResult(data.allExperts);
		}
	}, [data]);

	if (error) {
		console.log(error);
		SimpleNotification({ type: 'danger', message: error.message || 'Error' });
	}

	const handleTelFormat = (data) => {
		const tel = PhoneFormatter(data.value);
		return tel;
	};	

	if (loading || !result) return <LoadingSpinner />;

	return (
		<div className="row">
			<div className="col-12">
				<div className="card">
					<div className="card-body">
						<div className="flex justify-between card-title-wrap">
							<h4 className="card-title">전문가 목록</h4>
							<div>
								<div className="flex justify-end btnbar mt-0">
									<Link
										className="btn btn-primary"
										to="/app-users/expert-create"
									>
										등록하기
									</Link>
								</div>
								<span className="card-sub">{`Total : ${result.length}`}</span>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div>
									<ReactTable
										data={result}
										filterable
										className="card-wrap"
										defaultPageSize={10}
										columns={[
											{
												Header: '전문가명',
												id: 'name',
												accessor: d => d.name,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, {
														keys: [item => item.name]
													}),
												filterAll: true,
												Cell: row => <div>{row.value}</div>
											},
											{
												Header: '업체명',
												id: 'companyName',
												accessor: d => d.companyName,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, {
														keys: [item => item.companyName]
													}),
												filterAll: true,
												Cell: row => (
													<Link
														to={`/app-users/expert-detail/${row.original.group}`}
														className="text-blue-700 font-medium"
													>
														{row.value}
													</Link>
												)
											},
											{
												Header: '장소',
												id: 'type',
												accessor: d => d.commonCode.name,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, {
														keys: [item => item.type]
													}),
												filterAll: true,
												Cell: row => <div>{row.value}</div>
											},
											{
												Header: '전화번호',
												id: 'tel',
												accessor: d => d.tel,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, {
														keys: [item => item.tel]
													}),
												filterAll: true,
												// Cell: handleTelFormat
											},
											{
												Header: '주소',
												id: 'address',
												accessor: d => d.address,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, {
														keys: [item => item.address]
													}),
												filterAll: true,
												Cell: row => <div>{row.value}</div>
											},
											{
												Header: '등록날짜',
												id: 'createAt',
												accessor: d =>
													FormatDateShort(new Date(parseInt(d.createAt, 10))),
												filterable: false,
												Cell: row => <div>{row.value}</div>
											},
											{
												Header: '',
												id: 'edit',
												accessor: 'edit',
												filterable: false,
												Cell: row => (
													<div>
														<Link
															className="ti-pencil-alt"
															to={`/app-users/expert-edit/${row.original.group}`}
														>
															수정하기
														</Link>
													</div>
												)
											}
										]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReactContentPage;
