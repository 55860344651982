import React, {useState, useEffect} from 'react'
import FocusSubtitle from './Subtitle'
import { Form } from "react-bootstrap";
import Select from "react-select";
import { getImagePath } from "components/common"

const FocusLayout = ({ colspan, isKorea, idx, lang, item}) => {

  const [name, setName] = useState(item?.name);
  const [time, setTime] = useState(item?.time);
  const [sound, setSound] = useState(item?.commonCodeSound?.name);
  const [priority, setPriority] = useState(item?.priority);

  const imageSteps = [
    item.fileInfos ? getImagePath(item.fileInfos.filter(info => info.id === item.imageStep1)[0]) : '',
    item.fileInfos ? getImagePath(item.fileInfos.filter(info => info.id === item.imageStep2)[0]) : '',
    item.fileInfos ? getImagePath(item.fileInfos.filter(info => info.id === item.imageStep3)[0]) : '',
    item.fileInfos ? getImagePath(item.fileInfos.filter(info => info.id === item.imageStep4)[0]) : '',
    item.fileInfos ? getImagePath(item.fileInfos.filter(info => info.id === item.imageStep5)[0]) : ''
  ];

  return (
    <>
      <h4 className="ml-2"> 기본 설정 </h4>
      <table className="app-table w-full">
        <tr>
            <td className="th w-1/6">유형 제목</td>
            <td colspan={colspan}>{name}</td>
        </tr>
        { 
          isKorea? (
            <>
              {
                Array.from({length : 5}).map((item, arrIdx) => (
                  <FocusImage componentIdx={idx} idx={arrIdx+1} colspan={colspan} image={imageSteps[arrIdx]}/>
                ))
              }
              <tr>
                <td className="th w-1/6">단계별 유지 시간</td>
                <td colspan={colspan}>{time}</td>
              </tr>
              <tr>
                <td className="th w-1/6">효과음</td>
                <td colspan={colspan}>{sound}
                </td>
              </tr>
              <tr>
                  <td className="th w-1/6">정렬 순서</td>
                  <td colspan={colspan}>{priority}</td>
              </tr>
            </>
          ) : ''
        }
      </table>
      <FocusSubtitle isKorea={isKorea} lang={lang} colspan={colspan} item={item.exerciseSubs}/>
    </>
  )
}

const FocusImage = ({componentIdx, idx, colspan, image}) => {
  return (
    <tr>
      <td className="th w-1/6">초점 단계별 이미지 - {idx}단계</td>
      <td colspan={colspan}>
        <div className="form-file">
          <img src={image} className="profile_preview" />
        </div>
      </td>
    </tr>
  )
}

export default FocusLayout;