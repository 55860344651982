import React, { useState, useEffect } from 'react'
import BlinkForceSubtitle from './Subtitle'
import { Form } from "react-bootstrap";
import Select from "react-select";
import { getImagePath } from "components/common"

const BlinkForceLayout = ({ colspan, isKorea, idx, lang, item }) => {

  const [name, setName] = useState(item?.name);
  const [closeImage, setCloseImage] = useState(item.fileInfos ? getImagePath(item.fileInfos.filter(info => info.id === item.eyeCloseImage)[0]) : '');
  const [openImage, setOpenImage] = useState(item.fileInfos ? getImagePath(item.fileInfos.filter(info => info.id === item.eyeOpenImage)[0]) : '');
  const [time, setTime] = useState(item?.time);
  const [sound, setSound] = useState(item?.commonCodeSound?.name);
  const [priority, setPriority] = useState(item?.priority);

  return (
    <table className="app-table w-full">
      <tr>
          <td className="th w-1/6">유형 제목</td>
          <td colspan={colspan}>{name}</td>
      </tr>
      { 
        isKorea? (
          <>
            <tr>
              <td className="th w-1/6">눈 감았을 때 표시 이미지</td>
              <td colspan={colspan}>
                <div className="form-file">
                  <img src={closeImage} className="profile_preview" />
                </div> 
              </td>
            </tr>
            <tr>
              <td className="th w-1/6">눈 떴을 때 표시 이미지</td>
              <td colspan={colspan}>
                <div className="form-file">
                  <img src={openImage} className="profile_preview" />
                </div> 
              </td>
            </tr> 
            <tr>
              <td className="th w-1/6">눈 감고 있는 기간</td>
              <td colspan={colspan}>{time}</td>
            </tr>
            <tr>
              <td className="th w-1/6">효과음</td>
              <td colspan={colspan}>{sound} </td>
            </tr>
            <tr>
              <td className="th w-1/6">정렬 순서</td>
              <td colspan={colspan}>{priority} </td>
            </tr>
          </>
        ) : ''
      }
      <BlinkForceSubtitle item={item.exerciseSubs}/>
    </table>  
  )
}

export default BlinkForceLayout;