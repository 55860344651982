/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Form } from "react-bootstrap";
import { SimpleNotification } from "components/SimpleNotification";
import { CREATE_PIXELRO } from "graphql/gql/insert";
import { PIXELRO_EMAIL_CHECK } from "graphql/gql/select";
import EmailCheck from "components/Functions";
import useSettings from "stores/settings";

const CreatePage = () => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }
  const goBack = () => {
    history.goBack();
  };

  // -- 데이터 값 저장 --
  const [check, setCheck] = useState(false);
  const [name, setName] = useState();
  const [tel, setTel] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();

  const [pixelroCreate, { data }] = useMutation(CREATE_PIXELRO, {
    onError(err) {
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      goBack();
    }
  }, [data]);

  const handleCreate = () => {
    if (!EmailCheck(email)) {
      SimpleNotification({
        type: "warning",
        message: "이메일 형식이 올바르지 않습니다",
      });
      return;
    }

    if (!check) {
      SimpleNotification({type: "warning", message: "중복되는 이메일입니다." });
      document.getElementById('email').focus();
      return;
    }

    if (!email || !password || !newPassword || !name || !tel) {
      SimpleNotification({
        type: "warning",
        message: "필수항목을 모두 입력해주세요.",
      });
      return;
    }

    if (password !== newPassword) {
      SimpleNotification({
        type: "warning",
        message: "비밀번호가 일치하지 않습니다.",
      });
      return;
    }
    
    pixelroCreate({
      variables: {
        name,
        tel,
        email,
        password,
      },
    });
  };


  const [emailCheck, { data: checkData }] = useMutation(PIXELRO_EMAIL_CHECK, {
    onError(err) {
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });    

  useEffect(() => {
    if (checkData && checkData.pixelroEmailCheck && checkData.pixelroEmailCheck.id) {
      SimpleNotification({type: "warning", message: "중복되는 이메일입니다." });
      document.getElementById('email').focus();
      return setCheck(false);
    }
    else {
      setCheck(true);
    }
  }, [checkData])


  const checkEmail = () => {
    if (email) {
      if (!EmailCheck(email)) 
        return SimpleNotification({type: "warning", message: "이메일 형식이 올바르지 않습니다" });
      emailCheck({ variables: { email }})
    }
  }

  return (
    <>
      {/* breadcrumb */}
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">관리자</li>
            <Link to="/pixelro/pixelro-table" className="breadcrumb-item active mt-1" aria-current="page">
              관리자 목록
            </Link>
            <Link to="/pixelro/pixelro-create" className="breadcrumb-item active mt-1" aria-current="page">
              관리자 신규 등록
            </Link>
          </ol>
        </nav>
      </div>
      {/* 관리자 정보 */}
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">관리자 신규 등록</h4>
              </div>
              <div className="mt-6 card-wrap">
                <table className="app-table bg-white w-full">
                  <tr>
                    <td className="th">이메일</td>
                    <td colSpan="3">
                      <Form.Control id="email" type="text" value={email} onChange={(e) => { setEmail(e.target.value); setCheck(false); }} onBlur={() => checkEmail()} />
                    </td>
                  </tr>
                  <tr>
                    <td className="th">비밀번호</td>
                    <td colSpan="3">
                      <Form.Control type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <td className="th">비밀번호 확인</td>
                    <td colSpan="3">
                      <Form.Control type="text" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <td className="th">이름</td>
                    <td colSpan="3">
                      <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <td className="th">전화번호</td>
                    <td colSpan="3">
                      <Form.Control type="text" value={tel} onChange={(e) => setTel(e.target.value)} />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 btnbar">
        <div className="row foot-edit">
          <button className="btn btn-warning" onClick={goBack}>
            취소하기
          </button>
          <button type="button" className="btn btn-primary" onClick={handleCreate}>
            저장하기
          </button>
        </div>
      </div>
    </>
  );
};

CreatePage.prototype = {
  id: PropTypes.string.isRequired,
};

export default CreatePage;
