import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { AllCommonCodes, AllCompanyInfos } from "graphql/query/select";
import { useMutation } from "@apollo/react-hooks";
import { SimpleNotification } from "components/SimpleNotification";
import { DELETE_COMPANY_INFO_FOR_PARTNER } from "graphql/gql/delete.js";
import { Button, Modal, Tabs, Tab } from "react-bootstrap";
import { getImagePathForObject, LoadingSpinner } from "components/common.js"
import useSettings from "stores/settings";

const DetailPage = ({ match }) => {
  const { group } = match.params;

  const [mdShow, setMdShow] = useState(false);
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const goBack = () => {
    history.goBack();
  };

  let items;
  if (group !== undefined && group !== "undefined") {
    const { result } = AllCompanyInfos({where : { group }});
    items = result;
  }

  const tabsParam = {
    where : {
      type: "L",
      isUse: "Y",
      lang: "ko"
    },
    order : {
      orderByKey : "priority",
      orderByValue : "asc",
    }
  }

  const tabs = AllCommonCodes(tabsParam).result; //한 영 일 중

  //#region delete
  const [companyInfoDelete, { data: deleteData }] = useMutation(DELETE_COMPANY_INFO_FOR_PARTNER, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (!deleteData) return;

    if (deleteData?.deleteCompanyInfoForPartner?.result.toString().toUpperCase()  === "OK") {
      goBack();
    }
    else {
      return SimpleNotification({ type: "danger", message: deleteData?.deleteCompanyInfoForPartner?.error || "예기치 못한 오류가 발생하였습니다." });
    }
  }, [deleteData])

  const handleDelete = () => {
    setMdShow(false);
    companyInfoDelete({ variables: { wheres: items.map(item => ({ id: item.id, group: item.group, type:item.type, priority: item.priority })) }});
  };
  //#endregion

  if (!tabs || !items) return <LoadingSpinner/>

  return (
    <>
      <div>
        <div>
          <nav aria-label="breadcrumb" role="navigation">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">회사정보 관리</li>
              <Link to="/company/partner-table" className="breadcrumb-item active mt-1" aria-current="page">
                협력사 목록
              </Link>
              <Link to="/company/partner-detail" className="breadcrumb-item active mt-1" aria-current="page">
                협력사 상세보기
              </Link>
            </ol>
          </nav>
        </div>
        <Tabs id="partner-info-tabs">
          {tabs.map((tab) => (
            <Tab eventKey={tab.value} title={tab.name}>
              <InfoForm
                lang={tab.value}
                item={items.filter((item) => item.lang === tab.value)[0]}
                goBack={goBack}
              />
            </Tab>
          ))}
        </Tabs>
        <div className="col-12 grid-margin mt-4">
          <div className="row justify-between">
            <button className="btn btn-danger" onClick={() => setMdShow(true)}>
              삭제하기
            </button>
            <Link className="btn btn-primary" to={`/company/partner-edit/${group}`}>
              수정하기
            </Link>
          </div>
          <Modal show={mdShow} onHide={() => setMdShow(false)} aria-labelledby="example-modal-sizes-title-md">
              <Modal.Header closeButton>
                <Modal.Title>해당 내용을 삭제하시겠습니까?</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <p>삭제를 원하시면 삭제 버튼을 눌러주세요.</p>
              </Modal.Body>

              <Modal.Footer className="fleex-wrap">
                <Button variant="danger m-2" onClick={handleDelete}>
                  삭제
                </Button>
                <Button variant="primary m-2" onClick={() => setMdShow(false)}>
                  취소
                </Button>
              </Modal.Footer>
            </Modal>
        </div>
      </div>
    </>
  );
};

const InfoForm = ({ item }) => {
  const imagePath = getImagePathForObject(item);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between card-title-wrap">
              <h4 className="card-title">협력사 상세보기</h4>
            </div>
            <div className="card-wrap">
              <table className="app-table bg-white w-full">
                <tr>
                  <td className="th w-1/6">로고 이미지</td>
                  <td><img src={imagePath} alt="" className="partner-logo-detail" /></td>
                </tr>
                <tr>
                  <td className="th w-1/6">협력사명</td>
                  <td>{item?.name}</td>
                </tr>
                <tr>
                  <td className="th w-1/6">주소</td>
                  <td>{item?.address}</td>
                </tr>
                <tr>
                  <td className="th w-1/6">담당자명</td>
                  <td>{item?.person}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
