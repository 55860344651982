import React, { useState, useEffect } from "react";
import BlinkComfortablySubtitle from "./Subtitle";
import { Form } from "react-bootstrap";
import Select from "react-select";
import {
  checkImage,
  getImagePath,
  getNumberSelectBox,
  getSoundEffectSelectBox,
} from "components/common";

const BlinkComfortablyLayout = ({
  colspan,
  isKorea,
  idx,
  soundEffects,
  lang,
  maxComponent,
  info,
  subtitles,
}) => {
  const [name, setName] = useState(info.name);
  const [closeImage, setCloseImage] = useState(
    info?.fileInfos
      ? getImagePath(
          info.fileInfos.filter(
            (fileInfo) => fileInfo.id === info.eyeCloseImage
          )[0]
        )
      : undefined
  );
  const [openImage, setOpenImage] = useState(
    info?.fileInfos
      ? getImagePath(
          info.fileInfos.filter(
            (fileInfo) => fileInfo.id === info.eyeOpenImage
          )[0]
        )
      : undefined
  );
  const [eyeBlinkCnt, setEyeBlinkCnt] = useState(info.eyeBlinkCnt);
  const [sound, setSound] = useState(info.sound);
  const [priority, setPriority] = useState(info.priority);

  const handleImageChange = async (e, target) => {
    const targetState = target === "closeImage" ? setCloseImage : setOpenImage;
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    if (!checkImage(file.name, file.size)) return;
    if (target === "closeImage") info.eyeCloseImageData = file;
    else info.eyeOpenImageData = file;
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => targetState([reader.result]);
  };

  useEffect(() => {
    info.name = name;
  }, [name]);

  useEffect(() => {
    info.eyeBlinkCnt = eyeBlinkCnt;
  }, [eyeBlinkCnt]);

  useEffect(() => {
    info.sound = sound;
  }, [sound]);

  useEffect(() => {
    info.priority = priority;
  }, [priority]);

  return (
    <table className="app-table w-full">
      <tr>
        <td className="th w-1/6">유형 제목</td>
        <td colspan={colspan}>
          <Form.Control
            type="text"
            placeholder="유형 제목을 입력하세요."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </td>
      </tr>
      {isKorea ? (
        <>
          <tr>
            <td className="th w-1/6">눈 감았을 때 표시 이미지 선택</td>
            <td colspan={colspan}>
              <div className="form-file">
                <img src={closeImage} className="profile_preview" />
                <form encType="multipart/form-data" className="w-full">
                  <label
                    className=""
                    for={`closeImg_primary${idx}`}
                    className="mb-0"
                  >
                    이미지를 선택하세요. (지원 이미지 png, jpg, gif)
                    <i className="ti-upload"></i>
                  </label>
                  <input
                    name="document"
                    id={`closeImg_primary${idx}`}
                    type="file"
                    accept=".jpg,.png,.gif"
                    method="POST"
                    onChange={(e) => handleImageChange(e, "closeImage")}
                  />
                </form>
              </div>
            </td>
          </tr>
          <tr>
            <td className="th w-1/6">눈 떴을 때 표시 이미지 선택</td>
            <td colspan={colspan}>
              <div className="form-file">
                <img src={openImage} className="profile_preview" />
                <form encType="multipart/form-data" className="w-full">
                  <label
                    className=""
                    for={`openImg_primary${idx}`}
                    className="mb-0"
                  >
                    이미지를 선택하세요. (지원 이미지 png, jpg, gif)
                    <i className="ti-upload"></i>
                  </label>
                  <input
                    name="document"
                    id={`openImg_primary${idx}`}
                    type="file"
                    accept=".jpg,.png,.gif"
                    method="POST"
                    onChange={(e) => handleImageChange(e, "openImage")}
                  />
                </form>
              </div>
            </td>
          </tr>
          <tr>
            <td className="th w-1/6">깜빡임 횟수 설정</td>
            <td colspan={colspan}>
              <Form.Control
                type="number"
                placeholder="유형이 종료되는 눈 깜빡임 횟수를 입력하세요. (최대 100)"
                value={eyeBlinkCnt}
                onChange={(e) => setEyeBlinkCnt(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="th w-1/6">효과음 설정</td>
            <td colspan={colspan}>
              {getSoundEffectSelectBox(
                soundEffects,
                { onChange: (e) => setSound(e.value) },
                sound
              )}
            </td>
          </tr>
          <tr>
            <td className="th w-1/6">정렬 순서 선택</td>
            <td colspan={colspan}>
              {getNumberSelectBox(
                maxComponent,
                { onChange: (e) => setPriority(e.value) },
                priority
              )}
            </td>
          </tr>
        </>
      ) : (
        ""
      )}
      <BlinkComfortablySubtitle item={subtitles} lang={lang} />
    </table>
  );
};

export default BlinkComfortablyLayout;
