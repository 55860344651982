import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Form } from "react-bootstrap";
import { UPDATE_NOTICE_BOARD } from "graphql/gql/update";
import { ALL_NOTICE_BOARDS } from "graphql/gql/select";
import { SimpleNotification } from "components/SimpleNotification";
import { checkImage, getImagePathForObject, hasValue, LoadingSpinner } from "components/common";
import useSettings from "stores/settings";
import { CREATE_NOTICE_BOARD_FOR_EVENT } from "graphql/gql/insert";

const EventPage = () => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  // 이벤트
  const { loading, error, data } = useQuery(ALL_NOTICE_BOARDS, {
    fetchPolicy: "network-only",
    variables: { visible:true, category:"2" },
  });
  if (loading) return <LoadingSpinner/>;
  if (error) {
    SimpleNotification({
      message: error.message || "Error",
    });
    return "";
  }

  let item;
  if (data) {
    item = data.allNoticeBoards[0];
    if (!item)
      item = {};
  }

  if (!item) return <LoadingSpinner/>;

  return (
    <>
      {/* breadcrumb */}
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">게시판 관리</li>
            <Link
              to="/notice-board/event"
              className="breadcrumb-item active mt-1"
              aria-current="page"
            >
              이벤트 관리
            </Link>
          </ol>
        </nav>
      </div>
      <Content item={item} />
    </>
  );
};

EventPage.propTypes = {
  id: PropTypes.string.isRequired,
};

const Content = ({ item }) => {
  const [id, setId] = useState(item.id);
  const [category, setCategory] = useState(item.category || "2");
  const [image, setImage] = useState(getImagePathForObject(item));
  const [paramImage, setParamImage] = useState();
  const [title, setTitle] = useState(item.title);
  const [fileInfos, setFileInfos] = useState(item.fileInfos);

  //#region update
  const [eventUpdate, { data: updateData }] = useMutation(UPDATE_NOTICE_BOARD, {
      onError(err) {
        console.log("updateNoticeBoard: err=", err);
        SimpleNotification({
          message: err.message || "Error",
        });
      },
    }
  );

  useEffect(() => {
    if (updateData?.updateNoticeBoard) {      
      setFileInfos(updateData.updateNoticeBoard.fileInfos);
      setParamImage(undefined);

      return SimpleNotification({ title: "success", message: "성공적으로 등록하였습니다.", type: "success"});
    }
  }, [updateData]);

  const handleUpdate = () => {
    if (!hasValue(title)) {
      window.scrollTo(0, 0);
      SimpleNotification({
        title: "Error",
        message: "이벤트 설명을 입력해주세요.",
      });
      return;
    }

    const variables = { 
      id,
      category,
      title,
      fileWhere: fileInfos.map(info => { return { id: info.id, parentId: info.parentId, path: info.path, name: info.name, type: info.type } })
    }

    if (paramImage) {
      variables.uploadSingleImage = {
        image: paramImage,
        size: paramImage.size,
      };
    }
    eventUpdate({ variables });
  };
  //#endregion

  //#region create
  const [eventCreate, { data: createData }]  = useMutation(CREATE_NOTICE_BOARD_FOR_EVENT, {
    onError(err) {
      console.log("createNoticeBoard: err=", err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },  
  })

  useEffect(() => {
    if (createData?.createNoticeBoard) {
      setId(createData.createNoticeBoard.id);
      setFileInfos(createData.createNoticeBoard.fileInfos);
      setParamImage(undefined);

      return SimpleNotification({ title: "success", message: "성공적으로 등록하였습니다.", type: "success"});
    };
  }, [createData]);

  const handleCreate = () => {
    if (!hasValue(title)) {
      return SimpleNotification({ type: "warning", message: "이벤트 설명을 입력해주세요."});
    }

    if (!image) {
      return SimpleNotification({ type: "warning", message: "이미지를 등록해주세요."});
    }

    const variables = { 
      data: {
        category,
        content: '',
        visible: true,
        title,
      },
      image : { 
        image: paramImage,
        size: paramImage.size
      }
    }
    eventCreate({ variables });
  }

  //#endregion

  // 이미지 변경
  const handleImageChange = async (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    if (!checkImage(file.name, file.size)) return;
    setParamImage(file); //bytearray 형태로 서버에 보낼 데이터
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => setImage([reader.result]); //base64 형태로 화면에 보여줄 데이터
  };

  return (
		<>
			<div className="row">
				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<div className="flex justify-between card-title-wrap">
								<h4 className="card-title">이벤트 관리</h4>
							</div>
							<div className="mt-6">
								<div className="card-wrap">
									<table className="app-table bg-white w-full">
										<tbody>
											<tr>
												<td className="th w-1/6">이벤트 이미지</td>
												<td>
													<div className="">
														<div className="w-full">
															<div className="form-file">
																<form
																	encType="multipart/form-data"
																	className="w-full"
																>
																	<label
																		className=""
																		for="img_primary"
																		className="mb-0"
																	>
																		파일을 선택하세요.
																		<i className="ti-upload"></i>
																	</label>
																	<input
																		name="document"
																		id="img_primary"
																		type="file"
																		accept="image/*"
																		method="POST"
																		onChange={handleImageChange}
																	/>
																</form>
															</div>
														</div>
														<img
															src={image && image}
															className="mgz-upload-thumb"
														/>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								{/* 내용 등록 */}
								<div className="card-wrap">
									<table className="app-table bg-white w-full">
										<tbody>
											<tr>
												<td className="th w-1/6">이벤트 설명</td>
												<td>
													<Form.Control
														// type="text"
														value={title}
														as="textarea"
														rows={7}
														onChange={e => setTitle(e.target.value)}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="col-12 btnbar">
				<div className="row foot-edit" style={{ justifyContent: 'flex-end' }}>
					{id ? (
						<button
							type="button"
							className="btn btn-primary"
							onClick={handleUpdate}
						>
							저장하기
						</button>
					) : (
						<button
							type="button"
							className="btn btn-primary"
							onClick={handleCreate}
						>
							등록하기
						</button>
					)}
				</div>
			</div>
		</>
	);
};

export default EventPage;
