/* eslint-disable no-useless-escape */
const EmailCheck = (email) => {
  const exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  // 이메일 형식이 알파벳+숫자@알파벳+숫자.알파벳+숫자 형식이 아닐경우
  if (exptext.test(email) === false) {
    return false;
  }
  return true;
};

export default EmailCheck;
