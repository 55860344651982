/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Form } from "react-bootstrap";
import { SimpleNotification } from "components/SimpleNotification";
import { Pixelro } from "graphql/query/select";
import { UPDATE_PIXELRO } from "graphql/gql/update";
import { DELETE_PIXELRO } from "graphql/gql/delete";
import { Button, Modal } from "react-bootstrap";
import useSettings from "stores/settings";
import { LoadingSpinner } from "components/common";

const EditPage = ({ match }) => {
  const { id } = match.params;
  const { result } = Pixelro(id);
  const items = result;

  if (!items) return <LoadingSpinner/>
  return <EditForm items={items}/>
};

const EditForm = ({ items }) => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const goBack = () => {
    history.goBack();
  };

  // -- 데이터 값 저장 --
  const { id, email } = items;
  const [name, setName] = useState(items.name);
  const [tel, setTel] = useState(items.tel);
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [mdShow, setMdShow] = useState(false);

  // 관리자 정보 업데이트
  const [pixelroUpdate, { data }] = useMutation(UPDATE_PIXELRO, {
    onError(err) {
      console.log("updateAdmin: err=", err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });
  useEffect(() => {
    if (data !== undefined) {
      goBack();
    }
  }, [data]);

  const handleUpdate = () => {
    if (!name || !tel) {
      SimpleNotification({
        title: "",
        message: "필수항목을 모두 입력해주세요.",
      });
      return;
    }

    if (password && newPassword) {
      if (password !== newPassword) {
        SimpleNotification({
          title: "",
          message: "비밀번호가 일치하지 않습니다.",
        });
        return;
      }
    }

    pixelroUpdate({
      variables: {
        id,
        name,
        tel,
        email,
        password: newPassword || "",
      },
    });
  };

  const [pixelroDelete, { data: deleteData }] = useMutation(DELETE_PIXELRO, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (deleteData !== undefined) {
      goBack();
    }
  }, [deleteData]);

  const handlePixelroDelete = () => {
    pixelroDelete({ variables: { id } });
  }

  return (
    <>
      {/* breadcrumb */}
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">관리자</li>
            <Link
              to="/pixelro/pixelro-table"
              className="breadcrumb-item active mt-1"
              aria-current="page"
            >
              관리자 목록
            </Link>
            <Link
              to={`/pixelro/pixelro-edit/${id}`}
              className="breadcrumb-item active mt-1"
              aria-current="page"
            >
              관리자 정보 수정
            </Link>
          </ol>
        </nav>
      </div>
      {/* 관리자 정보 */}
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">관리자 정보 수정</h4>
              </div>
              <div className="mt-6 card-wrap">
                <table className="app-table bg-white w-full">
                  <tr>
                    <td className="th">이메일</td>
                    <td colSpan="3">{email}</td>
                  </tr>
                  <tr>
                    <td className="th">비밀번호</td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="th">비밀번호 확인</td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="th">이름</td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="th">전화번호</td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        value={tel}
                        onChange={(e) => setTel(e.target.value)}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 btnbar">
        <div className={`row ${settings.email === "pixelro" && items.email !== "pixelro" ? 'foot-edit' : 'flex justify-end'}`}>
          {
            settings.email === "pixelro" && items.email !== "pixelro" ? (
              <button className="btn btn-danger" onClick={() => setMdShow(true)}>
                삭제하기
              </button>
            ) : ''
          }
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleUpdate}
          >
            저장하기
          </button>
        </div>
      </div>
      {/* Modal Starts  */}
      <Modal
        show={mdShow}
        onHide={() => setMdShow(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title>관리자 삭제</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>해당 관리자를 삭제하시겠습니까?</p>
        </Modal.Body>

        <Modal.Footer className="flex-wrap">
          <Button
            variant="btn btn-primary m-2"
            onClick={handlePixelroDelete}
          >
            확인
          </Button>
          <Button variant="btn btn-light m-2" onClick={() => setMdShow(false)}>
            취소
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Ends */}
    </>
  );
};
EditPage.prototype = {
  id: PropTypes.string.isRequired,
};

export default EditPage;
