import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Tabs, Tab } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { AllCommonCodes, AllExercises } from "graphql/query/select";
import { SimpleNotification } from "components/SimpleNotification";
import { getImagePathForObject, LoadingSpinner } from "components/common.js";
import { FormatDateShort } from "components/FormatDate";
import { UPDATE_EXERCISE_PRIORITY } from "graphql/gql/update.js";
import useSettings from "stores/settings";

const TablePage = () => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const where = {
    type: "E",
    isUse: "Y",
    lang: "ko",
  };

  const order = {
    orderByKey: "priority",
    orderByValue: "asc",
  };

  const tabs = AllCommonCodes({ where, order }).result;
  const exercises = AllExercises({
    where: { lang: "ko", isDelete: "N" },
    order: { orderByKey: "priority", orderByValue: "asc" },
  }).result;

  if (!tabs || !exercises) return <LoadingSpinner />;
  return (
    <>
      <div>
        <div>
          <nav aria-label="breadcrumb" role="navigation">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">눈 운동 관리</li>
              <Link
                to="/eye-movement/table"
                className="breadcrumb-item active mt-1"
                aria-current="page"
              >
                눈 운동 목록
              </Link>
            </ol>
          </nav>
        </div>
        <div className="my-4 pl-0">
          <Tabs id="controlled-tab-example" defaultActiveKey={tabs[0].value}>
            {tabs.map((tab) => (
              <Tab eventKey={tab.value} title={tab.name}>
                <Table
                  type={tab.value}
                  items={exercises.filter((data) => data.type === tab.value)}
                />
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </>
  );
};

const Table = ({ type, items }) => {
  const [lists, setLists] = useState(items);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    const targetWhere = { group: lists[oldIndex].group };
    setLists(arrayMove(lists, oldIndex, newIndex));

    let where = {};
    let data = {};
    const newPriority = newIndex + 1;
    const oldPriority = oldIndex + 1;
    const targetData = { priority: newPriority };

    if (oldPriority > newPriority) {
      where = { type, priority: { gte: newPriority, lt: oldPriority } };
      data = { priority: { increment: 1 } };
    } else {
      where = { type, priority: { gt: oldPriority, lte: newPriority } };
      data = { priority: { decrement: 1 } };
    }
    exercisePriorityUpdate({
      variables: { where, data, targetWhere, targetData },
    });
  };

  const [exercisePriorityUpdate, { data: updateData }] = useMutation(
    UPDATE_EXERCISE_PRIORITY,
    {
      onError(err) {
        SimpleNotification({
          type: "danger",
          message: err.message || "Error",
        });
      },
    }
  );

  useEffect(() => {
    if (
      updateData?.updateExercisePriority &&
      updateData.updateExercisePriority.result != "ok"
    ) {
      alert(`노출순서 변경에 실패하였습니다.`);
      window.location.reload();
    }
  }, [updateData]);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="card-wrap">
              <div className="overflow-hidden">
                <>
                  <table className="app-table w-full">
                    <thead>
                      <tr>
                        <td className="th">대표 이미지</td>
                        <td className="th">제목</td>
                        <td className="th">등록날짜</td>
                        <td className="th" />
                        <td className="th" />
                      </tr>
                    </thead>
                    <SortableList
                      onSortEnd={onSortEnd}
                      items={lists}
                      helperClass="sorting-row"
                      useDragHandle
                    />
                  </table>
                </>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end btnbar">
          <Link
            className="btn btn-primary"
            to={{ pathname: "/eye-movement/create", state: { type } }}
          >
            등록하기
          </Link>
        </div>
      </div>
    </div>
  );
};

const DragHandle = SortableHandle(() => <span>::</span>);
const SortableItem = SortableElement(({ item }) => {
  const imagePath = getImagePathForObject(item);

  if (!item) return "";
  return (
    <tr>
      <td>
        <img src={imagePath} alt="" className="mgz-thumb" />
      </td>
      <td>
        <DragHandle /> {item.name}
      </td>
      <td>{FormatDateShort(new Date(parseInt(item.createAt, 10)))}</td>
      <td>
        <Link
          className="ti-pencil-alt"
          to={`/eye-movement/detail/${item.group}`}
        >
          상세보기
        </Link>
      </td>
      <td>
        <Link
          className="ti-pencil-alt"
          to={`/eye-movement/update/${item.group}`}
        >
          수정하기
        </Link>
      </td>
    </tr>
  );
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <tbody className="bg-white">
      {items.map((item, index) => {
        return (
          <SortableItem
            key={`item-${item.id}`}
            index={index}
            sortIndex={index}
            item={item}
          />
        );
      })}
    </tbody>
  );
});

export default TablePage;
