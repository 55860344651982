/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from "react";
import useSettings from "stores/settings";
import { useHistory, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { FormatDate } from "components/FormatDate";
import Pagination from "components/Pagination";
import { SimpleNotification } from "components/SimpleNotification";
import { Button, Modal } from "react-bootstrap";
import { DELETE_SERVICE_CENTER } from "graphql/gql/delete";
import { FILTER_SERVICE_CENTER } from "graphql/gql/select";

const Tables = () => {
  // 로그인 체크
  const settings = useSettings();
  const history = useHistory();
  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const [id, setId] = useState();

  const [pageOfItems, setPageOfItems] = useState([]);
  const [page, setPage] = useState(1);

  const [mdShow, setMdShow] = useState(false);

  // 앱 사용자 정보 업데이트
  const [operationDelete, { data: deleteData }] = useMutation(DELETE_SERVICE_CENTER, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (deleteData !== undefined) {
      // 리스트가 1개일때 삭제하면 안지워짐.
      if (pageOfItems.length === 1) {
        setPageOfItems([]);
      }
      refetch();
    }
  }, [deleteData]);

  // 사용자 FAQ
  const { loading, error, data, refetch } = useQuery(FILTER_SERVICE_CENTER, {
    fetchPolicy: "network-only",
    variables: { value: "operation" },
  });
  if (loading) return <p>Loading...</p>;
  if (error) {
    SimpleNotification({
      message: error.message || "Error",
    });
    return "";
  }

  let items;
  if (data) {
    items = data.filterServiceCenter;

    // 최근 등록날짜 순으로 나타내기
    items.sort((a, b) => {
      return b.createAt - a.createAt;
    });
  }

  const handleDeleteButton = (id) => {
    setMdShow(true);
    setId(id);
  };

  const handleDelete = () => {
    setMdShow(false);
    operationDelete({ variables: { id } });
  };

  function onChangePage(pageOfItems, pageNumber) {
    setPageOfItems(pageOfItems);
    setPage(pageNumber);
    // 페이지 이동시 맨 위로 스크롤 이동
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div>
        <TableHeader />
        <div className="row grid-margin">
          <div className="col-12">
            <div className="flex justify-between mb-4">
              <h4 className="card-title mt-2 pt-1 text-xl">운영정책 목록</h4>
              <Link className="btn btn-warning" to="/service-center/operation-create">
                등록하기
              </Link>
            </div>
            <div className="flex flex-col">
              <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                  <>
                    <table className="min-w-full">
                      <thead className="bg-gray-200">
                        <tr>
                          <th className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-black uppercase tracking-wider w-2/12">
                            구분
                          </th>
                          <th className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-black uppercase tracking-wider w-7/12">
                            제목
                          </th>
                          <th className="px-3 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-black uppercase tracking-wider w-1/12">
                            등록날짜
                          </th>
                          <th className="px-3 py-3 border-b border-gray-200 bg-gray-50 w-2/12" />
                        </tr>
                      </thead>
                      {pageOfItems && (
                        <tbody className="bg-white">
                          {pageOfItems.map((m) => (
                            <tr>
                              <td className="px-3 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                {m.category}
                              </td>
                              <td className="px-3 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-black">
                                {m.title}
                              </td>
                              <td className="px-3 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-black">
                                {FormatDate(new Date(parseInt(m.createAt, 10)))}
                              </td>
                              <td className="px-3 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                                <Link className="ti-pencil-alt ml-3" to={`/service-center/operation-detail/${m.id}`}>
                                  상세보기
                                </Link>
                                <button className="ti-trash ml-3" onClick={() => handleDeleteButton(m.id)}>
                                  삭제하기
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                    {/* Modal Starts  */}
                    <Modal show={mdShow} onHide={() => setMdShow(false)} aria-labelledby="example-modal-sizes-title-md">
                      <Modal.Header closeButton>
                        <Modal.Title>해당 내용을 삭제하시겠습니까?</Modal.Title>
                      </Modal.Header>

                      <Modal.Body>
                        <p>삭제를 원하시면 삭제 버튼을 눌러주세요.</p>
                      </Modal.Body>

                      <Modal.Footer className="fleex-wrap">
                        <Button variant="danger m-2" onClick={handleDelete}>
                          삭제
                        </Button>
                        <Button variant="primary m-2" onClick={() => setMdShow(false)}>
                          취소
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {/* Modal Ends */}
                  </>

                  <Page items={items} page={page} onChangePage={onChangePage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// /* breadcrumb */
const TableHeader = () => {
  return (
    <div>
      <nav aria-label="breadcrumb" role="navigation">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">고객센터</li>
          <Link to="/service-center/operation-table" className="breadcrumb-item active mt-1" aria-current="page">
            운영정책
          </Link>
        </ol>
      </nav>
    </div>
  );
};

const Page = (props) => {
  const PAGE_SIZE = 10;
  const { items, page, onChangePage } = props;
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="hidden sm:block">
        <p className="text-sm leading-5 text-gray-700">
          <span className="font-medium mx-1">{(page - 1) * PAGE_SIZE + 1}</span>
          {" ~  "}
          <span className="font-medium mx-1">{items && items.length < page * PAGE_SIZE ? items.length : page * PAGE_SIZE}</span>
          {" / 총 "}
          <span className="font-medium mx-1">{items && items.length}</span>
          {" 건"}
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">{items && <Pagination items={items} onChangePage={onChangePage} />}</div>
    </div>
  );
};

Page.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
};

export default Tables;
