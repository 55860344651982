import React, { useState } from "react";
import { AllCommonCodes } from "graphql/query/select"

const Interests = ({ selectMode, selectedInterestCode, addInterestCode, deleteInterestCode, lang }) => {
    const where = {
      isUse : "Y",
      type : "I",
      lang
    };
    const order = {
      orderByKey : "priority",
      orderByValue : "asc",
    };

    const commonCodes = AllCommonCodes({ where, order }).result;      

    if (!commonCodes) return <div />

    return (
        <CommonCodes commonCodes={commonCodes} selectMode={selectMode} selectedInterestCode={selectedInterestCode} addInterestCode={addInterestCode} deleteInterestCode={deleteInterestCode} />
    )
}

function CommonCodes({ commonCodes, selectMode, addInterestCode, deleteInterestCode, selectedInterestCode }) {
  if (!commonCodes) return <ul className="tag-warp"></ul>;

  const listItems = commonCodes.map((code) => (
    <Code
      key={code.id.toString()}
      name={code.name}
      value={code.value}
      selectMode={selectMode}
      selected={selectedInterestCode.includes(code.value)}
      selectedInterestCode={selectedInterestCode}
      addInterestCode={addInterestCode}
      deleteInterestCode={deleteInterestCode}
    />
  ));

  return <ul className={`tag-wrap${selectMode? " select-mode": "" }`}>{listItems}</ul>;
}

function Code({ name, value, selectMode, selected, selectedInterestCode, addInterestCode, deleteInterestCode }) {
  const [select, setSelect] = useState(selected);

  const selectedCode = (select) => {
    if (selectMode) {
      setSelect(select);
      if (select) addInterestCode(value, selectedInterestCode);
      else deleteInterestCode(value, selectedInterestCode);
    }
  };

  return (
    <li
      className={`tag-item ${select ? "select" : ""}`}
      onClick={() => selectedCode(!select)}
    >
      {name}
    </li>
  );
}

export default Interests;

export const addInterestCode = (code, selectedInterestCode) => selectedInterestCode.push(code);
export const deleteInterestCode = (code, selectedInterestCode) => {
  const index = selectedInterestCode.indexOf(code);
  if (index > -1 ) selectedInterestCode.splice(index, 1);
}