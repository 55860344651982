import React, {useState, useEffect} from 'react'
import { Form } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_EXERCISE_SUB } from "graphql/gql/delete";
import { SimpleNotification } from "components/SimpleNotification";
import { hasValue } from "components/common";

const DefaultSubtitles = ({ isKorea, lang, colspan, isSaved, subtitles, addSubtitle, deleteSubtitle }) => {
    return (
        <>
            {
                subtitles.map((subtitle, idx) => <Subtitle key={subtitle.id.toString()} id={subtitle.id} item={subtitle[lang]} isSaved={isSaved} rowSpan={subtitles.length+1} isKorea={isKorea} canDelete={idx!==0} deleteSubtitle={deleteSubtitle}/>)
            }
            {
                isKorea ? (
                    <tr>
                        <td className="pt-0" colspan={colspan}>
                            <button type="button" className="btn w-full btn-light" onClick={() => addSubtitle()}> + 자막 추가하기 </button>
                        </td>
                    </tr>
                )
                : ''
            }
        </>
    )
}

const Subtitle = ({ id, item, isSaved, isKorea, rowSpan, canDelete, deleteSubtitle }) => {
    const className = `border-bottom-0 border-right-0 ${canDelete? "pt-0" : ''}`;
    const [subtitles, setSubtitles] = useState(item.subtitles);
    const [time, setTime] = useState(item.time);

    useEffect(() => {
        if (subtitles)
            item.subtitles = subtitles;
    }, [subtitles])

    useEffect(() => {
        if (time)
            item.time = time;
    }, [time])

    //#region Delete
    const [exerciseSubDelete, { data: deleteData }] = useMutation(DELETE_EXERCISE_SUB, {
        onError(err) { SimpleNotification({type : "danger", message: err.message || "Error"}); },
    });

    useEffect(() => {
        if (deleteData && deleteData.deleteExerciseSub) {
            if (!hasValue(deleteData.deleteExerciseSub.result))
                return SimpleNotification({type : "danger", message: "알 수 없는 오류가 발생하였습니다."});
        }
    }, [deleteData]);

    const handleDelete = () => {
        deleteSubtitle(id);
        if (isSaved) exerciseSubDelete({ variables: { group: id }});
    }
    //#endregion
    
    return ( 
        <tr>
            { !canDelete ? <td className="th w-1/6" rowSpan={rowSpan}>자막 설정</td> : '' }
            <td className={`w-3/6 ${className}`}>
                <Form.Control type="text" placeholder="자막을 입력하세요." value={subtitles} onChange={e => setSubtitles(e.target.value)} />
            </td>
            <td className={`w-2/6 ${className}`}>
                <Form.Control type="number" placeholder="자막 시작 시간 (초 단위로 입력)" value={time} onChange={e => setTime(e.target.value)} />
            </td>
            {
                isKorea && canDelete ? (
                    <td className="w-1/6 border-bottom-0 pt-0">
                        <button type="button" className="btn btn-light" onClick={handleDelete}>X</button>
                    </td>
                ) : ''
            }
        </tr>             
    )
}

export default DefaultSubtitles;