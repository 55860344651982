import React from "react";
import Select from "react-select";
import { SimpleNotification } from "./SimpleNotification";
import SyncLoader from "react-spinners/SyncLoader";
const url = "https://pixelro.nenoons.com";

export const getImagePathForObject = (obj) => {
  if (!obj || !obj.fileInfos || obj.fileInfos.length === 0) return undefined;
  const imageInfo =
    obj.fileInfos[
      obj.fileInfos.findIndex((file) => {
        return file.type === "I";
      })
    ];
  return getImagePath(imageInfo);
};

export const getImagePath = (info) => {
  if (!info || !info.path || !info.name) return undefined;
  return (
    (process.env.NODE_ENV === "production" ? "" : url) + info?.path + info?.name
  );
};

export const getImagePath2 = (info) => {
  if (!info || !info.imagePath || !info.imageName) return undefined;
  return (
    (process.env.NODE_ENV === "production" ? "" : url) +
    info?.imagePath +
    info?.imageName
  );
};

export const hasValue = (string) => {
  return string && string !== "" && string !== null;
};

export const canParseInt = (string) => {
  return !isNaN(parseInt(string));
};

export const numberSizeCheck = (num, size = "smallInt") => {
  let n = num;
  if (typeof n === "string") {
    if (isNaN(parseInt(n))) {
      console.log(`숫자가 아닙니다.`);
      return false;
    }
    n = parseInt(n);
  }

  switch (size) {
    case "smallInt":
      return -32768 < n && n < 32767;
  }
  return false;
};

export const checkImage = (name, size) => {
  return checkFileType(name, ["jpg", "png", "gif"]) && checkImageSize(size);
};

export const checkFileType = (name, arr) => {
  const nameArr = name.split(".");
  const ext = nameArr[nameArr.length - 1];
  if (!arr.includes(ext)) {
    SimpleNotification({
      type: "warning",
      message: ".jpg, .png, .gif 파일만 첨부할 수 있습니다.",
    });
    return false;
  }
  return true;
};

export const checkImageSize = (size) => {
  if (size > 10 * 1024 * 1024) {
    SimpleNotification({
      type: "warning",
      message: "10MB가 넘는 이미지는 첨부할 수 없습니다.",
    });
    return false;
  }
  return true;
};

export const getNumberSelectBox = (num, props, value) => {
  //props : placeholder, onChange...
  const options = Array.from({ length: num }).map((val, idx) => ({
    value: (idx + 1).toString(),
    label: (idx + 1).toString(),
  }));
  return (
    <Select
      value={
        options.filter((op) => op.value.toString() === value?.toString())[0]
      }
      options={options}
      {...props}
    />
  );
};

export const getSoundEffectSelectBox = (soundEffectCode, props, value) => {
  const options = soundEffectCode.map((code) => ({
    value: code.value,
    label: code.name,
  }));
  return (
    <Select
      placeholder="효과음을 선택하세요."
      value={
        options.filter((op) => op.value.toString() === value?.toString())[0]
      }
      options={options}
      {...props}
    />
  );
};

export const LoadingSpinner = ({ styled }) => {
  return (
    <div
      className="flex w-full justify-center align-items-center"
      style={!styled ? { height: "100vh" } : styled}
    >
      <SyncLoader color="#0CC2AA" size={10} />
    </div>
  );
};

export const AddComma = (num) => {
  if (!num) return "0";
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ",");
};

export const handleKeyPress = (e, key, event) => {
  if (e.key === key) {
    event();
  }
};
