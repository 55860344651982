import React, { useState, useEffect } from "react";
import { Form, Tabs, Tab, Button, Modal } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { SimpleNotification } from "components/SimpleNotification";
import Select from "react-select";
import ExerciseLayout from "./ExerciseComponent/CreateTypeComponent/ExerciseLayout"
import cuid from "cuid";
import { AllCommonCodes, AllExpertsForExercise } from "graphql/query/select";
import { canParseInt, checkImage, getImagePathForObject, hasValue, LoadingSpinner, numberSizeCheck } from "components/common";
import { CREATE_EXERCISE } from "graphql/gql/insert";
import { UPDATE_EXERCISE } from "graphql/gql/update";
import useSettings from "stores/settings";

const CreatePage = ({ location }) => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const goBack = () => {
    history.push("/eye-movement/table");
  };

  const typesParam = {
    where : {
      type: "E",
      isUse: "Y",
      lang: "ko"
    },
    order : {
      orderByKey : "priority",
      orderByValue : "asc",
    }
  }

  const tabsParam = {
    where : {
      type: "L",
      isUse: "Y",
      lang: "ko"
    },
    order : {
      orderByKey : "priority",
      orderByValue : "asc",
    }
  }

  const exerciseTypeParam = {
    where : {
      type: "ET",
      isUse: "Y",
      lang: "ko"
    },
    order : {
      orderByKey : "priority",
      orderByValue : "asc",
    }
  }

  const soundEffectTypeParam = {
    where : {
      type: "ETS",
      isUse: "Y",
      lang: "ko"
    },
    order : {
      orderByKey : "priority",
      orderByValue : "asc",
    }
  }
 
  const types = AllCommonCodes(typesParam).result; //눈운동 시기능
  const tabs = AllCommonCodes(tabsParam).result; //한 영 일 중
  const exerciseTypes = AllCommonCodes(exerciseTypeParam).result; //유형 1~6
  const experts = AllExpertsForExercise({ lang: 'ko'}).result; //전문가들
  const soundEffects = AllCommonCodes(soundEffectTypeParam).result; //효과음 사용 안함, 효과음 1..

  const [movementType, setMovementType] = useState();
  
  useEffect(()=> {
    try {
      setMovementType(location.state.type);
    }
    catch(e) {
      goBack();
    }
  }, [])

  const [group, setGroup] = useState(`grp${cuid()}`);
  const [koSaved, setKoSaved] = useState(false);
  const [tabSaved, setTabSaved] = useState({});
  const [tabKey, setTabKey] = useState();
  const maxComponent = 10;

  const handleTabKey = (key) => {
    if (key !== 'ko' && !koSaved) {
      SimpleNotification({ type:"warning", message : "한국어 저장 후 사용 가능한 탭입니다." });
      setTabKey('ko');
      return;
    };
    setTabKey(key);
  }

  const [type, setType] = useState();
  const [typeComponentList, setTypeComponentList] = useState([]);
  const deleteTypeComponent = (id) => {
    setTypeComponentList(typeComponentList.filter(type => type.id !== id));
  }
  const addTypeComponent = () => {
    if (type) {
      if (typeComponentList.length === maxComponent) 
        return SimpleNotification({ type:"warning", message : `운동 유형은 최대 ${maxComponent}개까지 설정 가능합니다.`});
      setTypeComponentList([...typeComponentList, { id: cuid(), ...type }]);
    }
  }

  if (!tabs || !types || !exerciseTypes || !experts || !soundEffects) return <LoadingSpinner/>
  
  return (
    <>
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">눈 운동 관리</li>
            <Link
              to="/eye-movement/table"
              className="breadcrumb-item active mt-1"
              aria-current="page"
            >
              눈 운동 목록
            </Link>
            <Link
              to={`/eye-movement/create`}
              className="breadcrumb-item active mt-1"
              aria-current="page"
            >
              눈 운동 등록
            </Link>
          </ol>
        </nav>
      </div>
      <div className="my-4 pl-0">
        <Tabs
          id="movement-default-info-tabs"
          activeKey={tabKey}
          onSelect={(k) => handleTabKey(k)}
        >
          {tabs.map((tab) => (
            <Tab eventKey={tab.value} title={tab.name}>
              <Table
                lang={tab.value}
                goBack={goBack}
                movementType={movementType}
                movementName={types[types.findIndex((type) => type.value === movementType)].name}
                group={group}
                setKoSaved={setKoSaved}
                tabSaved={tabSaved}
                setTabSaved={setTabSaved}
                experts={experts}
              />
            </Tab>
          ))}
        </Tabs>
      </div>
      { koSaved ? (
        <div className="mt-3 row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="card-wrap">
                  <table className="app-table w-full">
                    <tr>
                      <td className="th w-1/6">유형 선택</td>
                      <td className="w-3/6">
                        <Select
                          menuPlacement="top"
                          placeholder="유형을 선택해주세요."
                          options={exerciseTypes.map((et) => ({
                            value: et.value,
                            label: et.name,
                          }))}
                          value={type}
                          onChange={(e) => setType(e)}
                        />
                      </td>
                      <td className="w-2/6">
                        <button
                          type="button"
                          className="btn btn-primary w-full"
                          onClick={() => addTypeComponent()}
                        >
                          +
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : ''}
      { typeComponentList.map((type, idx) => (
        <Exercise_default_layout
          tabs={tabs}
          tabSaved={tabSaved}
          idx={idx}
          parentId={group}
          key={type.id.toString()}
          type={type}
          deleteTypeComponent={deleteTypeComponent}
          maxComponent={maxComponent}
          goBack={goBack}
          soundEffects={soundEffects}
        />
      ))}
    </>
  );
};

//#region exercise
const Exercise_default_layout = ({ type, tabSaved, idx, parentId, tabs, deleteTypeComponent, maxComponent, goBack, soundEffects }) => {
  const [koSaved, setKoSaved] = useState(false);
  const [tabKey, setTabKey] = useState();
  const [group, setGroup] = useState(`grp${cuid()}`);

  const handleTabKey = (key) => {
    if (key !== 'ko' && !koSaved) { //다른 언어 저장 안되어있는데 다른 탭을 클릭했을 경우
      SimpleNotification({ type:"warning", message : "한국어 저장 후 사용 가능한 탭입니다." });
      setTabKey('ko');
      return;
    };
    if (tabSaved[key] || key === 'ko') //한국어 탭을 눌렀거나, 저장되어있는 언어를 클릭했을 경우
      return setTabKey(key)

    SimpleNotification({ type:"warning", message : "해당 언어의 기본 정보 저장 후 사용 가능합니다." });
    setTabKey('ko');
  }

  const createObject = () => {
    const obj = { id : `grp${cuid()}` };
    tabs.forEach(tab => { obj[tab.value] = {}});
    return obj;
  }

  const [typeSubtitles, setTypeSubtitles] = useState( type.value.toString() === "3" || type.value.toString() === "4" ? [createObject(), createObject()] : [createObject()]);

  const addSubtitle = () => {
    setTypeSubtitles([...typeSubtitles, createObject()]);
  }
  const deleteSubtitle = (id) => {
    setTypeSubtitles(typeSubtitles.filter(subtitle => subtitle.id !== id));
  }

  const subtitlesProps = { typeSubtitles, addSubtitle, deleteSubtitle };

  return(
    <div className="my-4 pl-0">
      <Tabs id={`exercise-default-layout-tabs${idx}`} activeKey={tabKey} onSelect={(k) => handleTabKey(k)}>
        {tabs.map((tab) => (
          <Tab eventKey={tab.value} title={tab.name}>
            <ExerciseLayout type={type} lang={tab.value} parentId={parentId} group={group} goBack={goBack} colspan="3" idx={idx} setKoSaved={setKoSaved} deleteTypeComponent={deleteTypeComponent} maxComponent={maxComponent} soundEffects={soundEffects} subtitlesProps={subtitlesProps} />
          </Tab>
        ))}
      </Tabs>
    </div>
  )
}
//#endregion

//#region Table
const Table = ({ lang, movementType, goBack, movementName, group, setKoSaved, tabSaved, setTabSaved, experts }) => {
  const [saved, setSaved] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectExpert, setSelectExpert] = useState();
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [type, setType] = useState(movementType);
  const [image, setImage] = useState();
  const [paramImage, setParamImage] = useState();
  const [keyword, setKeyword] = useState();
  const [time, setTime] = useState();
  const [purpose, setPurpose] = useState();
  const [method, setMethod] = useState();
  const [fileInfos, setFileInfos] = useState();

  //#region create
  const [exerciseCreate, { data: createData }] = useMutation(CREATE_EXERCISE, {
    onError(err) {
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  const handleCreate = () => {
    if (lang == "ko") {
      if (!([name, type, keyword, time, purpose, method, selectExpert?.id].every(hasValue))) {
        return SimpleNotification({ type: "warning", message: "기본 정보를 모두 입력해주세요." });
      }

      if (!canParseInt(time)) { 
        return SimpleNotification({ type: "warning", message: "운동 달성 점수 (초단위)은 숫자로만 입력해주세요." });
      }

      if (!numberSizeCheck(time)) {
        return SimpleNotification({ type: "warning", message: "운동 달성 점수 (초단위)의 값이 너무 큽니다." });
      }

      if (!paramImage) {
        return SimpleNotification({ type: "warning", message: "대표 이미지를 등록해주세요." });
      }
      
      exerciseCreate({
        variables: {
          data: {
            group,
            type,
            name,
            keyword,
            expertId: selectExpert.id,
            time : parseInt(time),
            purpose,
            method,
            lang
          },
          image : {
            image: paramImage,
            size: paramImage.size
          },
        },
      });
    }
    else {
      if (!([name, type, keyword, purpose, method].every(hasValue))) {
        SimpleNotification({
          title: "",
          message: "기본 정보를 모두 입력해주세요.",
        });
        return;
      }

      exerciseCreate({
        variables: {
          data: {
            group,
            type,
            name,
            keyword,
            purpose,
            method,
            lang
          }
        },
      });
    }
  };

  useEffect(() => {
    if (createData && createData.createExercise) {
      setParamImage(undefined);
      setId(createData.createExercise.id);
      setFileInfos(createData.createExercise.fileInfos);
      setSaved(true);
      if (lang==='ko') setKoSaved(true);
      else {
        tabSaved[lang] = true;
        setTabSaved({...tabSaved});
      }
      SimpleNotification({
        title: "success",
        type: "success",
        message: "성공적으로 등록하였습니다."
      });
    }
  }, [createData]);
  //#endregion

  //#region update
  const [exerciseUpdate, { data: updateData }] = useMutation(UPDATE_EXERCISE, {
    onError(err) {
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (updateData && updateData.updateExercise) {
      setParamImage(undefined);
      setFileInfos(updateData.updateExercise.fileInfos);
      SimpleNotification({
        title: "success",
        type: "success",
        message: "성공적으로 수정하였습니다."
      });
    }
  }, [updateData])

  // 수정 버튼 클릭시 실행
  const handleChange = () => {
    if (lang == "ko") {
      if (!([name, type, keyword, time, purpose, method, selectExpert?.id].every(hasValue))) {
        return SimpleNotification({ type: "warning", message: "기본 정보를 모두 입력해주세요." });
      }

      if (!canParseInt(time)) { 
        return SimpleNotification({ type: "warning", message: "운동 달성 점수 (초단위)은 숫자로만 입력해주세요." });
      }

      if (!numberSizeCheck(time)) {
        return SimpleNotification({ type: "warning", message: "운동 달성 점수 (초단위)의 값이 너무 큽니다." });
      }

      if (!id || !fileInfos) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      const variables = {
        where: {
          id
        },
        data: {
          group,
          type,
          name,
          keyword,
          expertId: selectExpert.id,
          time: parseInt(time),
          purpose,
          method,
        },
        fileWhere: fileInfos.map(info => { return { id: info.id, parentId: info.parentId, path: info.path, name: info.name, type: info.type } }),
      }

      if (paramImage) {
        variables.image = {
          image: paramImage,
          size: paramImage.size,
        };
      }  

      exerciseUpdate({ variables });
    }
    else {
      if (!([name, type, keyword, purpose, method].every(hasValue))) {
        return SimpleNotification({ type: "warning", message: "기본 정보를 모두 입력해주세요." });
      }

      if (!id) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      const variables = {
        where: {
          id
        },
        data: {
          type,
          name,
          keyword,
          purpose,
          method,
        }
      }

      exerciseUpdate({ variables });
    }
  };
  //#endregion

  const handleImageChange = async (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    if (!checkImage(file.name, file.size)) return;
    setParamImage(file); //bytearray 형태로 서버에 보낼 데이터
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => setImage([reader.result]); //base64 형태로 화면에 보여줄 데이터
  };

  const dataParams = { selectExpert, setShowModal, name, setName, image, handleImageChange, keyword, setKeyword, time, setTime, purpose, setPurpose, method, setMethod };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="card-title-wrap">
                <h4 className="card-title">눈 운동 등록</h4>
              </div>
              <div className="mt-6 card-wrap">
                {lang === "ko" ? (
                  <KoreanTable movementName={movementName} dataParams={dataParams} />
                ) : (
                  <ForeignTable dataParams={dataParams} />
                )}
              </div>
              <div className="col-12 btnbar">
                <div className="row flex justify-end">
                  {
                    !saved? (
                      <button type="button" className="btn btn-primary" onClick={handleCreate}>
                        저장하기
                      </button>  
                    ) : (
                      <button type="button" className="btn btn-primary" onClick={handleChange}>
                        수정하기
                      </button>  
                    )
                  }
                </div>
              </div>
              <Modal show={showModal} onHide={() => setShowModal(false)} aria-labelledby="example-modal-sizes-title-md" centered dialogClassName="w-4/6">
                <Modal.Header closeButton>
                  <Modal.Title>제작자 선택</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <table className="app-table min-w-full">
                    <thead className="">
                      <tr>
                        <td className="th">
                          프로필 이미지
                        </td>
                        <td className="th">
                          이름
                        </td>
                        <td className="th">
                          연락처
                        </td>
                        <td className="th">
                          주소
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      { experts.map(expert => (
                        <>
                          <tr style={{ cursor: "pointer" }} onClick={() => { setSelectExpert(expert); setShowModal(false) }}>
                            <td>
                              <img src={getImagePathForObject(expert)} alt="" className="mgz-thumb" />
                            </td>
                            <td style={{ cursor:"pointer" }}>
                              {expert.name}
                            </td>
                            <td>
                              {expert.tel}
                            </td>
                            <td>
                              {`${expert.address} ${expert.detailAddress}`}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </Modal.Body>

                <Modal.Footer className="fleex-wrap">
                  <Button variant="primary m-2 p-1" onClick={() => setShowModal(false)}>
                    취소
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const KoreanTable = ({ movementName, dataParams }) => {
  const { selectExpert, setShowModal, name, setName, image, handleImageChange, keyword, setKeyword, time, setTime, purpose, setPurpose, method, setMethod } = dataParams;
  return (
    <table className="app-table w-full">
      <tr>
        <td className="th w-1/6">운동명</td>
        <td>
          <Form.Control type="text" value={name} onChange={e => setName(e.target.value)}/>
        </td>
      </tr>
      <tr>
        <td className="th w-1/6">구분</td>
        <td> {movementName} </td>
      </tr>
      <tr>
        <td className="th w-1/6">대표 이미지</td>
        <td>
          <div className="form-file">
            <img src={image} className="profile_preview" />
            <form encType="multipart/form-data" className="w-full">
              <label className="" for="img_primary" className="mb-0">
                파일을 선택하세요.
                <i className="ti-upload"></i>
              </label>
              <input
                name="document"
                id="img_primary"
                type="file"
                accept="image/*"
                method="POST"
                onChange={handleImageChange}
              />
            </form>
          </div>
        </td>
      </tr>
      <tr>
        <td className="th w-1/6">간단 키워드</td>
        <td>
          <Form.Control type="text" value={keyword} onChange={e => setKeyword(e.target.value)}/>
        </td>
      </tr>
      <tr>
        <td className="th w-1/6">제작자</td>
        <td>
          <input type="button" className="w-full form-control" style={{ textAlign:"left" }} onClick={()=> setShowModal(true)} value={selectExpert ? `${selectExpert.name} / ${selectExpert.tel} / ${selectExpert.address} ${selectExpert.detailAddress}` : ''} />
        </td>
      </tr>
      <tr>
        <td className="th w-1/6">운동 달성 점수 (초단위)</td>
        <td>
          <Form.Control type="number" value={time} onChange={e => setTime(e.target.value)} />
        </td>
      </tr>
      <tr>
        <td className="th" colSpan="2">
          운동 목적
        </td>
      </tr>
      <tr>
        <td colSpan="2">
          <Form.Control as="textarea" rows="3" value={purpose} onChange={e => setPurpose(e.target.value)}/>
        </td>
      </tr>
      <tr>
        <td className="th" colSpan="2">
          운동 방법
        </td>
      </tr>
      <tr>
        <td colSpan="2" style={{ borderBottom: "none" }}>
          <Form.Control as="textarea" rows="3" value={method} onChange={e => setMethod(e.target.value)}/>
        </td>
      </tr>
    </table>
  );
};

const ForeignTable = ({ dataParams }) => {
  const { name, setName, keyword, setKeyword, purpose, setPurpose, method, setMethod } = dataParams;
  return (
    <table className="app-table w-full">
      <tr>
        <td className="th w-1/6">운동명</td>
        <td>
          <Form.Control type="text" value={name} onChange={e => setName(e.target.value)}/>
        </td>
      </tr>
      <tr>
        <td className="th w-1/6">간단 키워드</td>
        <td>
          <Form.Control type="text" value={keyword} onChange={e => setKeyword(e.target.value)}/>
        </td>
      </tr>
      <tr>
        <td className="th" colSpan="2">
          운동 목적
        </td>
      </tr>
      <tr>
        <td colSpan="2">
          <Form.Control as="textarea" rows="3" value={purpose} onChange={e => setPurpose(e.target.value)}/>
        </td>
      </tr>
      <tr>
        <td className="th" colSpan="2">
          운동 방법
        </td>
      </tr>
      <tr>
        <td colSpan="2" style={{ borderBottom: "none" }}>
          <Form.Control as="textarea" rows="3" value={method} onChange={e => setMethod(e.target.value)}/>
        </td>
      </tr>
    </table>
  );
};
//#endregion 

export default CreatePage;
