import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import customCKEditor from "@dev_sm/ckeditor5-classic-custom";

const Editor = ({ data, onBlurGetData }) => {
  return (
    <CKEditor
      editor={customCKEditor}
      data={data}
      config={{
        simpleUpload: {
          uploadUrl: "/gql/imageUpload",
        },
      }}
      onReady={(editor) => {
        const interval = setInterval(() => {
          if (editor && editor.editing) {
            editor.editing.view.document.on(
              "enter",
              (evt, data) => {
                // 엔터키 입력시 <p>가 아닌 <br>이 되게끔 해주는 코드
                if (data.isSoft) {
                  editor.execute("enter");
                } else {
                  editor.execute("shiftEnter");
                }
                data.preventDefault();
                evt.stop();
                editor.editing.view.scrollToTheSelection();
              },
              { priority: "high" }
            );

            clearInterval(interval);
          }
        }, 300);
      }}
      onBlur={(evt, editor) => {
        onBlurGetData(editor.getData());
      }}
    />
  );
};

export default Editor;
