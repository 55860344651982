import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormatDate } from 'components/FormatDate';
import { LoadingSpinner } from 'components/common';
import ReactTable from 'react-table';
import useSettings from 'stores/settings';
import { ALL_SEARCHS } from 'graphql/gql/select';
import { useQuery } from '@apollo/react-hooks';
import { SimpleNotification } from 'components/SimpleNotification';
import matchSorter from "match-sorter";

const TablePage = () => {
	const settings = useSettings();
	const history = useHistory();
	if (!settings.isLogin) {
		history.push('/adminIn');
	}

	const [result, setResult] = useState();

	const { loading, error, data } = useQuery(ALL_SEARCHS, {
		fetchPolicy: 'network-only',
		variables: {
			orderBy: { createAt: 'desc' }
		}
	});

	useEffect(() => {
		if (data?.allSearchs) {
			setResult(data.allSearchs);
		}
	}, [data]);

	if (error) {
		console.log(error);
		SimpleNotification({type:'danger', message: error.message || 'Error'});
	}

	if (loading || !result) return <LoadingSpinner />;

	return (
		<>
			<div>
				<div>
					<nav aria-label="breadcrumb" role="navigation">
						<ol className="breadcrumb">
							<li className="breadcrumb-item active">사용자 관리</li>
							<Link
								to="/app-user/search-history"
								className="breadcrumb-item active mt-1"
								aria-current="page"
							>
								검색기록 내역
							</Link>
						</ol>
					</nav>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="flex justify-between card-title-wrap">
									<h4 className="card-title">검색기록 내역</h4>
								</div>
								<ReactTable
									data={result}
									defaultPageSize={10}
									filterable
									className="card-wrap"
									columns={[
										{
											id: 'createAt',
											Header: '검색일자',
											accessor: 'createAt',
											filterable: false,
											Cell: row =>
												`${FormatDate(new Date(parseInt(row.value, 10)))}`
										},
										{
											id: 'keyword',
											Header: '검색 키워드 유형',
											filterMethod: (filter, rows) => 
												matchSorter(rows, filter.value, {
													keys: [item => item.keyword]
												}),
											filterAll: true,
											accessor: d => d.keyword
										},
										{
											id: 'condition',
											Header: '선택 조건',
											filterMethod: (filter, rows) => 
												matchSorter(rows, filter.value, {
													keys: [item => item.condition]
												}),
											filterAll: true,
											accessor: d => d.condition
										},
										{
											id: 'tag',
											Header: '선택 태그',
											filterMethod: (filter, rows) => 
												matchSorter(rows, filter.value, {
													keys: [item => item.tag]
												}),
											filterAll: true,
											accessor: d => d.tag
										}
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TablePage;
