import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import useSettings from "stores/settings";
import { SERVICE_CENTER } from "graphql/gql/select";
import { SimpleNotification } from "components/SimpleNotification";
import { UPDATE_SERVICE_CENTER } from "graphql/gql/update";
import Editor from "app/shared/Editor"
import { LoadingSpinner } from "components/common";

const EditPage = ({ match }) => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }
  
  const { id } = match.params;

  // 공지사항
  const { loading, error, data } = useQuery(SERVICE_CENTER, {
    fetchPolicy: "network-only",
    variables: { id },
  });
  if (loading) return <LoadingSpinner />;
  if (error) {
    SimpleNotification({
      message: error.message || "Error",
    });
    return "";
  }

  let item;
  if (data && data.serviceCenter) {
    item = data.serviceCenter;
  }
  
  if (!item) return <LoadingSpinner />;
  
  return <EditForm item={item} />;
};

const EditForm = observer(({ item }) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  
  const [title, setTitle] = useState(item.title);
  const [content, setContent] = useState(item.content);
  const { id } = item;
  const { type } = item;
  const { category } = item;

  const [noticeUpdate, { data: noticeUpdateData }] = useMutation(UPDATE_SERVICE_CENTER, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (noticeUpdateData !== undefined) {
      goBack();
    }
  }, [noticeUpdateData]);

  const handleUpdate = () => {
    if (!title || !content || content === '') {
      SimpleNotification({
        title: "",
        message: "내용을 입력해주세요.",
      });
      return;
    }

    noticeUpdate({
      variables: {
        id,
        type,
        category,
        title,
        content
      },
    });
  };
  
  return (
    <>
      {/* breadcrumb */}
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">고객센터</li>
            <Link to="/service-center/notice-table" className="breadcrumb-item active mt-1" aria-current="page">
              공지사항 목록
            </Link>
            <Link to={`/service-center/notice-edit/${id}`} className="breadcrumb-item active mt-1" aria-current="page">
              공지사항 수정
            </Link>
          </ol>
        </nav>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">공지사항 수정</h4>
              </div>
              <div className="mt-6">
                <div className="card-wrap">
                  <table className="app-table bg-white w-full">
                    <tr>
                      <td className="th">제목</td>
                      <td colSpan="3">
                        <Form.Control type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                      </td>
                    </tr>
                  </table>
                </div>
              
                <div className="row mt-4">
                  <div className="col-md-12">
                    <Editor data={content} onBlurGetData={(data) => setContent(data)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 btnbar">
        <div className="row foot-edit">
          <button className="btn btn-warning" onClick={goBack}>
            취소하기
          </button>
          <button type="button" className="btn btn-primary" onClick={handleUpdate}>
            저장하기
          </button>
        </div>
      </div>
    </>
  );
});
EditPage.prototype = {
  id: PropTypes.string.isRequired,
};

export default EditPage;