import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Form, Tabs, Tab } from "react-bootstrap";
import { ALL_SERVICE_CENTERS } from "graphql/gql/select";
import { SimpleNotification } from "components/SimpleNotification";
import { UPDATE_SERVICE_CENTER } from "graphql/gql/update";
import Editor from "app/shared/Editor"
import { hasValue, LoadingSpinner } from "components/common";
import { AllCommonCodes } from "graphql/query/select";
import { CREATE_SERVICE_CENTER } from "graphql/gql/insert";
import useSettings from "stores/settings";

const EditPage = ({ match }) => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const { group } = match.params;
  const type = "PV";
  const types = AllCommonCodes({ where : { type, isUse: "Y", lang: "ko" }, order : { orderByKey : "priority", orderByValue : "asc" }}).result;

  const tabsParam = {
    where : {
      type: "L",
      isUse: "Y",
      lang: "ko"
    },
    order : {
      orderByKey : "priority",
      orderByValue : "asc",
    }
  }

  const tabs = AllCommonCodes(tabsParam).result; //한 영 일 중

  // 사용자 FAQ
  const { loading, error, data } = useQuery(ALL_SERVICE_CENTERS, {
    fetchPolicy: "network-only",
    variables: { where : { group }},
  });
  if (loading) return <LoadingSpinner/>;
  if (error) {
    SimpleNotification({
      message: error.message || "Error",
    });
    return "";
  }

  let items;
  if (data && data.allServiceCenters) {
    items = data.allServiceCenters;
  }

  if (!items || !types || !tabs) return <LoadingSpinner />;

  return (
    <div>
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">회사정보 관리</li>
            <Link to="/company/provision-table" className="breadcrumb-item active mt-1" aria-current="page">
              약관 관리
            </Link>

            <Link to={`/company/provision-edit/${group}`} className="breadcrumb-item active mt-1" aria-current="page">
              약관 수정
            </Link>
          </ol>
        </nav>
      </div>
      <Tabs id="partner-info-tabs">
        {tabs.map((tab) => (
          <Tab eventKey={tab.value} title={tab.name}>
            <EditForm
              lang={tab.value}
              group={group}
              item={items.filter((item) => item.lang === tab.value)[0]}
              types={types}
              type={type}
            />
          </Tab>
        ))}
      </Tabs>
    </div>
  )
};

const EditForm = ({ lang, group, item, types, type }) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  const [id, setId] = useState(item?.id);
  const [category, setCategory] = useState(item?.category);
  const [title, setTitle] = useState(item?.title);
  const [content, setContent] = useState(item?.content);
  const [isSaved, setIsSaved] = useState(item ? true : false);

  //#region create
  const [provisionCreate, { data: createData }] = useMutation(CREATE_SERVICE_CENTER, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (createData?.createServiceCenter?.id) {
      setId(createData.createServiceCenter.id);
      setIsSaved(true);
      SimpleNotification({ title: "success", type: "success", message: "성공적으로 등록하였습니다." });
    }
  }, [createData]);

  const handleCreate = () => {

    if (!hasValue(title) || !hasValue(content) || !hasValue(category)) {
      return SimpleNotification({ title: "", type:"warning", message: "내용을 입력해주세요." });
    }

    provisionCreate({
      variables: {
        group,
        type,
        category,
        title,
        content,
        lang,
      },
    });
  };
  //#endregion

  //#region update
  const [provisionUpdate, { data: provisionUpdateData }] = useMutation(UPDATE_SERVICE_CENTER, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (provisionUpdateData?.updateServiceCenter.id) {
      SimpleNotification({title: "success", type: "success", message: "성공적으로 수정하였습니다." });
    }
  }, [provisionUpdateData]);

  const handleUpdate = () => {
    if (!id) {
      alert("죄송합니다. 오류가 발생하였습니다.");
      goBack();
      return;
    }
  
    if (!hasValue(title) || !hasValue(content) || !hasValue(category)) {
      return SimpleNotification({ title: "", type:"warning", message: "내용을 입력해주세요." });
    }

    provisionUpdate({
      variables: {
        id,
        category,
        title,
        content
      },
    });
  };
  //#endregion

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">약관 수정</h4>
              </div>
              <div className="mt-6">
                <div className="card-wrap">
                  <table className="app-table bg-white w-full">
                    <tr>
                      <td className="th w-1/6">주제</td>
                      <td colSpan="3">
                          <select className="form-control w-1/2" value={category} onChange={(e) => setCategory(e.target.value)}>
                            {
                              types.map(type => (
                                <option value={type.value}>{type.name}</option>
                              ))
                            }
                          </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="th">제목</td>
                      <td colSpan="3">
                        <Form.Control type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                      </td>
                    </tr>
                  </table>
                </div>
              
                <div className="row mt-4">
                  <div className="col-md-12">
                    <Editor data={content} onBlurGetData={(data) => setContent(data)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 btnbar">
        <div className="row foot-edit">
          <button className="btn btn-warning" onClick={goBack}>
            취소하기
          </button>
          {
            !isSaved? (
              <button type="button" className="btn btn-primary" onClick={handleCreate}>
                저장하기
              </button>  
            ) : (
              <button type="button" className="btn btn-primary" onClick={handleUpdate}>
                수정하기
              </button>  
            )
          }
        </div>
      </div>
    </>
  );
};

EditPage.prototype = {
  id: PropTypes.string.isRequired,
};

export default EditPage;
