import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import useSettings from "stores/settings";
import { SERVICE_CENTER } from "graphql/gql/select";
import { SimpleNotification } from "components/SimpleNotification";
import { UPDATE_SERVICE_CENTER } from "graphql/gql/update";
import Editor from "app/shared/Editor"
import { AllCommonCodes } from "graphql/query/select";
import { LoadingSpinner } from "components/common";

const EditPage = ({ match }) => {    
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }
  
  const { id } = match.params;

  const types = AllCommonCodes({ where : { type: "F", isUse: "Y", lang: "ko" }, order : { orderByKey : "priority", orderByValue : "asc" }}).result;
  // 사용자 FAQ
  const { loading, error, data } = useQuery(SERVICE_CENTER, {
    fetchPolicy: "network-only",
    variables: { id },
  });
  if (loading) return <LoadingSpinner />;
  if (error) {
    SimpleNotification({
      message: error.message || "Error",
    });
    return "";
  }

  let items;
  if (data && data.serviceCenter) {
    items = data.serviceCenter;
  }

  if (!items || !types) return <LoadingSpinner />;

  return <EditForm items={items} types={types} />;
};

const EditForm = observer(({ items, types }) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  // --------------------------
  // -- 데이터 값 저장 --

  const [category, setCategory] = useState(items.category);
  const [title, setTitle] = useState(items.title);
  const [content, setContent] = useState(items.content);

  const { id } = items;
  const { type } = items;

  const [noticeUpdate, { data: noticeUpdateData }] = useMutation(UPDATE_SERVICE_CENTER, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (noticeUpdateData !== undefined) {
      goBack();
    }
  }, [noticeUpdateData]);

  const handleUpdate = () => {
    if (!title || !content || content === '') {
      SimpleNotification({
        title: "",
        message: "내용을 입력해주세요.",
      });
      return;
    }

    noticeUpdate({
      variables: {
        id,
        type,
        category,
        title,
        content
      },
    });
  };

  return (
    <>
      {/* breadcrumb */}
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">고객센터</li>
            <Link to="/service-center/app-user-faq-table" className="breadcrumb-item active mt-1" aria-current="page">
              FAQ
            </Link>
            <Link to={`/service-center/app-user-faq-edit/${id}`} className="breadcrumb-item active mt-1" aria-current="page">
              FAQ 수정
            </Link>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">FAQ 수정</h4>
              </div>
              <div className="mt-6">
                <div className="card-wrap">
                  <table className="app-table bg-white w-full">
                    <tbody>
                      <tr>
                        <td className="th">구분</td>
                        <td colSpan="3">
                          <select
                            className="form-control w-1/4"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            {
                              types.map(type => (<option value={type.value}>{type.name}</option>))
                            }
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className="th">제목</td>
                        <td colSpan="3">
                          <Form.Control type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              
                <div className="row mt-4">
                  <div className="col-md-12">
                    <Editor data={content} onBlurGetData={(data) => setContent(data)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 btnbar">
        <div className="row foot-edit">
          <button className="btn btn-warning" onClick={goBack}>
            취소하기
          </button>
          <button type="button" className="btn btn-primary" onClick={handleUpdate}>
            저장하기
          </button>
        </div>
      </div>
    </>
  );
});
EditPage.prototype = {
  id: PropTypes.string.isRequired,
};

export default EditPage;
