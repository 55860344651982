import React, {useState, useEffect} from 'react'
import FocusSubtitle from './Subtitle'
import { Form } from "react-bootstrap";
import Select from "react-select";
import { checkImage, getImagePath, getNumberSelectBox, getSoundEffectSelectBox } from "components/common"

const FocusLayout = ({ colspan, isKorea, idx, maxComponent, lang, isSaved, soundEffects, info, subtitles, addSubtitle, deleteSubtitle }) => {

  const [name, setName] = useState(info.name);
  const [time, setTime] = useState(info.time);
  const [sound, setSound] = useState(info.sound);
  const [priority, setPriority] = useState(info.priority);

  useEffect(() => {
    info.name = name;
  }, [name])

  useEffect(() => {
    info.time = time;
  }, [time])
  
  useEffect(() => {
    info.sound = sound;
  }, [sound]);
  
  useEffect(() => {
    info.priority = priority;
  }, [priority]);

  return (
    <>
      <h4 className="ml-2"> 기본 설정 </h4>
      <table className="app-table w-full">
        <tr>
            <td className="th w-1/6">유형 제목</td>
            <td colspan={colspan}>
                <Form.Control type="text" placeholder="유형 제목을 입력하세요." value={name} onChange={e => setName(e.target.value)}/>
            </td>
        </tr>
        { 
          isKorea? (
            <>
              {
                Array.from({length : 5}).map((item, arrIdx) => (
                  <FocusImage path={info.id ? info.fileInfos.filter(fileInfo => fileInfo.id === info[`imageStep${arrIdx+1}`])[0] : ''} componentIdx={idx} idx={arrIdx+1} colspan={colspan} info={info}/>
                ))
              }
              <tr>
                <td className="th w-1/6">단계별 유지 시간 설정</td>
                <td colspan={colspan}>
                  <Form.Control type="number" placeholder="단계별 유지 시간을 설정하세요. (초단위)" value={time} onChange={e => setTime(e.target.value)} />
                </td>
              </tr>
              <tr>
                <td className="th w-1/6">효과음 설정</td>
                <td colspan={colspan}>
                  { getSoundEffectSelectBox(soundEffects, { onChange : e => setSound(e.value) }, sound) }
                </td>
              </tr>
              <tr>
                  <td className="th w-1/6">정렬 순서 선택</td>
                  <td colspan={colspan}>
                    { getNumberSelectBox(maxComponent, { onChange : e => setPriority(e.value)}, priority) }              
                  </td>
              </tr>
            </>
          ) : ''
        }
      </table>
      <FocusSubtitle isKorea={isKorea} lang={lang} isSaved={isSaved} colspan={colspan} subtitles={subtitles} addSubtitle={addSubtitle} deleteSubtitle={deleteSubtitle}/>
    </>
  )
}

const FocusImage = ({path, componentIdx, idx, colspan, info}) => {
  const [image, setImage] = useState(getImagePath(path));
  const handleImageChange = async (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    if (!checkImage(file.name, file.size)) return;
    info[`imageStep${idx}Data`] = file;
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => setImage([reader.result]);
  };

  return (
    <tr>
      <td className="th w-1/6">초점 단계별 이미지 - {idx}단계</td>
      <td colspan={colspan}>
        <div className="form-file">
          <img src={image} className="profile_preview" />
          <form encType="multipart/form-data" className="w-full">
            <label className="" for={`img_primary_${componentIdx}_${idx}`} className="mb-0">
              이미지를 선택하세요. (지원 이미지 png, jpg, gif)
              <i className="ti-upload"></i>
            </label>
            <input
              name="document"
              id={`img_primary_${componentIdx}_${idx}`}
              type="file"
              accept=".jpg,.png,.gif"
              method="POST"
              onChange={(e) => handleImageChange(e)}
            />
          </form>
        </div>
      </td>
    </tr>
  )
}

export default FocusLayout;