import React, {useState, useEffect} from 'react'
import { Form } from "react-bootstrap";

const EyeMuscleSubtitle = ({ item }) => {
    const [subtitles, setSubtitles] = useState(item.subtitles);
    
    useEffect(() => {
        if (subtitles)
            item.subtitles = subtitles;
    }, [subtitles])

    return (
        <>
            <tr>
                <td className="th w-1/6">자막 설정</td>
                <td>
                    <Form.Control type="text" placeholder="자막을 입력하세요." value={subtitles} onChange={e => setSubtitles(e.target.value)} />
                </td>
            </tr>
        </>
    )
}

export default EyeMuscleSubtitle;