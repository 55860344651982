import { useContext, createContext } from "react";
import * as mobx from "mobx";

const context = createContext(
  mobx.observable({
    url: 'https://pixelro.nenoons.com',
    port: '28003', //웹뷰호출 port
    pushUrl: `https://pixelro.nenoons.com:28000/api/push`,

    // 로그인 정보
    isLogin: false,
    state: false,
    pixelroId: "",
    email: "",
    storeId: "",
    // bannerTab: "메인_배너",
    bannerTab: "1",
    recommendProductTab: "1",
    // 상점 리스트
    stores: [],
    // 상품 리스트
    products: [],
    productList: [],
    pageOfItems: 0,
    // 쿠폰 리스트
    couponList: [],

    items: [],

    // 토큰
    token: "",

    // service center content 저장
    serviceCenterContent: "",
  })
);

const useSettings = () => {
  return useContext(context);
};

export default useSettings;
