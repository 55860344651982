import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Form, Modal } from "react-bootstrap";
import useSettings from "stores/settings";
import { CREATE_LOG } from "graphql/gql/insert";
import { SimpleNotification } from "components/SimpleNotification";
import publicIp from "public-ip";
import { User } from "graphql/query/select";
import { UPDATE_USER } from "graphql/gql/update";
import DaumPostcode from "react-daum-postcode";

const EditPage = ({ match }) => {
  const { id } = match.params;
  // 로그인 체크
  const settings = useSettings();
  const history = useHistory();
  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const { result } = User(id);
  const items = result;

  return <div>{items && <EditForm items={items} />}</div>;
};

const EditForm = ({ items }, props) => {
  const history = useHistory();
  // 로그인 체크
  const settings = useSettings();
  const goBack = () => {
    history.goBack();
  };

  // --------------------------

  const [mdShow, setMdShow] = useState(false);

  // -- 데이터 값 저장 --
  const { id } = items;
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [name, setName] = useState(items.name);
  const [tel, setTel] = useState(items.tel);
  const [post, setPost] = useState(items.post);
  const [address, setAddress] = useState(items.address);
  const [detailAddress, setDetailAddress] = useState(items.detailAddress);

  const [birthday, setBirthday] = useState(items.birthday);
  const [job, setJob] = useState(items.job);
  const [gender, setGender] = useState(items.gender);

  // Log
  const [logCreate, { data: createLogData }] = useMutation(CREATE_LOG, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (createLogData !== undefined) {
      goBack();
    }
  }, [createLogData]);

  // 앱 사용자 정보 업데이트
  const [userUpdate, { data: updateData }] = useMutation(UPDATE_USER, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (updateData !== undefined) {
      async function logData() {
        const adminEmail = settings.email;
        const ip = await publicIp.v4();
        const url = `/app-users/user-edit/${id}`;
        const target = "user";
        const detail = "수정";

        logCreate({ variables: { adminEmail, ip, url, target, detail } });
      }

      logData();
    }
  }, [updateData]);

  const handleUpdate = () => {
    if (!name || !tel || !gender) {
      SimpleNotification({
        title: "",
        message: "작성되지 않은 항목이 있습니다.",
      });
      return;
    }

    let pwd;

    if (password !== passwordCheck) {
      SimpleNotification({
        title: "",
        message: "비밀번호가 일치하지 않습니다.",
      });
      return;
    }

    if (password && passwordCheck && password !== "" && passwordCheck !== "") {
      if (password === passwordCheck) {
        pwd = password;
      }
    }

    userUpdate({
      variables: {
        id,
        password: items.password,
        newPassword: pwd,
        name,
        tel,
        post,
        address,
        detailAddress,
        birthday,
        job,
        gender,
      },
    });
  };

  // 주소 값 저장
  const handlePostComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    let zonecode = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    zonecode = data.zonecode;

    setMdShow(false);
    setPost(zonecode);
    setAddress(fullAddress);
  };

  return (
    <>
      {/* breadcrumb */}
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">사용자 관리</li>
            <Link to="/app-users/user-table" className="breadcrumb-item active mt-1" aria-current="page">
              회원 목록
            </Link>
            <Link to={`/app-users/user-detail/${id}`} className="breadcrumb-item active mt-1" aria-current="page">
              회원 정보
            </Link>
            <Link to={`/app-users/user-edit/${id}`} className="breadcrumb-item active mt-1" aria-current="page">
              회원 정보 수정
            </Link>
          </ol>
        </nav>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
              <h4 className="card-title">멤버 정보 수정</h4>
            </div>
              <div className="mt-6 card-wrap">
              <table className="app-table bg-white w-full">
                <tr>
                  <td className="th text-left">이메일</td>
                  <td colSpan="3" className="">
                    <Form.Control type="text" value={items.email} readOnly />
                  </td>
                </tr>
                {/*<tr className="m-0 p-0">
                  <td className="th text-left">비밀번호</td>
                  <td colSpan="3" className="">
                    <Form.Control
                      className="mb-2 w-3/12"
                      type="password"
                      value={password}
                      placeholder="비밀번호"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="flex mb-2">
                      <Form.Control
                        className="w-3/12"
                        type="password"
                        value={passwordCheck}
                        placeholder="비밀번호 확인"
                        onChange={(e) => setPasswordCheck(e.target.value)}
                      />
                    </div>
                  </td>
                </tr>*/}

                <tr className="m-0 p-0">
                  <td className="th text-left w-2/12">이름</td>
                  <td className="w-4/12">
                    <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
                  </td>
                  <td className="th text-left w-2/12">전화번호</td>
                  <td className="w-4/12">
                    <Form.Control type="text" value={tel} onChange={(e) => setTel(e.target.value)} />
                  </td>
                </tr>

                <tr>
                  <td className="th text-left">주소</td>
                  <td colSpan="3" className="">
                    <div className="flex mb-2">
                      <Form.Control className="w-2/12" type="text" value={post} readOnly placeholder="우편번호" />
                      <button onClick={(e) => setMdShow(true)} type="button" className="btn btn-outline-primary btn-sm ml-2">
                        주소찾기
                      </button>
                    </div>
                    <Form.Control className="mb-2" type="text" readOnly value={address} placeholder="주소" />
                    <Form.Control
                      type="text"
                      value={detailAddress}
                      placeholder="상세주소"
                      onChange={(e) => setDetailAddress(e.target.value)}
                    />
                    <Modal show={mdShow} onHide={() => setMdShow(false)} aria-labelledby="example-modal-sizes-title-md">
                      <Modal.Header closeButton>
                        <DaumPostcode className="h-full" onComplete={handlePostComplete} {...props} />
                      </Modal.Header>
                    </Modal>
                    {/* Modal Ends */}
                  </td>
                </tr>
                <tr>
                  <td className="th text-left">생년월일</td>
                  <td colSpan="3" className="">
                    <Form.Control type="text" value={birthday} onChange={(e) => setBirthday(e.target.value)} />
                  </td>
                </tr>
                <tr>
                  <td className="th text-left">직업</td>
                  <td colSpan="3" className="">
                    <Form.Control type="text" value={job} onChange={(e) => setJob(e.target.value)} />
                  </td>
                </tr>
                <tr>
                  <td className="th text-left">성별</td>
                  <td colSpan="3" className="">
                    <select className="form-control w-1/4" value={gender} onChange={(e) => setGender(e.target.value)}>
                      <option value="">성별을 선택해주세요</option>
                      <option value="남성">남성</option>
                      <option value="여성">여성</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="th">성별</td>
                  <td colSpan="3">
                    <ul className="tag-wrap select-mode">
                      <li className="tag-item select">자가테스트</li>
                      <li className="tag-item">노안</li>
                      <li className="tag-item">눈건강</li>
                      <li className="tag-item select">눈운동</li>
                      <li className="tag-item">컨텐트렌즈</li>
                      <li className="tag-item">시력</li>
                      <li className="tag-item">라섹</li>
                      <li className="tag-item">빛번짐</li>
                      <li className="tag-item">색약</li>
                      <li className="tag-item">색맹</li>
                      <li className="tag-item">아이웨어</li>
                      <li className="tag-item">황반변성</li>
                      <li className="tag-item">잘모르겠어요</li>
                    </ul>
                  </td>
                </tr>
              </table>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 btnbar">
        <div className="row foot-edit">
          <button className="btn btn-warning" onClick={goBack}>
            취소하기
          </button>
          <button type="button" className="btn btn-primary" onClick={handleUpdate}>
            저장하기
          </button>
        </div>
      </div>
    </>
  );
};
EditPage.prototype = {
  id: PropTypes.string.isRequired,
};

export default EditPage;
