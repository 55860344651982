import React, { useState, useEffect } from "react";
import Type1Layout from "./defaultType/Layout";
import Type2Layout from "./RestType/Layout";
import Type3Layout from "./BlinkComfortablyType/Layout";
import Type4Layout from "./BlinkForceType/Layout";
import Type5Layout from "./FocusType/Layout";
import Type6Layout from "./EyeMuscleType/Layout";
import { hasValue, canParseInt, numberSizeCheck } from "components/common";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_EXERCISE_TYPE1,
  CREATE_EXERCISE_TYPE2,
  CREATE_EXERCISE_TYPE3,
  CREATE_EXERCISE_TYPE4,
  CREATE_EXERCISE_TYPE5,
  CREATE_EXERCISE_TYPE6,
} from "graphql/gql/insert";
import {
  UPDATE_EXERCISE_TYPE1,
  UPDATE_EXERCISE_TYPE2,
  UPDATE_EXERCISE_TYPE3,
  UPDATE_EXERCISE_TYPE4,
  UPDATE_EXERCISE_TYPE5,
  UPDATE_EXERCISE_TYPE6,
} from "graphql/gql/update";
import {
  DELETE_EXERCISE_TYPE1,
  DELETE_EXERCISE_TYPE2,
  DELETE_EXERCISE_TYPE3,
  DELETE_EXERCISE_TYPE4,
  DELETE_EXERCISE_TYPE5,
  DELETE_EXERCISE_TYPE6,
} from "graphql/gql/delete";
import { SimpleNotification } from "components/SimpleNotification";

const ExerciseLayout = ({
  type,
  lang,
  parentId,
  group,
  goBack,
  colspan,
  idx,
  setKoSaved,
  deleteTypeComponent,
  maxComponent,
  soundEffects,
  item,
  subtitlesProps,
  sharedProps,
  setSharedProps,
}) => {
  const { typeSubtitles, addSubtitle, deleteSubtitle } = subtitlesProps;
  const isKorea = lang === "ko";
  const [isSaved, setIsSaved] = useState(item?.id ? true : false);
  const [id, setId] = useState(item?.id);
  const [fileInfos, setFileInfos] = useState(item?.fileInfos);
  const [typeInfo, setTypeInfo] = useState(item || {});
  useEffect(() => {
    console.log("this is shared", sharedProps);
  }, [sharedProps]);
  const commonProps = {
    colspan,
    isKorea,
    maxComponent,
    soundEffects,
    idx,
    isSaved,
    lang,
    info: typeInfo,
    subtitles: typeSubtitles,
  };

  //#region type1

  //#region create
  const [exerciseType1Create, { data: type1CreateData }] = useMutation(
    CREATE_EXERCISE_TYPE1,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type1CreateData && type1CreateData.createExerciseType1withSubs) {
      if (!hasValue(type1CreateData.createExerciseType1withSubs.id))
        return SimpleNotification({
          type: "danger",
          message: "알 수 없는 오류가 발생하였습니다.",
        });
      typeInfo.paramImage = undefined;
      setId(type1CreateData.createExerciseType1withSubs.id);
      setFileInfos(type1CreateData.createExerciseType1withSubs.fileInfos);
      setIsSaved(true);
      if (isKorea) setKoSaved(true);
      return SimpleNotification({
        type: "success",
        message: "성공적으로 등록하였습니다.",
      });
    }
  }, [type1CreateData]);

  const checkSubtitle = (resolve, reject, subtitle) => {
    if (
      !subtitle[lang] ||
      !hasValue(subtitle[lang].subtitles) ||
      !hasValue(subtitle[lang].time)
    ) {
      SimpleNotification({
        type: "warning",
        message: `모든 자막을 설정해주세요.`,
      });
      reject();
      return;
    }

    if (isNaN(parseInt(subtitle[lang].time))) {
      SimpleNotification({
        type: "warning",
        message: "자막 시작 시간은 숫자로만 입력해주세요.",
      });
      reject();
      return;
    }
    if (!numberSizeCheck(subtitle[lang].time)) {
      return SimpleNotification({
        type: "warning",
        message: "자막 시작 시간의 값이 너무 큽니다.",
      });
    }
    resolve();
  };

  const handleType1Create = () => {
    if (isKorea) {
      const { name, paramImage, time, priority } = typeInfo;
      if (![name, time, priority].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![time, priority].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "시간은 숫자로만 입력해주세요.",
        });
      }

      if (!numberSizeCheck(time)) {
        return SimpleNotification({
          type: "warning",
          message: "시간의 값이 너무 큽니다.",
        });
      }

      if (!paramImage) {
        return SimpleNotification({
          type: "warning",
          message: "이미지를 선택해주세요.",
        });
      }

      let requests = typeSubtitles.map((subtitle) => {
        return new Promise((resolve, reject) => {
          checkSubtitle(resolve, reject, subtitle);
        });
      });

      Promise.all(requests).then(() => {
        exerciseType1Create({
          variables: {
            data: {
              parentId,
              group,
              name,
              time: parseInt(time),
              lang,
              priority: parseInt(priority),
            },
            image: {
              image: paramImage,
              size: paramImage.size,
            },
            subDatas: typeSubtitles.map((subtitle) => ({
              group: subtitle.id,
              parentId: group,
              type: type.value,
              subtitles: subtitle[lang].subtitles,
              time: parseInt(subtitle[lang].time),
              lang,
            })),
          },
        });
        setSharedProps({ time, priority });
      });
    } else {
      const { name } = typeInfo;
      const { time, priority } = sharedProps;
      if (![name].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      let requests = typeSubtitles.map((subtitle) => {
        return new Promise((resolve, reject) => {
          checkSubtitle(resolve, reject, subtitle);
        });
      });

      Promise.all(requests).then(() => {
        exerciseType1Create({
          variables: {
            data: {
              parentId,
              group,
              name,
              time: parseInt(time),
              lang,
              priority: parseInt(priority),
            },
            subDatas: typeSubtitles.map((subtitle) => ({
              group: subtitle.id,
              parentId: group,
              type: type.value,
              subtitles: subtitle[lang].subtitles,
              time: parseInt(subtitle[lang].time),
              lang,
            })),
          },
        });
      });
    }
  };
  //#endregion

  //#region update
  const [exerciseType1Update, { data: type1UpdateData }] = useMutation(
    UPDATE_EXERCISE_TYPE1,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type1UpdateData && type1UpdateData.updateExerciseType1withSubs) {
      if (!type1UpdateData.updateExerciseType1withSubs.id)
        return SimpleNotification({
          type: "danger",
          message: "알 수 없는 오류가 발생하였습니다.",
        });
      typeInfo.paramImage = undefined;
      setFileInfos(type1UpdateData.updateExerciseType1withSubs.fileInfos);
      return SimpleNotification({
        type: "success",
        message: "성공적으로 수정하였습니다.",
      });
    }
  }, [type1UpdateData]);

  const handleType1Change = () => {
    if (isKorea) {
      const { name, paramImage, time, priority } = typeInfo;
      if (![name, time, priority].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![time, priority].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "시간은 숫자로만 입력해주세요.",
        });
      }

      if (!numberSizeCheck(time)) {
        return SimpleNotification({
          type: "warning",
          message: "시간의 값이 너무 큽니다.",
        });
      }

      if (!id || !fileInfos) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      let requests = typeSubtitles.map((subtitle) => {
        return new Promise((resolve, reject) => {
          checkSubtitle(resolve, reject, subtitle);
        });
      });

      Promise.all(requests).then(() => {
        const variables = {
          where: {
            id,
            group,
            lang,
          },
          data: {
            name,
            time: parseInt(time),
            priority: parseInt(priority),
          },
          subDatas: typeSubtitles.map((subtitle) => ({
            group: subtitle.id,
            parentId: group,
            type: type.value,
            subtitles: subtitle[lang].subtitles,
            time: parseInt(subtitle[lang].time),
            lang,
          })),
          fileWhere: fileInfos.map((info) => {
            return {
              id: info.id,
              parentId: info.parentId,
              path: info.path,
              name: info.name,
              type: info.type,
            };
          }),
        };

        if (paramImage) {
          variables.image = {
            image: paramImage,
            size: paramImage.size,
          };
        }

        exerciseType1Update({ variables });
      });
    } else {
      const { name } = typeInfo;

      if (!id) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      if (![name].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      let requests = typeSubtitles.map((subtitle) => {
        return new Promise((resolve, reject) => {
          checkSubtitle(resolve, reject, subtitle);
        });
      });

      Promise.all(requests).then(() => {
        const variables = {
          where: {
            id,
            group,
            lang,
          },
          data: {
            name,
          },
          subDatas: typeSubtitles.map((subtitle) => ({
            group: subtitle.id,
            parentId: group,
            type: type.value,
            subtitles: subtitle[lang].subtitles,
            time: parseInt(subtitle[lang].time),
            lang,
          })),
        };

        exerciseType1Update({ variables });
      });
    }
  };
  //#endregion

  //#region delete
  const [exerciseType1Delete, { data: type1DeleteData }] = useMutation(
    DELETE_EXERCISE_TYPE1,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type1DeleteData && type1DeleteData.deleteExerciseType1) {
      if (type1DeleteData.deleteExerciseType1.error)
        return SimpleNotification({
          type: "danger",
          message: `오류가 발생하였습니다.\r\n${type1DeleteData.deleteExerciseType1.error}`,
        });
    }
  }, [type1DeleteData]);

  const handleType1Delete = () => {
    exerciseType1Delete({ variables: { group } });
  };
  //#endregion

  //#endregion

  //#region type2

  //#region create
  const [exerciseType2Create, { data: type2CreateData }] = useMutation(
    CREATE_EXERCISE_TYPE2,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type2CreateData && type2CreateData.createExerciseType2withSubs) {
      if (!hasValue(type2CreateData.createExerciseType2withSubs.id))
        return SimpleNotification({
          type: "danger",
          message: "알 수 없는 오류가 발생하였습니다.",
        });
      typeInfo.paramImage = undefined;
      setId(type2CreateData.createExerciseType2withSubs.id);
      setFileInfos(type2CreateData.createExerciseType2withSubs.fileInfos);
      setIsSaved(true);
      if (isKorea) setKoSaved(true);
      return SimpleNotification({
        type: "success",
        message: "성공적으로 등록하였습니다.",
      });
    }
  }, [type2CreateData]);

  const handleType2Create = () => {
    if (isKorea) {
      const { name, paramImage, time, priority } = typeInfo;
      if (
        ![
          name,
          time,
          priority,
          typeSubtitles[0][lang].subtitles,
          typeSubtitles[0][lang].time,
        ].every(hasValue)
      ) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![time, priority, typeSubtitles[0][lang].time].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "시간은 숫자로만 입력해주세요.",
        });
      }

      if (
        !numberSizeCheck(time) ||
        !numberSizeCheck(typeSubtitles[0][lang].time)
      ) {
        return SimpleNotification({
          type: "warning",
          message: "시간의 값이 너무 큽니다.",
        });
      }

      if (!paramImage) {
        return SimpleNotification({
          type: "warning",
          message: "이미지를 선택해주세요.",
        });
      }

      exerciseType2Create({
        variables: {
          data: {
            parentId,
            group,
            name,
            time: parseInt(time),
            lang,
            priority: parseInt(priority),
          },
          image: {
            image: paramImage,
            size: paramImage.size,
          },
          subDatas: [
            {
              group: typeSubtitles[0].id,
              parentId: group,
              type: type.value,
              subtitles: typeSubtitles[0][lang].subtitles,
              time: parseInt(typeSubtitles[0][lang].time),
              lang,
            },
          ],
        },
      });
      setSharedProps({ time, priority });
    } else {
      const { name } = typeInfo;
      const { time, priority } = sharedProps;
      if (
        ![
          name,
          typeSubtitles[0][lang].subtitles,
          typeSubtitles[0][lang].time,
        ].every(hasValue)
      ) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![typeSubtitles[0][lang].time].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "시간은 숫자로만 입력해주세요.",
        });
      }

      if (!numberSizeCheck(typeSubtitles[0][lang].time)) {
        return SimpleNotification({
          type: "warning",
          message: "시간의 값이 너무 큽니다.",
        });
      }

      exerciseType2Create({
        variables: {
          data: {
            parentId,
            group,
            name,
            time: parseInt(time),
            lang,
            priority: parseInt(priority),
          },
          subDatas: [
            {
              group: typeSubtitles[0].id,
              parentId: group,
              type: type.value,
              subtitles: typeSubtitles[0][lang].subtitles,
              time: parseInt(typeSubtitles[0][lang].time),
              lang,
            },
          ],
        },
      });
    }
  };
  //#endregion

  //#region update
  const [exerciseType2Update, { data: type2UpdateData }] = useMutation(
    UPDATE_EXERCISE_TYPE2,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type2UpdateData && type2UpdateData.updateExerciseType2withSubs) {
      if (!type2UpdateData.updateExerciseType2withSubs.id)
        return SimpleNotification({
          type: "danger",
          message: "알 수 없는 오류가 발생하였습니다.",
        });
      typeInfo.paramImage = undefined;
      setFileInfos(type2UpdateData.updateExerciseType2withSubs.fileInfos);
      return SimpleNotification({
        type: "success",
        message: "성공적으로 수정하였습니다.",
      });
    }
  }, [type2UpdateData]);

  const handleType2Change = () => {
    if (isKorea) {
      const { name, paramImage, time, priority } = typeInfo;
      if (
        ![
          name,
          time,
          priority,
          typeSubtitles[0][lang].subtitles,
          typeSubtitles[0][lang].time,
        ].every(hasValue)
      ) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![time, priority, typeSubtitles[0][lang].time].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "시간은 숫자로만 입력해주세요.",
        });
      }

      if (
        !numberSizeCheck(time) ||
        !numberSizeCheck(typeSubtitles[0][lang].time)
      ) {
        return SimpleNotification({
          type: "warning",
          message: "시간의 값이 너무 큽니다.",
        });
      }

      if (!id || !fileInfos) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      const variables = {
        where: {
          id,
          group,
          lang,
        },
        data: {
          name,
          time: parseInt(time),
          priority: parseInt(priority),
        },
        subDatas: [
          {
            group: typeSubtitles[0].id,
            parentId: group,
            type: type.value,
            subtitles: typeSubtitles[0][lang].subtitles,
            time: parseInt(typeSubtitles[0][lang].time),
            lang,
          },
        ],
        fileWhere: fileInfos.map((info) => {
          return {
            id: info.id,
            parentId: info.parentId,
            path: info.path,
            name: info.name,
            type: info.type,
          };
        }),
      };

      if (paramImage) {
        variables.image = {
          image: paramImage,
          size: paramImage.size,
        };
      }

      exerciseType2Update({ variables });
    } else {
      const { name } = typeInfo;

      if (!id) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      if (
        ![
          name,
          typeSubtitles[0][lang].subtitles,
          typeSubtitles[0][lang].time,
        ].every(hasValue)
      ) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![typeSubtitles[0][lang].time].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "시간은 숫자로만 입력해주세요.",
        });
      }

      if (!numberSizeCheck(typeSubtitles[0][lang].time)) {
        return SimpleNotification({
          type: "warning",
          message: "시간의 값이 너무 큽니다.",
        });
      }

      const variables = {
        where: {
          id,
          group,
          lang,
        },
        data: {
          name,
        },
        subDatas: [
          {
            group: typeSubtitles[0].id,
            parentId: group,
            type: type.value,
            subtitles: typeSubtitles[0][lang].subtitles,
            time: parseInt(typeSubtitles[0][lang].time),
            lang,
          },
        ],
      };

      exerciseType2Update({ variables });
    }
  };
  //#endregion

  //#region delete
  const [exerciseType2Delete, { data: type2DeleteData }] = useMutation(
    DELETE_EXERCISE_TYPE2,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type2DeleteData && type2DeleteData.deleteExerciseType2) {
      if (type2DeleteData.deleteExerciseType2.error)
        return SimpleNotification({
          type: "danger",
          message: `오류가 발생하였습니다.\r\n${type2DeleteData.deleteExerciseType2.error}`,
        });
    }
  }, [type2DeleteData]);

  const handleType2Delete = () => {
    exerciseType2Delete({ variables: { group } });
  };
  //#endregion

  //#endregion

  //#region type3

  //#region create
  const [exerciseType3Create, { data: type3CreateData }] = useMutation(
    CREATE_EXERCISE_TYPE3,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type3CreateData && type3CreateData.createExerciseType3withSubs) {
      if (!hasValue(type3CreateData.createExerciseType3withSubs.id))
        return SimpleNotification({
          type: "danger",
          message: "알 수 없는 오류가 발생하였습니다.",
        });
      typeInfo.eyeCloseImageData = undefined;
      typeInfo.eyeOpenImageData = undefined;
      typeInfo.eyeCloseImage =
        type3CreateData.createExerciseType3withSubs.eyeCloseImage;
      typeInfo.eyeOpenImage =
        type3CreateData.createExerciseType3withSubs.eyeOpenImage;
      setId(type3CreateData.createExerciseType3withSubs.id);
      setFileInfos(type3CreateData.createExerciseType3withSubs.fileInfos);
      setIsSaved(true);
      if (isKorea) setKoSaved(true);
      return SimpleNotification({
        type: "success",
        message: "성공적으로 등록하였습니다.",
      });
    }
  }, [type3CreateData]);

  const handleType3Create = () => {
    const eyeCloseSubtitle = typeSubtitles[0][lang].eyeCloseSubtitle;
    const eyeOpenSubtitle = typeSubtitles[1][lang].eyeOpenSubtitle;

    if (isKorea) {
      const {
        name,
        eyeCloseImageData,
        eyeOpenImageData,
        eyeBlinkCnt,
        sound,
        priority,
      } = typeInfo;

      if (
        ![
          name,
          eyeBlinkCnt,
          sound,
          priority,
          eyeCloseSubtitle,
          eyeOpenSubtitle,
        ].every(hasValue)
      ) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![eyeBlinkCnt, priority].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "깜빡임 횟수는 숫자로만 입력해주세요.",
        });
      }

      if (parseInt(eyeBlinkCnt) > 100) {
        return SimpleNotification({
          type: "warning",
          message: "깜빡임 횟수의 값이 너무 큽니다.",
        });
      }

      if ((!eyeCloseImageData, !eyeOpenImageData)) {
        return SimpleNotification({
          type: "warning",
          message: "이미지를 선택해주세요.",
        });
      }

      exerciseType3Create({
        variables: {
          data: {
            parentId,
            group,
            name,
            eyeBlinkCnt: parseInt(eyeBlinkCnt),
            lang,
            sound,
            priority: parseInt(priority),
          },
          openImage: {
            image: eyeOpenImageData,
            size: eyeOpenImageData.size,
          },
          closeImage: {
            image: eyeCloseImageData,
            size: eyeCloseImageData.size,
          },
          subDatas: [
            {
              group: typeSubtitles[0].id,
              parentId: group,
              type: type.value,
              subtitles: eyeCloseSubtitle,
              condition: "4",
              lang,
            },
            {
              group: typeSubtitles[1].id,
              parentId: group,
              type: type.value,
              subtitles: eyeOpenSubtitle,
              condition: "5",
              lang,
            },
          ],
        },
      });
      setSharedProps({ eyeBlinkCnt, sound, priority });
    } else {
      const { name } = typeInfo;
      const { eyeBlinkCnt, sound, priority } = sharedProps;
      if (![name, eyeCloseSubtitle, eyeOpenSubtitle].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      exerciseType3Create({
        variables: {
          data: {
            parentId,
            group,
            name,
            eyeBlinkCnt: parseInt(eyeBlinkCnt),
            lang,
            sound,
            priority: parseInt(priority),
          },
          subDatas: [
            {
              group: typeSubtitles[0].id,
              parentId: group,
              type: type.value,
              subtitles: eyeCloseSubtitle,
              condition: "4",
              lang,
            },
            {
              group: typeSubtitles[1].id,
              parentId: group,
              type: type.value,
              subtitles: eyeOpenSubtitle,
              condition: "5",
              lang,
            },
          ],
        },
      });
    }
  };
  //#endregion

  //#region update
  const [exerciseType3Update, { data: type3UpdateData }] = useMutation(
    UPDATE_EXERCISE_TYPE3,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type3UpdateData && type3UpdateData.updateExerciseType3withSubs) {
      if (!type3UpdateData.updateExerciseType3withSubs.id)
        return SimpleNotification({
          type: "danger",
          message: "알 수 없는 오류가 발생하였습니다.",
        });
      typeInfo.eyeCloseImageData = undefined;
      typeInfo.eyeOpenImageData = undefined;
      typeInfo.eyeCloseImage =
        type3UpdateData.updateExerciseType3withSubs.eyeCloseImage;
      typeInfo.eyeOpenImage =
        type3UpdateData.updateExerciseType3withSubs.eyeOpenImage;
      setFileInfos(type3UpdateData.updateExerciseType3withSubs.fileInfos);
      return SimpleNotification({
        type: "success",
        message: "성공적으로 수정하였습니다.",
      });
    }
  }, [type3UpdateData]);

  const handleType3Change = () => {
    const eyeCloseSubtitle = typeSubtitles[0][lang].eyeCloseSubtitle;
    const eyeOpenSubtitle = typeSubtitles[1][lang].eyeOpenSubtitle;

    if (isKorea) {
      const {
        name,
        eyeCloseImageData,
        eyeOpenImageData,
        eyeBlinkCnt,
        sound,
        priority,
      } = typeInfo;

      if (
        ![
          name,
          eyeBlinkCnt,
          sound,
          priority,
          eyeCloseSubtitle,
          eyeOpenSubtitle,
        ].every(hasValue)
      ) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![eyeBlinkCnt, priority].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "깜빡임 횟수는 숫자로만 입력해주세요.",
        });
      }

      if (parseInt(eyeBlinkCnt) > 100) {
        return SimpleNotification({
          type: "warning",
          message: "깜빡임 횟수의 값이 너무 큽니다.",
        });
      }

      if (
        !id ||
        !fileInfos ||
        !typeInfo.eyeCloseImage ||
        !typeInfo.eyeOpenImage
      ) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      const variables = {
        where: {
          id,
          group,
          lang,
          eyeCloseImage: typeInfo.eyeCloseImage,
          eyeOpenImage: typeInfo.eyeOpenImage,
        },
        data: {
          name,
          eyeBlinkCnt: parseInt(eyeBlinkCnt),
          sound,
          priority: parseInt(priority),
        },
        subDatas: [
          {
            group: typeSubtitles[0].id,
            parentId: group,
            type: type.value,
            subtitles: eyeCloseSubtitle,
            condition: "4",
            lang,
          },
          {
            group: typeSubtitles[1].id,
            parentId: group,
            type: type.value,
            subtitles: eyeOpenSubtitle,
            condition: "5",
            lang,
          },
        ],
        fileWhere: fileInfos.map((info) => {
          return {
            id: info.id,
            parentId: info.parentId,
            path: info.path,
            name: info.name,
            type: info.type,
          };
        }),
      };

      if (eyeOpenImageData) {
        variables.openImage = {
          image: eyeOpenImageData,
          size: eyeOpenImageData.size,
        };
      }

      if (eyeCloseImageData) {
        variables.closeImage = {
          image: eyeCloseImageData,
          size: eyeCloseImageData.size,
        };
      }

      exerciseType3Update({ variables });
    } else {
      const { name } = typeInfo;

      if (!id) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      if (![name, eyeCloseSubtitle, eyeOpenSubtitle].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      const variables = {
        where: {
          id,
          group,
          lang,
        },
        data: {
          name,
        },
        subDatas: [
          {
            group: typeSubtitles[0].id,
            parentId: group,
            type: type.value,
            subtitles: eyeCloseSubtitle,
            condition: "4",
            lang,
          },
          {
            group: typeSubtitles[1].id,
            parentId: group,
            type: type.value,
            subtitles: eyeOpenSubtitle,
            condition: "5",
            lang,
          },
        ],
      };

      exerciseType3Update({ variables });
    }
  };
  //#endregion

  //#region delete
  const [exerciseType3Delete, { data: type3DeleteData }] = useMutation(
    DELETE_EXERCISE_TYPE3,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type3DeleteData && type3DeleteData.deleteExerciseType3) {
      if (type3DeleteData.deleteExerciseType3.error)
        return SimpleNotification({
          type: "danger",
          message: `오류가 발생하였습니다.\r\n${type3DeleteData.deleteExerciseType3.error}`,
        });
    }
  }, [type3DeleteData]);

  const handleType3Delete = () => {
    exerciseType3Delete({ variables: { group } });
  };
  //#endregion
  //#endregion

  //#region type4

  //#region create
  const [exerciseType4Create, { data: type4CreateData }] = useMutation(
    CREATE_EXERCISE_TYPE4,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type4CreateData && type4CreateData.createExerciseType4withSubs) {
      if (!hasValue(type4CreateData.createExerciseType4withSubs.id))
        return SimpleNotification({
          type: "danger",
          message: "알 수 없는 오류가 발생하였습니다.",
        });
      typeInfo.eyeCloseImageData = undefined;
      typeInfo.eyeOpenImageData = undefined;
      typeInfo.eyeCloseImage =
        type4CreateData.createExerciseType4withSubs.eyeCloseImage;
      typeInfo.eyeOpenImage =
        type4CreateData.createExerciseType4withSubs.eyeOpenImage;
      setId(type4CreateData.createExerciseType4withSubs.id);
      setFileInfos(type4CreateData.createExerciseType4withSubs.fileInfos);
      setIsSaved(true);
      if (isKorea) setKoSaved(true);
      return SimpleNotification({
        type: "success",
        message: "성공적으로 등록하였습니다.",
      });
    }
  }, [type4CreateData]);

  const handleType4Create = () => {
    const eyeCloseSubtitle = typeSubtitles[0][lang].eyeCloseSubtitle;
    const eyeOpenSubtitle = typeSubtitles[1][lang].eyeOpenSubtitle;

    if (isKorea) {
      const {
        name,
        eyeCloseImageData,
        eyeOpenImageData,
        time,
        sound,
        priority,
      } = typeInfo;

      if (
        ![name, time, sound, priority, eyeCloseSubtitle, eyeOpenSubtitle].every(
          hasValue
        )
      ) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![time, priority].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "시간은 숫자로만 입력해주세요.",
        });
      }

      if (!numberSizeCheck(time)) {
        return SimpleNotification({
          type: "warning",
          message: "시간의 값이 너무 큽니다.",
        });
      }

      if ((!eyeCloseImageData, !eyeOpenImageData)) {
        return SimpleNotification({
          type: "warning",
          message: "이미지를 선택해주세요.",
        });
      }

      exerciseType4Create({
        variables: {
          data: {
            parentId,
            group,
            name,
            time: parseInt(time),
            lang,
            sound,
            priority: parseInt(priority),
          },
          openImage: {
            image: eyeOpenImageData,
            size: eyeOpenImageData.size,
          },
          closeImage: {
            image: eyeCloseImageData,
            size: eyeCloseImageData.size,
          },
          subDatas: [
            {
              group: typeSubtitles[0].id,
              parentId: group,
              type: type.value,
              subtitles: eyeCloseSubtitle,
              condition: "4",
              lang,
            },
            {
              group: typeSubtitles[1].id,
              parentId: group,
              type: type.value,
              subtitles: eyeOpenSubtitle,
              condition: "5",
              lang,
            },
          ],
        },
      });
      setSharedProps({ time, sound, priority });
    } else {
      const { name } = typeInfo;
      const { time, sound, priority } = sharedProps;
      if (![name, eyeCloseSubtitle, eyeOpenSubtitle].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      exerciseType4Create({
        variables: {
          data: {
            parentId,
            group,
            name,
            time: parseInt(time),
            lang,
            sound,
            priority: parseInt(priority),
          },
          subDatas: [
            {
              group: typeSubtitles[0].id,
              parentId: group,
              type: type.value,
              subtitles: eyeCloseSubtitle,
              condition: "4",
              lang,
            },
            {
              group: typeSubtitles[1].id,
              parentId: group,
              type: type.value,
              subtitles: eyeOpenSubtitle,
              condition: "5",
              lang,
            },
          ],
        },
      });
    }
  };
  //#endregion

  //#region update
  const [exerciseType4Update, { data: type4UpdateData }] = useMutation(
    UPDATE_EXERCISE_TYPE4,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type4UpdateData && type4UpdateData.updateExerciseType4withSubs) {
      if (!type4UpdateData.updateExerciseType4withSubs.id)
        return SimpleNotification({
          type: "danger",
          message: "알 수 없는 오류가 발생하였습니다.",
        });
      typeInfo.eyeCloseImageData = undefined;
      typeInfo.eyeOpenImageData = undefined;
      typeInfo.eyeCloseImage =
        type4UpdateData.updateExerciseType4withSubs.eyeCloseImage;
      typeInfo.eyeOpenImage =
        type4UpdateData.updateExerciseType4withSubs.eyeOpenImage;
      setFileInfos(type4UpdateData.updateExerciseType4withSubs.fileInfos);
      return SimpleNotification({
        type: "success",
        message: "성공적으로 수정하였습니다.",
      });
    }
  }, [type4UpdateData]);

  const handleType4Change = () => {
    const eyeCloseSubtitle = typeSubtitles[0][lang].eyeCloseSubtitle;
    const eyeOpenSubtitle = typeSubtitles[1][lang].eyeOpenSubtitle;

    if (isKorea) {
      const {
        name,
        eyeCloseImageData,
        eyeOpenImageData,
        time,
        sound,
        priority,
      } = typeInfo;

      if (
        ![name, time, sound, priority, eyeCloseSubtitle, eyeOpenSubtitle].every(
          hasValue
        )
      ) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![time, priority].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "시간은 숫자로만 입력해주세요.",
        });
      }

      if (!numberSizeCheck(time)) {
        return SimpleNotification({
          type: "warning",
          message: "시간의 값이 너무 큽니다.",
        });
      }

      if (
        !id ||
        !fileInfos ||
        !typeInfo.eyeCloseImage ||
        !typeInfo.eyeOpenImage
      ) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      const variables = {
        where: {
          id,
          group,
          lang,
          eyeCloseImage: typeInfo.eyeCloseImage,
          eyeOpenImage: typeInfo.eyeOpenImage,
        },
        data: {
          name,
          time: parseInt(time),
          sound,
          priority: parseInt(priority),
        },
        subDatas: [
          {
            group: typeSubtitles[0].id,
            parentId: group,
            type: type.value,
            subtitles: eyeCloseSubtitle,
            condition: "4",
            lang,
          },
          {
            group: typeSubtitles[1].id,
            parentId: group,
            type: type.value,
            subtitles: eyeOpenSubtitle,
            condition: "5",
            lang,
          },
        ],
        fileWhere: fileInfos.map((info) => {
          return {
            id: info.id,
            parentId: info.parentId,
            path: info.path,
            name: info.name,
            type: info.type,
          };
        }),
      };

      if (eyeOpenImageData) {
        variables.openImage = {
          image: eyeOpenImageData,
          size: eyeOpenImageData.size,
        };
      }

      if (eyeCloseImageData) {
        variables.closeImage = {
          image: eyeCloseImageData,
          size: eyeCloseImageData.size,
        };
      }

      exerciseType4Update({ variables });
    } else {
      const { name } = typeInfo;

      if (!id) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      if (![name, eyeCloseSubtitle, eyeOpenSubtitle].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      const variables = {
        where: {
          id,
          group,
          lang,
        },
        data: {
          name,
        },
        subDatas: [
          {
            group: typeSubtitles[0].id,
            parentId: group,
            type: type.value,
            subtitles: eyeCloseSubtitle,
            condition: "4",
            lang,
          },
          {
            group: typeSubtitles[1].id,
            parentId: group,
            type: type.value,
            subtitles: eyeOpenSubtitle,
            condition: "5",
            lang,
          },
        ],
      };

      exerciseType4Update({ variables });
    }
  };
  //#endregion

  //#region delete
  const [exerciseType4Delete, { data: type4DeleteData }] = useMutation(
    DELETE_EXERCISE_TYPE4,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type4DeleteData && type4DeleteData.deleteExerciseType4) {
      if (type4DeleteData.deleteExerciseType4.error)
        return SimpleNotification({
          type: "danger",
          message: `오류가 발생하였습니다.\r\n${type4DeleteData.deleteExerciseType4.error}`,
        });
    }
  }, [type4DeleteData]);

  const handleType4Delete = () => {
    exerciseType4Delete({ variables: { group } });
  };
  //#endregion
  //#endregion

  //#region type5

  //#region create
  const [exerciseType5Create, { data: type5CreateData }] = useMutation(
    CREATE_EXERCISE_TYPE5,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type5CreateData && type5CreateData.createExerciseType5withSubs) {
      if (!hasValue(type5CreateData.createExerciseType5withSubs.id))
        return SimpleNotification({
          type: "danger",
          message: "알 수 없는 오류가 발생하였습니다.",
        });
      typeInfo.imageStep1Data = undefined;
      typeInfo.imageStep2Data = undefined;
      typeInfo.imageStep3Data = undefined;
      typeInfo.imageStep4Data = undefined;
      typeInfo.imageStep5Data = undefined;
      typeInfo.imageStep1 =
        type5CreateData.createExerciseType5withSubs.imageStep1;
      typeInfo.imageStep2 =
        type5CreateData.createExerciseType5withSubs.imageStep2;
      typeInfo.imageStep3 =
        type5CreateData.createExerciseType5withSubs.imageStep3;
      typeInfo.imageStep4 =
        type5CreateData.createExerciseType5withSubs.imageStep4;
      typeInfo.imageStep5 =
        type5CreateData.createExerciseType5withSubs.imageStep5;
      setId(type5CreateData.createExerciseType5withSubs.id);
      setFileInfos(type5CreateData.createExerciseType5withSubs.fileInfos);
      setIsSaved(true);
      if (isKorea) setKoSaved(true);
      return SimpleNotification({
        type: "success",
        message: "성공적으로 등록하였습니다.",
      });
    }
  }, [type5CreateData]);

  const checkType5Subtitle = (resolve, reject, subtitle) => {
    if (!subtitle[lang] || !subtitle[lang].subtitles) {
      SimpleNotification({
        type: "warning",
        message: "모든 자막을 설정해주세요.",
      });
      return reject();
    }

    if (lang === "ko") {
      if (!hasValue(subtitle[lang].condition)) {
        SimpleNotification({
          type: "warning",
          message: "성공 조건을 설정해주세요.",
        });
        return reject();
      }

      if (subtitle[lang].condition === "3") {
        if (
          !(hasValue(subtitle[lang].startDx) && hasValue(subtitle[lang].endDx))
        ) {
          SimpleNotification({
            type: "warning",
            message: "성공 조건을 설정해주세요.",
          });
          return reject();
        }
        if (
          !(
            canParseInt(subtitle[lang].startDx) &&
            canParseInt(subtitle[lang].endDx)
          )
        ) {
          SimpleNotification({
            type: "warning",
            message: "성공 조건은 숫자로만 입력해주세요.",
          });
          return reject();
        }
        if (
          !(
            numberSizeCheck(subtitle[lang].startDx) &&
            numberSizeCheck(subtitle[lang].endDx)
          )
        ) {
          return SimpleNotification({
            type: "warning",
            message: "성공 조건의 값이 너무 큽니다.",
          });
        }
      } else {
        if (!hasValue(subtitle[lang].startDx)) {
          SimpleNotification({
            type: "warning",
            message: "성공 조건을 설정해주세요.",
          });
          return reject();
        }
        if (!canParseInt(subtitle[lang].startDx)) {
          SimpleNotification({
            type: "warning",
            message: "성공 조건은 숫자로만 입력해주세요.",
          });
          return reject();
        }
        if (!numberSizeCheck(subtitle[lang].startDx)) {
          return SimpleNotification({
            type: "warning",
            message: "성공 조건의 값이 너무 큽니다.",
          });
        }
      }
    }
    resolve();
  };

  const handleType5Create = () => {
    if (isKorea) {
      const {
        name,
        time,
        sound,
        priority,
        imageStep1Data,
        imageStep2Data,
        imageStep3Data,
        imageStep4Data,
        imageStep5Data,
      } = typeInfo;
      if (![name, time, sound, priority].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![time, priority].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "시간은 숫자로만 입력해주세요.",
        });
      }

      if (!numberSizeCheck(time)) {
        return SimpleNotification({
          type: "warning",
          message: "시간의 값이 너무 큽니다.",
        });
      }

      if (
        !imageStep1Data ||
        !imageStep2Data ||
        !imageStep3Data ||
        !imageStep4Data ||
        !imageStep5Data
      ) {
        return SimpleNotification({
          type: "warning",
          message: "이미지를 선택해주세요.",
        });
      }

      let requests = typeSubtitles.map((subtitle) => {
        return new Promise((resolve, reject) => {
          checkType5Subtitle(resolve, reject, subtitle);
        });
      });

      Promise.all(requests).then(() => {
        exerciseType5Create({
          variables: {
            data: {
              parentId,
              group,
              name,
              time: parseInt(time),
              sound,
              lang,
              priority: parseInt(priority),
            },
            imageStep1: {
              image: imageStep1Data,
              size: imageStep1Data.size,
            },
            imageStep2: {
              image: imageStep2Data,
              size: imageStep2Data.size,
            },
            imageStep3: {
              image: imageStep3Data,
              size: imageStep3Data.size,
            },
            imageStep4: {
              image: imageStep4Data,
              size: imageStep4Data.size,
            },
            imageStep5: {
              image: imageStep5Data,
              size: imageStep5Data.size,
            },
            subDatas: typeSubtitles.map((subtitle) => ({
              group: subtitle.id,
              parentId: group,
              type: type.value,
              subtitles: subtitle[lang].subtitles,
              condition: subtitle[lang].condition,
              startDx: parseInt(subtitle[lang].startDx),
              endDx: parseInt(subtitle[lang].endDx),
              lang,
            })),
          },
        });
      });
      setSharedProps({ time, sound, priority });
    } else {
      const { name } = typeInfo;
      const { time, sound, priority } = sharedProps;
      if (![name].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      let requests = typeSubtitles.map((subtitle) => {
        return new Promise((resolve, reject) => {
          checkType5Subtitle(resolve, reject, subtitle);
        });
      });

      Promise.all(requests).then(() => {
        exerciseType5Create({
          variables: {
            data: {
              parentId,
              group,
              name,
              time: parseInt(time),
              sound,
              lang,
              priority: parseInt(priority),
            },
            subDatas: typeSubtitles.map((subtitle) => ({
              group: subtitle.id,
              parentId: group,
              type: type.value,
              subtitles: subtitle[lang].subtitles,
              lang,
            })),
          },
        });
      });
    }
  };
  //#endregion

  //#region update
  const [exerciseType5Update, { data: type5UpdateData }] = useMutation(
    UPDATE_EXERCISE_TYPE5,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type5UpdateData && type5UpdateData.updateExerciseType5withSubs) {
      if (!type5UpdateData.updateExerciseType5withSubs.id)
        return SimpleNotification({
          type: "danger",
          message: "알 수 없는 오류가 발생하였습니다.",
        });
      typeInfo.imageStep1Data = undefined;
      typeInfo.imageStep2Data = undefined;
      typeInfo.imageStep3Data = undefined;
      typeInfo.imageStep4Data = undefined;
      typeInfo.imageStep5Data = undefined;
      typeInfo.imageStep1 =
        type5UpdateData.updateExerciseType5withSubs.imageStep1;
      typeInfo.imageStep2 =
        type5UpdateData.updateExerciseType5withSubs.imageStep2;
      typeInfo.imageStep3 =
        type5UpdateData.updateExerciseType5withSubs.imageStep3;
      typeInfo.imageStep4 =
        type5UpdateData.updateExerciseType5withSubs.imageStep4;
      typeInfo.imageStep5 =
        type5UpdateData.updateExerciseType5withSubs.imageStep5;
      setFileInfos(type5UpdateData.updateExerciseType5withSubs.fileInfos);
      return SimpleNotification({
        type: "success",
        message: "성공적으로 수정하였습니다.",
      });
    }
  }, [type5UpdateData]);

  const handleType5Change = () => {
    if (isKorea) {
      const {
        name,
        time,
        sound,
        priority,
        imageStep1Data,
        imageStep2Data,
        imageStep3Data,
        imageStep4Data,
        imageStep5Data,
      } = typeInfo;
      if (![name, time, sound, priority].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![time, priority].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "시간은 숫자로만 입력해주세요.",
        });
      }

      if (!numberSizeCheck(time)) {
        return SimpleNotification({
          type: "warning",
          message: "시간의 값이 너무 큽니다.",
        });
      }

      if (!id || !fileInfos) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }
      console.log(typeSubtitles);
      let requests = typeSubtitles.map((subtitle) => {
        return new Promise((resolve, reject) => {
          checkType5Subtitle(resolve, reject, subtitle);
        });
      });

      Promise.all(requests).then(() => {
        const variables = {
          where: {
            id,
            group,
            lang,
            imageStep1: typeInfo.imageStep1,
            imageStep2: typeInfo.imageStep2,
            imageStep3: typeInfo.imageStep3,
            imageStep4: typeInfo.imageStep4,
            imageStep5: typeInfo.imageStep5,
          },
          data: {
            name,
            time: parseInt(time),
            sound,
            priority: parseInt(priority),
          },
          subDatas: typeSubtitles.map((subtitle) => ({
            group: subtitle.id,
            parentId: group,
            type: type.value,
            subtitles: subtitle[lang].subtitles,
            condition: subtitle[lang].condition,
            startDx: parseInt(subtitle[lang].startDx),
            endDx: parseInt(subtitle[lang].endDx),
            lang,
          })),
          fileWhere: fileInfos.map((info) => {
            return {
              id: info.id,
              parentId: info.parentId,
              path: info.path,
              name: info.name,
              type: info.type,
            };
          }),
        };

        if (imageStep1Data) {
          variables.imageStep1 = {
            image: imageStep1Data,
            size: imageStep1Data.size,
          };
        }

        if (imageStep2Data) {
          variables.imageStep2 = {
            image: imageStep2Data,
            size: imageStep2Data.size,
          };
        }

        if (imageStep3Data) {
          variables.imageStep3 = {
            image: imageStep3Data,
            size: imageStep3Data.size,
          };
        }

        if (imageStep4Data) {
          variables.imageStep4 = {
            image: imageStep4Data,
            size: imageStep4Data.size,
          };
        }

        if (imageStep5Data) {
          variables.imageStep5 = {
            image: imageStep5Data,
            size: imageStep5Data.size,
          };
        }

        exerciseType5Update({ variables });
      });
    } else {
      const { name } = typeInfo;

      if (!id) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      if (![name].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      let requests = typeSubtitles.map((subtitle) => {
        return new Promise((resolve, reject) => {
          checkType5Subtitle(resolve, reject, subtitle);
        });
      });

      Promise.all(requests).then(() => {
        const variables = {
          where: {
            id,
            group,
            lang,
          },
          data: {
            name,
          },
          subDatas: typeSubtitles.map((subtitle) => ({
            group: subtitle.id,
            parentId: group,
            type: type.value,
            subtitles: subtitle[lang].subtitles,
            lang,
          })),
        };

        exerciseType5Update({ variables });
      });
    }
  };
  //#endregion

  //#region delete
  const [exerciseType5Delete, { data: type5DeleteData }] = useMutation(
    DELETE_EXERCISE_TYPE5,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type5DeleteData && type5DeleteData.deleteExerciseType5) {
      if (type5DeleteData.deleteExerciseType5.error)
        return SimpleNotification({
          type: "danger",
          message: `오류가 발생하였습니다.\r\n${type5DeleteData.deleteExerciseType5.error}`,
        });
    }
  }, [type5DeleteData]);

  const handleType5Delete = () => {
    exerciseType5Delete({ variables: { group } });
  };
  //#endregion

  //#endregion

  //#region type6

  //#region create
  const [exerciseType6Create, { data: type6CreateData }] = useMutation(
    CREATE_EXERCISE_TYPE6,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type6CreateData && type6CreateData.createExerciseType6withSubs) {
      if (!hasValue(type6CreateData.createExerciseType6withSubs.id))
        return SimpleNotification({
          type: "danger",
          message: "알 수 없는 오류가 발생하였습니다.",
        });
      setId(type6CreateData.createExerciseType6withSubs.id);
      setIsSaved(true);
      if (isKorea) setKoSaved(true);
      return SimpleNotification({
        type: "success",
        message: "성공적으로 등록하였습니다.",
      });
    }
  }, [type6CreateData]);

  const handleType6Create = () => {
    if (isKorea) {
      const { name, repeatCnt, sound, priority } = typeInfo;
      if (
        ![
          name,
          typeInfo.type,
          repeatCnt,
          sound,
          priority,
          typeSubtitles[0][lang].subtitles,
        ].every(hasValue)
      ) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![repeatCnt, priority].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "반복 횟수는 숫자로만 입력해주세요.",
        });
      }

      exerciseType6Create({
        variables: {
          data: {
            parentId,
            group,
            name,
            type: typeInfo.type,
            repeatCnt: parseInt(repeatCnt),
            sound,
            lang,
            priority: parseInt(priority),
          },
          subDatas: [
            {
              group: typeSubtitles[0].id,
              parentId: group,
              type: type.value,
              subtitles: typeSubtitles[0][lang].subtitles,
              lang,
            },
          ],
        },
      });
      setSharedProps({ repeatCnt, sound, priority, type: typeInfo.type });
    } else {
      const { name } = typeInfo;
      const { repeatCnt, sound, priority } = sharedProps;
      if (![name, typeSubtitles[0][lang].subtitles].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      exerciseType6Create({
        variables: {
          data: {
            parentId,
            group,
            name,
            lang,
            type: sharedProps.type,
            repeatCnt: parseInt(repeatCnt),
            sound,
            priority: parseInt(priority),
          },
          subDatas: [
            {
              group: typeSubtitles[0].id,
              parentId: group,
              type: type.value,
              subtitles: typeSubtitles[0][lang].subtitles,
              lang,
            },
          ],
        },
      });
    }
  };
  //#endregion

  //#region update
  const [exerciseType6Update, { data: type6UpdateData }] = useMutation(
    UPDATE_EXERCISE_TYPE6,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type6UpdateData && type6UpdateData.updateExerciseType6withSubs) {
      if (!type6UpdateData.updateExerciseType6withSubs.id)
        return SimpleNotification({
          type: "danger",
          message: "알 수 없는 오류가 발생하였습니다.",
        });
      return SimpleNotification({
        type: "success",
        message: "성공적으로 수정하였습니다.",
      });
    }
  }, [type6UpdateData]);

  const handleType6Change = () => {
    if (isKorea) {
      const { name, repeatCnt, sound, priority } = typeInfo;
      if (
        ![
          name,
          typeInfo.type,
          repeatCnt,
          sound,
          priority,
          typeSubtitles[0][lang].subtitles,
        ].every(hasValue)
      ) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      if (![repeatCnt, priority].every(canParseInt)) {
        return SimpleNotification({
          type: "warning",
          message: "반복 횟수는 숫자로만 입력해주세요.",
        });
      }

      if (!id) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      const variables = {
        where: {
          id,
          group,
          lang,
        },
        data: {
          name,
          type: typeInfo.type,
          repeatCnt: parseInt(repeatCnt),
          sound,
          priority: parseInt(priority),
        },
        subDatas: [
          {
            group: typeSubtitles[0].id,
            parentId: group,
            type: type.value,
            subtitles: typeSubtitles[0][lang].subtitles,
            lang,
          },
        ],
      };

      exerciseType6Update({ variables });
    } else {
      const { name } = typeInfo;

      if (!id) {
        alert("죄송합니다. 오류가 발생하였습니다.");
        goBack();
        return;
      }

      if (![name, typeSubtitles[0][lang].subtitles].every(hasValue)) {
        return SimpleNotification({
          type: "warning",
          message: "기본 정보를 모두 입력해주세요.",
        });
      }

      const variables = {
        where: {
          id,
          group,
          lang,
        },
        data: {
          name,
        },
        subDatas: [
          {
            group: typeSubtitles[0].id,
            parentId: group,
            type: type.value,
            subtitles: typeSubtitles[0][lang].subtitles,
            lang,
          },
        ],
      };

      exerciseType6Update({ variables });
    }
  };
  //#endregion

  //#region delete
  const [exerciseType6Delete, { data: type6DeleteData }] = useMutation(
    DELETE_EXERCISE_TYPE6,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (type6DeleteData && type6DeleteData.deleteExerciseType6) {
      if (type6DeleteData.deleteExerciseType6.error)
        return SimpleNotification({
          type: "danger",
          message: `오류가 발생하였습니다.\r\n${type6DeleteData.deleteExerciseType6.error}`,
        });
    }
  }, [type6DeleteData]);

  const handleType6Delete = () => {
    exerciseType6Delete({ variables: { group } });
  };
  //#endregion
  //#endregion

  const returnTypeExercise = () => {
    switch (type.value) {
      case "1":
        return (
          <Type1Layout
            {...commonProps}
            addSubtitle={addSubtitle}
            deleteSubtitle={deleteSubtitle}
          />
        );
      case "2":
        return <Type2Layout {...commonProps} />;
      case "3":
        return <Type3Layout {...commonProps} />;
      case "4":
        return <Type4Layout {...commonProps} />;
      case "5":
        return (
          <Type5Layout
            {...commonProps}
            addSubtitle={addSubtitle}
            deleteSubtitle={deleteSubtitle}
          />
        );
      case "6":
        return <Type6Layout {...commonProps} />;
      default:
        return "";
    }
  };

  const handleCreate = () => {
    switch (type.value) {
      case "1":
        return handleType1Create();
      case "2":
        return handleType2Create();
      case "3":
        return handleType3Create();
      case "4":
        return handleType4Create();
      case "5":
        return handleType5Create();
      case "6":
        return handleType6Create();
    }
  };

  const handleUpdate = () => {
    switch (type.value) {
      case "1":
        return handleType1Change();
      case "2":
        return handleType2Change();
      case "3":
        return handleType3Change();
      case "4":
        return handleType4Change();
      case "5":
        return handleType5Change();
      case "6":
        return handleType6Change();
    }
  };

  const handleDelete = () => {
    deleteTypeComponent(type.id || group);
    if (!isKorea || isSaved) {
      switch (type.value) {
        case "1":
          return handleType1Delete();
        case "2":
          return handleType2Delete();
        case "3":
          return handleType3Delete();
        case "4":
          return handleType4Delete();
        case "5":
          return handleType5Delete();
        case "6":
          return handleType6Delete();
      }
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="card-wrap">{returnTypeExercise()}</div>
            <div className="col-12 btnbar">
              <div className="row foot-edit">
                <button className="btn btn-danger" onClick={handleDelete}>
                  삭제하기
                </button>
                {!isSaved ? (
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleCreate}
                  >
                    저장하기
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleUpdate}
                  >
                    수정하기
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExerciseLayout;
