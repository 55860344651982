import React, { useState, useEffect } from 'react'
import DefaultSubtitles from './Subtitles'
import { Form } from "react-bootstrap";
import { getImagePathForObject, getNumberSelectBox } from "components/common"

const DefaultLayout = ({ colspan, isKorea, idx, lang, item}) => {
  const [name, setName] = useState(item?.name);
  const [image, setImage] = useState(item.fileInfos ? getImagePathForObject(item) : '');
  const [time, setTime] = useState(item?.time);
  const [priority, setPriority] = useState(item?.priority);

  return (
    <table className="app-table w-full">
      <tr>
          <td className="th w-1/6">유형 제목</td>
          <td colspan={colspan}>{name}</td>
      </tr>
      { 
        isKorea? (
          <>
            <tr>
              <td className="th w-1/6">이미지</td>
              <td colspan={colspan}>
                <div className="form-file">
                  <img src={image} className="profile_preview" />
                </div>
              </td>
            </tr>
            <tr>
              <td className="th w-1/6">시간</td>
              <td colspan={colspan}>{time}</td>
            </tr>
            <tr>
              <td className="th w-1/6">정렬 순서</td>
              <td colspan={colspan}>{priority}</td>
            </tr>
          </>
        ) : ''
      }
      <DefaultSubtitles isKorea={isKorea} lang={lang} colspan={colspan} item={item.exerciseSubs}/>
    </table>  
  )
}

export default DefaultLayout;