/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from "react";
import useSettings from "stores/settings";
import { useHistory, Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { FormatDate } from "components/FormatDate";
import { SimpleNotification } from "components/SimpleNotification";
import { ALL_SERVICE_CENTERS } from "graphql/gql/select";
import { LoadingSpinner } from "components/common";
import ReactTable from "react-table";
import matchSorter from "match-sorter";

const Tables = () => {
  // 로그인 체크
  const settings = useSettings();
  const history = useHistory();
  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const [result, setResult] = useState();

	const { loading, error, data } = useQuery(ALL_SERVICE_CENTERS, {
		fetchPolicy: 'network-only',
		variables: {
			where: { type: "NT", lang: "ko" },
			orderBy: { createAt: 'desc' }
		}
	});

	useEffect(() => {
		if (data?.allServiceCenters) {
			setResult(data.allServiceCenters);
		}
	}, [data]);

	if (error) {
		console.log(error);
		SimpleNotification({ type: 'danger', message: error.message || 'Error' });
	}

	if (loading || !result) return <LoadingSpinner />;

  return (
    <>
      <div>
        <div>
          <nav aria-label="breadcrumb" role="navigation">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">고객센터</li>
              <Link to="/service-center/notice-table" className="breadcrumb-item active mt-1" aria-current="page">
                공지사항 목록
              </Link>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="flex justify-between card-title-wrap">
                  <h4 className="card-title">공지사항 목록</h4>
                  <Link className="btn btn-primary" to="/service-center/notice-create">
                    등록하기
                  </Link>
                </div>
                <ReactTable
                  data={result}
                  className="card-wrap"
                  filterable
                  defaultPageSize={10}
                  columns={[
                    {
                      id: "name",
                      Header: "구분",
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: [item => item.name]
                        }),
                      filterAll: true,
                      accessor: (d) => d.commonCode?.name,
                    },
                    {
                      id: "title",
                      Header: "제목",
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: [item => item.title]
                        }),
                      filterAll: true,
                      accessor: (d) => d.title,
                    },
                    {
                      id: "createAt",
                      Header: "등록날짜",
                      accessor: "createAt",
                      filterable: false,
                      Cell: (row) =>
                        `${FormatDate(new Date(parseInt(row.value, 10)))}`,
                    },
                    {
                      id: "detail",
                      Header: "",
                      accessor: "detail",
                      filterable: false,
                      Cell: (row) => (
                        <Link className="ti-pencil-alt" to={`/service-center/notice-detail/${row.original.id}`}>
                          상세보기
                        </Link>
                      )
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
