import React from 'react';
import DefaultLayout from "./defaultType/Layout";
import RestLayout from "./RestType/Layout"
import BlinkComfortablyLayout from "./BlinkComfortablyType/Layout"
import BlinkForceLayout from "./BlinkForceType/Layout"
import FocusLayout from "./FocusType/Layout"
import EyeMuscleLayout from "./EyeMuscleType/Layout"

const ExerciseLayout = ({ type, lang, parentId, group, goBack, colspan, idx, item}) => {
    const isKorea = lang === "ko";
    const commonProps = { colspan, isKorea, idx, lang, item};

    const returnTypeExercise = () => {
        switch(type.value) {
          case "1" :
            return <DefaultLayout {...commonProps}/>
          case "2" :
            return <RestLayout {...commonProps}/>
          case "3":
            return <BlinkComfortablyLayout {...commonProps}/>
          case "4":
            return <BlinkForceLayout {...commonProps}/>
          case "5":
            return <FocusLayout {...commonProps}/>
          case "6":
            return <EyeMuscleLayout {...commonProps}/>
          default:
              return '';
        }
    }
      
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body">
                        <div className="card-wrap">
                            { returnTypeExercise() }
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    )
}

export default ExerciseLayout