import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Form, Modal } from "react-bootstrap";
import { SimpleNotification } from "components/SimpleNotification";
import { checkImage, getImagePathForObject, hasValue } from "components/common.js"
import { AllCommonCodes, AllExperts } from "graphql/query/select";
import DaumPostcode from "react-daum-postcode";
import { Tabs, Tab } from "react-bootstrap";
import { CREATE_EXPERT } from "graphql/gql/insert";
import { UPDATE_EXPERT } from "graphql/gql/update";
import Interests, { addInterestCode, deleteInterestCode } from "app/shared/Interest"  
import useSettings from "stores/settings";

const EditPage = ({ match }) => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const goBack = () => {
    history.push("/app-users/expert-table");
  };

  const { group } = match.params;
  if (!group) goBack();

  const where = {
    type: "L",
    isUse: "Y",
    lang: "ko"
  };

  const order = {
    orderByKey : "priority",
    orderByValue : "asc",
  };
  
  const commonCodeL = AllCommonCodes({ where, order }).result;
  const expertData = AllExperts({ where : { group }}).result;

  if (!expertData || !commonCodeL) return <div />
  return <div><EditForm expertData={expertData} commonCodeL={commonCodeL} group={group}/></div>;
};

const EditForm = ({ expertData, commonCodeL, group }) => {
  return (
    <>
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">사용자 관리</li>
            <Link to="/app-users/expert-table" className="breadcrumb-item active mt-1" aria-current="page">
              전문가 목록
            </Link>
            <Link to={`/app-users/expert-detail/${group}`} className="breadcrumb-item active mt-1" aria-current="page">
              전문가 정보
            </Link>
            <Link to={`/app-users/expert-edit/${group}`} className="breadcrumb-item active mt-1" aria-current="page">
              전문가 정보 수정
            </Link>
          </ol>
        </nav>
      </div>

      <Tabs id="controlled-tab-example" defaultActiveKey={commonCodeL[0].value}>
        {commonCodeL.map(code => {
          return (
            <Tab eventKey={code.value} title={code.name}>
              <Table lang={code.value} group={group} expert={expertData.filter(ex => ex.lang === code.value)[0]}/>
            </Tab>
          )
        })}
      </Tabs>
    </>
  );
};

const Table = ({lang, expert, group}) => {
  const [mdShow, setMdShow] = useState(false);
  const [id, setId] = useState(expert?.id);
  const [type, setType] = useState(expert?.type);
  const [name, setName] = useState(expert?.name);
  const [tel, setTel] = useState(expert?.tel);
  const [post, setPost] = useState(expert?.post);
  const [address, setAddress] = useState(expert?.address);
  const [detailAddress, setDetailAddress] = useState(expert?.detailAddress);
  const [gpsLatitude, setgpsLatitude] = useState(expert?.gpsLatitude);
  const [gpsLongitude, setGpsLongitude] = useState(expert?.gpsLongitude);
  const [companyName, setCompanyName] = useState(expert?.companyName);
  const [opentime, setOpentime] = useState(expert?.opentime);
  const [site, setSite] = useState(expert?.site);
  const [image, setImage] = useState(getImagePathForObject(expert));
  const [profileImage, setProfileImage] = useState();
  const [intro, setIntro] = useState(expert?.intro);
  const [fileInfos, setFileInfos] = useState(expert?.fileInfos);
  const [selectedInterestCode, setSelectedInterestCode] = useState(expert ? Array.from(expert.interests, interest => interest.value) : []);

  const where = {
    type: "S",
    isUse: "Y",
    lang: "ko"
  };

  const order = {
    orderByKey : "priority",
    orderByValue : "asc",
  };
  
  const commonCodeS = AllCommonCodes({ where, order }).result;

  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  //#region update
  const [expertUpdate, { data: updateData }] = useMutation(UPDATE_EXPERT, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (updateData && updateData.updateExpert) {
      setProfileImage(undefined);
      setFileInfos(updateData.updateExpert.fileInfos);
      SimpleNotification({
        title: "success",
        message: "성공적으로 수정하였습니다."
      });
    }
  }, [updateData])

  const handleUpdate = () => {
    if (!([type, name, tel, post, address, detailAddress, gpsLatitude, gpsLongitude, companyName, opentime, site, intro].every(hasValue))) {
      SimpleNotification({
        title: "",
        message: "기본 정보 입력해주세요.",
      });
      return;
    }

    if (!id || !fileInfos) {
      alert("죄송합니다. 오류가 발생하였습니다.");
      goBack();
      return;
    }

    const variables = {
      where: {
        id
      },
      data: {
        type: `${type.value ? type.value : type}`,
        name,
        tel,
        post: `${post}`,
        address,
        detailAddress,
        gpsLatitude,
        gpsLongitude,
        companyName,
        opentime,
        site,  
        intro,
      },
      fileWhere: fileInfos.map(info => { return { id: info.id, parentId: info.parentId, path: info.path, name: info.name, type: info.type } }),
      interests: selectedInterestCode.map(code => { return { value: code } }),
    }

    if (profileImage) {
      variables.image = {
        image: profileImage,
        size: profileImage.size,
      };
    }  

    expertUpdate({ variables });
  };
  //#endregion

  //#region create
  const [expertCreate, { data: createData }] = useMutation(CREATE_EXPERT, {
    onError(err) {
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  const handleCreate = () => {
    if (!([type, name, tel, post, address, detailAddress, gpsLatitude, gpsLongitude, companyName, opentime, site, intro].every(hasValue))) {
      SimpleNotification({
        title: "",
        message: "기본 정보를 모두 입력해주세요.",
      });
      return;
    }

    if (!profileImage) {
      SimpleNotification({
        title: "",
        message: "프로필 사진을 등록해주세요.",
      });
      return;
    }
    
    expertCreate({
      variables: {
        data: {
          group,
          type: `${type.value}`,
          name,
          tel,
          post: `${post}`,
          address,
          detailAddress,
          gpsLatitude,
          gpsLongitude,
          companyName,
          opentime,
          site,  
          intro,
          lang
        },
        interests: selectedInterestCode.map(code => { return { value: code } }),
        image : {
          image: profileImage,
          size: profileImage.size
        },
      },
    });
  };

  useEffect(() => {
    if (createData && createData.createExpert) {
      setProfileImage(undefined);
      setId(createData.createExpert.id);
      setFileInfos(createData.createExpert.fileInfos);
      SimpleNotification({
        title: "success",
        message: "성공적으로 수정하였습니다."
      });
    }
  }, [createData])
  //#endregion

  //#region 주소 값 저장
  const handlePostComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";
    let zonecode = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    zonecode = data.zonecode;

    setMdShow(false);
    setPost(zonecode);
    setAddress(fullAddress);

    // 다음 API 호출 ,
    const encodedFullAddress = encodeURIComponent(fullAddress);
    const URL = `https://dapi.kakao.com/v2/local/search/address.json?query=${encodedFullAddress}`;
    fetch(URL, {
      headers: { Authorization: "KakaoAK 27c0f7d907c519c3195db1360600eb68" },
    })
      .then((res) => {
        res.json().then((json) => {
          if (json && json.documents && json.documents.length > 0) {
            const { x, y } = json.documents[0];
            // DB에 저장 (API 코드 참조할 것)
            setGpsLongitude(x);
            setgpsLatitude(y);
          }
        });
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  };
  //#endregion

  const handleImageChange = async (e) => {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    if (!checkImage(file.name, file.size)) return;
    setProfileImage(file); //bytearray 형태로 서버에 보낼 데이터
    const reader = new FileReader();
    const url = reader.readAsDataURL(file);
    reader.onloadend = () => setImage([reader.result]); //base64 형태로 화면에 보여줄 데이터
  }

  if (!commonCodeS) return <div />

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="">
              <div className="card-title-wrap">
                <h4 className="card-title">전문가 정보 수정</h4>
              </div>
              <div className="mt-6 card-wrap">
                <table className="app-table w-full">
                  <tr>
                    <td className="th">분류</td>
                    <td colSpan="3">
                      <Select
                        isOptionSelected
                        value={commonCodeS.filter(code => code.value === type).map(code => ({ value:code.value, label:code.name }))[0]}
                        onChange={(e) => setType(e)}
                        options={commonCodeS.map((code) => ({
                          value: code.value,
                          label: code.name,
                        }))}
                      />
                    </td>
                  </tr>
                  <tr className="">
                    <td className="th w-2/12">전문가명</td>
                    <td className="w-4/12">
                      <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </td>
                    <td className="th w-2/12">연락처</td>
                    <td className="w-4/12">
                      <Form.Control type="text" value={tel} onChange={(e) => setTel(e.target.value)} />
                    </td>
                  </tr>
                  {lang === "ko" ? (
                    <>
                      <tr>
                        <td className="th">주소</td>
                        <td colSpan="3">
                          <div className="flex mb-2">
                            <Form.Control
                              className="w-2/12"
                              type="text"
                              value={post}
                              readOnly
                              placeholder="우편번호"
                            />
                            <button
                              onClick={(e) => setMdShow(true)}
                              type="button"
                              className="btn btn-outline-primary btn-sm ml-2"
                            >
                              주소찾기
                            </button>
                          </div>
                          <Form.Control
                            className="mb-2"
                            type="text"
                            readOnly
                            value={address}
                            placeholder="주소"
                          />
                          <Form.Control
                            type="text"
                            value={detailAddress}
                            placeholder="상세주소"
                            onChange={(e) => setDetailAddress(e.target.value)}
                          />

                          <Modal
                            show={mdShow}
                            onHide={() => setMdShow(false)}
                            aria-labelledby="example-modal-sizes-title-md"
                          >
                            <Modal.Header closeButton>
                              <DaumPostcode
                                className="h-full"
                                onComplete={handlePostComplete}
                              />
                            </Modal.Header>
                          </Modal>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td className="th">주소</td>
                        <td colSpan="3">
                          <div className="flex mb-2">
                            <Form.Control
                              className="w-2/12"
                              type="text"
                              value={post}
                              placeholder="우편번호"
                              onChange={(e) => setPost(e.target.value)}
                            />
                          </div>
                          <Form.Control
                            className="mb-2"
                            type="text"
                            value={address}
                            placeholder="주소"
                            onChange={(e) => setAddress(e.target.value)}
                          />
                          <Form.Control
                            type="text"
                            value={detailAddress}
                            placeholder="상세주소"
                            onChange={(e) => setDetailAddress(e.target.value)}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className="th w-2/12">위도</td>
                        <td className="w-4/12">
                          <Form.Control
                            type="text"
                            value={gpsLatitude}
                            onChange={(e) => setgpsLatitude(e.target.value)}
                          />
                        </td>
                        <td className="th w-2/12">경도</td>
                        <td className="w-4/12">
                          <Form.Control
                            type="text"
                            value={gpsLongitude}
                            onChange={(e) => setGpsLongitude(e.target.value)}
                          />
                        </td>
                      </tr>
                    </>
                  )}
                  <tr className="">
                    <td className="th w-2/12">업체명</td>
                    <td className="w-4/12">
                      <Form.Control type="text" placeholder="업체명을 입력하세요." value={companyName} onChange={(e)=> setCompanyName(e.target.value)}/>
                    </td>
                    <td className="th w-2/12">영업시간</td>
                    <td className="w-4/12">
                      <Form.Control type="text" placeholder="영업시간을 입력하세요." value={opentime} onChange={(e)=> setOpentime(e.target.value)}/>
                    </td>
                  </tr>
                  <tr>
                    <td className="th">등록 URL</td>
                    <td colSpan="3">
                      <Form.Control type="text" value={site} onChange={(e) => setSite(e.target.value)} placeholder="www.example.com" />
                    </td>
                  </tr>
                  <tr>
                    <td className="th">입력 태그</td>
                    <td colSpan="3">
                      <Interests lang="ko" selectMode={true} selectedInterestCode={selectedInterestCode} addInterestCode={addInterestCode} deleteInterestCode={deleteInterestCode} />
                    </td>
                  </tr>
                  <tr>
                    <td className="th">프로필 사진</td>
                    <td colSpan="3">
                      <div className="form-file">
                        <img src={image} className="profile_preview" />
                        <form encType="multipart/form-data" className="w-full">
                          <label
                            className=""
                            for={`img_primary_${lang}`}
                            className="mb-0"
                          >
                            파일을 선택하세요.
                            <i className="ti-upload"></i>
                          </label>
                          <input
                            id={`img_primary_${lang}`}
                            type="file"
                            accept="image/*"
                            method="POST"
                            onChange={handleImageChange}
                          />
                        </form>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="th">소개</td>
                    <td colSpan="3">
                      <textarea value={intro} onChange={e => setIntro(e.target.value)} className="expert-text" />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 btnbar">
          <div className="row foot-edit">
            <button className="btn btn-warning" onClick={goBack}>
              취소하기
            </button>
            <button type="button" className="btn btn-primary" onClick={id ? handleUpdate : handleCreate}>
              저장하기
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

EditPage.prototype = {
  id: PropTypes.string.isRequired,
};

export default EditPage;
