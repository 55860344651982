import gql from "graphql-tag";

export const LOGIN = gql`
  mutation ($email: String!, $password: String!) {
    pixelroIn(email: $email, password: $password) {
      error
      token
    }
  }
`;

// StoreGroup 정보
export const STORE_GROUP = gql`
  query ($id: String!) {
    storeGroup(where: { id: $id }) {
      id
      adminId
      members {
        id
        defaultStoreId
      }
    }
  }
`;

// Store 정보
export const STORE = gql`
  query ($id: String!) {
    store(where: { id: $id }) {
      id
      place
      name
      tel
      post
      address
      detailAddress
      intro
      createAt
      onLine
      offLine
      blog
      category
      keyword
      site
      star
      storeRegister
      businessNumber
      bankName
      bankNumber
      businessNumberImage
      bankImage
      accountHolder
      image
      storeGroup {
        id
        adminId
        members {
          id
          name
          email
          tel
          admin
          createAt
          signedAt
        }
      }
      interests {
        value
      }
    }
  }
`;

// Store 전체 정보
export const ALL_STORES = gql`
  query {
    allStores {
      id
      place
      name
      tel
      address
      createAt
      onLine
      offLine
      blog
      category
      keyword
      site
      star
      storeRegister
      businessNumber
      bankName
      bankNumber
      businessNumberImage
      bankImage
      accountHolder
      image
      withdraw
      withdrawStatus
      withdrawCreateAt
      storeGroup {
        id
        adminId
        members {
          id
          name
          email
          tel
          admin
          createAt
          signedAt
        }
      }
    }
  }
`;

//STORE 전체 정보 페이지네이션
export const ALL_STORES_TABLE = gql`
  mutation (
    $storeRegister: String
    $withdraw: Boolean
    $place: String
    $take: Int!
    $skip: Int
    $cursorId: String
    $orderByKey: String
    $orderByValue: String
  ) {
    allStoresTable(
      where: {
        storeRegister: $storeRegister
        withdraw: $withdraw
        place: $place
      }
      pagination: {
        take: $take
        skip: $skip
        cursorId: $cursorId
        orderByKey: $orderByKey
        orderByValue: $orderByValue
      }
    ) {
      id
      place
      name
      tel
      address
      createAt
      onLine
      offLine
      blog
      category
      keyword
      site
      star
      storeRegister
      businessNumber
      bankName
      bankNumber
      businessNumberImage
      bankImage
      accountHolder
      image
      withdraw
      withdrawStatus
      withdrawCreateAt
      storeGroup {
        id
        adminId
        members {
          id
          name
          email
          tel
          admin
          createAt
          signedAt
        }
      }
    }
  }
`;

// STORE COUNT
export const STORE_COUNT = gql`
  query ($withdraw: Boolean, $place: String) {
    storeCount(where: { withdraw: $withdraw, place: $place })
  }
`;

// 회원사 전체 정보
export const ALL_MEMBERS = gql`
  query {
    allMembers {
      id
      email
      name
      tel
      admin
      memberOpen
      createAt
      signedAt
      storeGroup {
        id
        adminId
        stores {
          id
          place
          name
          tel
          address
          intro
          onLine
          offLine
          createAt
          category
          keyword
          site
          blog
          star
          storeRegister
        }
      }
    }
  }
`;

// Member 정보
export const MEMBER = gql`
  query ($id: String!) {
    member(where: { id: $id }) {
      id
      email
      name
      tel
      password
      admin
      memberOpen
      defaultStoreId
      createAt
      signedAt
      storeGroup {
        id
        adminId
        stores {
          id
          place
          name
          tel
          address
          intro
          onLine
          offLine
          createAt
          category
          keyword
          site
          blog
          star
          storeRegister
        }
      }
    }
  }
`;

// 상품 전체 정보
export const ALL_PRODUCTS = gql`
  query {
    allProducts {
      id
      name
      price
      discount
      productInfo
      description
      createAt
      brand
      model
      option
      mainImage
      detailImage
      category
      categoryDetail
      keyword
      onSale
      visible
      store {
        id
        place
        name
        tel
        address
        createAt
        onLine
        offLine
        blog
        category
        keyword
        site
        star
        storeRegister
      }
      member {
        id
        email
        name
        tel
        admin
        memberOpen
        createAt
        signedAt
      }
    }
  }
`;

// 상품 정보
export const PRODUCT = gql`
  query ($id: String!) {
    product(where: { id: $id }) {
      id
      name
      price
      discount
      productInfo
      description
      createAt
      brand
      model
      option
      mainImage
      detailImage
      category
      categoryDetail
      keyword
      onSale
      store {
        id
        place
        name
        tel
        address
        createAt
        onLine
        offLine
        blog
        category
        keyword
        site
        star
        storeRegister
      }
      member {
        id
        email
        name
        tel
        memberOpen
        admin
        createAt
        signedAt
      }
    }
  }
`;

// 상품 등록 전체 정보
export const ALL_PRODUCT_REGISTERS = gql`
  query {
    allProductRegisters {
      id
      name
      price
      discount
      productInfo
      description
      createAt
      brand
      model
      option
      mainImage
      detailImage
      reply
      category
      categoryDetail
      keyword
      store {
        id
        place
        name
        tel
        address
        createAt
        onLine
        offLine
        blog
        category
        keyword
        site
        star
        storeRegister
      }
      member {
        id
        email
        name
        tel
        admin
        memberOpen
        createAt
        signedAt
      }
    }
  }
`;

// 상품 등록 정보
export const PRODUCT_REGISTER = gql`
  query ($id: String!) {
    productRegister(where: { id: $id }) {
      id
      name
      price
      discount
      productInfo
      description
      createAt
      brand
      model
      option
      mainImage
      detailImage
      reply
      category
      categoryDetail
      keyword
      store {
        id
        place
        name
        tel
        address
        createAt
        onLine
        offLine
        blog
        category
        keyword
        site
        star
        storeRegister
      }
      member {
        id
        email
        name
        tel
        admin
        memberOpen
        createAt
        signedAt
      }
    }
  }
`;

// 앱 사용자 전체 정보
export const ALL_USERS = gql`
  query (
    $where: UserWhereUniqueInput
    $pagination: PaginationInput
    $orderBy: OrderByInput
  ) {
    allUsers(where: $where, orderBy: $orderBy, pagination: $pagination) {
      id
      email
      name
      nick
      tel
      password
      sns
      snsId
      birthday
      gender
      status
      token
      gpsLatitude
      gpsLongitude
      gpsAddress
      withdraw
      interestEtc
      ip
      point
      lang
      deviceOs
      loginAt
      createAt
      updateAt
      commonCode {
        id
        type
        name
        value
      }
    }
  }
`;

// 앱 사용자 전체 정보 페이지네이션
export const ALL_USERS_TABLE = gql`
  query (
    $where: UserWhereUniqueInput
    $take: Int!
    $skip: Int
    $cursorId: String
    $orderByKey: String
    $orderByValue: String
  ) {
    allUsersTable(
      where: $where
      pagination: {
        take: $take
        skip: $skip
        cursorId: $cursorId
        orderByKey: $orderByKey
        orderByValue: $orderByValue
      }
    ) {
      id
      email
      name
      nick
      tel
      password
      sns
      snsId
      birthday
      gender
      status
      token
      gpsLatitude
      gpsLongitude
      gpsAddress
      withdraw
      interestEtc
      ip
      point
      lang
      deviceOs
      loginAt
      createAt
      updateAt
    }
  }
`;

// 앱 사용자 전체 수
export const USER_COUNT_QUERY = gql`
  query ($where: UserWhereUniqueInput) {
    userCountQuery(where: $where)
  }
`;

export const USER_COUNT_MUTATION = gql`
  mutation ($where: UserWhereUniqueInput) {
    userCountMutation(where: $where)
  }
`;

export const USER_TODAY_COUNT = gql`
  query ($where: UserWhereUniqueInput, $targetField: String!) {
    userTodayCount(where: $where, targetField: $targetField)
  }
`;

export const USER_DELETE_COUNT = gql`
  query ($where: UserDeleteWhereUniqueInput) {
    userDeleteCount(where: $where)
  }
`;

// 앱 사용자 정보
export const USER = gql`
  query ($id: String!) {
    user(where: { id: $id }) {
      id
      email
      name
      nick
      tel
      password
      sns
      snsId
      birthday
      gender
      status
      token
      gpsLatitude
      gpsLongitude
      gpsAddress
      withdraw
      interestEtc
      ip
      point
      lang
      deviceOs
      loginAt
      createAt
      updateAt
      interests {
        value
      }
    }
  }
`;

export const USER_EYE_CHECK_RESULTS = gql`
  query ($id: String!) {
    userEyeCheckResults(where: { id: $id }) {
      id
      eyeCheckLights {
        halo
        glare
        priority
        createAt
      }
      eyeCheckSights {
        rightSight
        leftSight
        priority
        createAt
      }
      eyeCheckMaculars {
        rightMacularSize
        leftMacularSize
        rightMacularLocation
        leftMacularLocation
        priority
        createAt
      }
      eyeCheckPresbyopias {
        distanceAvg
        priority
        createAt
      }
    }
  }
`;

export const USER_EXERCISE_RESULTS = gql`
  query ($id: String!) {
    userEyeCheckResults(where: { id: $id }) {
      id
      exerciseResults {
        id
        time
        createAt
        exercise {
          id
          name
          commonCode {
            id
            name
          }
        }
      }
    }
  }
`;

// 예약 관리 전체 정보
export const ALL_RESERVATIONS = gql`
  query {
    allReservations {
      id
      inStoreId
      outStoreId
      status
      pay {
        id
        orderList
        process1
        process2
        process3
        process4
        process5
        processMemo
        status
        user {
          id
          name
          email
          tel
          point
        }
        name
        phone
        email
        receiveType
        receiverName
        receiverPhone1
        receiverPhone2
        receiverPost
        receiverAddr1
        receiverAddr2
        city
        state
        country
        memo
        paymentType
        productTotal
        feeTotal
        payTotal
        currency
        iniTid
        iniAuthDate
        iniAuthNo
        iniCardName
        iniHppNum
        invoiceNumber
        invoiceCompany
        invoicePhone
        createAt
        cancelCnt
        cancelPriceTotal
        createAt
        point
        pixelroCouponId
        pixelroCouponDiscount
        storeCoupon
      }
      createAt
      reservationAt
      visitAt
      storeMemo
      cancelMemo
      inStoreCheck
      inStoreMemo
      user {
        id
        name
      }
    }
  }
`;

// 예약 관리 정보
export const RESERVATION = gql`
  query ($id: String!) {
    reservation(where: { id: $id }) {
      id
      inStoreId
      outStoreId
      status
      pay {
        id
        orderList
        process1
        process2
        process3
        process4
        process5
        processMemo
        status
        user {
          id
          name
          email
          tel
          point
        }
        name
        phone
        email
        receiveType
        receiverName
        receiverPhone1
        receiverPhone2
        receiverPost
        receiverAddr1
        receiverAddr2
        city
        state
        country
        memo
        paymentType
        productTotal
        feeTotal
        payTotal
        currency
        iniTid
        iniAuthDate
        iniAuthNo
        iniCardName
        iniHppNum
        invoiceNumber
        invoiceCompany
        invoicePhone
        createAt
        cancelCnt
        cancelPriceTotal
        createAt
        point
        pixelroCouponId
        pixelroCouponDiscount
        storeCoupon
      }
      createAt
      reservationAt
      visitAt
      storeMemo
      cancelMemo
      inStoreCheck
      inStoreMemo
      user {
        id
        name
      }
    }
  }
`;
export const ORDER = gql`
  query ($id: String!) {
    order(where: { id: $id }) {
      id
      createAt
      user {
        id
        email
        name
        tel
        post
        address
        detailAddress
        createAt
        point
      }
      product {
        id
        name
        price
        discount
        productInfo
        description
        createAt
        brand
        model
        option
        mainImage
        detailImage
        category
        keyword
        store {
          id
          name
          tel
        }
      }
      payId
      productOption
      productCount
      productPrice
      productAddPrice
      productTotal
      currency
      reviewStar
      reviewImage
      reviewVideo
      reviewText
      reviewCreateAt
      returnType
      returnText
      returnAt
      returnOkAt
      reply
      replyCreateAt
      receiveType
      storeId
      delivery
      trackingNumber
      status
      process1
      process2
      process3
      process4
      process5
      cancelPrice
      cancel
      coupon
      couponDiscount
      point
      pixelroCouponDiscount
      changeDelivery
      changeTrackingNumber
      changeAt
      changeType
      changeText
      changeOkAt
      changeCheckAt
    }
  }
`;

// 사용자 렌즈 예약 정보 가져오기
export const ALL_LENS_RESERVATIONS = gql`
  query {
    allLensReservations {
      id
      createAt
      productOption
      productTotal
      status
      reservationAt
      visitAt
      cancelAt
      storeId
      product {
        id
        name
        mainImage
        option
      }
      user {
        id
        name
        tel
        email
      }
    }
  }
`;

// 사용자 렌즈 예약 정보 가져오기 - 하나
export const LENS_RESERVATION = gql`
  query ($id: String!) {
    lensReservation(where: { id: $id }) {
      id
      createAt
      productOption
      productTotal
      status
      reservationAt
      visitAt
      cancelAt
      storeId
      product {
        id
        name
        mainImage
        option
      }
      user {
        id
        name
        tel
        email
      }
    }
  }
`;

// 고객센터 - 공지사항 & FAQ 전체 정보
export const ALL_SERVICE_CENTERS = gql`
  query ($where: ServiceCenterWhereUniqueInput, $orderBy: OrderByInput) {
    allServiceCenters(where: $where, orderBy: $orderBy) {
      id
      group
      type
      category
      title
      content
      topic
      createAt
      lang
      commonCode {
        id
        type
        name
        value
        category
      }
    }
  }
`;

// 고객센터 - 공지사항 & FAQ 정보
export const SERVICE_CENTER = gql`
  query ($id: String!) {
    serviceCenter(where: { id: $id }) {
      id
      type
      category
      title
      content
      topic
      createAt
      fileInfos {
        id
        parentId
        type
        path
        name
      }
      commonCode {
        id
        type
        name
        value
        category
      }
    }
  }
`;

// QNA 전체 정보
export const ALL_QNAS = gql`
  query {
    allQnas {
      id
      category
      title
      content
      comment
      reply
      createAt
      member {
        id
        email
        name
        tel
        admin
      }
      store {
        id
        place
        name
        tel
        address
      }
    }
  }
`;

// QNA 정보
export const QNA = gql`
  query ($id: String!) {
    qna(where: { id: $id }) {
      id
      category
      title
      content
      comment
      reply
      createAt
      member {
        id
        email
        name
        tel
        admin
      }
      store {
        id
        place
        name
        tel
        address
      }
    }
  }
`;

// 게시판 전체 정보
export const ALL_NOTICE_BOARDS = gql`
  query ($visible: Boolean, $category: String, $orderBy: OrderByInput) {
    allNoticeBoards(
      where: { visible: $visible, category: $category }
      orderBy: $orderBy
    ) {
      id
      category
      title
      content
      image
      link1
      linkImage1
      link2
      linkImage2
      link3
      linkImage3
      no
      visible
      startAt
      endAt
      createAt
      interests {
        value
      }
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

export const ALL_NOTICE_BOARDS_TABLE = gql`
  mutation ($where: NoticeBoardWhereUniqueInput, $pagination: PaginationInput) {
    allNoticeBoardsTable(where: $where, pagination: $pagination) {
      id
      category
      title
      content
      image
      link1
      linkImage1
      link2
      linkImage2
      link3
      linkImage3
      no
      visible
      startAt
      endAt
      createAt
      interests {
        value
      }
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

export const NOTICE_BOARD_COUNT = gql`
  mutation ($where: NoticeBoardWhereUniqueInput) {
    noticeBoardCount(where: $where)
  }
`;

// 게시판 정보
export const NOTICE_BOARD = gql`
  query ($id: String!) {
    noticeBoard(where: { id: $id }) {
      id
      category
      title
      content
      image
      link1
      linkImage1
      link2
      linkImage2
      link3
      linkImage3
      no
      visible
      startAt
      endAt
      createAt
      interests {
        value
      }
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

// 배너 전체 정보
export const ALL_BANNERS = gql`
  query {
    allBanners {
      id
      image
      category
      title
      content
      targetId
      targetUrl
      no
      visible
      createAt
    }
  }
`;

// 배너 정보
export const BANNER = gql`
  query ($id: String!) {
    banner(where: { id: $id }) {
      id
      image
      category
      title
      content
      targetId
      targetUrl
      no
      visible
      createAt
    }
  }
`;

// 배너 정보
export const FILTER_BANNER = gql`
  query ($value: String!) {
    filterBanner(searchString: $value) {
      id
      image
      category
      title
      content
      targetId
      targetUrl
      no
      visible
      createAt
    }
  }
`;

// 공지사항 & FAQ 정보
export const FILTER_SERVICE_CENTER = gql`
  query ($value: String!) {
    filterServiceCenter(searchString: $value) {
      id
      type
      category
      title
      content
      topic
      createAt
      commonCode {
        id
        type
        name
        value
        category
      }
    }
  }
`;

// 앱 사용자 문의
export const ALL_APP_QNAS = gql`
  query ($orderBy: OrderByInput) {
    allAppQnas(orderBy: $orderBy) {
      id
      category
      title
      content
      comment
      reply
      createAt
      user {
        id
        email
        name
        tel
      }
      commonCode {
        id
        type
        name
        value
      }
    }
  }
`;

// 앱 사용자 문의
export const APP_QNA = gql`
  query ($id: String!) {
    appQna(where: { id: $id }) {
      id
      category
      title
      content
      comment
      reply
      createAt
      user {
        id
        email
        name
        tel
      }
      commonCode {
        id
        type
        name
        value
      }
      fileInfos {
        id
        type
        path
        name
      }
    }
  }
`;

// 전체 쿠폰 목록
export const ALL_COUPONS = gql`
  query {
    allCoupons {
      id
      type
      storeId
      createAt
      openAt
      startAt
      endAt
      downloadCount
      downloadLimit
      adminActive
      storeActive
      min
      feeFree
      cashDiscount
      percentDiscount
      visit
      visitName
      couponName
      couponRecodes {
        id
        payId
      }
    }
  }
`;

// 쿠폰 정보
export const COUPON = gql`
  query ($id: String!) {
    coupon(where: { id: $id }) {
      id
      type
      storeId
      createAt
      openAt
      startAt
      endAt
      downloadCount
      downloadLimit
      adminActive
      storeActive
      min
      feeFree
      cashDiscount
      percentDiscount
      visit
      visitName
      couponName
      couponRecodes {
        id
        payId
      }
    }
  }
`;

// Store 전체 정보
export const ALL_ORDERS = gql`
  query {
    allOrders {
      id
      createAt
      user {
        id
        email
        name
        tel
        post
        address
        detailAddress
        createAt
      }
      product {
        id
        name
        price
        discount
        productInfo
        description
        createAt
        brand
        model
        option
        mainImage
        detailImage
        category
        keyword
        store {
          id
          name
        }
      }
      payId
      productOption
      productCount
      productPrice
      productAddPrice
      productTotal
      currency
      reviewStar
      reviewImage
      reviewVideo
      reviewText
      reviewCreateAt
      returnType
      returnText
      returnAt
      reply
      replyCreateAt
      receiveType
      storeId
      delivery
      trackingNumber
      status
      process1
      process2
      process3
      process4
      process5
      cancelPrice
      cancel
      coupon
      couponDiscount
      point
      pixelroCouponDiscount
      changeDelivery
      changeTrackingNumber
      changeAt
      changeType
      changeText
      changeOkAt
      changeCheckAt
    }
  }
`;

// 정산 내역 가져오기
export const ALL_SETTLE_REPORTS = gql`
  query {
    allSettleReports {
      id
      startAt
      endAt
      cuid1
      cuid2
      createAt
      recodeCount
      productTotal
      pixelroTotal
      storeTotal
      pointTotal
      userTotal
      salesCommission
      settlementPrice
      tax
      supplyPrice
      year
      month
      status
      settlementRequestAt
      settlementAt
      taxBillAt
      store {
        id
        name
      }
    }
  }
`;

// 정산 내역 가져오기
export const SETTLE_REPORT = gql`
  query ($id: String!) {
    settleReport(where: { id: $id }) {
      id
      startAt
      endAt
      cuid1
      cuid2
      createAt
      recodeCount
      productTotal
      pixelroTotal
      storeTotal
      pointTotal
      userTotal
      salesCommission
      settlementPrice
      tax
      supplyPrice
      year
      month
      status
      settlementRequestAt
      settlementAt
      taxBillAt
      store {
        id
        name
        tel
        accountHolder
        address
        bankName
        bankNumber
        businessNumber
      }
    }
  }
`;

// 정산 내역 가져오기
export const FILTER_SETTLE_REPORT = gql`
  query ($value: String) {
    filterSettleReport(searchString: $value) {
      id
      startAt
      endAt
      cuid1
      cuid2
      createAt
      recodeCount
      productTotal
      pixelroTotal
      storeTotal
      pointTotal
      userTotal
      salesCommission
      settlementPrice
      tax
      supplyPrice
      year
      month
      status
      settlementRequestAt
      settlementAt
      taxBillAt
      store {
        id
        name
        tel
        accountHolder
        address
        bankName
        bankNumber
        businessNumber
      }
    }
  }
`;

// 정산 내역 가져오기
export const SETTLEMENT_ORDER_DATA = gql`
  query ($id: String) {
    settlementOrderData(id: $id) {
      result
    }
  }
`;

// 관리자 전체 목록
export const ALL_PIXELROS = gql`
  query {
    allPixelros {
      id
      createAt
      email
      password
      name
      tel
    }
  }
`;

// 관리자 전체 목록 페이지네이션
export const ALL_PIXELROS_TABLE = gql`
  mutation (
    $take: Int!
    $skip: Int
    $cursorId: String
    $orderByKey: String
    $orderByValue: String
  ) {
    allPixelrosTable(
      pagination: {
        take: $take
        skip: $skip
        cursorId: $cursorId
        orderByKey: $orderByKey
        orderByValue: $orderByValue
      }
    ) {
      id
      createAt
      email
      password
      name
      tel
    }
  }
`;

// 관리자 COUNT
export const PIXELRO_COUNT = gql`
  query {
    pixelroCount
  }
`;

// 관리자 정보 필터
export const FILTER_PIXELRO = gql`
  query ($value: String!) {
    filterPixelro(searchString: $value) {
      id
      createAt
      email
      password
      name
      tel
    }
  }
`;

// 관리자 정보
export const PIXELRO = gql`
  query ($id: String!) {
    pixelro(where: { id: $id }) {
      id
      createAt
      email
      password
      name
      tel
    }
  }
`;

// 회사 정보
export const ALL_COMPANY_INFOS = gql`
  query ($where: CompanyInfoWhereUniqueInput, $order: OrderInput) {
    allCompanyInfos(where: $where, order: $order) {
      id
      group
      type
      name
      number
      priority
      representative
      tel
      person
      address
      email
      homepage
      lang
      regId
      udtId
      createAt
      updateAt
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

// 회사 정보
export const COMPANY_INFO = gql`
  query ($where: CompanyInfoWhereUniqueInput) {
    companyInfo(where: $where) {
      id
      group
      type
      name
      number
      priority
      representative
      tel
      person
      address
      email
      homepage
      regId
      udtId
      createAt
      updateAt
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

// 메세지 충전 신청 전체 정보
export const ALL_MESSAGES = gql`
  query {
    allMessages {
      id
      createAt
      chargePrice
      tax
      sendPrice
      depositor
      status
      checkAt
      store {
        id
        name
        messageCount
      }
    }
  }
`;

// 셋팅 값 가져오기
export const FILTER_SETTING_KEY_VALUE = gql`
  query ($value: String!) {
    filterSettingKeyValue(searchString: $value) {
      id
      key
      value
    }
  }
`;

// 공통코드 가져오기
export const ALL_COMMON_CODES = gql`
  query ($where: CommonCodeWhereUniqueInput, $order: OrderInput) {
    allCommonCodes(where: $where, order: $order) {
      id
      type
      name
      value
      priority
      isUse
      lang
      regId
      udtId
      createAt
      updateAt
    }
  }
`;

//전문가 카운트 가져오기
export const EXPERT_COUNT = gql`
  mutation ($where: ExpertWhereUniqueInput) {
    expertCount(where: $where)
  }
`;

export const EXPERT_POPULAR = gql`
  query {
    expertPopular {
      expertName
      companyName
      imagePath
      imageName
      count
    }
  }
`;

export const ALL_EXPERTS = gql`
  query ($where: ExpertWhereUniqueInput, $orderBy: OrderByInput) {
    allExperts(where: $where, orderBy: $orderBy) {
      id
      group
      type
      name
      tel
      post
      address
      detailAddress
      gpsLatitude
      gpsLongitude
      companyName
      opentime
      site
      profileImage
      intro
      lang
      isDelete
      createAt
      updateAt
      commonCode {
        name
      }
      interests {
        value
      }
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

export const EXPERT = gql`
  query ($where: ExpertWhereUniqueInput) {
    expert(where: $where) {
      id
      group
      type
      name
      tel
      post
      address
      detailAddress
      gpsLatitude
      gpsLongitude
      companyName
      opentime
      site
      profileImage
      intro
      lang
      isDelete
      createAt
      updateAt
      commonCode {
        name
      }
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

//전문가 정보 페이지네이션
export const ALL_EXPERTS_TABLE = gql`
  mutation (
    $where: ExpertWhereUniqueInput
    $take: Int!
    $skip: Int
    $cursorId: String
    $orderByKey: String
    $orderByValue: String
  ) {
    allExpertsTable(
      where: $where
      pagination: {
        take: $take
        skip: $skip
        cursorId: $cursorId
        orderByKey: $orderByKey
        orderByValue: $orderByValue
      }
    ) {
      id
      group
      type
      name
      tel
      post
      address
      detailAddress
      gpsLatitude
      gpsLongitude
      companyName
      opentime
      site
      profileImage
      intro
      lang
      isDelete
      createAt
      updateAt
      commonCode {
        name
      }
    }
  }
`;

export const ALL_EXERCISES = gql`
  query ($where: ExerciseWhereUniqueInput, $order: OrderInput) {
    allExercises(where: $where, order: $order) {
      id
      group
      type
      name
      image
      keyword
      expertId
      time
      purpose
      method
      priority
      lang
      regId
      udtId
      createAt
      updateAt
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

export const EXERCISE = gql`
  query ($where: ExerciseWhereUniqueInput) {
    exercise(where: $where) {
      id
      group
      type
      name
      image
      keyword
      expertId
      time
      purpose
      method
      priority
      lang
      regId
      udtId
      createAt
      updateAt
      fileInfos {
        id
        parentId
        type
        path
        name
      }
    }
  }
`;

export const EXERCISE_RESULT_TODAY_POPULAR = gql`
  query {
    exerciseResultTodayPopular
  }
`;

export const EYE_CHECK_TODAY_POPULAR = gql`
  query {
    eyeCheckTodayPopular
  }
`;

export const ALL_EXPERTS_FOR_EXERCISE = gql`
  query ($lang: String, $isDelete: String) {
    allExperts(where: { lang: $lang, isDelete: $isDelete }) {
      id
      name
      tel
      address
      detailAddress
      fileInfos {
        id
        type
        path
        name
      }
    }
  }
`;

export const ALL_EXERCISES_DETAIL = gql`
  query ($group: String!) {
    allExercises(where: { group: $group }) {
      id
      group
      type
      name
      image
      keyword
      expertId
      time
      purpose
      method
      priority
      lang
      fileInfos {
        id
        parentId
        type
        path
        name
      }
      exerciseType1s {
        id
        parentId
        group
        name
        image
        time
        lang
        priority
        exerciseSubs {
          id
          parentId
          group
          type
          subtitles
          time
          condition
          lang
          priority
        }
        fileInfos {
          id
          parentId
          type
          path
          name
        }
      }
      exerciseType2s {
        id
        parentId
        group
        name
        image
        time
        lang
        priority
        exerciseSubs {
          id
          parentId
          group
          type
          subtitles
          time
          condition
          lang
          priority
        }
        fileInfos {
          id
          parentId
          type
          path
          name
        }
      }
      exerciseType3s {
        id
        group
        parentId
        name
        eyeCloseImage
        eyeOpenImage
        eyeBlinkCnt
        sound
        lang
        priority
        exerciseSubs {
          id
          parentId
          group
          type
          subtitles
          time
          condition
          lang
          priority
        }
        fileInfos {
          id
          parentId
          type
          path
          name
        }
        commonCodeSound {
          name
          value
        }
      }
      exerciseType4s {
        id
        group
        parentId
        name
        eyeCloseImage
        eyeOpenImage
        time
        sound
        lang
        priority
        exerciseSubs {
          id
          parentId
          group
          type
          subtitles
          time
          condition
          lang
          priority
        }
        fileInfos {
          id
          parentId
          type
          path
          name
        }
        commonCodeSound {
          name
          value
        }
      }
      exerciseType5s {
        id
        group
        parentId
        name
        imageStep1
        imageStep2
        imageStep3
        imageStep4
        imageStep5
        time
        sound
        lang
        priority
        exerciseSubs {
          id
          parentId
          group
          type
          subtitles
          time
          condition
          startDx
          endDx
          lang
          priority
        }
        fileInfos {
          id
          parentId
          type
          path
          name
        }
        commonCodeSound {
          name
          value
        }
      }
      exerciseType6s {
        id
        group
        parentId
        name
        type
        repeatCnt
        sound
        lang
        priority
        exerciseSubs {
          id
          parentId
          group
          type
          subtitles
          time
          condition
          lang
          priority
        }
        commonCodeSound {
          name
          value
        }
      }
      expert {
        id
        name
        tel
        address
        detailAddress
        fileInfos {
          id
          type
          path
          name
        }
      }
      commonCode {
        id
        type
        name
        value
      }
    }
  }
`;

export const ALL_SEARCHS = gql`
  query ($orderBy: OrderByInput) {
    allSearchs(orderBy: $orderBy) {
      id
      keyword
      condition
      tag
      uid
      createAt
    }
  }
`;

// 앱 사용자 전체 수
export const SEARCH_COUNT = gql`
  mutation ($where: SearchWhereUniqueInput) {
    searchCount(where: $where)
  }
`;

export const ALL_SEARCHS_TABLE = gql`
  mutation ($where: SearchWhereUniqueInput, $pagination: PaginationInput) {
    allSearchsTable(where: $where, pagination: $pagination) {
      id
      keyword
      condition
      tag
      uid
      createAt
    }
  }
`;

export const APP_QNA_COUNT = gql`
  query ($where: AppQnaWhereUniqueInput) {
    appQnaCount(where: $where)
  }
`;

export const APP_QNA_COUNT_MUTATION = gql`
  mutation ($where: AppQnaWhereUniqueInput) {
    appQnaCountMutation(where: $where)
  }
`;

export const APP_QNA_TODAY_COUNT = gql`
  query ($where: AppQnaWhereUniqueInput) {
    appQnaTodayCount(where: $where)
  }
`;

export const ALL_APP_QNAS_TABLE = gql`
  mutation ($where: AppQnaWhereUniqueInput, $pagination: PaginationInput) {
    allAppQnasTable(where: $where, pagination: $pagination) {
      id
      category
      title
      content
      comment
      reply
      createAt
      user {
        id
        email
        name
        tel
      }
      commonCode {
        id
        type
        name
        value
      }
    }
  }
`;

export const SERVICE_CENTER_COUNT = gql`
  query ($where: ServiceCenterWhereUniqueInput) {
    serviceCenterCount(where: $where)
  }
`;

export const ALL_SERVICE_CENTERS_TABLE = gql`
  mutation (
    $where: ServiceCenterWhereUniqueInput
    $pagination: PaginationInput
  ) {
    allServiceCentersTable(where: $where, pagination: $pagination) {
      id
      type
      category
      title
      content
      topic
      createAt
      commonCode {
        id
        type
        name
        value
        category
      }
    }
  }
`;

// 관리자 정보
export const PIXELRO_EMAIL_CHECK = gql`
  mutation ($email: String!) {
    pixelroEmailCheck(where: { email: $email }) {
      id
      createAt
      email
      password
      name
      tel
    }
  }
`;
