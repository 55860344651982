import React, {useState, useEffect} from 'react'
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_EXERCISE_SUB } from "graphql/gql/delete";
import { SimpleNotification } from "components/SimpleNotification";
import { hasValue } from "components/common";

const FocusSubtitles = ({ isKorea, lang, colspan, isSaved, subtitles, addSubtitle, deleteSubtitle }) => {
    return (
        <>
            <h4 className="ml-2 mt-6"> 사이클 설정 </h4>
            {
                subtitles.map((subtitle, idx) => <Subtitle key={subtitle.id.toString()} id={subtitle.id} item={subtitle[lang]} isSaved={isSaved} colspan={colspan} isKorea={isKorea} canDelete={idx!==0} deleteSubtitle={deleteSubtitle}/>)
            }
            {
                isKorea ? (
                <div className="flex-center mt-4 px-2">
                    <button type="button" className="btn btn-light w-full" onClick={() => addSubtitle()}> + </button>
                </div>
                ) : ''
            }
        </>
    )
}

const Subtitle = ({ id, isKorea, item, isSaved, colspan, canDelete, deleteSubtitle }) => {
    const [condition, setCondition] = useState(item.condition);
    const [startDx, setStartDx] = useState(item.startDx);
    const [endDx, setEndDx] = useState(item.endDx);
    const [subtitles, setSubtitles] = useState(item.subtitles);
    const options = [
        { value: "1", label: "~ 이상" },
        { value: "2", label: "~ 이하" },
        { value: "3", label: "값 사이" }
    ];
    const style = { display: "inline", width: "auto"};

    useEffect(() => {
        if (condition)
            item.condition = condition;
    }, [condition])

    useEffect(() => {
        if (startDx)
            item.startDx = startDx;
    }, [startDx])

    useEffect(() => {
        if (endDx)
            item.endDx = endDx;
    }, [endDx])

    useEffect(() => {
        if (subtitles)
            item.subtitles = subtitles;
    }, [subtitles])

    //#region Delete
    const [exerciseSubDelete, { data: deleteData }] = useMutation(DELETE_EXERCISE_SUB, {
        onError(err) { SimpleNotification({type : "danger", message: err.message || "Error"}); },
    });

    useEffect(() => {
        if (deleteData && deleteData.deleteExerciseSub) {
            if (!hasValue(deleteData.deleteExerciseSub.result))
                return SimpleNotification({type : "danger", message: "알 수 없는 오류가 발생하였습니다."});
        }
    }, [deleteData]);

    const handleDelete = () => {
        deleteSubtitle(id)
        if (isSaved) exerciseSubDelete({ variables: { group: id }});
    }
    //#endregion

    return ( 
        <>
            { isKorea && canDelete ? <button type="button" className="btn btn-danger mt-3" style={{ lineHeight: '1.0' }} onClick={handleDelete}>X</button> : '' }
            <table className="app-table w-full">
                { isKorea ? (
                    <tr>
                        <td className="th w-1/6">성공 조건 설정</td>
                        <td className="w-1/6 border-right-0">
                            <Select
                                placeholder="성공 조건 설정"
                                value={options.filter(op => op.value.toString() === condition?.toString())}
                                options={options}
                                onChange={(e) => setCondition(e.value)}
                            />
                        </td>
                        {
                            condition !== "3" ?
                            (<td className="w-2/6 border-right-0">
                                <Form.Control type="number" placeholder="0" value={startDx} onChange={e => setStartDx(e.target.value)} style={style}/> 
                                <div style={style}>CM</div>
                            </td>)
                            :
                            (<>
                                <td className="w-1/6 border-right-0">
                                    <Form.Control type="number" placeholder="0" value={startDx} onChange={e => setStartDx(e.target.value)} style={style}/> 
                                    <div style={style}>CM</div>
                                </td>
                                <td className="w-1/6 border-right-0">
                                    <Form.Control type="number" placeholder="0" value={endDx} onChange={e => setEndDx(e.target.value)} style={style}/> 
                                    <div style={style}>CM</div>
                                </td>
                            </>)
                        }
                    </tr>
                ) : ''}
                <tr>
                    <td className="th w-1/6">표시 자막 설정</td>
                    <td colspan={colspan}>
                        <Form.Control type="text" placeholder="자막을 입력하세요." value={subtitles} onChange={e => setSubtitles(e.target.value)}/>
                    </td>
                </tr>
            </table>
        </>
    )
}

export default FocusSubtitles;