import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { AllCommonCodes, User } from "graphql/query/select";
import { useHistory, Link } from "react-router-dom";
import { FormatDate, FormatDateShort } from "components/FormatDate";
import { PhoneFormatter } from "components/PhoneFormatter";
import { observer } from "mobx-react-lite";
import useSettings from "stores/settings";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CREATE_LOG } from "graphql/gql/insert";
import { UPDATE_USER } from "graphql/gql/update";
import { Modal, Button } from "react-bootstrap";
import { SimpleNotification } from "components/SimpleNotification";
import publicIp from "public-ip";
import Interests from "app/shared/Interest";
import { LoadingSpinner } from "components/common";
import { Tabs, Tab } from "react-bootstrap";
import {
  USER_EYE_CHECK_RESULTS,
  USER_EXERCISE_RESULTS,
} from "graphql/gql/select";

const DetailPage = observer(({ match }) => {
  // 로그인 체크
  const settings = useSettings();
  const history = useHistory();
  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const { id } = match.params;
  const [mdShow, setMdShow] = useState(false);

  const types = AllCommonCodes({
    where: { type: "SL", isUse: "Y", lang: "ko" },
    order: { orderByKey: "priority", orderByValue: "asc" },
  }).result;
  const [logCreate, { data: createLogData }] = useMutation(CREATE_LOG, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    async function logData() {
      const adminEmail = settings.email;
      const ip = await publicIp.v4();
      const url = `/app-users/user-detail/${id}`;
      const target = "user";
      const detail = "열람";

      logCreate({ variables: { adminEmail, ip, url, target, detail } });
    }

    logData();
  }, []);

  useEffect(() => {
    if (createLogData !== undefined) {
      console.log("ok");
    }
  }, [createLogData]);

  const [withdrawUser, { data: updateData }] = useMutation(UPDATE_USER, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });
  useEffect(() => {
    if (updateData !== undefined) {
      history.goBack();
    }
  }, [updateData]);

  const handleWithdrawModal = () => {
    setMdShow(false);
    withdrawUser({
      variables: { id, withdraw: true },
    });
  };

  const items = User(id).result;

  if (!items || !types) return <LoadingSpinner />;
  return (
    <>
      <div>
        {/* breadcrumb */}
        <div>
          <nav aria-label="breadcrumb" role="navigation">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">사용자 관리</li>
              <Link
                to="/app-users/user-table"
                className="breadcrumb-item active mt-1"
                aria-current="page"
              >
                회원 목록
              </Link>
              <Link
                to={`/app-users/user-detail/${id}`}
                className="breadcrumb-item active mt-1"
                aria-current="page"
              >
                회원 정보
              </Link>
            </ol>
          </nav>
        </div>
        <Tabs id="controlled-tab-example">
          <Tab eventKey={"기본정보"} title={"기본정보"}>
            <InfoForm items={items} types={types} />
          </Tab>
          <Tab eventKey={"검사 이력"} title={"검사 이력"}>
            <EyeCheckHistory id={id} />
          </Tab>
          <Tab eventKey={"눈운동 이력"} title={"눈운동 이력"}>
            <ExerciseHistory id={id} />
          </Tab>
        </Tabs>
        {/* <div className="col-12 btnbar">
            <div className="row justify-between">
              <button className="btn btn-danger" onClick={() => setMdShow(true)}>
                삭제하기
              </button>
              <Link className="btn btn-primary" to={`/app-users/user-edit/${items.id}`}>
                수정하기
              </Link>
            </div>
          </div> */}
        {/* Modal Starts  */}
        {/* <Modal show={mdShow} onHide={() => setMdShow(false)} aria-labelledby="example-modal-sizes-title-md">
            <Modal.Header closeButton>
              <Modal.Title>사용자 삭제</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>정말 삭제하시겠습니까?</p>
            </Modal.Body>

            <Modal.Footer className="flex-wrap">
              <Button variant="btn btn-primary m-2" onClick={handleWithdrawModal}>
                확인
              </Button>
              <Button variant="btn btn-light m-2" onClick={() => setMdShow(false)}>
                취소
              </Button>
            </Modal.Footer>
          </Modal> */}
        {/* Modal Ends */}
      </div>
    </>
  );
});

const InfoForm = ({ items, types }) => {
  console.log(items);
  const selectedInterestCode = Array.from(
    items.interests,
    (interest) => interest.value
  );

  if (!selectedInterestCode) return <LoadingSpinner />;
  return (
    <div className="row tab1">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between card-title-wrap">
              <h4 className="card-title">기본 정보</h4>
            </div>
            <div className="mt-6 card-wrap">
              <table className="app-table bg-white w-full">
                <tr>
                  <td className="th">이메일</td>
                  <td colSpan="3" className="">
                    {items.email}
                  </td>
                </tr>
                <tr>
                  <td className="th">이름</td>
                  <td colSpan="3" className="">
                    {items.name}
                  </td>
                </tr>
                <tr>
                  <td className="th">전화번호</td>
                  <td colSpan="3" className="">
                    {PhoneFormatter(items.tel)}
                  </td>
                </tr>
                <tr>
                  <td className="th">주소</td>
                  <td colSpan="3" className="">
                    {items.gpsAddress}
                  </td>
                </tr>
                <tr>
                  <td className="th">생년월일</td>
                  <td colSpan="3" className="">
                    {items.birthday}
                  </td>
                </tr>
                <tr>
                  <td className="th">성별</td>
                  <td colSpan="3" className="">
                    {items.gender === "M" ? "남성" : "여성"}
                  </td>
                </tr>
                <tr>
                  <td className="th w-2/12">가입경로</td>
                  <td className="w-4/12">
                    {types.filter((type) => type.value === items.sns)[0].name}
                  </td>
                  <td className="th w-2/12">단말기 OS</td>
                  <td className="w-4/12">
                    {items.deviceOs === "A" ? "Android" : "IOS"}
                  </td>
                </tr>
                <tr className="">
                  <td className="th w-2/12">가입 날짜</td>
                  <td className="w-4/12">
                    {FormatDate(new Date(parseInt(items.createAt, 10)))}
                  </td>
                  <td className="th w-2/12">최근 접속일</td>
                  <td className="w-4/12">
                    {FormatDate(new Date(parseInt(items.loginAt, 10)))}
                  </td>
                </tr>
                <tr>
                  <td className="th">입력 태그</td>
                  <td colSpan="3">
                    <Interests
                      lang="ko"
                      selectMode={false}
                      selectedInterestCode={selectedInterestCode}
                    />
                  </td>
                </tr>
                {items.interestEtc ? (
                  <tr>
                    <td className="th">입력 태그 (기타)</td>
                    <td colSpan="3">{items.interestEtc}</td>
                  </tr>
                ) : (
                  ""
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EyeCheckHistory = ({ id }) => {
  const [eyeChecks, setEyeChecks] = useState();
  const { data, loading } = useQuery(USER_EYE_CHECK_RESULTS, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && data.userEyeCheckResults) {
      const results = data.userEyeCheckResults;
      const tempResults = [];
      const keys = Object.keys(results);
      for (const key of keys) {
        if (typeof results[key] !== "object") continue;
        results[key].forEach((result) => {
          tempResults.push(result);
        });
      }
      tempResults.sort((a, b) => {
        return b.createAt - a.createAt;
      });
      setEyeChecks(tempResults);
    }
  }, [data]);

  const getTypeName = (type) => {
    switch (type.toString()) {
      case "1":
        return "노안검사";
      case "2":
        return "시력검사";
      case "3":
        return "황반변성";
      case "4":
        return "빛번짐";
    }
  };

  const getTypeResult = (eyeCheck) => {
    const getPresbyopiaResult = (eyeCheck) => {
      const getResult = (distance) => {
        if (distance <= 10) {
          return 30;
        } else if (distance >= 44) {
          return 55;
        } else {
          switch (distance) {
            case 11:
              return 32;
            case 12:
              return 35;
            case 13:
              return 36;
            case 14:
              return 38;
            case 15:
              return 39;
            case 16:
              return 41;
            case 17:
              return 42;
            case 18:
              return 43;
            case 19:
              return 44;
            case 20:
              return 45;
            case 21:
              return 46;
            case 22:
              return 47;
            case 23:
              return 47;
            case 24:
              return 48;
            case 25:
              return 49;
            case 26:
              return 49;
            case 27:
              return 50;
            case 28:
              return 50;
            case 29:
              return 51;
            case 30:
              return 51;
            case 31:
              return 51;
            case 32:
              return 52;
            case 33:
              return 52;
            case 34:
              return 52;
            case 35:
              return 53;
            case 36:
              return 53;
            case 37:
              return 53;
            case 38:
              return 53;
            case 39:
              return 54;
            case 40:
              return 54;
            case 41:
              return 54;
            case 42:
              return 54;
            case 43:
              return 54;
          }
        }
      };

      return `노안 나이 ${getResult(eyeCheck.distanceAvg)}세`;
    };

    const getSightResult = (eyeCheck) => {
      return `좌안 시력 : ${eyeCheck.leftSight}, 우안 시력 : ${eyeCheck.rightSight}`;
    };

    const getMacularResult = (eyeCheck) => {
      const getLocation = (location) => {
        switch (location) {
          case 1:
            return "좌상";
          case 2:
            return "중상";
          case 3:
            return "우상";
          case 4:
            return "우";
          case 5:
            return "우하";
          case 6:
            return "중하";
          case 7:
            return "좌하";
          case 8:
            return "좌";
          case 9:
            return "중";
        }
      };

      const getResult = (size, location) => {
        return size === 0 ? "정상" : `${getLocation(location)} ${size}`;
      };

      return `좌 ${getResult(
        eyeCheck.leftMacularSize,
        eyeCheck.leftMacularLocation
      )}, 우 ${getResult(
        eyeCheck.rightMacularSize,
        eyeCheck.rightMacularLocation
      )}`;
    };

    const getLightResult = (eyeCheck) => {
      return `Halo : ${eyeCheck.halo}, Glare : ${eyeCheck.glare}`;
    };

    switch (eyeCheck.priority.toString()) {
      case "1":
        return getPresbyopiaResult(eyeCheck);
      case "2":
        return getSightResult(eyeCheck);
      case "3":
        return getMacularResult(eyeCheck);
      case "4":
        return getLightResult(eyeCheck);
    }
  };

  if (loading || !eyeChecks) return <LoadingSpinner />;
  return (
    <div className="row tab2">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between card-title-wrap">
              <h4 className="card-title">검사 이력</h4>
              <span className="card-sub">Total : {eyeChecks.length}</span>
            </div>
            <div className="row">
              <div className="col-12">
                <ReactTable
                  data={eyeChecks}
                  className="card-wrap"
                  defaultPageSize={10}
                  columns={[
                    {
                      id: "name",
                      Header: "검사 유형",
                      accessor: (d) => d.priority,
                      Cell: (row) => `${getTypeName(row.value)}`,
                    },
                    {
                      id: "result",
                      Header: "결과",
                      accessor: (d) => d,
                      Cell: (row) => `${getTypeResult(row.value)}`,
                    },
                    {
                      id: "createAt",
                      Header: "검사 일시",
                      accessor: "createAt",
                      Cell: (row) =>
                        `${FormatDate(new Date(parseInt(row.value, 10)))}`,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExerciseHistory = ({ id }) => {
  const [exerciseResults, setExerciseResults] = useState();
  const { data, loading } = useQuery(USER_EXERCISE_RESULTS, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && data.userEyeCheckResults) {
      const results = data.userEyeCheckResults.exerciseResults;
      results.sort((a, b) => {
        return b.createAt - a.createAt;
      });
      setExerciseResults(results);
    }
  }, [data]);

  const secondParse = (seconds) => {
    const min = parseInt((seconds % 3600) / 60);
    const sec = seconds % 60;
    return `${min}분 ${sec}초`;
  };

  if (loading || !exerciseResults) return <LoadingSpinner />;

  return (
    <div className="row tab3">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between card-title-wrap">
              <h4 className="card-title">눈운동 이력</h4>
              <span className="card-sub">Total : {exerciseResults.length}</span>
            </div>
            <div className="row">
              <div className="col-12">
                <ReactTable
                  data={exerciseResults}
                  className="card-wrap"
                  defaultPageSize={10}
                  columns={[
                    {
                      id: "type",
                      Header: "운동 유형",
                      accessor: (d) => d.exercise.commonCode.name,
                    },
                    {
                      id: "name",
                      Header: "운동명",
                      accessor: (d) => d.exercise.name,
                    },
                    {
                      id: "result",
                      Header: "결과",
                      accessor: (d) => d.time,
                      Cell: (row) => `${secondParse(parseInt(row.value))}`,
                    },
                    {
                      id: "createAt",
                      Header: "운동 일시",
                      accessor: "createAt",
                      Cell: (row) =>
                        `${FormatDate(new Date(parseInt(row.value, 10)))}`,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailPage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DetailPage;
