import React, {useState, useEffect} from 'react'
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_EXERCISE_SUB } from "graphql/gql/delete";
import { SimpleNotification } from "components/SimpleNotification";
import { hasValue } from "components/common";

const FocusSubtitles = ({ isKorea, lang, colspan, item }) => {
    return (
        <>
            <h4 className="ml-2 mt-6"> 사이클 </h4>
            {
                item ?
                    item.map((i, idx) => <Subtitle key={i.id.toString()} id={i.id} item={i} colspan={colspan} isKorea={isKorea} canDelete={idx!==0}/>)
                : (
                    <table className="app-table w-full">
                        <tr>
                            <td className="th w-1/6">성공 조건</td>
                            <td colspan={colspan}></td>
                        </tr>
                        <tr>
                            <td className="th w-1/6">표시 자막 설정</td>
                            <td colspan={colspan}></td>
                        </tr>
                    </table>
                )
            }
        </>
    )
}

const Subtitle = ({ id, isKorea, item, colspan, canDelete }) => {
    const [condition, setCondition] = useState(item?.condition);
    const [startDx, setStartDx] = useState(item?.startDx);
    const [endDx, setEndDx] = useState(item?.endDx);
    const [subtitles, setSubtitles] = useState(item?.subtitles);
    const style = { display: "inline", width: "auto"};

    return ( 
        <>
            <table className="app-table w-full">
                { isKorea ? (
                    <tr>
                        <td className="th w-1/6">성공 조건</td>
                        <td className="w-1/6">{condition}</td>
                        {
                            condition !== "3" ?
                            (<td className="w-2/6">
                                <div style={style}>{`${startDx} CM`}</div>
                            </td>)
                            :
                            (<>
                                <td className="w-1/6">
                                    <div style={style}>{`${startDx} CM`}</div>
                                </td>
                                <td className="w-1/6">
                                <div style={style}>{`${endDx} CM`}</div>
                                </td>
                            </>)
                        }
                    </tr>
                ) : ''}
                <tr>
                    <td className="th w-1/6">표시 자막 설정</td>
                    <td colspan={colspan}>{subtitles}</td>
                </tr>
            </table>
        </>
    )
}

export default FocusSubtitles;