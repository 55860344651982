import React, {useState, useEffect} from 'react'
import { Form } from "react-bootstrap";

const BlinkComfortablySubtitle = ({ item, lang }) => {
    const [eyeCloseSubtitle, setEyeCloseSubtitle] = useState(item[0][lang].subtitles);
    const [eyeOpenSubtitle, setEyeOpenSubtitle] = useState(item[1][lang].subtitles);
    
    useEffect(() => {
        if (eyeCloseSubtitle)
            item[0][lang].eyeCloseSubtitle = eyeCloseSubtitle;
    }, [eyeCloseSubtitle])

    useEffect(() => {
        if (eyeOpenSubtitle)
            item[1][lang].eyeOpenSubtitle = eyeOpenSubtitle;
    }, [eyeOpenSubtitle])

    return (
        <>
            <tr>
                <td className="th w-1/6">눈 감았을 때 표시 자막 설정</td>
                <td className="w-3/6 border-right-0">
                    <Form.Control type="text" placeholder="자막을 입력하세요." value={eyeCloseSubtitle} onChange={e => setEyeCloseSubtitle(e.target.value)} />
                </td>
            </tr>
            <tr>
                <td className="th w-1/6">눈 떴을 때 표시 자막 설정</td>
                <td className="w-3/6 border-right-0">
                    <Form.Control type="text" placeholder="자막을 입력하세요." value={eyeOpenSubtitle} onChange={e => setEyeOpenSubtitle(e.target.value)} />
                </td>
            </tr>
        </>
    )
}

export default BlinkComfortablySubtitle;