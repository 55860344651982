import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  ALL_USERS,
  ALL_PRODUCTS,
  ALL_NOTICE_BOARDS,
  ALL_SERVICE_CENTERS,
} from "graphql/gql/select";
import { SEND_MANY_USER_FCM } from "graphql/gql/update";
import Paginator from "react-hooks-paginator";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Form, Modal, Button } from "react-bootstrap";
import { PhoneFormatter } from "components/PhoneFormatter";
import useSettings from "stores/settings";
import { SimpleNotification } from "components/SimpleNotification";
import { observer } from "mobx-react-lite";
import { InView } from "react-intersection-observer";
import {
  getImagePath,
  getImagePathForObject,
  handleKeyPress,
  hasValue,
  LoadingSpinner,
} from "components/common";
import Select from "react-select";
import cuid from "cuid";
import { CREATE_MANY_ALARM_LOG } from "graphql/gql/insert";

const AppPushMessage = observer(() => {
  // 로그인 체크
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const [ready, setReady] = useState(false);
  const [result, setResult] = useState();
  const { loading, error, data, fetchMore } = useQuery(ALL_USERS, {
    fetchPolicy: "network-only",
    variables: {
      where: { withdraw: false, NOT: { token: "" } },
      orderBy: { createAt: "desc" },
      pagination: {
        take: 50,
        skip: 0,
      },
    },
  });
  if (error) return SimpleNotification({ message: error.message || "Error" });

  useEffect(() => {
    if (data?.allUsers) {
      for (const user of data.allUsers) {
        user.checked = false;
      }
      setResult(data.allUsers);
      setReady(true);
    }
  }, [data]);

  if (loading || !data?.allUsers || !ready) return <LoadingSpinner />;

  return (
    <div>
      {/* breadcrumb */}
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">사용자 관리</li>
            <Link
              to="/app-users/app-push-message"
              className="breadcrumb-item active mt-1"
              aria-current="page"
            >
              푸시 메시지 발송
            </Link>
          </ol>
        </nav>
      </div>
      <MesssageForm items={result} fetchMore={fetchMore} />
    </div>
  );
});

const MesssageForm = ({ items, fetchMore }) => {
  const [selectedUsers, setSelectedUsers] = useState();
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();
  const [category, setCategory] = useState("1");
  const [targetUrl, setTargetUrl] = useState();

  const [magazineShow, setMagazineShow] = useState(false);
  const [noticeShow, setNoticeShow] = useState(false);
  const [sendShow, setSendShow] = useState(false);

  const settings = useSettings();

  const handleRadioClick = (val) => {
    setCategory(val);
    if (val === "3") {
      setTargetUrl(`${settings.url}:${settings.port}/share/event?isShared=Y`);
    } else {
      setTargetUrl("");
    }
  };

  const handleTargetChoice = () => {
    if (category === "1") setMagazineShow(true);
    if (category === "2") setNoticeShow(true);
    if (category === "3")
      setTargetUrl(`${settings.url}:${settings.port}/share/event?isShared=Y`);
  };

  const handleMagazineValue = (targetId) => {
    const url = `${settings.url}:${settings.port}/share/magazine?id=${targetId}&isShared=Y`;
    setTargetUrl(url);
    setMagazineShow(false);
  };

  const handleNoticeValue = (targetId) => {
    const url = `${settings.url}:${settings.port}/share/notice?id=${targetId}&isShared=Y`;
    setTargetUrl(url);
    setNoticeShow(false);
  };

  const [reservation, setReservation] = useState(false);
  const [reservationDate, setReservationDate] = useState();
  const [reservationTime, setReservationTime] = useState();

  const getDate = () => {
    const date = new Date();
    const nowDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    const nowTime = `${date.getHours()}:${date.getMinutes()}`;
    return { nowDate, nowTime };
  };

  const [alarmCreate, { data: createData }] = useMutation(
    CREATE_MANY_ALARM_LOG,
    {
      onError(err) {
        SimpleNotification({ type: "danger", message: err.message || "Error" });
      },
    }
  );

  useEffect(() => {
    if (createData?.createManyAlarmLog?.result) {
      if (createData.createManyAlarmLog.result === "ok") {
        return SimpleNotification({
          title: "success",
          type: "success",
          message: "성공적으로 발송되었습니다.",
        });
      } else {
        return SimpleNotification({
          title: "failed",
          type: "danger",
          message: "발송에 실패하였습니다.",
        });
      }
    }
  }, [createData]);

  const handleSendModal = async () => {
    setSendShow(false);

    if (!reservation) {
      fetch(settings.pushUrl, {
        method: "POST",
        body: JSON.stringify({
          topic: "P",
          title,
          contents: message,
          link: targetUrl,
          uids: selectedUsers.map((user) => user.id),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.res_code) {
            return SimpleNotification({
              title: "error",
              type: "danger",
              message: "예기치 못한 오류가 발생하였습니다.",
            });
          } else if (res?.res_code === 0) {
            return SimpleNotification({
              title: "success",
              type: "success",
              message: "성공적으로 발송되었습니다.",
            });
          } else {
            return SimpleNotification({
              title: "failed",
              type: "danger",
              message: "발송에 실패하였습니다.",
            });
          }
        });
    } else {
      const data = selectedUsers.map((user) => ({
        uid: user.id,
        title,
        message,
        link: targetUrl,
        reserveAt: `${reservationDate} ${reservationTime}`,
      }));

      alarmCreate({ variables: { data } });
    }
  };

  const handleSend = () => {
    if (selectedUsers.length === 0) {
      return SimpleNotification({
        type: "warning",
        message: "푸쉬메세지를 전송할 사용자를 선택해주세요.",
      });
    }

    if (!hasValue(targetUrl)) {
      return SimpleNotification({
        type: "warning",
        message: "이동 타켓을 선택해주세요.",
      });
    }

    if (!hasValue(title) || !hasValue(message)) {
      return SimpleNotification({
        type: "warning",
        message: "내용을 입력해주세요.",
      });
    }

    if (reservation) {
      if (!hasValue(reservationDate) || !hasValue(reservationTime)) {
        return SimpleNotification({
          type: "warning",
          message: "예약 시간을 설정해주세요.",
        });
      }

      if (
        Date.parse(`${reservationDate} ${reservationTime}`) - Date.now() <
        0
      ) {
        return SimpleNotification({
          type: "warning",
          message: "과거는 선택할 수 없습니다.",
        });
      }
    }

    setSendShow(true);
  };

  return (
    <div className="wrap">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">타겟 선택</h4>
                <div className="radiobox">
                  <label htmlFor="push_magazine" className="radio-box">
                    <input
                      id="push_magazine"
                      name="push_notifications"
                      type="radio"
                      className="form-radio transition duration-150 ease-in-out"
                      defaultChecked
                      onChange={() => handleRadioClick("1")}
                    />
                    <span>매거진</span>
                  </label>

                  <label htmlFor="push_notice" className="radio-box">
                    <input
                      id="push_notice"
                      name="push_notifications"
                      type="radio"
                      className="form-radio transition duration-150 ease-in-out"
                      onChange={() => handleRadioClick("2")}
                    />
                    <span>공지사항</span>
                  </label>

                  <label htmlFor="push_event" className="radio-box">
                    <input
                      id="push_event"
                      name="push_notifications"
                      type="radio"
                      className="form-radio transition duration-150 ease-in-out"
                      onChange={() => handleRadioClick("3")}
                    />
                    <span>이벤트</span>
                  </label>
                </div>
              </div>
              {/* 바디영역 */}
              <div className="wrap">
                <div className="flex items-center justify-between">
                  <div className="w-2/12">
                    <button
                      className="btn btn-default p-2"
                      onClick={handleTargetChoice}
                    >
                      이동 타겟 선택
                    </button>
                  </div>
                  {/* 매거진 모달 */}
                  <Modal
                    show={magazineShow}
                    onHide={() => setMagazineShow(false)}
                    aria-labelledby="example-modal-sizes-title-md"
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className="push">
                      <MagazineModal handleValue={handleMagazineValue} />
                    </Modal.Body>
                  </Modal>

                  {/* 공지 모달 */}
                  <Modal
                    show={noticeShow}
                    onHide={() => setNoticeShow(false)}
                    aria-labelledby="example-modal-sizes-title-md"
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className="push">
                      <NoticeModal handleValue={handleNoticeValue} />
                    </Modal.Body>
                  </Modal>
                  <div className="w-10/12">
                    <Form.Control
                      type="text"
                      className="form-control"
                      readOnly
                      value={targetUrl}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 유저 리스트 */}
      <UserForm
        users={items}
        setSelectedUsers={setSelectedUsers}
        fetchMore={fetchMore}
      />
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">메시지 작성</h4>
              </div>
              {/* 제목 & 메세지 */}
              <div className="card-wrap">
                <table className="app-table w-full">
                  <tr>
                    <td className="th">제목</td>
                    <td colSpan="3" className="">
                      <Form.Control
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="th">내용</td>
                    <td colSpan="3" className="">
                      <textarea
                        id="about"
                        rows="5"
                        className="orm-control expert-text form-textarea block w-full transition duration-150 ease-in-out"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </td>
                  </tr>
                </table>
              </div>
              {/* Modal Starts  */}
              <Modal
                show={sendShow}
                onHide={() => setSendShow(false)}
                aria-labelledby="example-modal-sizes-title-md"
              >
                <Modal.Header closeButton>
                  <Modal.Title>푸쉬 메세지 전송</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <p>메세지를 전송하시겠습니까?</p>
                </Modal.Body>

                <Modal.Footer className="flex-wrap">
                  <Button
                    variant="btn btn-primary m-2"
                    onClick={handleSendModal}
                  >
                    확인
                  </Button>
                  <Button
                    variant="btn btn-light m-2"
                    onClick={() => setSendShow(false)}
                  >
                    취소
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* Modal Ends */}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">예약설정</h4>
                <div className="checkbox">
                  <label className="checkbox-label">
                    <input
                      name=""
                      type="checkbox"
                      className="checkbox-input"
                      onChange={() => {
                        setReservation(true);
                      }}
                    />
                    <span>예약 발송하기</span>
                  </label>
                </div>
              </div>

              <div className="reserv-wrap row">
                <div className="col-md-6 col-xs-12">
                  <input
                    type="date"
                    className="form-control"
                    min={getDate().nowDate}
                    placeholder="원하는 날짜를 입력하세요."
                    onChange={(e) => setReservationDate(e.target.value)}
                  ></input>
                </div>
                <div className="col-md-6 col-xs-12">
                  <input
                    type="time"
                    className="form-control"
                    placeholder="원하는 시간을 입력하세요."
                    onChange={(e) => setReservationTime(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center mt-8">
        <button className="btn btn-primary" onClick={handleSend}>
          보내기
        </button>
      </div>
    </div>
  );
};

const UserForm = ({ users, setSelectedUsers, fetchMore }) => {
  const options = [
    { value: "email", label: "이메일" },
    { value: "nick", label: "이름" },
  ];
  const [userList, setUserList] = useState(users);
  const [viewUserList, setViewUserList] = useState(users);
  const [targetDevice, setTargetDevice] = useState(0); //0:전체 1:아이폰 2:안드로이드
  const [viewSelectedUser, setViewSelectedUser] = useState(false);
  const [searchField, setSearchField] = useState(options[0]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [lastSearch, setLastSearch] = useState();
  const [allCheck, setAllCheck] = useState(false);

  useEffect(() => {
    setSelectedUsers(userList.filter((user) => user.checked));
  }, [userList]);
  useEffect(() => {
    setViewUserList(users);
    setUserList(users);
  }, [users]);
  const filterUser = () => {
    let tempUserList;

    switch (targetDevice) {
      case 0:
        tempUserList = userList;
        break;
      case 1:
        tempUserList = userList.filter((user) => user.deviceOs === "I");
        break;
      case 2:
        tempUserList = userList.filter((user) => user.deviceOs === "A");
        break;
      default:
        return;
    }

    if (viewSelectedUser)
      tempUserList = tempUserList.filter((user) => user.checked);
    if (lastSearch)
      tempUserList = tempUserList.filter((user) =>
        user[lastSearch.field.value].includes(lastSearch.keyword)
      );
    return tempUserList;
  };
  const settingViewUserList = () => {
    setViewUserList(filterUser());
  };
  useEffect(() => {
    settingViewUserList();
  }, [targetDevice, viewSelectedUser, lastSearch]);

  useEffect(() => {
    if (!viewSelectedUser)
      setViewUserList(
        viewUserList.filter((user) => {
          user.checked = allCheck;
          return true;
        })
      );
    else setViewUserList([]);

    userList.forEach((user) => {
      if (
        targetDevice === 0 ||
        (targetDevice === 1 && user.deviceOs === "I") ||
        (targetDevice === 2 && user.deviceOs === "A")
      ) {
        if (
          !lastSearch ||
          (lastSearch &&
            user[lastSearch.field.value].includes(lastSearch.keyword))
        ) {
          if (!viewSelectedUser || (viewSelectedUser && user.checked)) {
            user.checked = allCheck;
          }
        }
      }
    });

    setUserList([...userList]);
  }, [allCheck]);

  const handleCheck = (user) => {
    const idx = userList.findIndex((u) => u.id === user.id);
    userList[idx] = user;
    setUserList([...userList]);

    if (viewSelectedUser && !user.checked) {
      setViewUserList(viewUserList.filter((u) => u.id !== user.id));
    }
  };

  const handleRadio = (val) => {
    setTargetDevice(val);
  };

  const handleViewSelectedUserCheckBox = (val) => {
    setViewSelectedUser(val);
  };

  const handleSearch = () => {
    if (hasValue(searchKeyword))
      setLastSearch({ field: searchField, keyword: searchKeyword });
    else setLastSearch(undefined);
  };

  if (!userList) return <div />;
  return (
    <div className="row mt-4">
      <div className="col-md-12">
        <div className="card overflow-hidden">
          <div className="card-body">
            <h4 className="card-title">사용자 목록</h4>

            <div className="flex justify-between card-title-wrap">
              <div className="radiobox">
                <label htmlFor="choice_all" className="radio-box">
                  <input
                    id="choice_all"
                    name="choice_platform"
                    type="radio"
                    className="form-radio transition duration-150 ease-in-out"
                    checked={targetDevice === 0}
                    onChange={(e) => !e.target.value || handleRadio(0)}
                  />
                  <span>전체</span>
                </label>

                <label htmlFor="choice_ios" className="radio-box">
                  <input
                    id="choice_ios"
                    name="choice_platform"
                    type="radio"
                    className="form-radio transition duration-150 ease-in-out"
                    checked={targetDevice === 1}
                    onChange={(e) => !e.target.value || handleRadio(1)}
                  />
                  <span>아이폰</span>
                </label>

                <label htmlFor="choice_android" className="radio-box">
                  <input
                    id="choice_android"
                    name="choice_platform"
                    type="radio"
                    className="form-radio transition duration-150 ease-in-out"
                    checked={targetDevice === 2}
                    onChange={(e) => !e.target.value || handleRadio(2)}
                  />
                  <span>안드로이드</span>
                </label>
              </div>

              <div className="flex align-items-center">
                <div className="checkbox">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      onChange={(e) =>
                        handleViewSelectedUserCheckBox(e.target.checked)
                      }
                    />
                    <span>선택된 사용자만 보기</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="flex mb-2">
              <div className="w-1/6">
                <Select
                  options={options}
                  defaultValue={options[0]}
                  onChange={(e) => setSearchField(e)}
                />
              </div>
              <Form.Control
                className="w-5/6 ml-2"
                type="text"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e, "Enter", handleSearch)}
              />
              <button className="w-1/12" onClick={handleSearch}>
                <i className="ti-search" />
              </button>
            </div>
            <div className="list-wrap" style={{ flexDirection: "column" }}>
              <div
                className="list-contents h-auto"
                style={{ flexBasis: "auto" }}
              >
                <div className="mail-list">
                  <div className="checkbox">
                    <label className="checkbox-label">
                      <input
                        id="checkboxUserAllCheck"
                        type="checkbox"
                        className="checkbox-input"
                        checked={allCheck}
                        onChange={(e) => setAllCheck(e.target.checked)}
                      />
                      <span></span>
                    </label>
                  </div>
                  <div className="content">
                    <div>
                      <span>전체 선택</span>
                    </div>
                  </div>
                  <div className="content" style={{ textAlign: "end" }}>
                    <p className="sender-name">
                      {lastSearch
                        ? `검색 조건 : ${lastSearch.field.label} | ${lastSearch.keyword}`
                        : ""}
                    </p>
                  </div>
                </div>
              </div>

              <Users
                userList={viewUserList}
                handleCheck={handleCheck}
                fetchMore={fetchMore}
                disabled={lastSearch || targetDevice !== 0}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Users = ({ userList, handleCheck, fetchMore, disabled }) => {
  const listItems = userList.map((user) => (
    <User user={user} handleCheck={handleCheck} />
  ));

  return (
    <div className="list-contents" style={{ flexBasis: "auto" }}>
      {listItems}
      {userList && !disabled && (
        <InView
          onChange={async (inview) => {
            const currentLength = userList.length;
            if (inview) {
              await fetchMore({
                variables: {
                  pagination: {
                    take: 50,
                    skip: currentLength,
                  },
                },
                updateQuery: (
                  previousResult,
                  { fetchMoreResult, queryVariables }
                ) => {
                  // Don't do anything if there weren't any new items
                  if (
                    !fetchMoreResult ||
                    fetchMoreResult.allUsers.length === 0
                  ) {
                    return previousResult;
                  }

                  return {
                    // Concatenate the new feed results after the old ones
                    allUsers: previousResult.allUsers.concat(
                      fetchMoreResult.allUsers
                    ),
                  };
                },
              });
            }
          }}
        />
      )}
    </div>
  );
};

const User = ({ user, handleCheck }) => {
  const checkboxOnClick = (checked) => {
    user.checked = checked;
    handleCheck(user);
  };

  return (
    <div className="mail-list" key={user.id}>
      <div className="checkbox">
        <label className="checkbox-label">
          <input
            type="checkbox"
            checked={user.checked}
            onChange={(e) => checkboxOnClick(e.target.checked)}
            className="checkbox-input"
          />
          <span></span>
        </label>
      </div>
      <div className="content">
        <div>
          <p className="sender-email">{user.email}</p>
        </div>
        <div className="flex">
          {user.name ? (
            <>
              <div>
                <p className="sender-name">{user.name}</p>
              </div>
            </>
          ) : (
            ""
          )}
          <p className="sender-phone">{PhoneFormatter(user.tel) || ""}</p>
        </div>
      </div>
    </div>
  );
};

// 매거진 모달
const MagazineModal = ({ handleValue }) => {
  // 검색
  const [searchItem, setSearchItem] = useState();
  // 상품 관련 데이터

  const [items, setItems] = useState(null);
  const [products, setProducts] = useState();

  // 페이지네이션 적용
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItem, setCurrentItem] = useState([]);

  const pageLimit = 7;

  // 페이지네이션 초기화
  useEffect(() => {
    if (products) {
      setCurrentItem(products.slice(offset, offset + pageLimit));
    }
  }, [offset, products]);

  //   배너 클릭시 이동할 상품 선택
  const handleProductChoice = (productId) => {
    handleValue(productId);
    setSearchItem();
    setProducts(items);
  };

  // 검색
  const handleSearch = () => {
    setProducts(items);

    if (searchItem) {
      setProducts(
        items.filter((f) => {
          return f.title.indexOf(searchItem) > -1;
        })
      );
    }
  };

  // 상품정보
  const { loading, error, data } = useQuery(ALL_NOTICE_BOARDS, {
    fetchPolicy: "network-only",
    variables: { visible: true, category: "1" },
    skip: !!items,
  });
  if (loading) return <LoadingSpinner />;
  if (error) {
    SimpleNotification({ type: "danger", message: error.message || "Error" });
  }

  if (data && data.allNoticeBoards) {
    const resultData = data.allNoticeBoards;

    resultData.sort((a, b) => {
      return b.createAt - a.createAt;
    });

    setItems(resultData);
    setProducts(resultData);
  }

  if (!items) return <LoadingSpinner />;

  return (
    <div className="w-full">
      <div className="sidebar-widget">
        <h4 className="pro-sidebar-title">Search </h4>
        <div className="pro-sidebar-search mt-15">
          <div className="pro-sidebar-search-form" action="#">
            <input
              type="text"
              className="form-control"
              placeholder="Search here..."
              value={searchItem}
              onChange={(e) => setSearchItem(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, "Enter", handleSearch)}
            />
            <button onClick={handleSearch}>
              <i className="ti-search" />
            </button>
          </div>
        </div>
      </div>
      <div className="flex mx-auto">
        <div className="w-100">
          {currentItem &&
            currentItem.map((m) => (
              <div className="px-3 pt-1 mt-1 flex justify-between border-b">
                <div className="justify-start w-3/4">
                  <img
                    src={getImagePathForObject(m)}
                    className="h-12 rounded-md"
                    alt="dp"
                  />
                  <div className="flex justify-start flex-wrap pb-3 mt-2 w-full">
                    <div className="flex justify-start font-bold">
                      {m.title}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end text-black w-1/4">
                  <button
                    type="button"
                    className="btn btn-warning btn-rounded py-1 px-3"
                    onClick={(e) => handleProductChoice(m.id)}
                  >
                    선택
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="pro-pagination-style text-center mt-30">
        <Paginator
          totalRecords={products.length}
          pageLimit={pageLimit}
          pageNeighbours={1} // 0, 1, 2
          setOffset={setOffset}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageContainerClass="mb-0 mt-0"
          pagePrevText="«"
          pageNextText="»"
        />
      </div>
    </div>
  );
};

// 공지 모달
const NoticeModal = ({ handleValue }) => {
  // 검색
  const [searchItem, setSearchItem] = useState();
  // 상품 관련 데이터

  const [items, setItems] = useState(null);
  const [products, setProducts] = useState();

  // 페이지네이션 적용
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItem, setCurrentItem] = useState([]);

  const pageLimit = 7;

  // 페이지네이션 초기화
  useEffect(() => {
    if (products) {
      setCurrentItem(products.slice(offset, offset + pageLimit));
    }
  }, [offset, products]);

  //   배너 클릭시 이동할 상품 선택
  const handleProductChoice = (productId) => {
    handleValue(productId);
    setSearchItem();
    setProducts(items);
  };

  // 검색
  const handleSearch = () => {
    setProducts(items);

    if (searchItem) {
      setProducts(
        items.filter((f) => {
          return f.title.indexOf(searchItem) > -1;
        })
      );
    }
  };

  //   상품정보
  const { loading, error, data } = useQuery(ALL_SERVICE_CENTERS, {
    fetchPolicy: "network-only",
    variables: {
      where: { type: "NT", category: "1" },
      orderBy: { createAt: "desc" },
    },
    skip: !!items,
  });
  if (loading) return <LoadingSpinner />;
  if (error)
    return SimpleNotification({
      type: "danger",
      message: error.message || "Error",
    });

  if (data && data.allServiceCenters) {
    const resultData = data.allServiceCenters;
    setItems(resultData);
    setProducts(resultData);
  }

  if (!items) return <LoadingSpinner />;

  return (
    <div className="w-full">
      <div className="sidebar-widget">
        <h4 className="pro-sidebar-title">Search </h4>
        <div className="pro-sidebar-search mt-15">
          <div className="pro-sidebar-search-form" action="#">
            <input
              type="text"
              placeholder="Search here..."
              value={searchItem}
              onChange={(e) => setSearchItem(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, "Enter", handleSearch)}
            />
            <button onClick={handleSearch}>
              <i className="ti-search" />
            </button>
          </div>
        </div>
      </div>
      <div className="flex mx-auto">
        <div className="w-full">
          {currentItem &&
            currentItem.map((m) => (
              <div className="px-3 pt-1 mt-1 flex justify-between border-b">
                <div className="justify-start w-3/4">
                  <div className="flex justify-start flex-wrap pb-3 mt-2 w-full">
                    <div className="flex justify-start font-bold">
                      {m.title}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end text-black w-1/4">
                  <button
                    type="button"
                    className="btn btn-warning btn-rounded py-1 px-3"
                    onClick={(e) => handleProductChoice(m.id)}
                  >
                    선택
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="pro-pagination-style text-center mt-30">
        <Paginator
          totalRecords={products.length}
          pageLimit={pageLimit}
          pageNeighbours={1} // 0, 1, 2
          setOffset={setOffset}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageContainerClass="mb-0 mt-0"
          pagePrevText="«"
          pageNextText="»"
        />
      </div>
    </div>
  );
};

export default AppPushMessage;
