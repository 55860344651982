import React, { useState, useEffect } from 'react'
import EyeMuscleSubtitle from './Subtitle'
import { Form } from "react-bootstrap";
import Select from "react-select";
import { getNumberSelectBox } from "components/common"

const EyeMuscleLayout = ({ colspan, isKorea, lang, item }) => {

  const [name, setName] = useState(item?.name);
  const [type, setType] = useState(item?.type);
  const [repeatCnt, setRepeatCnt] = useState(item?.repeatCnt);
  const [sound, setSound] = useState(item?.commonCodeSound?.name);
  const [priority, setPriority] = useState(item?.priority);

  return (
    <table className="app-table w-full">
      <tr>
          <td className="th w-1/6">유형 제목</td>
          <td colspan={colspan}>{name}</td>
      </tr>
      { 
        isKorea? (
          <>
            <tr>
              <td className="th w-1/6">운동 유형</td>
              <td colspan={colspan}>
                {
                  type === "H" ? "H 운동" : "S 운동"
                }
              </td>
            </tr>
            <tr>
              <td className="th w-1/6">반복 횟수</td>
              <td colspan={colspan}>{repeatCnt}</td>
            </tr>
            <tr>
              <td className="th w-1/6">효과음</td>
              <td colspan={colspan}>{sound}</td>
            </tr>
            <tr>
                <td className="th w-1/6">정렬 순서</td>
                <td colspan={colspan}>{priority}</td>
            </tr>
          </>
        ) : ''
      }
      <EyeMuscleSubtitle item={item.exerciseSubs}/>
    </table>  
  )
}

export default EyeMuscleLayout;