/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import useSettings from "stores/settings";
import { ALL_NOTICE_BOARDS } from "graphql/gql/select"
import {  useQuery } from "@apollo/react-hooks";
import { FormatDate } from "components/FormatDate";
import { getImagePathForObject, LoadingSpinner } from "components/common";
import ReactTable from "react-table";
import { SimpleNotification } from "components/SimpleNotification";
import matchSorter from "match-sorter";

const Tables = () => {
  // 로그인 체크
  const settings = useSettings();
  const history = useHistory();
  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const [result, setResult] = useState();

	const { loading, error, data } = useQuery(ALL_NOTICE_BOARDS, {
		fetchPolicy: 'network-only',
		variables: {
			category : "1", 
      visible : true,
			orderBy: { createAt: 'desc' }
		}
	});

	useEffect(() => {
		if (data?.allNoticeBoards) {
			setResult(data.allNoticeBoards);
		}
	}, [data]);

	if (error) {
		console.log(error);
		SimpleNotification({ type: 'danger', message: error.message || 'Error' });
	}

	if (loading || !result) return <LoadingSpinner />;

  return (
    <>
      <div>
        <div>
          <nav aria-label="breadcrumb" role="navigation">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">게시판 관리</li>
              <Link to="/notice-board/magazine-table" className="breadcrumb-item active mt-1" aria-current="page">
                매거진 목록
              </Link>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="flex justify-between card-title-wrap">
                  <h4 className="card-title">매거진 목록</h4>
                  <Link className="btn btn-primary" to="/notice-board/magazine-create">
                    등록하기
                  </Link>
                </div>
                <ReactTable
                  data={result}
                  className="card-wrap"
                  defaultPageSize={10}
                  filterable
                  columns={[
                    {
                      id: "image",
                      Header: "대표이미지",
                      accessor: d => d,
                      filterable: false,
                      Cell: (row) => (
                        <img src={getImagePathForObject(row?.value)} alt="" className="mgz-thumb" />
                      )
                    },
                    {
                      id: "title",
                      Header: "제목",
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: [item => item.title]
                        }),
                      filterAll: true,
                      accessor: (d) => d.title,
                    },
                    {
                      id: "createAt",
                      Header: "등록날짜",
                      accessor: (d) => d.createAt,
                      filterable: false,
                      Cell: (row) => `${FormatDate(new Date(parseInt(row.value, 10)))}`
                    },
                    {

                      id:"detail",
                      Header: "",
                      accessor: "detail",
                      filterable: false,
                      Cell: (row) => (
                        <Link className="ti-pencil-alt" to={`/notice-board/magazine-detail/${row.original.id}`}>
                          상세보기
                        </Link>
                      )
                    },
                    {
                      Header: "",
                      id : 'edit',
                      accessor: "edit",
                      filterable: false,
                      Cell: (row) => (
                        <Link className="ti-pencil-alt" to={`/notice-board/magazine-edit/${row.original.id}`}>
                          수정하기
                        </Link>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
