import React, { useState, useEffect } from "react";
import { AllCommonCodes, AllExerciseDetail } from "graphql/query/select";
import { useHistory, Link } from "react-router-dom";
import {
  canParseInt,
  getImagePathForObject,
  hasValue,
  LoadingSpinner,
} from "components/common";
import { Form, Tabs, Tab, Button, Modal } from "react-bootstrap";
import ExerciseLayout from "./ExerciseComponent/DetailTypeComponent/ExerciseLayout";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_EXERCISE } from "graphql/gql/delete";
import { SimpleNotification } from "components/SimpleNotification";
import useSettings from "stores/settings";

const DetailPage = ({ match }) => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const goBack = () => {
    history.push("/eye-movement/table");
  };

  const { group } = match.params;
  if (!group) goBack();

  const tabsParam = {
    where: {
      type: "L",
      isUse: "Y",
      lang: "ko",
    },
    order: {
      orderByKey: "priority",
      orderByValue: "asc",
    },
  };

  const tabs = AllCommonCodes(tabsParam).result; //한 영 일 중
  const allExercises = AllExerciseDetail({ group }).result;
  const typeComponentList = [];
  const exerciseTypeList = {};
  useEffect(() => {
    console.log(allExercises);
  }, [allExercises]);

  const typeSet = (type, typeNum) => {
    if (!exerciseTypeList[type.group]) {
      typeComponentList.push({ id: type.group, type: { value: typeNum } });
      exerciseTypeList[type.group] = {};
      tabs.forEach((tab) => (exerciseTypeList[type.group][tab.value] = {}));
    }
    exerciseTypeList[type.group][type.lang] = type;
    if (type.priority)
      typeComponentList[
        typeComponentList.findIndex((tc) => tc.id === type.group)
      ].priority = type.priority;
  };

  //#region Delete
  const [showMd, setShowMd] = useState(false);
  const [exerciseDelete, { data: deleteData }] = useMutation(DELETE_EXERCISE, {
    onError(err) {
      SimpleNotification({ type: "danger", message: err.message || "Error" });
    },
  });

  useEffect(() => {
    if (deleteData && deleteData.deleteExercise) {
      goBack();
    }
  }, [deleteData]);

  const handleDelete = () => {
    exerciseDelete({
      variables: {
        group,
        type: allExercises[0].type,
        priority: allExercises[0].priority,
      },
    });
  };
  //#endregion

  if (!tabs || !allExercises) return <LoadingSpinner />;

  if (allExercises) {
    allExercises.forEach((exercise) => {
      exercise.exerciseType1s.forEach((type) => typeSet(type, "1"));
      exercise.exerciseType2s.forEach((type) => typeSet(type, "2"));
      exercise.exerciseType3s.forEach((type) => typeSet(type, "3"));
      exercise.exerciseType4s.forEach((type) => typeSet(type, "4"));
      exercise.exerciseType5s.forEach((type) => typeSet(type, "5"));
      exercise.exerciseType6s.forEach((type) => typeSet(type, "6"));
    });
    typeComponentList.sort((a, b) => {
      return a.priority - b.priority;
    });
  }

  return (
    <>
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">눈 운동 관리</li>
            <Link
              to="/eye-movement/table"
              className="breadcrumb-item active mt-1"
              aria-current="page"
            >
              눈 운동 목록
            </Link>
            <Link className="breadcrumb-item active mt-1" aria-current="page">
              눈 운동 정보
            </Link>
          </ol>
        </nav>
      </div>
      <div className="w-full flex justify-between">
        <button
          type="button"
          className="btn btn-danger m-1 mr-2"
          onClick={() => setShowMd(true)}
        >
          삭제하기
        </button>
        <Link
          className="btn btn-primary m-1"
          to={`/eye-movement/update/${group}`}
        >
          수정하기
        </Link>
        <Modal
          show={showMd}
          onHide={() => setShowMd(false)}
          aria-labelledby="example-modal-sizes-title-md"
        >
          <Modal.Header closeButton>
            <Modal.Title>해당 내용을 삭제하시겠습니까?</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>삭제를 원하시면 삭제 버튼을 눌러주세요.</p>
          </Modal.Body>

          <Modal.Footer className="fleex-wrap">
            <Button variant="danger m-2" onClick={handleDelete}>
              삭제
            </Button>
            <Button variant="primary m-2" onClick={() => setShowMd(false)}>
              취소
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="my-4 pl-0">
        <Tabs id="movement-default-info-tabs">
          {tabs.map((tab) => (
            <Tab eventKey={tab.value} title={tab.name}>
              <Table
                goBack={goBack}
                lang={tab.value}
                group={group}
                exercise={
                  allExercises.filter(
                    (exercise) => tab.value === exercise.lang
                  )[0]
                }
              />
            </Tab>
          ))}
        </Tabs>
      </div>
      {typeComponentList.map((type, idx) => (
        <Exercise_default_layout
          tabs={tabs}
          idx={idx}
          parentId={group}
          group={type.id}
          key={type.id.toString()}
          type={type.type}
          goBack={goBack}
          item={exerciseTypeList[type.id]}
        />
      ))}
      <div className="w-full flex justify-between">
        <button
          type="button"
          className="btn btn-danger m-1 mr-2"
          onClick={() => setShowMd(true)}
        >
          삭제하기
        </button>
        <Link
          className="btn btn-primary m-1"
          to={`/eye-movement/update/${group}`}
        >
          수정하기
        </Link>
      </div>
    </>
  );
};

const Exercise_default_layout = ({
  tabs,
  idx,
  parentId,
  group,
  type,
  goBack,
  item,
}) => {
  return (
    <div className="my-4 pl-0">
      <Tabs id={`exercise-default-layout-tabs${idx}`}>
        {tabs.map((tab) => (
          <Tab eventKey={tab.value} title={tab.name}>
            <ExerciseLayout
              type={type}
              lang={tab.value}
              parentId={parentId}
              group={group}
              goBack={goBack}
              colspan="3"
              idx={idx}
              item={item[tab.value]}
            />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

//#region Table
const Table = ({ goBack, lang, group, exercise }) => {
  const [name, setName] = useState(exercise?.name);
  const [typeValue, setTypeValue] = useState(exercise?.commonCode.value);
  const [typeName, setTypeName] = useState(exercise?.commonCode.name);
  const [image, setImage] = useState(getImagePathForObject(exercise));
  const [keyword, setKeyword] = useState(exercise?.keyword);
  const [expert, setExpert] = useState(exercise?.expert);
  const [time, setTime] = useState(exercise?.time);
  const [purpose, setPurpose] = useState(exercise?.purpose);
  const [method, setMethod] = useState(exercise?.method);
  const [priority, setPriority] = useState(exercise?.priority);

  const dataParams = {
    name,
    typeName,
    image,
    keyword,
    expert,
    time,
    purpose,
    method,
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="card-title-wrap flex">
                <h4 className="card-title w-1/6">눈 운동 정보</h4>
              </div>
              <div className="mt-6 card-wrap">
                <KoreanTable dataParams={dataParams} />
                {/*lang === "ko" ? (
                  <KoreanTable dataParams={dataParams} />
                ) : (
                  <ForeignTable dataParams={dataParams} />
                )*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const KoreanTable = ({ dataParams }) => {
  const { name, typeName, image, keyword, expert, time, purpose, method } =
    dataParams;
  return (
    <table className="app-table w-full">
      <tr>
        <td className="th w-1/6">운동명</td>
        <td>{name}</td>
      </tr>
      <tr>
        <td className="th w-1/6">구분</td>
        <td> {typeName} </td>
      </tr>
      <tr>
        <td className="th w-1/6">대표 이미지</td>
        <td>
          <div className="form-file">
            <img src={image} className="profile_preview" />
          </div>
        </td>
      </tr>
      <tr>
        <td className="th w-1/6">간단 키워드</td>
        <td>{keyword}</td>
      </tr>
      <tr>
        <td className="th w-1/6">제작자</td>
        <td>{`${expert?.name} / ${expert?.tel} / ${expert?.address} ${expert?.detailAddress}`}</td>
      </tr>
      <tr>
        <td className="th w-1/6">운동 달성 점수 (초단위)</td>
        <td>{time}</td>
      </tr>
      <tr>
        <td className="th" colSpan="2">
          운동 목적
        </td>
      </tr>
      <tr>
        <td colSpan="2">
          <div
            className="ck-content"
            introeditable="true"
            dangerouslySetInnerHTML={{ __html: purpose }}
          />
        </td>
      </tr>
      <tr>
        <td className="th" colSpan="2">
          운동 방법
        </td>
      </tr>
      <tr>
        <td colSpan="2" style={{ borderBottom: "none" }}>
          <div
            className="ck-content"
            introeditable="true"
            dangerouslySetInnerHTML={{ __html: method }}
          />
        </td>
      </tr>
    </table>
  );
};

const ForeignTable = ({ dataParams }) => {
  const { name, keyword, purpose, method } = dataParams;
  return (
    <table className="app-table w-full">
      <tr>
        <td className="th w-1/6">운동명</td>
        <td>{name}</td>
      </tr>
      <tr>
        <td className="th w-1/6">간단 키워드</td>
        <td>{keyword}</td>
      </tr>
      <tr>
        <td className="th" colSpan="2">
          운동 목적
        </td>
      </tr>
      <tr>
        <td colSpan="2">
          <div
            className="ck-content"
            introeditable="true"
            dangerouslySetInnerHTML={{ __html: purpose }}
          />
        </td>
      </tr>
      <tr>
        <td className="th" colSpan="2">
          운동 방법
        </td>
      </tr>
      <tr>
        <td colSpan="2" style={{ borderBottom: "none" }}>
          <div
            className="ck-content"
            introeditable="true"
            dangerouslySetInnerHTML={{ __html: method }}
          />
        </td>
      </tr>
    </table>
  );
};
//#endregion

export default DetailPage;
