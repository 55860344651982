import React, { useState } from "react";
import PropTypes from "prop-types";
import { ServiceCenter } from "graphql/query/select";
import { Link, useHistory } from "react-router-dom";
import { FormatDate } from "components/FormatDate";
import useSettings from "stores/settings";
import { Button, Modal } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_SERVICE_CENTER } from "graphql/gql/delete";

const DetailPage = ({ match }) => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }
  
  const { id } = match.params;

  let items;
  if (id !== undefined && id !== "undefined") {
    const { result } = ServiceCenter(id);
    items = result;
  }

  const [mdShow, setMdShow] = useState(false);
  const goBack = () => {
    history.goBack();
  };

  const [noticeDelete] = useMutation(DELETE_SERVICE_CENTER, {
    update: (proxy, result) => {
      goBack();
    },
    variables: {
      id,
    },
  });

  const handleDelete = () => {
    setMdShow(false);

    noticeDelete();
  };

  return (
    <>
      {items && (
        <div>
          {/* breadcrumb */}
          <div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">고객센터</li>
                <Link to="/service-center/notice-table" className="breadcrumb-item active mt-1" aria-current="page">
                  공지사항 목록
                </Link>
                <Link to={`/service-center/notice-detail$/{id}`} className="breadcrumb-item active mt-1" aria-current="page">
                  공지사항 정보
                </Link>
              </ol>
            </nav>
          </div>
          <InfoForm items={items} />
          <div className="col-12 grid-margin mt-4">
            <div className="row justify-between">
              <button className="btn btn-danger" onClick={() => setMdShow(true)}>
                삭제하기
              </button>
              <Link className="btn btn-primary" to={`/service-center/notice-edit/${items.id}`}>
                수정하기
              </Link>
            </div>
            {/* Modal Starts  */}
            <Modal show={mdShow} onHide={() => setMdShow(false)} aria-labelledby="example-modal-sizes-title-md">
              <Modal.Header closeButton>
                <Modal.Title>해당 내용을 삭제하시겠습니까?</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <p>삭제를 원하시면 삭제 버튼을 눌러주세요.</p>
              </Modal.Body>

              <Modal.Footer className="fleex-wrap">
                <Button variant="danger m-2" onClick={handleDelete}>
                  삭제
                </Button>
                <Button variant="primary m-2" onClick={() => setMdShow(false)}>
                  취소
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Modal Ends */}
          </div>
        </div>
      )}
    </>
  );
};

const InfoForm = ({ items }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between card-title-wrap">
              <h4 className="card-title">공지사항 정보</h4>
            </div>
            <div className="card-wrap">
              <table className="app-table bg-white w-full">
                <tr>
                  <td className="th">구분</td>
                  <td>{items.commonCode.name}</td>
                  <td className="th">등록날짜</td>
                  <td>{FormatDate(new Date(parseInt(items.createAt, 10)))}</td>
                </tr>
                <tr>
                  <td className="th">제목</td>
                  <td colSpan="3">
                    {items.title}
                  </td>
                </tr>
              </table>

              <div className="w-full pt-4 pl-4 pr-4 ck-content">
                <div className="w-full">
                  <div className="table-content table-responsive  cart-table-content  w-full html-content" dangerouslySetInnerHTML={{ __html: items.content }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailPage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DetailPage;
