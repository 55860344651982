import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Tabs, Tab} from "react-bootstrap";
import { AllCommonCodes, AllCompanyInfos } from "graphql/query/select.js";
import { UPDATE_COMPANY_INFO } from "graphql/gql/update.js";
import { SimpleNotification } from "components/SimpleNotification";
import { useMutation } from "@apollo/react-hooks";
import { hasValue, LoadingSpinner } from "components/common.js";
import { CREATE_COMPANY_INFO } from "graphql/gql/insert";
import useSettings from "stores/settings";

const EditPage = () => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const [group, setGroup] = useState();
  const [type, setType] = useState('P');
  const companyInfos = AllCompanyInfos({ where: { type }}).result;
  const tabsParam = {
    where : {
      type: "L",
      isUse: "Y",
      lang: "ko"
    },
    order : {
      orderByKey : "priority",
      orderByValue : "asc",
    }
  }

  const tabs = AllCommonCodes(tabsParam).result; //한 영 일 중

  if (!companyInfos || !tabs) return <LoadingSpinner/>;

  return (
    <>
      <div>
        <div>
          <nav aria-label="breadcrumb" role="navigation">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">회사정보 관리</li>
              <Link
                to="/company/company-info-detail"
                className="breadcrumb-item active mt-1"
                aria-current="page"
              >
                기업정보
              </Link>
              <Link
                to="/company/company-info-edit"
                className="breadcrumb-item active mt-1"
                aria-current="page"
              >
                기업정보 수정
              </Link>
            </ol>
          </nav>
        </div>
        <Tabs id="partner-info-tabs">
          {tabs.map((tab) => (
            <Tab eventKey={tab.value} title={tab.name}>
              <EditInputPage 
              lang={tab.value} 
              info={companyInfos.filter((item) => item.lang === tab.value)[0]}
              group={group}
              setGroup={setGroup}
              type={type}
              />
            </Tab>
          ))}
        </Tabs>
      </div>
    </>
  );
};

const EditInputPage = ({ info, lang, group, setGroup, type }) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  const [id, setId] = useState(info?.id);
  const [name, setName] = useState(info?.name);
  const [number, setNumber] = useState(info?.number);
  const [representative, setRepresentative] = useState(info?.representative);
  const [tel, setTel] = useState(info?.tel);
  const [person, setPerson] = useState(info?.person);
  const [address, setAddress] = useState(info?.address);
  const [email, setEmail] = useState(info?.email);
  const [isSaved, setIsSaved] = useState(info ? true : false);

  useEffect(() => { 
    if (info && lang === 'ko') {
      if (info.group) 
        setGroup(info.group);
      else {
        alert("죄송합니다. 오류가 발생하였습니다. 관리자에게 문의해주세요.");
      }
    }
  }, [])

  //#region create
  const [companyInfoCreate, { data: createData }] = useMutation(
    CREATE_COMPANY_INFO,
    {
      onError(err) {
        SimpleNotification({
          message: err.message || "Error",
        });
      },
    }
  );

  useEffect(() => {
    if (createData?.createCompanyInfo) {
      setId(createData.createCompanyInfo.id);
      setIsSaved(true);
      SimpleNotification({ title: "success", type: "success", message: "성공적으로 등록하였습니다." });
    }
  }, [createData]);

  const handleCreate = async () => {
    if (!([name, number, representative, tel, person, address, email].every(hasValue))) {
      return SimpleNotification({ title: "Error", type: "warning", message: "내용을 입력해주세요." });
    }

    // 데이터 저장
    companyInfoCreate({
      variables: {
        data : {
          type,
          group,
          name,
          number,
          representative,
          tel,
          person,
          address,
          email,
          lang,
          priority: 1,
        }
      },
    });
  };
  //#endregion

  //#region update
  const updateCompanyInfo = () => {
    if (!id) {
      alert("죄송합니다. 오류가 발생하였습니다.");
      goBack();
      return;
    }

    if (!([name, number, representative, tel, person, address, email].every(hasValue))) {
      SimpleNotification({
        title: "Error", type: "warning",
        message: "내용을 입력해주세요.",
      });
      return;
    }
    
    const variables = {
      where: { id },
      data: { name, number, representative, tel, person, address, email }
    }

    companyInfoUpdate({ variables });
  };

  const [companyInfoUpdate, { data: updateData }] = useMutation(UPDATE_COMPANY_INFO, {
      onError(err) {
        SimpleNotification({
          message: err.message || "Error",
        });
      },
    }
  );

  useEffect(() => {
    if (updateData?.updateCompanyInfo?.id) {
      SimpleNotification({title: "success", type: "success", message: "성공적으로 수정하였습니다." });
    }
  }, [updateData]);
  //#endregion

  return (
    <>
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="flex justify-between card-title-wrap">
                  <h4 className="card-title">기업정보 수정</h4>
                </div>
                <div className="card-wrap">
                  <table className="app-table bg-white w-full">
                    <tr>
                      <td className="th w-1/12">상호</td>
                      <td>
                        <Form.Control
                          type="text"
                          className="form-control w-5/12"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </td>
                      <td className="th w-1/12">사업자등록번호</td>
                      <td>
                        <Form.Control
                          type="text"
                          className="form-control w-5/12"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="th w-1/12">대표자명</td>
                      <td>
                        <Form.Control
                          type="text"
                          className="form-control w-5/12"
                          value={representative}
                          onChange={(e) => setRepresentative(e.target.value)}
                        />
                      </td>
                      <td className="th w-1/12">대표전화번호</td>
                      <td>
                        <Form.Control
                          type="text"
                          className="form-control w-5/12"
                          value={tel}
                          onChange={(e) => setTel(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="th">담당자</td>
                      <td colspan="3">
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={person}
                          onChange={(e) => setPerson(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="th">사업소재지</td>
                      <td colspan="3">
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="th">제휴문의</td>
                      <td colspan="3">
                        <Form.Control
                          type="text"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 btnbar">
          <div className="row foot-edit">
            <button className="btn btn-warning" onClick={goBack}>
              취소하기
            </button>
            {
            !isSaved? (
              <button type="button" className="btn btn-primary" onClick={handleCreate}>
                저장하기
              </button>  
            ) : (
              <button type="button" className="btn btn-primary" onClick={updateCompanyInfo}>
                수정하기
              </button>  
            )
          }
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPage;
