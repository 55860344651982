import { useQuery } from "@apollo/react-hooks";
import {
  STORE,
  ALL_STORES,
  STORE_COUNT,
  ALL_MEMBERS,
  MEMBER,
  ALL_PRODUCTS,
  PRODUCT,
  ALL_PRODUCT_REGISTERS,
  PRODUCT_REGISTER,
  ALL_USERS,
  USER,
  ALL_RESERVATIONS,
  RESERVATION,
  ALL_SERVICE_CENTERS,
  SERVICE_CENTER,
  ALL_QNAS,
  QNA,
  ALL_NOTICE_BOARDS,
  NOTICE_BOARD,
  ALL_BANNERS,
  BANNER,
  APP_QNA,
  ALL_APP_QNAS,
  ALL_COUPONS,
  ORDER,
  ALL_LENS_RESERVATIONS,
  LENS_RESERVATION,
  ALL_PIXELROS,
  PIXELRO_COUNT,
  PIXELRO,
  FILTER_SETTING_KEY_VALUE,
  ALL_COMMON_CODES,
  ALL_COMPANY_INFOS,
  COMPANY_INFO,
  EXPERT_COUNT,
  ALL_EXPERTS,
  EXPERT,
  ALL_EXERCISES,
  EXERCISE,
  ALL_EXPERTS_FOR_EXERCISE,
  ALL_EXERCISES_DETAIL,
  USER_EYE_CHECK_RESULTS,
  ALL_SEARCHS,
} from "../gql/select";

/* 관리자 전체 목록 가져오기 */
export const AllPixelros = () => {
  const { error, data } = useQuery(ALL_PIXELROS, {
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.allPixelros,
    error: error,
  };
};

/* 관리자 Count */
export const PixelroCount = () => {
  const { error, data } = useQuery(PIXELRO_COUNT, {
    fetchPolicy: "network-only"
  });
  return {
    result: data && data.pixelroCount,
    error: error
  };
}


/* 관리자 정보 가져오기 */
export const Pixelro = (id) => {
  const { error, data } = useQuery(PIXELRO, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.pixelro,
    error: error,
  };
};

/* Store 정보 가져오기 */
export const Store = (id) => {
  const { error, data } = useQuery(STORE, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.store,
    error: error,
  };
};

/* Store 전체 목록 가져오기 */
export const AllStores = () => {
  const { error, data } = useQuery(ALL_STORES, {
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.allStores,
    error: error,
  };
};

/* Store 카운트 가져오기 */
export const StoreCount = ({withdraw, place}) => {
  const { error, data } = useQuery(STORE_COUNT, {
    variables: { withdraw, place },
    fetchPolicy: "network-only"
  });
  return {
    result: data && data.storeCount,
    error: error
  }
}

/* 회원사 전체 목록 가져오기 */
export const AllMembers = () => {
  const { error, data } = useQuery(ALL_MEMBERS, {
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.allMembers,
    error: error,
  };
};

/* Member 정보 가져오기 */
export const Member = (id) => {
  const { error, data } = useQuery(MEMBER, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.member,
    error: error,
  };
};

/* 상품 전체 목록 가져오기 */
export const AllProducts = () => {
  const { error, data } = useQuery(ALL_PRODUCTS, {
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.allProducts,
    error: error,
  };
};

/* 상품 정보 가져오기 */
export const Product = (id) => {
  const { error, data } = useQuery(PRODUCT, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.product,
    error: error,
  };
};

/* 상품 등록 전체 목록 가져오기 */
export const AllProductRegisters = () => {
  const { error, data } = useQuery(ALL_PRODUCT_REGISTERS, {
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.allProductRegisters,
    error: error,
  };
};

/* 상품 등록 정보 가져오기 */
export const ProductRegister = (id) => {
  const { error, data } = useQuery(PRODUCT_REGISTER, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.productRegister,
    error: error,
  };
};

/* 앱 사용자 전체 목록 가져오기 */
export const AllUsers = ({ variables }) => {
  const { error, data } = useQuery(ALL_USERS, {
    fetchPolicy: "network-only",
    variables
  });
  return {
    result: data && data.allUsers,
    error: error,
  };
};

/* 앱 사용자 정보 가져오기 */
export const User = (id) => {
  const { error, data } = useQuery(USER, {
    variables: { id },
    fetchPolicy: "network-only"
  });  
  return {
    result: data && data.user,
    error: error,
  };
};

/* 주문 정보 가져오기 */
export const Order = (id) => {
  const { error, data } = useQuery(ORDER, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.order,
    error: error,
  };
};

/* 예약 관리 전체 목록 가져오기 */
export const AllReservations = () => {
  const { error, data } = useQuery(ALL_RESERVATIONS, {
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.allReservations,
    error: error,
  };
};

/* 예약 관리 정보 가져오기 */
export const Reservation = (id) => {
  const { error, data } = useQuery(RESERVATION, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.reservation,
    error: error,
  };
};

/* 공지사항 & FAQ 전체 목록 가져오기 */
export const AllServiceCenter = ({ where }) => {
  const { error, data } = useQuery(ALL_SERVICE_CENTERS, {
    variables: { where },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.allServiceCenters,
    error: error,
  };
};

/* 공지사항 & FAQ 전체 목록 가져오기 */
export const AllLensReservations = () => {
  const { error, data } = useQuery(ALL_LENS_RESERVATIONS, {
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.allLensReservations,
    error: error,
  };
};

/* 공지사항 & FAQ 전체 목록 가져오기 */
export const LensReservation = (id) => {
  const { error, data } = useQuery(LENS_RESERVATION, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.lensReservation,
    error: error,
  };
};

/* 공지사항 & FAQ 정보 가져오기 */
export const ServiceCenter = (id) => {
  const { error, data } = useQuery(SERVICE_CENTER, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.serviceCenter,
    error: error,
  };
};

/* QNA 전체 목록 가져오기 */
export const AllQnas = () => {
  const { error, data } = useQuery(ALL_QNAS, {
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.allQnas,
    error: error,
  };
};

/* QNA 정보 가져오기 */
export const Qna = (id) => {
  const { error, data } = useQuery(QNA, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.qna,
    error: error,
  };
};

/* QNA 전체 목록 가져오기 */
export const AllAppQnas = ({ orderBy }) => {
  const { error, data } = useQuery(ALL_APP_QNAS, {
    fetchPolicy: "network-only",
    variables: { orderBy }
  });
  return {
    result: data && data.allAppQnas,
    error: error,
  };
};

/* QNA 정보 가져오기 */
export const AppQna = (id) => {
  const { error, data } = useQuery(APP_QNA, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.appQna,
    error: error,
  };
};

/* 게시판 관리 전체 목록 가져오기 */
export const AllNoticeBoards = () => {
  const { error, data } = useQuery(ALL_NOTICE_BOARDS, {
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.allNoticeBoards,
    error: error,
  };
};

/* 게시판 관리 정보 가져오기 */
export const NoticeBoard = (id) => {
  const { error, data } = useQuery(NOTICE_BOARD, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.noticeBoard,
    error: error,
  };
};

/* 배너 전체 목록 가져오기 */
export const AllBanners = () => {
  const { error, data } = useQuery(ALL_BANNERS, {
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.allBanners,
    error: error,
  };
};

/* 배너 정보 가져오기 */
export const Banner = (id) => {
  const { error, data } = useQuery(BANNER, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.banner,
    error: error,
  };
};

/* 쿠폰 전체 목록 가져오기 */
export const AllCoupons = () => {
  const { error, data } = useQuery(ALL_COUPONS, {
    fetchPolicy: "network-only",
  });
  return {
    result: data && data.allCoupons,
    error: error,
  };
};

/* 셋팅 값 가져오기 */
export const FilterSettingKeyValue = (value) => {
  const { error, data } = useQuery(FILTER_SETTING_KEY_VALUE, {
    fetchPolicy: "network-only",
    variables: { value },
  });
  return {
    result: data && data.filterSettingKeyValue,
    error: error,
  };
};

/* 공통코드 값 가져오기 */
export const AllCommonCodes = ({ where, order }) => {
  const { error, data } = useQuery(ALL_COMMON_CODES, {
    fetchPolicy: "network-only",
    variables: { where, order },
  });
  return {
    result: data && data.allCommonCodes,
    error: error
  };
};

// 회사정보 모두 가져오기
export const AllCompanyInfos = ({ where, order}) => {
  const { error, data } = useQuery(ALL_COMPANY_INFOS, {
    fetchPolicy: "network-only",
    variables: { where, order },
  });
  return {
    result: data && data.allCompanyInfos,
    error: error
  };
};

// 회사정보 가져오기
export const CompanyInfo = ({where}) => {
  const { error, data } = useQuery(COMPANY_INFO, {
    fetchPolicy: "network-only",
    variables: { where },
  });
  return {
    result: data && data.companyInfo,
    error: error
  };
};

/* 전문가 카운트 가져오기 */
export const ExpertCount = ({ isDelete, type, lang }) => {
  const { error, data } = useQuery(EXPERT_COUNT, {
    variables: { isDelete, type, lang },
    fetchPolicy: "network-only"
  });
  return {
    result: data && data.expertCount,
    error: error
  }
};

/* 전문가 가져오기 */
export const AllExperts = ({ where }) => {
  const { error, data } = useQuery(ALL_EXPERTS, {
    variables: { where },
    fetchPolicy: "network-only"
  });
  return {
    result: data && data.allExperts,
    error: error
  }
}

/* 전문가 가져오기 */
export const Expert = ({ where }) => {
  const { error, data } = useQuery(EXPERT, {
    variables: { where },
    fetchPolicy: "network-only"
  });
  return {
    result: data && data.expert,
    error: error
  }
}

/* 눈운동 */
export const AllExercises = ({ where, order }) => {
  const { error, data } = useQuery(ALL_EXERCISES, {
    variables: { where, order },
    fetchPolicy: "network-only"
  });
  return {
    result: data && data.allExercises,
    error: error
  }
}

/* 눈운동 */
export const Exercise = ({ where }) => {
  const { error, data } = useQuery(EXERCISE, {
    variables: { where },
    fetchPolicy: "network-only"
  });
  return {
    result: data && data.exercise,
    error: error
  }
}

export const AllExpertsForExercise = ({ lang }) => {
  const { error, data } = useQuery(ALL_EXPERTS_FOR_EXERCISE, {
    variables: { lang, isDelete:'N' },
    fetchPolicy: "network-only"
  });
  return {
    result: data && data.allExperts,
    error: error
  }
}

export const AllExerciseDetail = ({ group }) => {
  const { error, data } = useQuery(ALL_EXERCISES_DETAIL, {
    variables: { group },
    fetchPolicy: "network-only"
  });
  return {
    result: data && data.allExercises,
    error: error
  }
}

export const AllSearchs = ({ orderBy }) => {
  const { error, data } = useQuery(ALL_SEARCHS, {
    variables: { orderBy },
    fetchPolicy: "network-only"
  });
  return {
    result: data && data.allSearchs,
    error: error
  }
}