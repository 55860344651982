import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { useHistory, Link } from "react-router-dom";
import useSettings from "stores/settings";
import { ALL_USERS } from "graphql/gql/select";
import { FormatDateShort } from "components/FormatDate";
import { PhoneFormatter } from "components/PhoneFormatter";
import { useQuery } from "@apollo/react-hooks";
import { SimpleNotification } from "components/SimpleNotification";
import { LoadingSpinner } from "components/common";
import matchSorter from "match-sorter";

const ReactContentPage = () => {
  // 로그인 체크
  const settings = useSettings();
  const history = useHistory();
  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const [result, setResult] = useState();
  //page
  const [page, setPage] = useState(0);
  const { loading, error, data, fetchMore } = useQuery(ALL_USERS, {
    fetchPolicy: "network-only",
    variables: {
      where: { withdraw: false },
      orderBy: { createAt: "desc" },
      pagination: {
        take: 50,
        skip: 0,
      },
    },
  });

  useEffect(() => {
    if (data?.allUsers) {
      setResult(data.allUsers);
    }
  }, [data]);
  useEffect(() => {
    if (result && page === Math.ceil(result?.length / 10) - 1) {
      console.log((page + 1) * 10);
      fetchMore({
        variables: { pagination: { skip: (page + 1) * 10, take: 50 } },
        updateQuery: (previousResult, { fetchMoreResult, queryVariables }) => {
          // Don't do anything if there weren't any new items
          if (!fetchMoreResult || fetchMoreResult.allUsers.length === 0) {
            return previousResult;
          }

          return {
            // Concatenate the new feed results after the old ones
            allUsers: previousResult.allUsers.concat(fetchMoreResult.allUsers),
          };
        },
      });
    }
  }, [page]);

  if (error) {
    console.log(JSON.stringify(error));
    SimpleNotification({ type: "danger", message: error.message || "Error" });
  }

  const handleTelFormat = (data) => {
    const tel = PhoneFormatter(data.value);
    return tel;
  };

  if (loading || !result) return <LoadingSpinner />;

  return (
    <div>
      {/* breadcrumb */}
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">사용자 관리</li>
            <Link
              to="/app-users/user-table"
              className="breadcrumb-item active mt-1"
              aria-current="page"
            >
              회원 목록
            </Link>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">회원 목록</h4>
                <span className="card-sub">{`Total : ${result.length}`}</span>
              </div>
              <div className="row">
                <div className="col-12">
                  <div>
                    <ReactTable
                      data={result}
                      filterable
                      page={page}
                      onPageChange={(page) => setPage(page)}
                      defaultPageSize={10}
                      className="card-wrap"
                      columns={[
                        {
                          Header: "이메일",
                          id: "email",
                          accessor: (d) => d.email,
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: [(item) => item.email],
                            }),
                          filterAll: true,
                          Cell: (row) => (
                            <Link
                              to={`/app-users/user-detail/${row.original.id}`}
                              className="text-blue-700 font-medium"
                            >
                              {row.value}
                            </Link>
                          ),
                        },
                        {
                          Header: "닉네임",
                          id: "nick",
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: [(item) => item.nick],
                            }),
                          filterAll: true,
                          accessor: (d) => d.nick,
                        },
                        {
                          Header: "전화번호",
                          id: "tel",
                          accessor: (d) => d.tel,
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: [(item) => item.tel],
                            }),
                          filterAll: true,
                          Cell: handleTelFormat,
                        },
                        {
                          Header: "주소",
                          id: "gpsAddress",
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: [(item) => item.gpsAddress],
                            }),
                          filterAll: true,
                          accessor: (d) => d.gpsAddress,
                        },
                        {
                          Header: "가입날짜",
                          id: "createAt",
                          accessor: "createAt",
                          filterable: false,
                          Cell: (row) =>
                            `${FormatDateShort(
                              new Date(parseInt(row.value, 10))
                            )}`,
                        },
                        {
                          Header: "가입경로",
                          id: "sns",
                          accessor: "commonCode.name",
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: [(item) => item.sns],
                            }),
                          filterAll: true,
                          Cell: (row) => <div>{`${row.value}`}</div>,
                        },
                        {
                          Header: "최근접속일",
                          id: "loginAt",
                          accessor: "loginAt",
                          filterable: false,
                          Cell: (row) =>
                            `${FormatDateShort(
                              new Date(parseInt(row.value, 10))
                            )}`,
                        },
                        // {
                        //   Header: "",
                        //   id : 'edit',
                        //   accessor: "edit",
                        //   Filter: () => false,
                        //   Cell: (row) => (
                        //     <div className="edit-wrap">
                        //       <Link className="edit ti-pencil-alt" to={`/app-users/user-edit/${row.original.id}`}>
                        //         수정
                        //       </Link>
                        //     </div>
                        //   ),
                        // },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReactContentPage;
