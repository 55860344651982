import React, { useState } from "react";
import PropTypes from "prop-types";
import { ServiceCenter } from "graphql/query/select";
import { Link, useHistory } from "react-router-dom";
import { FormatDate } from "components/FormatDate";
import useSettings from "stores/settings";
import { Button, Modal } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_SERVICE_CENTER } from "graphql/gql/delete";

const DetailPage = ({ match }) => {
  const { id } = match.params;

  let items;
  if (id !== undefined && id !== "undefined") {
    const { result } = ServiceCenter(id);
    items = result;
  }

  const [mdShow, setMdShow] = useState(false);
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }
  const goBack = () => {
    history.goBack();
  };

  const [noticeDelete] = useMutation(DELETE_SERVICE_CENTER, {
    update: (proxy, result) => {
      goBack();
    },
    variables: {
      id,
    },
  });

  const handleDelete = () => {
    setMdShow(false);

    noticeDelete();
  };

  return (
    <>
      {items && (
        <div>
          <div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">고객센터</li>
                <Link to="/service-center/operation-table" className="breadcrumb-item active mt-1" aria-current="page">
                  운영정책
                </Link>
                <Link to={`/service-center/operation-detail/${id}`} className="breadcrumb-item active mt-1" aria-current="page">
                  운영정책 상세보기
                </Link>
              </ol>
            </nav>
          </div>
          <InfoForm items={items} />
          <div className="col-12 grid-margin mt-3">
            <div className="row justify-between mr-1">
              <button className="btn btn-danger mt-2" onClick={() => setMdShow(true)}>
                삭제하기
              </button>
              <Link className="btn btn-primary mr-2 mt-2 " to={`/service-center/operation-edit/${items.id}`}>
                수정하기
              </Link>
            </div>
            {/* Modal Starts  */}
            <Modal show={mdShow} onHide={() => setMdShow(false)} aria-labelledby="example-modal-sizes-title-md">
              <Modal.Header closeButton>
                <Modal.Title>해당 내용을 삭제하시겠습니까?</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <p>삭제를 원하시면 삭제 버튼을 눌러주세요.</p>
              </Modal.Body>

              <Modal.Footer className="fleex-wrap">
                <Button variant="danger m-2" onClick={handleDelete}>
                  삭제
                </Button>
                <Button variant="primary m-2" onClick={() => setMdShow(false)}>
                  취소
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Modal Ends */}
          </div>
        </div>
      )}
    </>
  );
};

const InfoForm = ({ items }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="mt-6 border-gray-200">
          <div>
            <h3 className="text-ml leading-6 font-medium text-black">▶︎ 운영정책</h3>
          </div>
          <div className="mt-6 sm:mt-5">
            <table className="shadow-sm bg-white w-full">
              <tr>
                <th className="bg-gray-200 border text-left px-8 py-2 text-sm">카테고리</th>
                <td className="border px-8 py-2 text-sm">{items.category}</td>
                <th className="bg-gray-200 border text-left px-8 py-2 text-sm">등록날짜</th>
                <td className="border px-8 py-2 text-sm">{FormatDate(new Date(parseInt(items.createAt, 10)))}</td>
              </tr>
              <tr>
                <th className="bg-gray-200 border text-left px-8 py-2 text-sm">제목</th>
                <td colSpan="3" className="border px-8 py-2 text-sm">
                  {items.title}
                </td>
              </tr>
            </table>
            <div className="row grid-margin">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">내용</h4>
                    <Content items={items} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Content = ({ items }) => {
  const settings = useSettings();
  const [content, setContent] = useState("");

  fetch(items.content)
    .then((res) => res.text())
    .then((body) => {
      setContent(body);
      settings.serviceCenterContent = body;
    });

  return (
    <div className="w-full">
      <div
        className="table-content table-responsive  cart-table-content w-full"
        contentEditable="false"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};
DetailPage.prototype = {
  id: PropTypes.string.isRequired,
};

export default DetailPage;
