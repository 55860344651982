import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { WITHDRAW_EXPERT } from "graphql/gql/update";
import { AllCommonCodes, AllExperts } from "graphql/query/select";
import { FormatDate } from "components/FormatDate";
import { Tabs, Tab, Modal, Button } from "react-bootstrap";
import { PhoneFormatter } from "components/PhoneFormatter";
import useSettings from "stores/settings";
import { SimpleNotification } from "components/SimpleNotification";
import Interests from "app/shared/Interest"
import { getImagePathForObject } from "components/common.js"

const DetailPage = ({ match }) => {
  // 로그인 체크
  const settings = useSettings();
  const history = useHistory();
  const goBack = () => {
    history.push("/app-users/expert-table");
  };
  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const { group } = match.params;
  if (!group) goBack();
  
  const [mdShow, setMdShow] = useState(false);

  const where = {
    type: "L",
    isUse: "Y",
    lang: "ko"
  };

  const order = {
    orderByKey : "priority",
    orderByValue : "asc",
  };
  
  const commonCodeL = AllCommonCodes({ where, order }).result;
  const expertData = AllExperts({ where : { group }}).result;

  const [withdrawExpert, { data: updateData }] = useMutation(WITHDRAW_EXPERT, {
    onError(err) {
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (updateData) {
      history.goBack();
    }
  }, [updateData]);

  const handleWithdrawModal = () => {
    setMdShow(false);
    withdrawExpert({
      variables: {
        group
      },
    });
  };

  if (!expertData || !commonCodeL) return <div />

  return (
    <>
        <div>
          {/* breadcrumb s */}
          <div>
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">사용자 관리</li>
                <Link
                  to="/app-users/expert-table"
                  className="breadcrumb-item active mt-1"
                  aria-current="page"
                >
                  전문가 목록
                </Link>
                <Link
                  to={`/app-users/expert-detail/${group}`}
                  className="breadcrumb-item active mt-1"
                  aria-current="page"
                >
                  전문가 정보
                </Link>
              </ol>
            </nav>
          </div>
          {/* breadcrumb e */}
          <div>
            <Tabs id="controlled-tab-example" defaultActiveKey={commonCodeL[0].value}>
              {commonCodeL.map(code => {
                return (
                  <Tab eventKey={code.value} title={code.name}>
                    <ExpertForm lang={code.value} group={group} expert={expertData.filter(ex => ex.lang === code.value)[0]}/>
                  </Tab>
                )
              })}
            </Tabs>
          </div>
          <div className="col-12 btnbar">
            <div className="row justify-between">
              <button
                className="btn btn-danger"
                onClick={() => setMdShow(true)}
              >
                삭제하기
              </button>
              <Link
                className="btn btn-primary"
                to={`/app-users/expert-edit/${group}`}
              >
                수정하기
              </Link>
            </div>
          </div>
          {/* Modal Starts  */}
          <Modal
            show={mdShow}
            onHide={() => setMdShow(false)}
            aria-labelledby="example-modal-sizes-title-md"
          >
            <Modal.Header closeButton>
              <Modal.Title>전문가 삭제</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>정말 삭제하시겠습니까?</p>
            </Modal.Body>

            <Modal.Footer className="flex-wrap">
              <Button
                variant="btn btn-primary m-2"
                onClick={handleWithdrawModal}
              >
                확인
              </Button>
              <Button
                variant="btn btn-light m-2"
                onClick={() => setMdShow(false)}
              >
                취소
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Modal Ends */}
        </div>
    </>
  );
};

const ExpertForm = ({ lang, group, expert }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="flex justify-between card-title-wrap">
          <h4 className="card-title">전문가 정보</h4>
        </div>

        <InfoForm lang={lang} group={group} expert={expert} />
      </div>
    </div>
  );
};

const InfoForm = ({ lang, group, expert }) => {
  const imagePath = getImagePathForObject(expert)
  const selectedInterestCode = expert ? Array.from(expert.interests, interest => interest.value) : [];

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="">
          <div className="expert-profile">
            <img
              className="profile-img"
              src={imagePath}
            />
          </div>
          <div className="mt-6 card-wrap">
            <table className="app-table w-full">
              <tr>
                <td className="th">전문가명</td>
                <td colSpan="3" className="">
                  {expert?.name}
                </td>
              </tr>
              <tr>
                <td className="th w-2/12">분류</td>
                <td className="w-4/12">{expert?.commonCode.name}</td>
                <td className="th w-2/12">연락처</td>
                <td className="w-4/12">{expert ? PhoneFormatter(expert.tel) : ""}</td>
              </tr>
              <tr>
                <td className="th">주소</td>
                <td colSpan="3" className="">
                  <div className="mb-1">{expert?.post}</div>
                  <div>
                    {expert?.address} {expert?.detailAddress}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="th">등록날짜</td>
                <td colSpan="3" className="">
                  {expert ? FormatDate(new Date(parseInt(expert.createAt, 10))) : ""}
                </td>
              </tr>
              <tr>
                <td className="th w-2/12">업체명</td>
                <td className="w-4/12">{expert?.companyName}</td>
                <td className="th w-2/12">영업시간</td>
                <td className="w-4/12">{expert?.opentime}</td>
              </tr>
              <tr>
                <td className="th">등록 URL</td>
                <td colSpan="3" className="">
                  {expert?.site}
                </td>
              </tr>
              <tr>
                <td className="th">입력 태그</td>
                <td colSpan="3" className="">
                  <Interests lang="ko" selectMode={false} selectedInterestCode={selectedInterestCode} />
                </td>
              </tr>
              <tr>
                <td className="th">소개</td>
                <td colSpan="3">
                <div className="ck-content"
                  introeditable="true"
                  dangerouslySetInnerHTML={{ __html: expert?.intro }}
                />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailPage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DetailPage;
