import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { AllCommonCodes, AllCompanyInfos } from "graphql/query/select.js";
import { LoadingSpinner } from "components/common";
import { Tabs, Tab } from "react-bootstrap";
import useSettings from "stores/settings";

const DetailPage = () => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const companyInfo = AllCompanyInfos({where : { type:"P" }}).result;
  const tabsParam = {
    where : {
      type: "L",
      isUse: "Y",
      lang: "ko"
    },
    order : {
      orderByKey : "priority",
      orderByValue : "asc",
    }
  }

  const tabs = AllCommonCodes(tabsParam).result; //한 영 일 중

  if (!companyInfo || !tabs) return <LoadingSpinner />

  return (
    <>
      <div>
        <div>
          <nav aria-label="breadcrumb" role="navigation">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">회사정보 관리</li>
              <Link to="/company/company-info-detail" className="breadcrumb-item active mt-1" aria-current="page">
                기업정보
              </Link>
            </ol>
          </nav>
        </div>
        <Tabs id="partner-info-tabs">
          {tabs.map((tab) => (
            <Tab eventKey={tab.value} title={tab.name}>
              <InfoForm lang={tab.value} info={companyInfo.filter((item) => item.lang === tab.value)[0]}/>
            </Tab>
          ))}
        </Tabs>
        <div className="col-12 grid-margin mt-4">
          <div className="row justify-between">
            <Link className="btn btn-primary ml-auto" to={`/company/company-info-edit`}>
              수정하기
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const InfoForm = ({info}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between card-title-wrap">
              <h4 className="card-title">기업 정보</h4>
            </div>
            <div className="card-wrap">
              <table className="app-table bg-white w-full">
                <tr>
                  <td className="th w-1/12">상호</td>
                  <td className="w-5/12">{info?.name}</td>
                  <td className="th w-1/12">사업자등록번호</td>
                  <td className="w-5/12">{info?.number}</td>
                </tr>
                <tr>
                  <td className="th w-1/12">대표자명</td>
                  <td className="w-5/12">{info?.representative}</td>
                  <td className="th w-1/12">대표전화번호</td>
                  <td className="w-5/12">{info?.tel}</td>
                </tr>
                <tr>
                  <td className="th">담당자</td>
                  <td colspan="3">{info?.person}</td>
                </tr>
                <tr>
                  <td className="th">사업소재지</td>
                  <td colspan="3">{info?.address}</td>
                </tr>
                <tr>
                  <td className="th">제휴문의</td>
                  <td colspan="3">{info?.email}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
