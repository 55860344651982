import ApolloClient from "apollo-client";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";

const link =
  process.env.NODE_ENV === "production"
    ? createUploadLink({ uri: "/gql/graphql" })
    : createUploadLink({ uri: "http://localhost:4002/graphql" }); //createUploadLink({ uri: "https://pixelro.nenoons.com/gql/graphql" });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  // console.log(token);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allUsers: {
            keyArgs: ["id", "createAt"],
            merge(existing, incoming, { args }) {
              // this is to find if there is filters args in query, if so, return incoming results
              if (
                args.filters &&
                Object.entries(args.filters).find(
                  ([key, value]) => value !== null && value !== undefined
                )
              ) {
                return incoming;
              }
              if (!existing) {
                return incoming;
              }
              const existingNodes = existing.nodes || [];
              const incomingNodes = incoming.nodes || [];
              const nodes = [...existingNodes, ...incomingNodes];
              return { ...incoming, nodes };
            },
          },
        },
      },
    },
  }),
  onError: ({ networkError, graphQLErrors }) => {
    console.log("graphQLErrors", graphQLErrors);
    console.log("networkError", networkError);
  },
});

export default client;
