/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Button, Modal } from "react-bootstrap";
import { SimpleNotification } from "components/SimpleNotification";
import { NOTICE_BOARD } from "graphql/gql/select";
import { DELETE_NOTICE_BOARD } from "graphql/gql/delete";
import Interests from "app/shared/Interest"  
import { getImagePathForObject } from "components/common";
import useSettings from "stores/settings";

const DetailPage = ({ match }) => {
  const settings = useSettings();
  const history = useHistory();

  if (!settings.isLogin) {
    history.push("/adminIn");
  }

  const { id } = match.params;

  //  매거진
  const { loading, error, data } = useQuery(NOTICE_BOARD, {
    fetchPolicy: "network-only",
    variables: { id },
  });
  if (loading) return <p>Loading...</p>;
  if (error) {
    SimpleNotification({
      message: error.message || "Error",
    });
    return "";
  }

  let items;
  if (data) {
    items = data.noticeBoard;
  }

  if (!items) return <div />;
  return (
    <>
      {/* breadcrumb */}
      <div>
        <nav aria-label="breadcrumb" role="navigation">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">게시판 관리</li>
            <Link to="/notice-board/magazine-table" className="breadcrumb-item active mt-1" aria-current="page">
              매거진 목록
            </Link>
            <Link to={`/notice-board/magazine-detail/${id}`} className="breadcrumb-item active mt-1" aria-current="page">
              매거진 정보
            </Link>
          </ol>
        </nav>
      </div>
      <Content items={items} />
    </>
  );
};

DetailPage.propTypes = {
  id: PropTypes.string.isRequired,
};

const Content = ({ items }) => {
  const [mdShow, setMdShow] = useState(false);

  const history = useHistory();
  const goBack = () => {
    history.push("/notice-board/magazine-table");
  };

  const [noticeBoardDelete, { data: deleteData }] = useMutation(DELETE_NOTICE_BOARD, {
    onError(err) {
      console.log(err);
      SimpleNotification({
        message: err.message || "Error",
      });
    },
  });

  useEffect(() => {
    if (deleteData !== undefined) {
      goBack();
    }
  }, [deleteData]);

  const imagePath = getImagePathForObject(items);

  const handleDelete = () => {
    setMdShow(false);
    noticeBoardDelete({ variables: { id: items.id } });
  };

  const selectedInterestCode = Array.from(items.interests, interest => interest.value);

  return (
    <>
      <div className="col-12 my-4">
        <div className="row justify-between">
          <button className="btn btn-danger" onClick={() => setMdShow(true)}>
            삭제하기
          </button>
          <Link
            className="btn btn-primary"
            to={`/notice-board/magazine-edit/${items.id}`}
          >
            수정하기
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="flex justify-between card-title-wrap">
                <h4 className="card-title">매거진 정보</h4>
              </div>
              {/* 대표 이미지 */}
              <div className="card-wrap">
                <img className="mgz-detail-img" src={imagePath} alt="" />
              </div>

              {/* 영상 영역 :: 입력 없으면 display: none 필요 */}
              <div
                className="card-wrap"
                style={items.link1 ? undefined : { display: "none" }}
              >
                <YoutubeLink link={items.link1} />
              </div>
              {/* 내용 */}
              <div className="card-wrap">
                <table className="app-table bg-white w-full">
                  <tr>
                    <td className="th w-1/6">제목</td>
                    <td colSpan="3">{items.title}</td>
                  </tr>
                  <tr>
                    <td className="th w-1/6">입력태그</td>
                    <td colSpan="3">
                      <Interests
                        lang="ko"
                        selectMode={false}
                        selectedInterestCode={selectedInterestCode}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <div className="w-full">
                        <div
                          className="table-intro table-responsive  cart-table-intro w-full bg-white html-content ck-content"
                          introeditable="true"
                          dangerouslySetInnerHTML={{ __html: items.content }}
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 grid-margin mt-4">
        <div className="row justify-between">
          <button className="btn btn-danger" onClick={() => setMdShow(true)}>
            삭제하기
          </button>
          <Link
            className="btn btn-primary"
            to={`/notice-board/magazine-edit/${items.id}`}
          >
            수정하기
          </Link>
        </div>
        <Modal
          show={mdShow}
          onHide={() => setMdShow(false)}
          aria-labelledby="example-modal-sizes-title-md"
        >
          <Modal.Header closeButton>
            <Modal.Title>해당 내용을 삭제하시겠습니까?</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>삭제를 원하시면 삭제 버튼을 눌러주세요.</p>
          </Modal.Body>

          <Modal.Footer className="fleex-wrap">
            <Button variant="danger m-2" onClick={handleDelete}>
              삭제
            </Button>
            <Button variant="primary m-2" onClick={() => setMdShow(false)}>
              취소
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

/*유투브 링크*/
const YoutubeLink = ({ link }) => {

  function youtubeId(url) {
    let tag = "";
    if (url) {
      let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      const matchs = url.match(regExp);
      if (matchs) {
        tag +='https://www.youtube.com/embed/' + matchs[7];
      }
      return tag;
    }
  }

  return (
    <div className="movie-container">
      <iframe src={youtubeId(link)} frameborder='0' allowfullscreen></iframe>
    </div>
  );
};

export default DetailPage;
